import { ArrayElement } from '@ac/library-utils/dist/declarations';
import {
  ArrayValidationErrors,
  ValidationSchema,
  ValidationStatuses,
} from '@ac/react-infrastructure';

import { GeneralSettingsStorage } from 'store/settings/interfaces';
import { createArrayValidator } from 'utils/form';
import { FormProperties } from 'views/RegistrationCardEditPersonal/types';

import {
  AddressValidationSchema,
  addressValidationSchema,
} from './addressValidationSchema';
import {
  DocumentValidationSchema,
  documentValidationSchema,
} from './documentValidationSchema';
import {
  EmailValidationSchema,
  emailValidationSchema,
} from './emailValidationSchema';
import {
  PersonalDetailsValidationSchema,
  personalDetailsValidationSchema,
} from './personalDetailsValidationSchema';
import {
  PhoneValidationSchema,
  phoneValidationSchema,
} from './phoneValidationSchema';

export type EditPersonalValidationSchema = ValidationStatuses & {
  personalDetails: PersonalDetailsValidationSchema;
  addresses?: ArrayValidationErrors<ArrayElement<AddressValidationSchema>>;
  emails?: ArrayValidationErrors<ArrayElement<EmailValidationSchema>>;
  mobiles?: ArrayValidationErrors<ArrayElement<PhoneValidationSchema>>;
  phones?: ArrayValidationErrors<ArrayElement<PhoneValidationSchema>>;
  documents: ArrayValidationErrors<ArrayElement<DocumentValidationSchema>>;
};

export const prepareEditPersonalValidationSchema = (
  businessDate: string,
  generalSettings?: GeneralSettingsStorage
): ValidationSchema<EditPersonalValidationSchema> => ({
  personalDetails: personalDetailsValidationSchema(businessDate),
  ...(generalSettings?.DISPLAY_ADDRESS
    ? { addresses: createArrayValidator(addressValidationSchema()) }
    : undefined),
  ...(generalSettings?.DISPLAY_EMAIL
    ? { emails: createArrayValidator(emailValidationSchema()) }
    : undefined),
  ...(generalSettings?.DISPLAY_MOBILE
    ? {
        mobiles: createArrayValidator(
          phoneValidationSchema(FormProperties.mobiles)
        ),
      }
    : undefined),
  ...(generalSettings?.DISPLAY_PHONE
    ? {
        phones: createArrayValidator(
          phoneValidationSchema(FormProperties.phones)
        ),
      }
    : undefined),
  documents: createArrayValidator(documentValidationSchema(businessDate)),
});
