import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { paths } from 'configs';
import { getAreSettingsInitialized } from 'store/settings/selectors';

export const requireSettingsInitialized = <
  Props extends Record<string, unknown>
>(
  WrappedComponent: React.ComponentType<Props>
): FC<Props> => {
  const SettingsInitializationValidator = (props: Props): JSX.Element => {
    const areSettingsInitialized = useSelector(getAreSettingsInitialized);

    if (!areSettingsInitialized) {
      return <Navigate to={paths.SETUP} replace />;
    }

    return <WrappedComponent {...props} />;
  };

  return SettingsInitializationValidator;
};
