import { useCallback, useMemo } from 'react';
import {
  generatePath,
  Location,
  matchPath,
  PathPattern,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { paths } from 'configs';

import { SameDevicePathParams } from 'types/pathParams';
import { BaseObject } from 'types/shared';

interface GoToOptions {
  replace?: boolean;
  params?: BaseObject<string>;
}

interface Router<Params> {
  location: Location;
  params: Params;
  isAnySameDevicePath: boolean;
  isCurrentPath: (path: string, options?: Omit<PathPattern, 'path'>) => boolean;
  getSerializedPath: (path: string, params?: BaseObject<string>) => string;
  goTo: (path: string, options?: GoToOptions) => void;
  goBack: () => void;
}

export const useRouter = <
  Params extends { [K in keyof Params]?: string }
>(): Router<Params> => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathParams = useParams() as Readonly<SameDevicePathParams & Params>;

  const isCurrentPath = useCallback(
    (path: string, options?: Omit<PathPattern, 'path'>): boolean => {
      return Boolean(
        matchPath(
          {
            path,
            caseSensitive: true,
            end: true,
            ...options,
          },
          location.pathname
        )
      );
    },
    [location]
  );

  const isAnySameDevicePath = useMemo((): boolean => {
    return isCurrentPath(paths.SAME_DEVICE_ENTRY_PATH, {
      caseSensitive: false,
      end: false,
    });
  }, [isCurrentPath]);

  const getSerializedPath = useCallback(
    (path: string, params?: BaseObject<string>): string => {
      const { cardId, propertyId } = pathParams;
      const isNestedRoutePath = !path.startsWith('/');

      const allParams = {
        cardId,
        propertyId,
        ...params,
      };

      let entryPathPattern = '';

      if (isNestedRoutePath) {
        entryPathPattern = isAnySameDevicePath
          ? `${paths.SAME_DEVICE_ENTRY_PATH}/`
          : `${paths.EXTERNAL_DEVICE_ENTRY_PATH}/`;
      }

      return generatePath(`${entryPathPattern}${path}`, allParams);
    },
    [pathParams, isAnySameDevicePath]
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const goTo = useCallback(
    (path: string, options: GoToOptions = {}): void => {
      const { params, replace } = options;

      navigate(getSerializedPath(path, params), {
        replace,
      });
    },
    [navigate, getSerializedPath]
  );

  return useMemo<Router<Params>>(
    () => ({
      location,
      params: pathParams,
      isAnySameDevicePath,
      isCurrentPath,
      getSerializedPath,
      goBack,
      goTo,
    }),
    [
      getSerializedPath,
      goBack,
      goTo,
      isCurrentPath,
      isAnySameDevicePath,
      location,
      pathParams,
    ]
  );
};
