import { createSelector } from 'reselect';

import { SelectOption } from '@ac/kiosk-components';

import { KioskDevice, KioskProperty } from 'api/KioskApi/entries';
import { getCurrentDeviceId } from 'store/app/selectors';
import { Store } from 'store/types';

export const getHasLoginStateErrors = (state: Store): boolean =>
  !!state.loginView.errors.length;

export const getFetchingPropertiesState = (state: Store): boolean =>
  state.loginView.fetching.properties;

export const getFetchingDeviceState = (state: Store): boolean =>
  state.loginView.fetching.devices;

export const getPropertyList = (state: Store): KioskProperty[] =>
  state.loginView.properties;

export const getDeviceList = (state: Store): KioskDevice[] =>
  state.loginView.devices;

export const getPropertyOptions = createSelector(
  [getPropertyList],
  (properties: KioskProperty[]): SelectOption[] => {
    return properties.map((property: KioskProperty) => ({
      title: property.name,
      value: property.id,
    }));
  }
);

export const getDeviceOptions = createSelector(
  [getDeviceList, getCurrentDeviceId],
  (
    devices: KioskDevice[],
    currentDeviceId: string | undefined
  ): SelectOption[] => {
    return devices
      .filter((device: KioskDevice) => device.description)
      .map((device: Required<KioskDevice>) => ({
        title: device.description,
        value: device.id,
        disabled: device.isOnline && device.id !== currentDeviceId,
      }));
  }
);
