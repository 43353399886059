import i18next from 'i18next';

import { isDefined } from '@ac/library-utils/dist/utils';

import { KioskAddress } from 'api/KioskApi/entries';
import { AddressSection } from 'store/settings/interfaces/settingTypes/sectionConfiguration';

import { ProfilePresentationDataElement } from '../types';

interface AddressVisibilityConfig {
  fieldConfiguration?: AddressSection;
  showOnlyPrimary?: boolean;
}

const mapPrimaryAddress = (
  addresses?: KioskAddress,
  fieldConfiguration: AddressSection = {}
): ProfilePresentationDataElement => {
  const {
    addressLine1,
    addressLine2,
    city,
    country,
    type,
    postalCode,
    state,
    district,
  } = addresses || {};

  const isAddressLineVisible =
    fieldConfiguration.addressLine1Field?.isVisible ||
    fieldConfiguration.addressLine2Field?.isVisible;

  const addressLine = [
    fieldConfiguration.addressLine1Field?.isVisible ? addressLine1 : undefined,
    fieldConfiguration.addressLine2Field?.isVisible ? addressLine2 : undefined,
  ].filter(isDefined);

  return {
    isCollapsible: false,
    sectionData: [
      isAddressLineVisible || fieldConfiguration.typeField?.isVisible
        ? {
            value: addressLine.length ? addressLine : undefined,
            label: fieldConfiguration.typeField?.isVisible
              ? type?.description
              : undefined,
          }
        : undefined,
      fieldConfiguration.cityField?.isVisible
        ? {
            value: city,
            label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.CITY'),
          }
        : undefined,
      fieldConfiguration.postalCodeField?.isVisible
        ? {
            value: postalCode,
            label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.POST_CODE'),
          }
        : undefined,
      fieldConfiguration.stateField?.isVisible
        ? {
            value: state?.name,
            label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.STATE'),
          }
        : undefined,
      fieldConfiguration.districtField?.isVisible
        ? {
            value: district?.description,
            label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.DISTRICT'),
          }
        : undefined,
      fieldConfiguration.countryField?.isVisible
        ? {
            value: country?.name,
            label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.COUNTRY'),
          }
        : undefined,
    ].filter(isDefined),
  };
};

const mapNonPrimaryAddress = (
  addresses?: KioskAddress,
  fieldConfiguration: AddressSection = {}
): ProfilePresentationDataElement => {
  const {
    addressLine1,
    addressLine2,
    city,
    country,
    type,
    postalCode,
    state,
    district,
  } = addresses || {};

  const isAddressLineVisible =
    fieldConfiguration.addressLine1Field?.isVisible ||
    fieldConfiguration.addressLine2Field?.isVisible;

  const addressLine = [
    fieldConfiguration.addressLine1Field?.isVisible ? addressLine1 : undefined,
    fieldConfiguration.addressLine2Field?.isVisible ? addressLine2 : undefined,
  ].filter(isDefined);

  return {
    isCollapsible: true,
    sectionData: [
      {
        label: fieldConfiguration.typeField?.isVisible
          ? type?.description
          : undefined,
        value: [
          ...(isAddressLineVisible ? addressLine : []),
          fieldConfiguration.cityField?.isVisible ? city : undefined,
          fieldConfiguration.postalCodeField?.isVisible
            ? postalCode
            : undefined,
          fieldConfiguration.stateField?.isVisible ? state?.name : undefined,
          fieldConfiguration.districtField?.isVisible
            ? district?.description
            : undefined,
          fieldConfiguration.countryField?.isVisible
            ? country?.name
            : undefined,
        ].filter(isDefined),
      },
    ],
  };
};

export const mapAddressData = (
  addresses?: KioskAddress[],
  visibilityConfig?: AddressVisibilityConfig
): ProfilePresentationDataElement[] => {
  if (!addresses?.length) return [];
  const fieldConfiguration = visibilityConfig?.fieldConfiguration;
  const primaryAddress = addresses.find((address) => address.isPrimary);
  const nonPrimaryAddresses = visibilityConfig?.showOnlyPrimary
    ? []
    : addresses.filter((address) => !address.isPrimary);

  return [
    primaryAddress
      ? mapPrimaryAddress(primaryAddress, fieldConfiguration)
      : undefined,
    ...nonPrimaryAddresses.map((address) => {
      return mapNonPrimaryAddress(address, fieldConfiguration);
    }),
  ].filter(isDefined);
};
