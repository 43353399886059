import i18next from 'i18next';

import { Field, FieldMode } from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';
import {
  composeValidators,
  formFieldFactory,
  ValidationExpression,
} from '@ac/react-infrastructure';

import { createNumericStringValidator, mapFieldRenderProps } from 'utils/form';
import {
  createNumericStringRangeValidator,
  RangeValidityType,
} from 'utils/form/createNumericStringRangeValidator';

import { BaseObject } from 'types/shared';

import { BasicFieldProvider, ComponentsData } from './basicFieldProvider';

const FormField = formFieldFactory<BaseObject>();

export class NumberFieldProvider extends BasicFieldProvider<
  typeof FormField,
  typeof Field
> {
  protected get minValue(): number {
    const { typeConstraints } = this.reservationHeaderDefinition;
    const isMinNumberValid =
      (typeConstraints?.minValue as number) > Number.MIN_SAFE_INTEGER;

    return isDefined(typeConstraints?.minValue) && isMinNumberValid
      ? (typeConstraints?.minValue as number)
      : Number.MIN_SAFE_INTEGER;
  }

  protected get maxValue(): number {
    const { typeConstraints } = this.reservationHeaderDefinition;
    const isMaxNumberValid =
      (typeConstraints?.minValue as number) < Number.MAX_SAFE_INTEGER;

    return isDefined(typeConstraints?.maxValue) && isMaxNumberValid
      ? (typeConstraints?.maxValue as number)
      : Number.MAX_SAFE_INTEGER;
  }

  getInitialFieldValue(): string | undefined {
    return isDefined(this.value) ? String(this.value) : undefined;
  }

  getValidationSchema(): ValidationExpression<BaseObject<string>> | undefined {
    const validators: Array<ValidationExpression<BaseObject, string>> = [
      createNumericStringValidator(
        i18next.t(
          'REGISTRATION_CARD_EDIT_COMPLEMENTARY.VALIDATION_MESSAGES.NO_SPECIAL_CHARACTERS'
        )
      ),
      createNumericStringRangeValidator(
        RangeValidityType.min,
        this.minValue,
        i18next.t(
          'REGISTRATION_CARD_EDIT_COMPLEMENTARY.VALIDATION_MESSAGES.MIN_VALUE',
          {
            value: this.minValue,
          }
        )
      ),
      createNumericStringRangeValidator(
        RangeValidityType.max,
        this.maxValue,
        i18next.t(
          'REGISTRATION_CARD_EDIT_COMPLEMENTARY.VALIDATION_MESSAGES.MAX_VALUE',
          {
            value: this.maxValue,
          }
        )
      ),
    ];

    return validators.length
      ? composeValidators<BaseObject>(...validators)
      : undefined;
  }

  getComponentsData(): ComponentsData<typeof FormField, typeof Field> {
    return {
      FormField,
      formFieldRenderPropsMapper: mapFieldRenderProps,
      Component: Field,
      componentProps: {
        label: this.reservationHeaderDefinition.displayName,
        placeholder: i18next.t('SHARED.FILL'),
        mode: FieldMode.numeric,
      },
    };
  }

  prepareUpdatePayloadValue = (
    currentValue: string | undefined
  ): number | undefined | null => {
    const initialValue = this.getInitialFieldValue();
    if (initialValue === currentValue) return undefined;

    return isDefined(currentValue) ? parseFloat(currentValue) : null;
  };
}
