import { isDefined } from '@ac/library-utils/dist/utils';

import {
  KioskPurchaseElementAvailabilityItemDto,
  KioskPurchaseElementCalculationRule,
} from 'api/KioskApi/entries';

export const isPurchaseElementAvailableForReservationOccupancy = (
  purchaseElement: KioskPurchaseElementAvailabilityItemDto,
  guestCount: number
): boolean => {
  if (isDefined(purchaseElement.quantityAvailable)) {
    if (
      purchaseElement.calculationRule ===
      KioskPurchaseElementCalculationRule.PerPerson
    ) {
      return purchaseElement.quantityAvailable >= guestCount;
    } else {
      return purchaseElement.quantityAvailable >= 1;
    }
  }

  return true;
};
