export interface RawKioskRegistrationCard {
  id?: string;
}

export class KioskRegistrationCard {
  public id?: string;

  constructor(raw: RawKioskRegistrationCard) {
    this.id = raw.id;
  }
}
