import { ValidationResult } from '@ac/react-infrastructure';

import { BaseObject, Schema } from 'types/shared';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ArrayValidationExpression<Model, Value = any> = (
  value: Value,
  model: Model,
  index: number
) => ValidationResult;

export const createArrayValidator = <T extends BaseObject>(
  schema: Schema<T>
): ((values: T[], model: T) => ValidationResult) => (
  values: T[],
  model: T
): ValidationResult => {
  if (!values) return [];

  return values.reduce((valuesErrors, item, index) => {
    for (const key in schema) {
      if (Object.prototype.hasOwnProperty.call(schema, key)) {
        const validator = schema[key] as ArrayValidationExpression<T>;

        const status = validator(item[key], model, index);

        valuesErrors[index] = {
          ...valuesErrors[index],
          ...{ [key]: status },
        };
      }
    }

    return valuesErrors;
  }, [] as ValidationResult);
};
