import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  PurchaseElementFieldType,
  PurchaseElementInclusionType,
} from '@ac/kiosk-components';

import { KioskPurchaseElementCalculationRule } from 'api/KioskApi/entries';
import { getRegistrationCardDetails } from 'store/electronicRegistrationProcess/selectors';

import {
  getIncludedPurchaseElements,
  getPurchaseElementsImages,
} from '../store/selectors';
import { PurchaseElementsSelectorDataItem } from '../types';

export const useIncludedPurchaseElementsData = (): PurchaseElementsSelectorDataItem[] => {
  const regCardDetails = useSelector(getRegistrationCardDetails);
  const includedPurchaseElements = useSelector(getIncludedPurchaseElements);
  const purchaseElementImages = useSelector(getPurchaseElementsImages);

  return useMemo<PurchaseElementsSelectorDataItem[]>(() => {
    return includedPurchaseElements.map((element) => {
      const wasElementAdded = regCardDetails?.reservation.addedPurchaseElements.some(
        (item) => {
          return item.id === element.id;
        }
      );

      const includedType = wasElementAdded
        ? PurchaseElementInclusionType.added
        : PurchaseElementInclusionType.inPrice;

      return {
        id: element.id,
        title: element.name,
        description: element.description,
        type:
          element.calculationRule ===
          KioskPurchaseElementCalculationRule.PerPerson
            ? PurchaseElementFieldType.perPerson
            : PurchaseElementFieldType.perReservation,
        hasImageInCard: false,
        includedQuantity: element.totalQuantity,
        readonly: true,
        image: purchaseElementImages[element.id],
        includedType,
      };
    });
  }, [includedPurchaseElements, purchaseElementImages, regCardDetails]);
};
