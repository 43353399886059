import { isDefined } from '@ac/library-utils/dist/utils';
import {
  createRequiredValidator,
  ValidationResult,
  ValidationSchema,
} from '@ac/react-infrastructure';

import { createConditionalRequire } from 'utils/form';
import {
  FormProperties,
  FormValues,
} from 'views/RegistrationCardEditPersonal/types';

export interface PhoneValidationSchema {
  type: ValidationResult;
  countryCode: ValidationResult;
  phoneNumber: ValidationResult;
}

export const phoneValidationSchema = (
  modelKey: FormProperties.phones | FormProperties.mobiles
): ValidationSchema<PhoneValidationSchema> => ({
  type: createRequiredValidator('VALIDATION.SELECT_TYPE'),
  phoneNumber: createRequiredValidator('VALIDATION.FIELD_IS_REQUIRED'),
  countryCode: createConditionalRequire(
    'VALIDATION.FIELD_IS_REQUIRED',
    (_, model: FormValues, index?: number): boolean => {
      if (!isDefined(index)) return false;
      const phoneRecord = model?.[modelKey]?.[index];

      return Boolean(phoneRecord && !phoneRecord.phoneNumber);
    }
  ),
});
