import { useCallback, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';

import { InactivityModal } from '@ac/kiosk-components';

import {
  EREGCARD_EXTERNAL_DEVICE_SCENARIO_INACTIVITY_TIMEOUT,
  EREGCARD_INACTIVITY_MODAL_PROLONG_TIMEOUT,
  EREGCARD_SAME_DEVICE_SCENARIO_INACTIVITY_TIMEOUT,
} from 'configs/timers';
import { getIsSameDeviceScenario } from 'store/app/selectors';
import { cancelElectronicRegistrationProcess } from 'store/electronicRegistrationProcess/actions';
import { getIsRegistrationCardCancelling } from 'store/electronicRegistrationProcess/selectors';

import './withIdleCheck.scss';

export const withIdleCheck = <Props extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<Props>
): ((props: Props) => JSX.Element) => {
  const IdleCheck = (props: Props): JSX.Element => {
    const dispatch = useDispatch();

    const [isAreYouThereShown, setAreYouThereShown] = useState(false);

    const isSameDeviceScenario = useSelector(getIsSameDeviceScenario);
    const isRegistrationCardCancelling = useSelector(
      getIsRegistrationCardCancelling
    );

    const handleCountdownEnd = useCallback(() => {
      dispatch(cancelElectronicRegistrationProcess.trigger());
      setAreYouThereShown(false);
    }, [dispatch]);

    return (
      <>
        {isAreYouThereShown && !isRegistrationCardCancelling && (
          <InactivityModal
            className="registration-card-inactivity-modal registration-card-inactivity-modal-wrapper"
            countdownFrom={EREGCARD_INACTIVITY_MODAL_PROLONG_TIMEOUT / 1000}
            onConfirmButtonClick={(): void => setAreYouThereShown(false)}
            onCountdownEnd={handleCountdownEnd}
          />
        )}
        <IdleTimer
          onIdle={(): void => setAreYouThereShown(true)}
          timeout={
            isSameDeviceScenario
              ? EREGCARD_SAME_DEVICE_SCENARIO_INACTIVITY_TIMEOUT
              : EREGCARD_EXTERNAL_DEVICE_SCENARIO_INACTIVITY_TIMEOUT
          }
        />
        <WrappedComponent {...props} />
      </>
    );
  };

  return IdleCheck;
};
