import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Field, SelectField } from '@ac/kiosk-components';
import { formNestedFieldFactory } from '@ac/react-infrastructure';

import { getLanguagesEntities } from 'store/settings/selectors';
import { mapFieldRenderProps, mapSelectOptions } from 'utils/form';
import { SubFormActionHeader } from 'views/RegistrationCardEditPersonal/components/SubFormActionHeader/SubFormActionHeader';
import {
  AlternateDetailsFormProperties,
  AlternateDetailsFormValues,
  FormProperties,
  PersonalDetailsFormProperties,
} from 'views/RegistrationCardEditPersonal/types';

import './AlternateDetailsSubSection.scss';

type SubFormValues = {
  [FormProperties.personalDetails]: {
    [PersonalDetailsFormProperties.alternateDetails]: AlternateDetailsFormValues;
  };
};

const NestedFormField = formNestedFieldFactory<SubFormValues>();

interface AlternateDetailsSubSectionProps {
  className?: string;
  removable: boolean;
  onRemoveClick?: () => void;
}

export const AlternateDetailsSubSection = ({
  className,
  removable,
  onRemoveClick,
}: AlternateDetailsSubSectionProps): JSX.Element => {
  const [t] = useTranslation();
  const languages = useSelector(getLanguagesEntities);

  const languageOptions = useMemo(() => {
    return mapSelectOptions(languages, 'name', 'code');
  }, [languages]);

  return (
    <div className={className}>
      <SubFormActionHeader
        deleteControlEnabled={removable}
        title={t('COMPONENTS.PERSONAL_DETAILS_SECTION.ALTERNATE_DETAILS')}
        onRemoveClick={onRemoveClick}
      />
      <div className="reg-card-edit-alternate-details">
        <NestedFormField
          valuePath={[
            FormProperties.personalDetails,
            PersonalDetailsFormProperties.alternateDetails,
            AlternateDetailsFormProperties.language,
          ]}
        >
          {(fieldFieldRenderProps): JSX.Element => (
            <SelectField
              {...mapFieldRenderProps(fieldFieldRenderProps)}
              label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.LANGUAGE')}
              placeholder={t('SHARED.SELECT')}
              options={languageOptions}
            />
          )}
        </NestedFormField>

        <NestedFormField
          valuePath={[
            FormProperties.personalDetails,
            PersonalDetailsFormProperties.alternateDetails,
            AlternateDetailsFormProperties.firstName,
          ]}
        >
          {(fieldFieldRenderProps): JSX.Element => (
            <Field
              {...mapFieldRenderProps(fieldFieldRenderProps)}
              label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.ALT_FIRST_NAME')}
              placeholder={t('SHARED.FILL')}
              optional
            />
          )}
        </NestedFormField>
        <NestedFormField
          valuePath={[
            FormProperties.personalDetails,
            PersonalDetailsFormProperties.alternateDetails,
            AlternateDetailsFormProperties.lastName,
          ]}
        >
          {(fieldFieldRenderProps): JSX.Element => (
            <Field
              {...mapFieldRenderProps(fieldFieldRenderProps)}
              label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.ALT_LAST_NAME')}
              placeholder={t('SHARED.FILL')}
              optional
            />
          )}
        </NestedFormField>
      </div>
    </div>
  );
};
