import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getSortedProfileAddresses,
  getValidatedProfileData,
} from 'store/electronicRegistrationProcess/selectors/profile';
import {
  getAddressTypeEntities,
  getFeatureToggles,
  getFieldsConfiguration,
  getGeneralSettings,
} from 'store/settings/selectors';
import { mapAddressData } from 'utils/regCardPresentationDataMappers';
import { mapAddressData as mapAddressDataV2 } from 'utils/regCardPresentationDataMappers/v2/mapAddressData';

import { ProfilePresentationSectionData } from '../types';

interface Props {
  showOnlyPrimary?: boolean;
}

export const useAddressesConfiguration = ({
  showOnlyPrimary,
}: Props): ProfilePresentationSectionData => {
  const { i18n } = useTranslation();
  const featureToggles = useSelector(getFeatureToggles);
  const validatedProfileData = useSelector(getValidatedProfileData);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const generalSettings = useSelector(getGeneralSettings);
  const addressTypes = useSelector(getAddressTypeEntities);
  const addresses = useSelector(getSortedProfileAddresses);

  const addressfieldsConfiguration =
    fieldsConfiguration?.addressesObjectCollection;

  const addressesData = useMemo(
    () =>
      featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
        ? mapAddressDataV2(addresses, {
            fieldConfiguration: addressfieldsConfiguration,
            showOnlyPrimary,
          })
        : mapAddressData(addresses, addressTypes, {
            hideDistrict: !generalSettings?.DISTRICT,
            showOnlyPrimary,
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      addresses,
      generalSettings?.DISTRICT,
      showOnlyPrimary,
      addressTypes,
      i18n.language,
    ]
  );

  return useMemo(
    () => ({
      data: addressesData,
      isVisible: featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
        ? fieldsConfiguration?.addressesObjectCollection?.isVisible ?? true
        : generalSettings?.DISPLAY_ADDRESS ?? true,
      isMissingRequiredInformation:
        featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION && validatedProfileData
          ? !validatedProfileData.addresses
          : false,
    }),
    [
      addressesData,
      featureToggles,
      fieldsConfiguration,
      validatedProfileData,
      generalSettings,
    ]
  );
};
