import { call, put, select, takeLatest } from 'redux-saga/effects';

import { LibraryApiResponse } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { isDefined } from '@ac/library-utils/dist/utils';

import { ElectronicRegistrationProcessApi } from 'api/KioskApi';
import {
  KioskPurchaseElementAvailabilityItemDto,
  KioskPurchaseElementsDto,
  KioskUpdatePurchaseElementItem,
} from 'api/KioskApi/entries';
import { KIOSK_SESSION_ID_HEADER } from 'configs/constants';
import {
  fetchRegistrationCardDetails,
  saveAddedInSessionPurchaseElements,
} from 'store/electronicRegistrationProcess/actions';
import { SessionPurchaseElementDetails } from 'store/electronicRegistrationProcess/interfaces/SessionPurchaseElementDetails';
import { getElectronicRegistrationProcessId } from 'store/electronicRegistrationProcess/selectors';
import { handleSagaError } from 'utils/sagas';

import { SagasGenerator } from 'types/shared';

import { isPurchaseElementOperationFailedErrorResponse } from '../utils/isPurchaseElementOperationFailedErrorResponse';
import { PurchaseElementOperationFailedError } from '../utils/PurchaseElementOperationFailedError';

import * as actions from './actions';
import { UpdatePurchaseElementsPayload } from './interfaces';
import {
  getCurrentAvailability,
  getIncludedPurchaseElements,
  getInitialAvailability,
  getIsEhancementProcessInitialized,
  getPurchaseElementsImages,
} from './selectors';

export function* updatePurchaseElementsSaga(
  action: Action<UpdatePurchaseElementsPayload>
): SagasGenerator {
  try {
    const purchaseElementsToAdd: KioskUpdatePurchaseElementItem[] = Object.entries(
      action.payload.formValues
    )
      .filter(([, value]) => value)
      .map(([key, value]) => ({
        id: key,
        quantity: value,
      }));

    const initialAvailability: KioskPurchaseElementAvailabilityItemDto[] = yield select(
      getInitialAvailability
    );
    const kioskSessionId: string = yield select(
      getElectronicRegistrationProcessId
    );

    yield ElectronicRegistrationProcessApi.updatePurchaseElement({
      data: {
        purchaseElements: purchaseElementsToAdd,
      },
      customConfig: {
        headers: {
          [KIOSK_SESSION_ID_HEADER]: kioskSessionId,
        },
      },
    });

    const addedPurchaseElements = purchaseElementsToAdd
      .map<SessionPurchaseElementDetails | undefined>((element) => {
        const purchaseElement = initialAvailability?.find(
          ({ id }) => id === element.id
        );
        if (!purchaseElement?.name || !purchaseElement?.dailyPrices) return;

        return {
          ...purchaseElement,
          quantity: element.quantity,
        };
      })
      .filter<SessionPurchaseElementDetails>(isDefined);

    yield put(fetchRegistrationCardDetails.trigger(kioskSessionId));
    yield put(saveAddedInSessionPurchaseElements(addedPurchaseElements));
    yield put(actions.updatePurchaseElements.success());
    yield put(actions.clearPurchaseElementsPreselection());
    action.payload.onSuccess?.();
  } catch (error) {
    yield put(
      actions.updatePurchaseElements.failure(
        handleSagaError(
          isPurchaseElementOperationFailedErrorResponse(error)
            ? new PurchaseElementOperationFailedError()
            : error
        )
      )
    );
  }
}

export function* getPurchaseElementsImagesSaga(
  availablePurchaseElements?: KioskPurchaseElementAvailabilityItemDto[]
): SagasGenerator {
  const kioskSessionId: string = yield select(
    getElectronicRegistrationProcessId
  );
  const images: Record<string, string> = yield select(
    getPurchaseElementsImages
  );
  const includedPurchaseElements: KioskPurchaseElementsDto[] = yield select(
    getIncludedPurchaseElements
  );

  const allVisiblePurchaseElements: Array<
    KioskPurchaseElementsDto | KioskPurchaseElementAvailabilityItemDto
  > = [...includedPurchaseElements, ...(availablePurchaseElements || [])];

  const purchaseElementsImageEntries: Array<
    [string, string]
  > = allVisiblePurchaseElements
    .filter((element) => {
      const hasSomeImage = !!element.imageIds?.[0];
      const hasNoFetchedImage = !images[element.id];

      return hasSomeImage && hasNoFetchedImage;
    })
    .map((element: Required<KioskPurchaseElementsDto>) => {
      return [element.id, element.imageIds[0]];
    });

  const purchaseImagesEntries: Array<[string, string]> = yield Promise.all(
    purchaseElementsImageEntries.map(async ([id, imageId]) => {
      const imageBlob = await ElectronicRegistrationProcessApi.getPurchaseElementContentImage(
        {
          pathParams: {
            imageId,
            purchaseElementId: id,
          },
          customConfig: {
            headers: {
              [KIOSK_SESSION_ID_HEADER]: kioskSessionId,
            },
          },
        }
      );

      return [id, URL.createObjectURL(imageBlob)];
    })
  );

  yield put(
    actions.savePurchaseElementsImages(
      Object.fromEntries(purchaseImagesEntries)
    )
  );
}

export function* checkInitialAvailabilityChanges(
  data: KioskPurchaseElementAvailabilityItemDto[]
): SagasGenerator {
  const newAppeardAvailability: KioskPurchaseElementAvailabilityItemDto[] = [];
  const initialAvailability: KioskPurchaseElementAvailabilityItemDto[] = yield select(
    getInitialAvailability
  );

  data.forEach((purchaseElement) => {
    const initialAvailabilityPurchaseElement = initialAvailability.find(
      ({ id }) => id === purchaseElement.id
    );

    if (initialAvailabilityPurchaseElement) {
      const availableQuantityIncreased =
        (purchaseElement.quantityAvailable ||
          initialAvailabilityPurchaseElement.quantityAvailable) &&
        (initialAvailabilityPurchaseElement?.quantityAvailable ?? 0) <
          (purchaseElement?.quantityAvailable ?? 0);

      if (availableQuantityIncreased) {
        newAppeardAvailability.push(purchaseElement);
      }
    } else {
      newAppeardAvailability.push(purchaseElement);
    }
  });

  const updatedInitialAvailability = initialAvailability.map((element) => {
    const availabilityChangedElement = newAppeardAvailability.find(
      ({ id }) => id === element.id
    );

    if (availabilityChangedElement) {
      return availabilityChangedElement;
    }

    return element;
  });

  if (newAppeardAvailability.length) {
    yield put(actions.updateInitialAvailability(updatedInitialAvailability));
  }
}

export function* fetchAvailabilitySaga(): SagasGenerator {
  try {
    const isInitialized: boolean = yield select(
      getIsEhancementProcessInitialized
    );
    const kioskSessionId: string = yield select(
      getElectronicRegistrationProcessId
    );

    const response: LibraryApiResponse<
      KioskPurchaseElementAvailabilityItemDto[]
    > = yield ElectronicRegistrationProcessApi.getAvailablePurchaseElements({
      customConfig: {
        headers: {
          [KIOSK_SESSION_ID_HEADER]: kioskSessionId,
        },
      },
    });

    yield call(getPurchaseElementsImagesSaga, response.data);

    if (isInitialized) {
      yield call(checkInitialAvailabilityChanges, response.data);
    }

    yield put(actions.fetchAvailability.success(response.data));
  } catch (error) {
    yield put(actions.fetchAvailability.failure(handleSagaError(error)));
  }
}

export function* setUpEnhancementsSaga(): SagasGenerator {
  try {
    yield call(fetchAvailabilitySaga);
    const currentAvailability: KioskPurchaseElementAvailabilityItemDto[] = yield select(
      getCurrentAvailability
    );
    yield put(actions.setUpEnhancements.success(currentAvailability));
  } catch (error) {
    yield put(actions.fetchAvailability.failure(handleSagaError(error)));
  }
}

export function* registrationCardPurchaseElementsViewSagas(): SagasGenerator {
  yield takeLatest(
    actions.updatePurchaseElements.trigger,
    updatePurchaseElementsSaga
  );
  yield takeLatest(actions.fetchAvailability.trigger, fetchAvailabilitySaga);
  yield takeLatest(actions.setUpEnhancements.trigger, setUpEnhancementsSaga);
}
