import { KioskCommunicationChannel } from 'api/KioskApi/entries';
import { EntitiesSettingsStorage } from 'store/settings/interfaces';
import { extendDataByDisplaySequence } from 'utils';
import { sortDisplayElements } from 'utils/sorting';
import { EmailsFormValues } from 'views/RegistrationCardEditPersonal/types';

export const prepareEmailsInitialValues = (
  emails: KioskCommunicationChannel[],
  entities?: EntitiesSettingsStorage
): EmailsFormValues[] => {
  const primary = emails.filter((email) => email.isPrimary);
  const nonPrimary = emails.filter((email) => !email.isPrimary);
  const sorted = entities?.communicationTypes
    ? sortDisplayElements(
        extendDataByDisplaySequence(nonPrimary, entities.communicationTypes)
      )
    : nonPrimary;

  return [...primary, ...sorted].map(prepareSingleEmailInitialValues);
};

export const prepareSingleEmailInitialValues = (
  email?: Partial<KioskCommunicationChannel>
): EmailsFormValues => ({
  id: email?.id,
  details: email?.details,
  type: email?.type?.id,
  isPrimary: email?.isPrimary || false,
});
