import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { isDefined } from '@ac/library-utils/dist/utils';

import {
  getDateTimeFormats,
  getDocumentTypeEntities,
  getFieldsConfiguration,
} from 'store/settings/selectors';
import { DateManager } from 'utils/DateManager';

import { DocumentFormValues } from '../../../types';

type PhoneFormatter = (document?: DocumentFormValues) => string | undefined;

export const useDocumentFormatter = (): PhoneFormatter => {
  const documentTypes = useSelector(getDocumentTypeEntities);
  const dateTimeFormats = useSelector(getDateTimeFormats);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const documentFieldsConfiguration =
    fieldsConfiguration?.identityDocumentObjectCollection;

  const stringifyAddress = useCallback(
    (document?: DocumentFormValues): string | undefined => {
      if (!document) return;

      return [
        documentFieldsConfiguration?.expiryDateField?.isVisible
          ? documentTypes?.find((option) => option.id === document?.type)
              ?.description
          : undefined,
        documentFieldsConfiguration?.expiryDateField?.isVisible
          ? document.number
          : undefined,
        documentFieldsConfiguration?.expiryDateField?.isVisible &&
        document.expiryDate
          ? DateManager.getFormattedDate(
              document.expiryDate,
              dateTimeFormats.shortDateFormat
            )
          : undefined,
      ]
        .filter(isDefined)
        .join(', ');
    },
    [dateTimeFormats, documentTypes, documentFieldsConfiguration]
  );

  return stringifyAddress;
};
