import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { paths } from 'configs';
import {
  getIsAnotherSessionOpenedInNewTab,
  getIsElectronicRegistrationProcessInitialized,
  getIsRegistrationCardCancelled,
  getIsRegistrationCardCompleted,
} from 'store/electronicRegistrationProcess/selectors';
import { useRouter } from 'utils/hooks';

type ReturnType<Props> = (props: Props) => JSX.Element;

export const requireRegCardProcessInProgress = <
  Props extends Record<string, unknown>
>(
  WrappedComponent: React.ComponentType<Props>
): ReturnType<Props> => {
  const ProcessValidate = (props: Props): JSX.Element => {
    const router = useRouter();

    const isElectronicRegistrationProcessInitialized = useSelector(
      getIsElectronicRegistrationProcessInitialized
    );
    const isAnotherSessionOpenedInNewTab = useSelector(
      getIsAnotherSessionOpenedInNewTab
    );
    const isRegistrationCardCancelled = useSelector(
      getIsRegistrationCardCancelled
    );
    const isRegistrationCardCompleted = useSelector(
      getIsRegistrationCardCompleted
    );

    const shouldRedirectToFinishPath = useMemo(() => {
      const isFinishPath = router.isCurrentPath(
        router.getSerializedPath(paths.FINISH)
      );

      return (
        !isFinishPath &&
        (isRegistrationCardCancelled ||
          isRegistrationCardCompleted ||
          isAnotherSessionOpenedInNewTab)
      );
    }, [
      isAnotherSessionOpenedInNewTab,
      isRegistrationCardCancelled,
      isRegistrationCardCompleted,
      router,
    ]);

    if (!isElectronicRegistrationProcessInitialized) {
      return router.isAnySameDevicePath ? (
        <Navigate
          to={router.getSerializedPath(paths.SAME_DEVICE_ENTRY_PATH)}
          replace
        />
      ) : (
        <Navigate to={paths.EXTERNAL_DEVICE_ENTRY_PATH} replace />
      );
    }

    if (shouldRedirectToFinishPath) {
      return <Navigate to={router.getSerializedPath(paths.FINISH)} replace />;
    }

    return <WrappedComponent {...props} />;
  };

  return ProcessValidate;
};
