import { BackgroundImagePosition } from './settingTypes/backgroundImagePosition';
import { FontFamilyCode } from './settingTypes/fontFamilyCodes';

export enum StylesCodesMap {
  MAIN_COLOR = 'EREGCARDMAINCOLOR',
  MAIN_FONT = 'EREGCARDMAINFONT',
  BODY_BACKGROUND_COLOR = 'EREGCARDBODYBACKGROUNDCOLOR',
  BODY_FONT_COLOR = 'EREGCARDBODYFONTCOLOR',
  FOOTER_BACKGROUND_COLOR = 'EREGCARDFOOTERBACKGROUNDCOLOR',
  HEADER_BACKGROUND_COLOR = 'EREGCARDHEADERBACKGROUNDCOLOR',
  HEADER_FONT_COLOR = 'EREGCARDHEADERFONTCOLOR',
  SCREEN_SAVER_MESSAGE_FONT_COLOR = 'EREGCARDSCREENSAVERMESSAGEFONTCOLOR',
  BACKGROUND_IMAGE_POSITION = 'EREGCARDBACKGROUNDIMAGEPOSITION',
  PRIMARY_ACTIVE_BACKGROUND_COLOR = 'EREGCARDPRIMARYACTIVEBACKGROUNDCOLOR',
  PRIMARY_ACTIVE_BORDER_COLOR = 'EREGCARDPRIMARYACTIVEBORDERCOLOR',
  PRIMARY_ACTIVE_TEXT_COLOR = 'EREGCARDPRIMARYACTIVETEXTCOLOR',
  PRIMARY_CLICKED_BACKGROUND_COLOR = 'EREGCARDPRIMARYCLICKEDBACKGROUNDCOLOR',
  PRIMARY_CLICKED_BORDER_COLOR = 'EREGCARDPRIMARYCLICKEDBORDERCOLOR',
  PRIMARY_CLICKED_TEXT_COLOR = 'EREGCARDPRIMARYCLICKEDTEXTCOLOR',
  PRIMARY_DISABLED_BACKGROUND_COLOR = 'EREGCARDPRIMARYDISABLEDBACKGROUNDCOLOR',
  PRIMARY_DISABLED_BORDER_COLOR = 'EREGCARDPRIMARYDISABLEDBORDERCOLOR',
  PRIMARY_DISABLED_TEXT_COLOR = 'EREGCARDPRIMARYDISABLEDTEXTCOLOR',
  TERTIARY_CLICKED_ACTION_FONT_COLOR = 'EREGCARDTERTIARYCLICKEDACTIONFONTCOLOR',
  TERTIARY_DISABLED_ACTION_FONT_COLOR = 'EREGCARDTERTIARYDISABLEDACTIONFONTCOLOR',
  TERTIARY_ACTIVE_ACTION_FONT_COLOR = 'EREGCARDTERTIARYACTIVEACTIONFONTCOLOR',
  SECONDARY_CLICKED_BORDER_COLOR = 'EREGCARDSECONDARYCLICKEDBORDERCOLOR',
  SECONDARY_CLICKED_BACKGROUND_COLOR = 'EREGCARDSECONDARYCLICKEDBACKGROUNDCOLOR',
  SECONDARY_CLICKED_TEXT_COLOR = 'EREGCARDSECONDARYCLICKEDTEXTCOLOR',
  SECONDARY_DISABLED_BORDER_COLOR = 'EREGCARDSECONDARYDISABLEDBORDERCOLOR',
  SECONDARY_DISABLED_BACKGROUND_COLOR = 'EREGCARDSECONDARYDISABLEDBACKGROUNDCOLOR',
  SECONDARY_DISABLED_TEXT_COLOR = 'EREGCARDSECONDARYDISABLEDTEXTCOLOR',
  SECONDARY_ACTIVE_BORDER_COLOR = 'EREGCARDSECONDARYACTIVEBORDERCOLOR',
  SECONDARY_ACTIVE_BACKGROUND_COLOR = 'EREGCARDSECONDARYACTIVEBACKGROUNDCOLOR',
  SECONDARY_ACTIVE_TEXT_COLOR = 'EREGCARDSECONDARYACTIVETEXTCOLOR',
  HINT_FONT_COLOR = 'EREGCARDHINTFONTCOLOR',
  FOOTER_FONT_COLOR = 'EREGCARDFOOTERFONTCOLOR',
}

export interface StylesSettingsStorage {
  MAIN_COLOR?: string;
  MAIN_FONT?: FontFamilyCode;
  BODY_BACKGROUND_COLOR?: string;
  BODY_FONT_COLOR?: string;
  FOOTER_BACKGROUND_COLOR?: string;
  HEADER_BACKGROUND_COLOR?: string;
  HEADER_FONT_COLOR?: string;
  BACKGROUND_IMAGE_POSITION?: BackgroundImagePosition;
  SCREEN_SAVER_MESSAGE_FONT_COLOR?: string;
  PRIMARY_ACTIVE_BACKGROUND_COLOR?: string;
  PRIMARY_ACTIVE_BORDER_COLOR?: string;
  PRIMARY_ACTIVE_TEXT_COLOR?: string;
  PRIMARY_CLICKED_BACKGROUND_COLOR?: string;
  PRIMARY_CLICKED_BORDER_COLOR?: string;
  PRIMARY_CLICKED_TEXT_COLOR?: string;
  PRIMARY_DISABLED_BACKGROUND_COLOR?: string;
  PRIMARY_DISABLED_BORDER_COLOR?: string;
  PRIMARY_DISABLED_TEXT_COLOR?: string;
  TERTIARY_CLICKED_ACTION_FONT_COLOR?: string;
  TERTIARY_DISABLED_ACTION_FONT_COLOR?: string;
  TERTIARY_ACTIVE_ACTION_FONT_COLOR?: string;
  SECONDARY_CLICKED_BORDER_COLOR?: string;
  SECONDARY_CLICKED_BACKGROUND_COLOR?: string;
  SECONDARY_CLICKED_TEXT_COLOR?: string;
  SECONDARY_DISABLED_BORDER_COLOR?: string;
  SECONDARY_DISABLED_BACKGROUND_COLOR?: string;
  SECONDARY_DISABLED_TEXT_COLOR?: string;
  SECONDARY_ACTIVE_BORDER_COLOR?: string;
  SECONDARY_ACTIVE_BACKGROUND_COLOR?: string;
  SECONDARY_ACTIVE_TEXT_COLOR?: string;
  HINT_FONT_COLOR?: string;
  FOOTER_FONT_COLOR?: string;
}
