import { KioskPreferenceDetailsDto } from 'api/KioskApi/entries/electronicRegistrationProcess/kioskPreferenceDetails';

import { BaseObject } from 'types/shared';

export type GetPreferenceDiffReturnType = {
  set: string[];
  remove: string[];
  changed: boolean;
};

export const getPreferencesDiff = (
  currentPreferences: KioskPreferenceDetailsDto[],
  preferenceFormValues: { [attributeGroupKey: string]: BaseObject<boolean> }
): GetPreferenceDiffReturnType => {
  const setPreferences: string[] = [];
  const removePreferences: string[] = [];
  let changed = false;

  Object.values(preferenceFormValues).forEach((formValuesInAttributeGroups) => {
    Object.entries(formValuesInAttributeGroups).forEach(
      ([attributeKey, boolFormValue]) => {
        const isAlreadySelected = currentPreferences.find(
          (preference) => preference.attributeId === attributeKey
        );
        if (boolFormValue && !isAlreadySelected) {
          setPreferences.push(attributeKey);
          changed = true;
        }
        if (!boolFormValue && isAlreadySelected) {
          changed = true;
          removePreferences.push(attributeKey);
        }
      }
    );
  });

  return {
    set: setPreferences,
    remove: removePreferences,
    changed,
  };
};
