import {
  createExpressionValidator,
  ValidationResult,
} from '@ac/react-infrastructure';

export const createFullDateTimeValidator = (
  message: string
): ((value?: string | undefined) => ValidationResult) => {
  return createExpressionValidator(
    message,
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:?\d{0,2}/
  );
};
