export interface RawSupportedUiLanguages {
  code?: string;
  name?: string;
  nativeLanguageName?: string;
}

export class KioskSupportedUiLanguages {
  public code?: string;
  public name?: string;
  public nativeLanguageName?: string;

  constructor(raw: RawSupportedUiLanguages) {
    this.code = raw.code;
    this.name = raw.name;
    this.nativeLanguageName = raw.nativeLanguageName;
  }
}
