import classNames from 'classnames';

import { Text, TextSize, TextWeight } from '@ac/kiosk-components';

import { ProfilePresentationSubsectionDataElement } from 'utils/regCardPresentationDataMappers';

import './PersonalSubSection.scss';

interface PersonalSubSectionProps {
  title?: string;
  data?: ProfilePresentationSubsectionDataElement[];
  className?: string;
  dataTestSelector?: string;
}

export const PersonalSubSection = ({
  data = [],
  className,
  title,
  dataTestSelector,
}: PersonalSubSectionProps): JSX.Element => {
  return (
    <div
      data-test-selector={dataTestSelector}
      className={classNames('reg-card-personal-subsection', className)}
    >
      {title && (
        <Text size={TextSize.sm} weight={TextWeight.regular}>
          {title}
        </Text>
      )}
      {!!data?.length &&
        data.map(({ value, label, isInSeparateLine }, index) => {
          const isValueArray = Array.isArray(value);

          return (
            <div
              key={`${index}-${label}`}
              className={classNames({
                'reg-card-personal-subsection-details-inline': !isValueArray,
                'spacing-top-sm': title || index,
              })}
            >
              {label && (
                <Text
                  weight={TextWeight.light}
                  className="spacing-inline-end-xs"
                >
                  {label}:
                </Text>
              )}

              {value && isValueArray ? (
                <>
                  {isInSeparateLine ? (
                    (value as string[]).map((singleValue, valueIndex) => (
                      <Text key={valueIndex}>
                        {singleValue}
                        {valueIndex + 1 >= value.length ? '' : ','}
                      </Text>
                    ))
                  ) : (
                    <Text>{(value as string[]).join(', ')}</Text>
                  )}
                </>
              ) : (
                <Text>{value}</Text>
              )}
            </div>
          );
        })}
    </div>
  );
};
