export interface RawKioskLoggedUser {
  userName?: string;
  firstName?: string;
  lastName?: string;
}

export class KioskLoggedUser {
  public userName?: string;
  public firstName?: string;
  public lastName?: string;

  constructor(raw: RawKioskLoggedUser) {
    this.userName = raw.userName;
    this.firstName = raw.firstName;
    this.lastName = raw.lastName;
  }
}
