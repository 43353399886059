import {
  BaseKioskConfigurationEntity,
  RawBaseKioskConfigurationEntity,
} from 'api/KioskApi/entries';

export interface RawKioskConsent {
  consentId: string;
  type?: RawBaseKioskConfigurationEntity;
  isGranted: boolean;
  grantedAt?: string;
  source?: RawBaseKioskConfigurationEntity;
  description?: string;
  expiryDate?: string;
}

export class KioskConsent {
  public consentId: string;
  public type?: BaseKioskConfigurationEntity;
  public isGranted: boolean;
  public grantedAt?: string;
  public source?: BaseKioskConfigurationEntity;
  public description?: string;
  public expiryDate?: string;

  constructor(raw: RawKioskConsent) {
    this.consentId = raw.consentId;
    this.type = raw.type && new BaseKioskConfigurationEntity(raw.type);
    this.isGranted = raw.isGranted;
    this.grantedAt = raw.grantedAt;
    this.source = raw.source && new BaseKioskConfigurationEntity(raw.source);
    this.description = raw.description;
    this.expiryDate = raw.expiryDate;
  }
}
