import { KioskAddress, RawKioskAddress } from './kioskAddress';
import {
  KioskCommunicationChannel,
  RawKioskCommunicationChannel,
} from './kioskCommunicationChannel';
import { KioskConsent, RawKioskConsent } from './kioskConsent';
import {
  KioskIdentityDocument,
  RawKioskIdentityDocument,
} from './kioskIdentityDocument';
import {
  KioskPersonalDetails,
  RawKioskPersonalDetails,
} from './kioskPersonalDetails';
import { KioskPreferenceDetailsDto } from './kioskPreferenceDetails';

export interface RawKioskProfile {
  id: string;
  version: number;
  personalDetails?: RawKioskPersonalDetails;
  addresses?: RawKioskAddress[];
  emails?: RawKioskCommunicationChannel[];
  mobiles?: RawKioskCommunicationChannel[];
  phones?: RawKioskCommunicationChannel[];
  identityDocuments?: RawKioskIdentityDocument[];
  consents?: RawKioskConsent[];
  preferences?: KioskPreferenceDetailsDto[];
}

export class KioskProfile {
  public id: string;
  public version: number;
  public personalDetails?: KioskPersonalDetails;
  public addresses: KioskAddress[];
  public emails: KioskCommunicationChannel[];
  public mobiles: KioskCommunicationChannel[];
  public phones: KioskCommunicationChannel[];
  public identityDocuments: KioskIdentityDocument[];
  public consents: KioskConsent[];
  public preferences: KioskPreferenceDetailsDto[];

  constructor(raw: RawKioskProfile) {
    this.id = raw.id;
    this.version = raw.version;
    this.personalDetails =
      raw.personalDetails && new KioskPersonalDetails(raw.personalDetails);
    this.addresses = (raw.addresses || []).map(
      (item) => new KioskAddress(item)
    );
    this.emails = (raw.emails || []).map(
      (item) => new KioskCommunicationChannel(item)
    );
    this.mobiles = (raw.mobiles || []).map(
      (item) => new KioskCommunicationChannel(item)
    );
    this.phones = (raw.phones || []).map(
      (item) => new KioskCommunicationChannel(item)
    );
    this.identityDocuments = (raw.identityDocuments || []).map(
      (item) => new KioskIdentityDocument(item)
    );
    this.consents = (raw.consents || []).map((item) => new KioskConsent(item));
    this.preferences = raw.preferences || [];
  }
}
