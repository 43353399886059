import { ValidationResult, ValidationSeverity } from '@ac/react-infrastructure';

export enum LengthValidityType {
  minLength,
  maxLength,
}

export const createLengthValidator = (
  validityType: LengthValidityType,
  constraint: number,
  description: string
) => (value: string | undefined): ValidationResult => {
  if (!value) return [];

  const isValid =
    validityType === LengthValidityType.minLength
      ? value.length >= constraint
      : value.length <= constraint;

  return isValid ? [] : [{ severity: ValidationSeverity.error, description }];
};
