import { LogReporter } from '@ac/kiosk-components';

import { LOG_TYPES } from 'configs';

export const validatePdfMimeType = (pdfFile: Blob): void => {
  if (pdfFile.type !== 'application/pdf') {
    LogReporter.log.error(LOG_TYPES.app, {
      message: '[PDF TYPE VALIDATION] Invalid type',
      details: {
        pdfFileType: pdfFile.type,
      },
    });
  }
};
