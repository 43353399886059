import { KioskCommunicationChannel } from 'api/KioskApi/entries';
import {
  EmailSection,
  FieldConfiguration,
} from 'store/settings/interfaces/settingTypes/sectionConfiguration';

const EMAIL_CONFIGURATION_MAP: Record<
  keyof EmailSection,
  keyof KioskCommunicationChannel | undefined
> = {
  detailsField: 'details',
  typeField: 'type',
  isEditable: undefined,
  isRequired: undefined,
  isVisible: undefined,
};

export const validateEmails = (
  emailsConfiguration: EmailSection,
  emails: KioskCommunicationChannel[]
): boolean => {
  if (emailsConfiguration.isRequired && !emails?.length) return false;
  if (!emails?.length) return true;

  return emails.every((email) => {
    return Object.entries(emailsConfiguration).every(
      ([key, fieldConfiguration]: [
        keyof EmailSection,
        FieldConfiguration
      ]): boolean => {
        const emailRecordKey = EMAIL_CONFIGURATION_MAP[key];

        if (emailRecordKey && fieldConfiguration.isRequired) {
          return !!email[emailRecordKey];
        }

        return true;
      }
    );
  });
};
