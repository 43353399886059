import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getRegistrationCardDetails } from 'store/electronicRegistrationProcess/selectors';
import { getValidatedProfileData } from 'store/electronicRegistrationProcess/selectors/profile';
import {
  getDateTimeFormats,
  getFeatureToggles,
  getFieldsConfiguration,
  getGeneralSettings,
} from 'store/settings/selectors';
import { mapPersonalData } from 'utils/regCardPresentationDataMappers';
import { mapPersonalData as mapPersonalDataV2 } from 'utils/regCardPresentationDataMappers/v2/mapPersonalData';

import { ProfilePresentationSectionData } from '../types';

export const usePersonalDetailsConfiguration = (): ProfilePresentationSectionData => {
  const { i18n } = useTranslation();
  const featureToggles = useSelector(getFeatureToggles);
  const validatedProfileData = useSelector(getValidatedProfileData);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const generalSettings = useSelector(getGeneralSettings);
  const dateTimeFormats = useSelector(getDateTimeFormats);
  const cardDetails = useSelector(getRegistrationCardDetails);

  const personalInfo = useMemo(
    () =>
      featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
        ? mapPersonalDataV2(cardDetails, {
            fieldsConfiguration,
            shortDateFormat: dateTimeFormats?.shortDateFormat,
          })
        : mapPersonalData(cardDetails, {
            isMiddleNameEnabled: generalSettings?.MIDDLENAME,
            isSuffixEnabled: generalSettings?.SUFFIX,
            shortDateFormat: dateTimeFormats?.shortDateFormat,
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cardDetails,
      fieldsConfiguration,
      featureToggles,
      generalSettings,
      dateTimeFormats?.shortDateFormat,
      i18n.language,
    ]
  );

  return useMemo(
    () => ({
      data: personalInfo,
      isVisible: featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
        ? fieldsConfiguration?.guestPersonalDetailsObject?.isVisible ?? true
        : true,
      isMissingRequiredInformation:
        featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION && validatedProfileData
          ? !validatedProfileData.personalDetails ||
            !validatedProfileData.alternateDetails
          : false,
    }),
    [personalInfo, featureToggles, fieldsConfiguration, validatedProfileData]
  );
};
