import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  Field,
  FieldMode,
  SelectField,
  SelectOption,
} from '@ac/kiosk-components';
import {
  ArrayFieldStateErrors,
  FormApi,
  formNestedFieldFactory,
  formNestedSingleCheckboxFieldFactory,
  FormSpy,
  generateFieldValuePath,
  getChanges,
} from '@ac/react-infrastructure';

import { KioskTelephoneRegionPrefixes } from 'api/KioskApi/entries';
import {
  getGeneralSettings,
  getMobileCommunicationTypes,
  getPhoneCommunicationTypes,
  getTelephoneRegionPrefixesEntities,
} from 'store/settings/selectors';
import { generateRandomString } from 'utils';
import {
  doesFormContainsErrors,
  mapFieldRenderProps,
  mapSelectOptions,
  setFieldAsTouch,
} from 'utils/form';
import { SubFormActionHeader } from 'views/RegistrationCardEditPersonal/components/SubFormActionHeader/SubFormActionHeader';
import {
  FormProperties,
  FormValues,
  PhoneFormProperties,
  PhonesFormValues,
} from 'views/RegistrationCardEditPersonal/types';

import './SinglePhoneSubForm.scss';

type SubFormValues = {
  [key: string]: PhonesFormValues[];
};

const NestedFormField = formNestedFieldFactory<SubFormValues>();
const NestedCheckboxFormField = formNestedSingleCheckboxFieldFactory<SubFormValues>();

export type PhoneCountryPrefixCodeChangeHandler = (
  value: string | undefined,
  oldValue: string | undefined
) => void;

interface SinglePhoneSubFormProps {
  id?: string;
  reorderControl?: string;
  className?: string;
  title: string;
  path: [string, number];
  formApi: FormApi<FormValues>;
  dataTestSelector?: string;
  onSetAsMainClick?: (identifier: number) => void;
  onRemoveClick?: (identifier: number) => void;
}

export const SinglePhoneSubForm = ({
  id,
  reorderControl,
  className,
  title,
  path,
  formApi,
  dataTestSelector,
  onSetAsMainClick,
  onRemoveClick,
}: SinglePhoneSubFormProps): JSX.Element => {
  const [t] = useTranslation();
  const telephoneRegionPrefixes = useSelector(
    getTelephoneRegionPrefixesEntities
  );
  const suggestedPhonePrefixes = useSelector(getGeneralSettings);
  const mobileCommunicationTypes = useSelector(getMobileCommunicationTypes);
  const phoneCommunicationTypes = useSelector(getPhoneCommunicationTypes);
  const [isPrefixSelected, setPrefixSelected] = useState(false);
  const [isNumberFieldFocused, setNumberFieldFocused] = useState(false);
  const [isPhonePrefixFieldHidden, setPhonePrefixFieldHidden] = useState(false);
  const [isInactiveTypeRemoved, setInactiveTypeRemoved] = useState(false);

  const fieldId = useMemo(() => generateRandomString(), []);
  const [phonePropertyKey, phoneIndex] = useMemo(
    () => path as [FormProperties.phones | FormProperties.mobiles, number],
    [path]
  );

  const getArrayValues = useCallback(() => {
    return formApi.getState().values?.[phonePropertyKey];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phonePropertyKey, formApi, reorderControl]);

  const getCurrentPhoneValues = useCallback(() => {
    return formApi.getState().values?.[phonePropertyKey]?.[phoneIndex];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phonePropertyKey, phoneIndex, formApi, reorderControl]);

  const checkIsSubFormValid = useCallback(() => {
    const addressesErrors = formApi.getState().errors?.[phonePropertyKey] as
      | ArrayFieldStateErrors<PhonesFormValues>
      | undefined;

    const subFormErrors = addressesErrors?.valuesErrors?.[phoneIndex];

    return !doesFormContainsErrors(subFormErrors);
  }, [phoneIndex, phonePropertyKey, formApi]);

  const checkIsTypeInactive = useCallback(() => {
    const currentType = getCurrentPhoneValues()?.type;
    const typeDictionary =
      phonePropertyKey === FormProperties.phones
        ? phoneCommunicationTypes
        : mobileCommunicationTypes;

    const selectedTypeDictionary = typeDictionary?.find(
      (type) => type.id === currentType
    );

    return !selectedTypeDictionary ? false : !selectedTypeDictionary.isActive;
  }, [
    getCurrentPhoneValues,
    mobileCommunicationTypes,
    phoneCommunicationTypes,
    phonePropertyKey,
  ]);

  const isPhonePrefixInitiallyHidden = useMemo(() => {
    const values = getCurrentPhoneValues();
    const phoneNumber = values?.phoneNumber;
    const countryCode = values?.countryCode;

    return Boolean(phoneNumber && !countryCode);
  }, [getCurrentPhoneValues]);

  const isTypeInactive = useMemo(() => {
    return checkIsTypeInactive();
  }, [checkIsTypeInactive]);

  const suggestedOptions = useMemo(() => {
    if (!suggestedPhonePrefixes?.SUGGESTED_PHONE_PREFIXES) return;

    return telephoneRegionPrefixes
      ?.filter(
        (element) =>
          element.code &&
          element.regionName &&
          suggestedPhonePrefixes?.SUGGESTED_PHONE_PREFIXES?.includes(
            element.code
          )
      )
      .sort(
        (
          a: Required<KioskTelephoneRegionPrefixes>,
          b: Required<KioskTelephoneRegionPrefixes>
        ) => a.regionName.localeCompare(b.regionName)
      )
      .map((element: Required<KioskTelephoneRegionPrefixes>) => element.code);
  }, [
    suggestedPhonePrefixes?.SUGGESTED_PHONE_PREFIXES,
    telephoneRegionPrefixes,
  ]);

  const typeOptions = useMemo((): SelectOption[] | undefined => {
    const types =
      phonePropertyKey === FormProperties.phones
        ? phoneCommunicationTypes
        : mobileCommunicationTypes;

    return mapSelectOptions(types, 'description', 'id');
  }, [mobileCommunicationTypes, phoneCommunicationTypes, phonePropertyKey]);

  const prefixOptions = useMemo(() => {
    return telephoneRegionPrefixes
      ?.filter((item) => item.prefix && item.code)
      .map((item: Required<KioskTelephoneRegionPrefixes>) => ({
        title: `${item.prefix} (${item.regionName})`,
        value: item.code,
      }));
  }, [telephoneRegionPrefixes]);

  useEffect(() => {
    setPhonePrefixFieldHidden(isPhonePrefixInitiallyHidden);
  }, [isPhonePrefixInitiallyHidden]);

  useEffect(() => {
    if (!isNumberFieldFocused || isPhonePrefixFieldHidden) return;
    const countryPrefixSelectElement = document.getElementById(fieldId);
    countryPrefixSelectElement?.focus();
  }, [isNumberFieldFocused, isPhonePrefixFieldHidden, fieldId]);

  const handleSetAsMainClick = useCallback(() => {
    onSetAsMainClick?.(phoneIndex);
  }, [phoneIndex, onSetAsMainClick]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick?.(phoneIndex);
  }, [phoneIndex, onRemoveClick]);

  const clearPhoneNumberValue = useCallback(() => {
    const sectionPhoneData = getArrayValues();
    if (!sectionPhoneData) return;
    const newPhones = sectionPhoneData.map((phone, index) => {
      if (index === phoneIndex) return { ...phone, phoneNumber: undefined };

      return phone;
    });
    formApi.change(phonePropertyKey, newPhones);
  }, [getArrayValues, formApi, phonePropertyKey, phoneIndex]);

  const handleDirtyStatusChange = useCallback(() => {
    const isTypeInactiveCheck = checkIsTypeInactive();
    if (isInactiveTypeRemoved || !isTypeInactiveCheck) return;

    const currentValues = formApi.getState().values?.[phonePropertyKey]?.[
      phoneIndex
    ];
    const dataId = currentValues?.id;

    const initialValues = formApi
      .getState()
      .initialValues?.[phonePropertyKey]?.find(
        (element) => element.id === dataId
      );

    if (!initialValues) return;

    const phones = getArrayValues();
    const changes = getChanges(initialValues, currentValues);

    if (
      !isTypeInactiveCheck ||
      !changes ||
      !Object.keys(changes).length ||
      !phones
    )
      return;

    const newPhones = phones.map((phone, index) => {
      if (index === phoneIndex) return { ...phone, type: undefined };

      return phone;
    });

    formApi.change(phonePropertyKey, newPhones);
    setFieldAsTouch(formApi, PhoneFormProperties.type, path);
    setInactiveTypeRemoved(true);
  }, [
    checkIsTypeInactive,
    isInactiveTypeRemoved,
    getArrayValues,
    formApi,
    phonePropertyKey,
    path,
    phoneIndex,
  ]);

  return (
    <div
      className={classNames('reg-card-edit-phone-sub-form', className)}
      id={id}
    >
      {isTypeInactive && !isInactiveTypeRemoved && (
        <FormSpy
          onChange={handleDirtyStatusChange}
          subscription={{ dirtyFields: true }}
        />
      )}

      <FormSpy subscription={{ errors: true }}>
        {(): JSX.Element => (
          <NestedCheckboxFormField
            valuePath={generateFieldValuePath(path)([
              PhoneFormProperties.isPrimary,
            ])}
          >
            {(checkboxFieldRenderProps): JSX.Element => (
              <SubFormActionHeader
                deleteControlEnabled
                mainControlEnabled
                isMain={checkboxFieldRenderProps.input.checked}
                setAsMainButtonDisabled={!checkIsSubFormValid()}
                title={`${title} ${phoneIndex + 1}`}
                onSetAsMainClick={handleSetAsMainClick}
                onRemoveClick={handleRemoveClick}
              />
            )}
          </NestedCheckboxFormField>
        )}
      </FormSpy>

      <div
        data-test-selector={dataTestSelector}
        className="reg-card-edit-phone-sub-form-fields-wrapper"
      >
        <NestedFormField
          valuePath={generateFieldValuePath(path)([PhoneFormProperties.type])}
        >
          {(fieldFieldRenderProps): JSX.Element => (
            <SelectField
              {...mapFieldRenderProps(fieldFieldRenderProps)}
              className="reg-card-edit-phone-sub-form-type"
              label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.TYPE')}
              placeholder={t('SHARED.SELECT')}
              modalTitle={t(
                phonePropertyKey === FormProperties.phones
                  ? 'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.PHONE.SELECT_TYPE_MODAL_TITLE'
                  : 'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.MOBILE.SELECT_TYPE_MODAL_TITLE'
              )}
              options={typeOptions}
              searchable={false}
            />
          )}
        </NestedFormField>

        {!isPhonePrefixFieldHidden && (
          <NestedFormField
            valuePath={generateFieldValuePath(path)([
              PhoneFormProperties.countryCode,
            ])}
          >
            {(fieldFieldRenderProps): JSX.Element => {
              const fieldRenderProps = mapFieldRenderProps(
                fieldFieldRenderProps
              );

              let newValue: string;

              return (
                <SelectField
                  {...fieldRenderProps}
                  id={fieldId}
                  suggestedOptionValues={suggestedOptions}
                  className="reg-card-edit-phone-sub-form-prefix"
                  label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.COUNTRY_CODE')}
                  placeholder={t('SHARED.SELECT')}
                  options={prefixOptions}
                  onChange={(value): void => {
                    newValue = value;
                    clearPhoneNumberValue();
                    fieldRenderProps.onChange(value);
                    setPrefixSelected(true);
                  }}
                  onBlur={(): void => {
                    if (
                      isPhonePrefixInitiallyHidden &&
                      !fieldFieldRenderProps.meta.dirty &&
                      !newValue
                    ) {
                      setPhonePrefixFieldHidden(true);
                    }
                    fieldRenderProps.onBlur();
                  }}
                />
              );
            }}
          </NestedFormField>
        )}

        <NestedFormField
          valuePath={generateFieldValuePath(path)([
            PhoneFormProperties.phoneNumber,
          ])}
        >
          {(fieldFieldRenderProps): JSX.Element => {
            const fieldRenderProps = mapFieldRenderProps(fieldFieldRenderProps);

            return (
              <Field
                {...fieldRenderProps}
                className="reg-card-edit-phone-sub-form-number"
                label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.PHONE_NUMBER')}
                placeholder={t('SHARED.FILL')}
                mode={FieldMode.tel}
                onFocus={(): void => {
                  if (!isPrefixSelected) {
                    setPhonePrefixFieldHidden(false);
                    setNumberFieldFocused(true);
                  } else {
                    fieldRenderProps.onFocus();
                  }
                }}
                onBlur={(): void => {
                  fieldRenderProps.onBlur();
                  setNumberFieldFocused(false);
                }}
              />
            );
          }}
        </NestedFormField>
      </div>
    </div>
  );
};
