import { createAction, updateAction } from '@ac/library-utils/dist/redux-utils';

import { SagaError } from 'types/shared';

import { UpdateComplementaryDetailsPayload } from './interfaces/actionPayloads';

export const clearRegistrationCardEditComplementary = createAction(
  '@registrationCardEditComplementary/clearRegistrationCardEditComplementary'
);

export const updateComplementaryDetails = updateAction<
  UpdateComplementaryDetailsPayload,
  undefined,
  SagaError
>('@registrationCardEditComplementary/updateComplementaryDetails');
