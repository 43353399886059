export const smoothScrollInToCenter = (
  selector: string,
  scrollableComponentSelector: string
): void => {
  const scrollableBody = document.querySelector(scrollableComponentSelector);
  const section = document.querySelector(selector);
  if (!scrollableBody || !section) return;

  const {
    height: listHeight,
    top: listTop,
  } = scrollableBody.getBoundingClientRect();

  const {
    height: optionHeight,
    top: optionTop,
  } = section.getBoundingClientRect();

  const centerOfList = listHeight / 2;
  const centerOfOption = optionHeight / 2;

  const positionToScroll = optionTop + centerOfOption - listTop - centerOfList;

  scrollableBody?.scrollBy({ top: positionToScroll, behavior: 'smooth' });
};
