import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import get from 'lodash.get';

import {
  Button,
  ButtonPattern,
  IconTypes,
  Text,
  TextSize,
} from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';
import {
  ArrayFieldStateErrors,
  FormApi,
  formNestedFieldArrayFactory,
  FormSpy,
} from '@ac/react-infrastructure';

import {
  getDefaultEmailType,
  getDefaultMobileType,
  getDefaultPhoneType,
  getTelephoneRegionPrefixesEntities,
} from 'store/settings/selectors';
import { generateRandomString, smoothScrollInToCenter } from 'utils';
import { doesFormContainsErrors } from 'utils/form';
import { DeleteConfirmationModal } from 'views/RegistrationCardEditPersonal/components/DeleteConfirmationModal/DeleteConfirmationModal';
import { BODY_ID } from 'views/RegistrationCardEditPersonal/config';
import {
  EmailsFormValues,
  FormProperties,
  FormValues,
  PhonesFormValues,
} from 'views/RegistrationCardEditPersonal/types';
import { prepareSingleEmailInitialValues } from 'views/RegistrationCardEditPersonal/utils/prepareInitialValues/prepareEmailsInitialValues';
import { prepareSinglePhoneInitialValues } from 'views/RegistrationCardEditPersonal/utils/prepareInitialValues/preparePhonesInitialValues';

import { SingleEmailSubForm } from '../SingleEmailSubForm/SingleEmailSubForm';
import { SinglePhoneSubForm } from '../SinglePhoneSubForm/SinglePhoneSubForm';

import { getTypeRelatedTranslations } from './translations';

type SubFormValues = {
  [FormProperties.emails]?: EmailsFormValues[];
  [FormProperties.phones]?: PhonesFormValues[];
  [FormProperties.mobiles]?: PhonesFormValues[];
};

const NestedFormFieldArray = formNestedFieldArrayFactory<SubFormValues>();

interface ContactSubSectionFormProps {
  formApi: FormApi<FormValues>;
  type: FormProperties.emails | FormProperties.phones | FormProperties.mobiles;
}

export const ContactSubSectionForm = ({
  formApi,
  type,
}: ContactSubSectionFormProps): JSX.Element => {
  const [t] = useTranslation();
  const telephoneRegionPrefixes = useSelector(
    getTelephoneRegionPrefixesEntities
  );
  const defaultEmailType = useSelector(getDefaultEmailType);
  const defaultPhoneType = useSelector(getDefaultPhoneType);
  const defaultMobileType = useSelector(getDefaultMobileType);

  const [
    requestedDeletedDataIndex,
    setRequestedDeleteDataIndex,
  ] = useState<number>();

  const typeRelatedTranslations = useMemo(() => {
    return getTypeRelatedTranslations(t, type);
  }, [t, type]);

  const defaultType = useMemo(() => {
    switch (type) {
      case FormProperties.emails:
        return defaultEmailType;
      case FormProperties.phones:
        return defaultPhoneType;
      case FormProperties.mobiles:
        return defaultMobileType;
    }
  }, [defaultEmailType, defaultMobileType, defaultPhoneType, type]);

  const SubFormRecordComponent = useMemo(() => {
    switch (type) {
      case FormProperties.emails:
        return SingleEmailSubForm;
      case FormProperties.mobiles:
        return SinglePhoneSubForm;
      case FormProperties.phones:
        return SinglePhoneSubForm;
    }
  }, [type]);

  const prepareSingleRecordData = useMemo(() => {
    switch (type) {
      case FormProperties.emails:
        return prepareSingleEmailInitialValues;
      case FormProperties.mobiles:
        return prepareSinglePhoneInitialValues;
      case FormProperties.phones:
        return prepareSinglePhoneInitialValues;
    }
  }, [type]);

  const checkIsSubFormValid = useCallback(() => {
    const errors = formApi.getState().errors?.[type];

    return !doesFormContainsErrors(errors);
  }, [formApi, type]);

  const scrollToSubSection = useCallback((subSectionElementIndex: string) => {
    window.requestAnimationFrame(() =>
      smoothScrollInToCenter(`#${subSectionElementIndex}`, `#${BODY_ID}`)
    );
  }, []);

  const stringifyDataDetails = useCallback(
    (elementData: EmailsFormValues | PhonesFormValues) => {
      if ('details' in elementData) {
        return elementData.details;
      }

      if ('phoneNumber' in elementData || 'countryCode' in elementData) {
        const prefix = telephoneRegionPrefixes?.find(
          ({ code }) => code === elementData?.countryCode
        );

        return `${prefix?.prefix || ''}${elementData.phoneNumber || ''}`;
      }

      return;
    },
    [telephoneRegionPrefixes]
  );

  const prepareMainReplaceOptions = useCallback(
    (elementIndex: number) => {
      const subSectionValues = formApi.getState().values?.[type] as
        | Array<EmailsFormValues | PhonesFormValues>
        | undefined;

      const errors = formApi.getState().errors?.[type] as
        | ArrayFieldStateErrors<PhonesFormValues>
        | undefined;

      const mappedSubSectionValues = subSectionValues?.map(
        (sectionData, index) => {
          const nestedSubformErrors = errors?.valuesErrors?.[index];
          const isValid = nestedSubformErrors
            ? !doesFormContainsErrors(nestedSubformErrors)
            : true;

          return isValid ? sectionData : undefined;
        }
      );

      return mappedSubSectionValues
        ?.map((subSectionElement, subSectionElementIndex) => {
          if (!subSectionElement || subSectionElementIndex === elementIndex) {
            return undefined;
          }

          return {
            identifier: subSectionElementIndex,
            title: t(
              'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.DELETE_OPTION_LABEL',
              {
                name: `${typeRelatedTranslations.dataType} ${
                  subSectionElementIndex + 1
                }`,
              }
            ),
            description:
              subSectionElement && stringifyDataDetails(subSectionElement),
          };
        })
        .filter(isDefined);
    },
    [typeRelatedTranslations, formApi, stringifyDataDetails, t, type]
  );

  const closeDeleteModal = useCallback(() => {
    setRequestedDeleteDataIndex(undefined);
  }, []);

  const setSubSectionRecordAsMain = useCallback(
    (elementIndex: number) => {
      const subSectionValues:
        | undefined
        | Array<EmailsFormValues | PhonesFormValues> = formApi.getState()
        .values?.[type];
      if (!subSectionValues) return;

      const oldPrimaryIndex = subSectionValues.findIndex(
        ({ isPrimary }) => isPrimary
      );

      const newSubSectionValues = subSectionValues.map((value, index) => {
        if (index === oldPrimaryIndex) return { ...value, isPrimary: false };
        if (index === elementIndex) return { ...value, isPrimary: true };

        return value;
      });

      formApi.change(FormProperties[type], [...newSubSectionValues]);
    },
    [formApi, type]
  );

  const removeSelectedRecord = useCallback(
    (newMainValueIndex?: number) => {
      const subSectionValues:
        | undefined
        | Array<PhonesFormValues | PhonesFormValues> = formApi.getState()
        .values?.[type];
      if (!subSectionValues) return;

      const newSubSectionValues = subSectionValues
        .map((value, index) => {
          if (index === requestedDeletedDataIndex) return undefined;
          if (index === newMainValueIndex) return { ...value, isPrimary: true };

          return value;
        })
        .filter(isDefined);

      formApi.change(type, newSubSectionValues);
      closeDeleteModal();
    },
    [closeDeleteModal, formApi, requestedDeletedDataIndex, type]
  );

  const createNewRecord = useCallback(() => {
    const subSectionValues = formApi.getState().values?.[type];
    const newSectionRecord = prepareSingleRecordData(
      subSectionValues?.length
        ? { type: defaultType }
        : { type: defaultType, isPrimary: true }
    );

    formApi.change(
      type,
      subSectionValues
        ? [...subSectionValues, newSectionRecord]
        : [newSectionRecord]
    );

    if (subSectionValues?.length) {
      scrollToSubSection(`${type}-${subSectionValues?.length}`);
    }
  }, [defaultType, formApi, prepareSingleRecordData, scrollToSubSection, type]);

  const requestedDeletePhoneModalData = useMemo(() => {
    if (!isDefined(requestedDeletedDataIndex)) return;
    const subSectionValues = formApi.getState().values?.[type];
    const subSectionRecord: EmailsFormValues | PhonesFormValues | undefined =
      subSectionValues?.[requestedDeletedDataIndex];

    if (!subSectionRecord) return;

    const replacementRequired = Boolean(
      subSectionRecord.isPrimary &&
        subSectionValues?.length &&
        subSectionValues.length > 1
    );

    const replacementOptions = replacementRequired
      ? prepareMainReplaceOptions(requestedDeletedDataIndex)
      : [];

    return {
      isMain: replacementRequired,
      title: replacementOptions?.length
        ? typeRelatedTranslations.deleteMainTitle
        : typeRelatedTranslations.deleteAdditionalTitle,
      description: replacementOptions?.length
        ? typeRelatedTranslations.deleteMainHint
        : stringifyDataDetails(subSectionRecord),
      options: replacementOptions,
    };
  }, [
    formApi,
    prepareMainReplaceOptions,
    requestedDeletedDataIndex,
    stringifyDataDetails,
    type,
    typeRelatedTranslations,
  ]);

  return (
    <>
      <FormSpy subscription={{ values: true, errors: true }}>
        {(): JSX.Element => {
          const isFormValid = checkIsSubFormValid();

          return (
            <div className="validation-header-wrapper">
              <Text size={TextSize.lg}>{typeRelatedTranslations.title}</Text>
              <Button
                disabled={!isFormValid}
                onClick={createNewRecord}
                pattern={ButtonPattern.tertiary}
                icon={IconTypes.plus}
                className="add-new-data-action-button"
              >
                {typeRelatedTranslations.addNewButton}
              </Button>

              {requestedDeletePhoneModalData && (
                <DeleteConfirmationModal
                  withReplacementOptions={Boolean(
                    requestedDeletePhoneModalData.isMain &&
                      requestedDeletePhoneModalData.options?.length
                  )}
                  onCancelClick={closeDeleteModal}
                  onConfirmClick={removeSelectedRecord}
                  header={requestedDeletePhoneModalData.title}
                  description={requestedDeletePhoneModalData.description}
                  replaceOptions={requestedDeletePhoneModalData.options}
                />
              )}
            </div>
          );
        }}
      </FormSpy>

      <NestedFormFieldArray valuePath={[type]} subscription={{}}>
        {(fieldArray): JSX.Element | JSX.Element[] =>
          get(fieldArray.fields, 'length') ? (
            fieldArray.fields.map((path, _, name) => (
              <SubFormRecordComponent
                id={path.join('-')}
                reorderControl={generateRandomString()}
                key={name}
                className="spacing-bottom-lg"
                path={path}
                title={typeRelatedTranslations.dataType}
                formApi={formApi}
                onSetAsMainClick={setSubSectionRecordAsMain}
                onRemoveClick={setRequestedDeleteDataIndex}
              />
            ))
          ) : (
            <Text className="spacing-top-lg spacing-bottom-xs">
              {t('SHARED.NO_CONTACT')}
            </Text>
          )
        }
      </NestedFormFieldArray>
    </>
  );
};
