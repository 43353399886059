import {
  KioskConfigurationConsent,
  KioskConsent,
  KioskUpdateConsents,
} from 'api/KioskApi/entries';
import { DateManager, DEFAULT_SERVER_DATE_FORMAT } from 'utils/DateManager';

import { BaseObject } from 'types/shared';

export const prepareUpdateTermsAndConditionPayload = (
  consentFormValues: BaseObject<boolean>,
  profileContents?: KioskConsent[],
  consentDictionary?: KioskConfigurationConsent[],
  businessDate?: string
): KioskUpdateConsents | undefined => {
  if (
    !Object.values(consentFormValues).length ||
    !profileContents ||
    !businessDate ||
    !consentDictionary
  ) {
    return;
  }

  const currentConsents = profileContents?.map((consent) => [
    consent.consentId,
    consent.isGranted,
  ]);

  const changedTermsAndConditionEntries = Object.entries(
    consentFormValues
  ).filter(([id, value]) => {
    const existingConsent = currentConsents?.find(
      ([currentConsentId]) => id === currentConsentId
    );

    return existingConsent ? existingConsent[1] !== value : value;
  });

  if (!changedTermsAndConditionEntries.length) return;

  return changedTermsAndConditionEntries.reduce(
    (acc, [id, value]) => {
      if (value) {
        const consentOption = consentDictionary.find(
          (consent) => consent.id === id
        );

        acc.grantConsents.push({
          consentId: id,
          expiryDate: consentOption?.defaultExpiryPeriod
            ? DateManager.addDays(
                businessDate,
                consentOption?.defaultExpiryPeriod,
                DEFAULT_SERVER_DATE_FORMAT
              )
            : undefined,
        });
      } else {
        acc.revokeConsents.push(id);
      }

      return acc;
    },
    {
      grantConsents: [],
      revokeConsents: [],
    } as Required<KioskUpdateConsents>
  );
};
