import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Offline } from 'react-detect-offline';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  ConnectionModal,
  ErrorModal,
  LoadingModal,
  LogReporter,
  MagicButton,
  MagicButtonHorizontalPosition,
  MagicButtonVerticalPosition,
} from '@ac/kiosk-components';
import { LoginService } from '@ac/library-utils/dist/services';

import { EnvironmentSettingsModal } from 'components';
import { LOG_MESSAGES_TITLES, LOG_TYPES } from 'configs/logs';
import { paths } from 'configs/paths';
import {
  restartApplication,
  setCurrentDevice,
  setCurrentProperty,
} from 'store/app/actions';
import {
  clearAllGlobalErrors,
  clearProcessFlowData,
} from 'store/globalActions';
import {
  getGlobalErrors,
  getGlobalStoreLoadingState,
} from 'store/globalSelectors';
import { refetchConfigurationJob } from 'store/settings/actions';
import { getErrorMessage } from 'utils/errorHelpers';
import { useRouter } from 'utils/hooks';

import './AppStateHandler.scss';

export const AppStateHandler = (
  props: PropsWithChildren<unknown>
): JSX.Element => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isServiceModalOpen, setServiceModalOpened] = useState(false);

  const isGlobalLoading = useSelector(getGlobalStoreLoadingState);
  const globalErrors = useSelector(getGlobalErrors);

  const someError = Boolean(globalErrors.length);

  const isMagicButtonAvailable = useMemo(() => {
    const pathsWithMagicButton = [
      paths.EXTERNAL_DEVICE_ENTRY_PATH,
      paths.LOGIN,
      paths.SETUP,
    ];

    return pathsWithMagicButton.some((path) => router.isCurrentPath(path));
  }, [router]);

  const toggleServiceModal = useCallback(() => {
    setServiceModalOpened((prevState) => !prevState);
  }, []);

  const handleGlobalError = useCallback(async () => {
    dispatch(clearAllGlobalErrors());

    if (router.isCurrentPath(paths.LOGIN) || router.isAnySameDevicePath) {
      dispatch(restartApplication());
    } else if (
      router.isCurrentPath(paths.SETUP) ||
      router.isCurrentPath(paths.EXTERNAL_DEVICE_ENTRY_PATH)
    ) {
      dispatch(setCurrentProperty(null));
      dispatch(setCurrentDevice(null));
      dispatch(refetchConfigurationJob.stop());
      dispatch(clearProcessFlowData());
      router.goTo(paths.LOGIN, { replace: true });
    } else {
      dispatch(clearProcessFlowData());
      router.goTo(paths.EXTERNAL_DEVICE_ENTRY_PATH, { replace: true });
    }
  }, [router, dispatch]);

  const handleConnectionStateChange = useCallback((isOnline: boolean) => {
    const connectionChangeMessage = isOnline
      ? LOG_MESSAGES_TITLES.connectionReceivedLogMessage
      : LOG_MESSAGES_TITLES.connectionLostLogMessage;

    if (isOnline) {
      if (!LoginService.isTokenRefreshScheduled()) {
        LoginService.refreshToken();
      }
    }

    LogReporter.log.warning(
      LOG_TYPES.app,
      {
        message: connectionChangeMessage,
      },
      true
    );
  }, []);

  return (
    <>
      {isMagicButtonAvailable && (
        <MagicButton
          dataTestSelector="service-modal-magic-button"
          positionHorizontal={MagicButtonHorizontalPosition.left}
          positionVertical={MagicButtonVerticalPosition.top}
          width={90}
          height={90}
          pressTimeout={5000}
          onLongPress={toggleServiceModal}
          className="service-modal-magic-button"
        />
      )}

      {isServiceModalOpen && (
        <EnvironmentSettingsModal onCancelClick={toggleServiceModal} />
      )}
      {props.children}

      {someError && (
        <ErrorModal
          dataTestSelector="app-state-handler-error-modal"
          onConfirm={handleGlobalError}
          description={getErrorMessage(globalErrors, t)}
        />
      )}

      {!someError && isGlobalLoading && (
        <LoadingModal dataTestSelector="app-state-handler-loading-modal" />
      )}

      <Offline
        onChange={handleConnectionStateChange}
        polling={{
          enabled: true,
          url: acConfig.healthCheckUrls?.registrationCard,
        }}
      >
        <ConnectionModal dataTestSelector="app-state-handler-connection-modal" />
      </Offline>
    </>
  );
};
