import {
  BaseKioskConfigurationEntity,
  RawBaseKioskConfigurationEntity,
} from '../shared';

export interface RawKioskMembershipLevel
  extends RawBaseKioskConfigurationEntity {
  color?: string;
}

export class KioskMembershipLevel extends BaseKioskConfigurationEntity {
  public color?: string;

  constructor(raw: RawKioskMembershipLevel) {
    super(raw);
    this.color = raw.color;
  }
}
