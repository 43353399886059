import { PropertyPermissions } from 'store/app/interfaces/state';

export const getMissingRequiredPermissionNames = (
  permissions: PropertyPermissions
): string[] => {
  return Object.entries(permissions)
    .filter(([, permission]) => {
      return permission.isRequired && !permission.isGranted;
    })
    .map(([key, permission]) => permission.name || key);
};
