export const CustomMessagesCodesMap = {
  DISCLAIMER: {
    key: 'EREGCARDDISCLAIMER',
    switch: 'EREGSHOWDISCLAIMER',
  },
  GREETINGS_MESSAGE: {
    key: 'EREGCARDGREETINGSMESSAGE',
    switch: 'EREGSHOWGREETINGSMESSAGE',
  },
  SCREEN_SAVER_MESSAGE: {
    key: 'EREGCARDSCREENSAVERMESSAGE',
    switch: 'EREGSHOWSCREENSAVERMESSAGE',
  },
  EDITING_INSTRUCTION_MAIN_TEXT: {
    key: 'EREGCARDEDITINGINSTRUCTIONMAINTEXT',
    switch: 'EREGSHOWEDITINGINSTRUCTIONMAINTEXT',
  },
  EDITING_INSTRUCTION_ADDITIONAL_TEXT: {
    key: 'EREGCARDEDITINGINSTRUCTIONADDITIONALTEXT',
    switch: 'EREGSHOWEDITINGINSTRUCTIONADDITIONALTEXT',
  },
  CONFIRMATION_MESSAGE: {
    key: 'EREGCARDCONFIRMATIONMESSAGE',
    switch: 'EREGSHOWCONFIRMATIONMESSAGE',
  },
  CONFIRMATION_MESSAGE_SEPARATE_DEVICE: {
    key: 'EREGCARDCONFIRMATIONMESSAGESEPARATEDEVICE',
    switch: 'EREGSHOWCONFIRMATIONMESSAGESEPARATEDEVICE',
  },
};

export interface CustomMessagesSettingsStorage {
  DISCLAIMER?: string;
  GREETINGS_MESSAGE?: string;
  SCREEN_SAVER_MESSAGE?: string;
  EDITING_INSTRUCTION_MAIN_TEXT?: string;
  EDITING_INSTRUCTION_ADDITIONAL_TEXT?: string;
  CONFIRMATION_MESSAGE?: string;
  CONFIRMATION_MESSAGE_SEPARATE_DEVICE?: string;
}
