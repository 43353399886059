import i18next from 'i18next';

import { TIME_PICKER_TIME_FORMAT, TimePickerField } from '@ac/kiosk-components';
import { TimeFormat } from '@ac/library-api';
import {
  composeValidators,
  formFieldFactory,
  ValidationExpression,
} from '@ac/react-infrastructure';

import { DateManager, TimeFormats } from 'utils/DateManager';
import {
  CompareDateType,
  createTimeCompareValidator,
  mapFieldRenderProps,
} from 'utils/form';

import { BaseObject } from 'types/shared';

import { BasicFieldProvider, ComponentsData } from './basicFieldProvider';

const FormField = formFieldFactory<BaseObject>();

export class TimeFieldProvider extends BasicFieldProvider<
  typeof FormField,
  typeof TimePickerField
> {
  private get formattedMinTimeValue(): string | undefined {
    const { typeConstraints } = this.reservationHeaderDefinition;

    return typeConstraints?.minValue
      ? DateManager.getFormattedTime(
          typeConstraints.minValue,
          this.config.timeFormat,
          TimeFormat.H24
        )
      : undefined;
  }

  private get formattedMaxTimeValue(): string | undefined {
    const { typeConstraints } = this.reservationHeaderDefinition;

    return typeConstraints?.maxValue
      ? DateManager.getFormattedTime(
          typeConstraints.maxValue,
          this.config.timeFormat,
          TimeFormat.H24
        )
      : undefined;
  }

  private get placeholder(): string {
    const placeholder = this.config.timeFormat
      ? TimeFormats[this.config.timeFormat] || TimeFormats.H24
      : TimeFormats.H24;

    return placeholder.toUpperCase();
  }

  getValidationSchema(): ValidationExpression<BaseObject<string>> | undefined {
    const { typeConstraints } = this.reservationHeaderDefinition;

    const validators = [
      ...(typeConstraints?.minValue
        ? [
            createTimeCompareValidator(
              i18next.t(
                'REGISTRATION_CARD_EDIT_COMPLEMENTARY.VALIDATION_MESSAGES.MIN_VALUE',
                {
                  value: this.formattedMinTimeValue,
                }
              ),
              typeConstraints.minValue as string,
              CompareDateType.isSameOrBefore,
              TIME_PICKER_TIME_FORMAT
            ),
          ]
        : []),
      ...(typeConstraints?.maxValue
        ? [
            createTimeCompareValidator(
              i18next.t(
                'REGISTRATION_CARD_EDIT_COMPLEMENTARY.VALIDATION_MESSAGES.MAX_VALUE',
                {
                  value: this.formattedMaxTimeValue,
                }
              ),
              typeConstraints.maxValue as string,
              CompareDateType.isSameOrAfter,
              TIME_PICKER_TIME_FORMAT
            ),
          ]
        : []),
    ];

    return validators.length
      ? composeValidators<BaseObject>(...validators)
      : undefined;
  }

  getComponentsData(): ComponentsData<
    typeof FormField,
    typeof TimePickerField
  > {
    const { typeConstraints } = this.reservationHeaderDefinition;

    return {
      FormField,
      formFieldRenderPropsMapper: mapFieldRenderProps,
      Component: TimePickerField,
      componentProps: {
        label: this.reservationHeaderDefinition.displayName,
        min: typeConstraints?.minValue as string | undefined,
        max: typeConstraints?.maxValue as string | undefined,
        allowClear: true,
        placeholder: this.placeholder,
        fieldDisplayFormat: this.config.timeFormat,
      },
    };
  }
}
