import { useTranslation } from 'react-i18next';

import { ConfirmModal, IconTypes } from '@ac/kiosk-components';

interface Props {
  dataTestSelector?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const AbortChangesWarningModal = ({
  dataTestSelector,
  onConfirm,
  onCancel,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ConfirmModal
      dataTestSelector={dataTestSelector}
      icon={IconTypes.exclamationMark}
      onPrimaryClick={onConfirm}
      onSecondaryClick={onCancel}
      primaryButtonLabel={t(
        'REGISTRATION_CARD_PURCHASE_ELEMENTS.ABORT_CHANGES_WARNING_MODAL.PRIMARY_BUTTON'
      )}
      description={t(
        'translations:REGISTRATION_CARD_PURCHASE_ELEMENTS.ABORT_CHANGES_WARNING_MODAL.DESCRIPTION'
      )}
    />
  );
};
