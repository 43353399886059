import { KioskAddress } from 'api/KioskApi/entries';
import {
  AddressSection,
  FieldConfiguration,
} from 'store/settings/interfaces/settingTypes/sectionConfiguration';

const ADDRESS_CONFIGURATION_MAP: Record<
  keyof AddressSection,
  keyof KioskAddress | undefined
> = {
  addressLine1Field: 'addressLine1',
  addressLine2Field: 'addressLine2',
  cityField: 'city',
  countryField: 'country',
  districtField: 'district',
  postalCodeField: 'postalCode',
  stateField: 'state',
  typeField: 'type',
  isEditable: undefined,
  isRequired: undefined,
  isVisible: undefined,
};

export const validateAddresses = (
  addressesConfiguration: AddressSection,
  addresses?: KioskAddress[]
): boolean => {
  if (addressesConfiguration.isRequired && !addresses?.length) return false;
  if (!addresses?.length) return true;

  return addresses.every((address) => {
    return Object.entries(addressesConfiguration).every(
      ([key, fieldConfiguration]: [
        keyof AddressSection,
        FieldConfiguration
      ]): boolean => {
        const addressRecordKey = ADDRESS_CONFIGURATION_MAP[key];

        if (addressRecordKey && fieldConfiguration.isRequired) {
          return !!address[addressRecordKey];
        }

        return true;
      }
    );
  });
};
