export interface RawKioskStateDetails {
  code?: string;
  countryCode?: string;
  name?: string;
}

export class KioskStateDetails {
  public code?: string;
  public countryCode?: string;
  public name?: string;

  constructor(raw: RawKioskStateDetails) {
    this.code = raw.code;
    this.countryCode = raw.countryCode;
    this.name = raw.name;
  }
}
