import { PreferenceOptionsGroup } from 'store/electronicRegistrationProcess/interfaces/preferenceOptions/preferenceOptionsGroup';

import { PreferenceSectionPresentationDataItem } from './types';

export const mapPreferenceSectionData = (
  chosenPreferences: PreferenceOptionsGroup[]
): PreferenceSectionPresentationDataItem[] => {
  return chosenPreferences
    .filter((preferenceGroup) =>
      preferenceGroup.preferences.some((preference) => preference.isSelected)
    )
    .map((preferenceGroup) => {
      const { description, preferences } = preferenceGroup;
      const joinedPreferences = preferences
        .filter((preference) => preference.isSelected)
        .map((preference) => preference.description)
        .join(', ');

      return {
        label: description || '',
        value: joinedPreferences,
      };
    });
};
