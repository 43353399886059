export enum KioskReservationHeaderType {
  Enum = 'Enum',
  LocalTime = 'LocalTime',
  LocalDateTime = 'LocalDateTime',
  LocalDate = 'LocalDate',
  Boolean = 'Boolean',
  Decimal = 'Decimal',
  Long = 'Long',
  Integer = 'Integer',
  String = 'String',
}
