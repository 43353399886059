export const COMMUNICATION_MODES = {
  email: 'Email',
  phone: 'Phone',
  mobile: 'Mobile',
};

export const DEFAULT_CONTACT_MODE_ORDER = [
  COMMUNICATION_MODES.mobile,
  COMMUNICATION_MODES.phone,
  COMMUNICATION_MODES.email,
];
