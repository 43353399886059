import { extendDataByDisplaySequence } from 'utils/extendDataByDisplaySequence';

import { sortDisplayElements } from './sortDisplayElements';

export const sortByTypeOrder = <
  TData extends { type?: { code?: string | undefined } },
  TDictionary extends {
    code?: string | undefined;
    displaySequence?: number | undefined;
  }
>(
  data: TData[],
  dictionary: TDictionary[]
): Array<TData & { displaySequence?: number }> => {
  return sortDisplayElements(extendDataByDisplaySequence(data, dictionary));
};
