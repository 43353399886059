import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  MagicButton,
  MagicButtonHorizontalPosition,
  MagicButtonVerticalPosition,
} from '@ac/kiosk-components';

import { ScreenSaver, View } from 'components';
import { paths } from 'configs';
import { startPollingRegistrationCard } from 'store/app/actions';
import { startElectronicRegistrationProcess } from 'store/electronicRegistrationProcess/actions';
import {
  getIsElectronicRegistrationProcessInitialized,
  getIsRegistrationCardReceived,
} from 'store/electronicRegistrationProcess/selectors';
import { prepareSetup, refetchConfigurationJob } from 'store/settings/actions';
import { getCustomMessages, getImages } from 'store/settings/selectors';
import { useRouter } from 'utils/hooks';

import './Welcome.scss';

export const Welcome = (): JSX.Element => {
  const dispatch = useDispatch();
  const router = useRouter();

  const settingsImages = useSelector(getImages);
  const customMessages = useSelector(getCustomMessages);
  const isRegistrationCardReceived = useSelector(getIsRegistrationCardReceived);
  const isElectronicRegistrationProcessInitialized = useSelector(
    getIsElectronicRegistrationProcessInitialized
  );

  useEffect(() => {
    dispatch(startPollingRegistrationCard());
    dispatch(refetchConfigurationJob.start());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isRegistrationCardReceived) return;
    dispatch(startElectronicRegistrationProcess.trigger());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRegistrationCardReceived]);

  useEffect(() => {
    if (!isElectronicRegistrationProcessInitialized) return;
    router.goTo(paths.REGISTRATION_CARD_SUMMARY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isElectronicRegistrationProcessInitialized]);

  const forceRefreshSetup = (): void => {
    dispatch(prepareSetup.trigger({ withDefaultLanguage: true }));
  };

  return (
    <View>
      <MagicButton
        width={90}
        height={90}
        positionVertical={MagicButtonVerticalPosition.top}
        positionHorizontal={MagicButtonHorizontalPosition.right}
        pressTimeout={3000}
        onLongPress={forceRefreshSetup}
        onDoubleClick={forceRefreshSetup}
        className="refresh-configuration-magic-button"
      />
      <ScreenSaver
        screenSaverImage={settingsImages?.SCREEN_SAVER}
        screenSaverMessage={customMessages?.SCREEN_SAVER_MESSAGE}
      />
    </View>
  );
};
