import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Action } from '@ac/library-utils/dist/declarations';

import { ElectronicRegistrationProcessApi } from 'api/KioskApi';
import { KIOSK_SESSION_ID_HEADER } from 'configs/constants';
import { fetchRegistrationCardDetails } from 'store/electronicRegistrationProcess/actions';
import { getElectronicRegistrationProcessId } from 'store/electronicRegistrationProcess/selectors';
import { handleSagaError } from 'utils/sagas';

import { SagasGenerator } from 'types/shared';

import { UpdateComplementaryDetailsPayload } from './interfaces/actionPayloads';
import * as actions from './actions';

function* updateComplementaryDetails(
  action: Action<UpdateComplementaryDetailsPayload>
): SagasGenerator {
  try {
    const kioskSessionId: string = yield select(
      getElectronicRegistrationProcessId
    );

    yield ElectronicRegistrationProcessApi.updateReservationHeader({
      data: {
        reservationHeader: action.payload.data,
      },
      customConfig: {
        headers: {
          [KIOSK_SESSION_ID_HEADER]: kioskSessionId,
        },
      },
    });

    yield put(fetchRegistrationCardDetails.trigger(kioskSessionId));
    yield put(actions.updateComplementaryDetails.success());

    if (action.payload.onSuccessfulUpdate) {
      yield call(action.payload.onSuccessfulUpdate);
    }
  } catch (error) {
    yield put(
      actions.updateComplementaryDetails.failure(handleSagaError(error))
    );
  }
}

export function* registrationCardEditComplementaryViewSagas(): SagasGenerator {
  yield takeLatest(
    actions.updateComplementaryDetails.trigger,
    updateComplementaryDetails
  );
}
