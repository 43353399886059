import {
  KioskCommunicationChannel,
  KioskCommunicationType,
} from 'api/KioskApi/entries';
import { extendDataByDisplaySequence } from 'utils';
import { sortByModeOrder, sortDisplayElements } from 'utils/sorting';

import { ProfilePresentationDataElement } from './types';

interface ContactsData {
  emails?: KioskCommunicationChannel[];
  mobiles?: KioskCommunicationChannel[];
  phones?: KioskCommunicationChannel[];
}

interface MapContactsDataSettings {
  modeOrder?: string[];
  isEmailEnabled?: boolean;
  isMobileEnabled?: boolean;
  isPhoneEnabled?: boolean;
  communicationTypes?: KioskCommunicationType[];
  showOnlyPrimary?: boolean;
}

export const mapContactsData = (
  data?: ContactsData,
  settings?: MapContactsDataSettings
): ProfilePresentationDataElement[] => {
  const contacts = [
    ...((settings?.isMobileEnabled && data?.mobiles) || []),
    ...((settings?.isPhoneEnabled && data?.phones) || []),
    ...((settings?.isEmailEnabled && data?.emails) || []),
  ];

  if (!settings?.communicationTypes?.length || !contacts.length) return [];

  const communicationChannelsWithDisplaySequence = extendDataByDisplaySequence(
    contacts,
    settings?.communicationTypes
  );

  const sortedData = sortDisplayElements(
    communicationChannelsWithDisplaySequence
  );

  const primaryContacts = sortByModeOrder(
    sortedData.filter((contact) => contact.isPrimary),
    settings?.modeOrder
  );

  const nonPrimaryContacts = sortByModeOrder(
    sortedData.filter((contact) => !contact.isPrimary),
    settings?.modeOrder
  );

  const contactsArray = settings.showOnlyPrimary
    ? [...primaryContacts]
    : [...primaryContacts, ...nonPrimaryContacts];

  return [
    ...contactsArray.map((record) => ({
      isCollapsible: !record.isPrimary,
      sectionData: [
        {
          value: record.details,
          label: record.type?.description,
        },
      ],
    })),
  ];
};
