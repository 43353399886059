import { DateManager } from 'utils';

import { MapComplementaryDataOptions } from '../types';

export const localDateTimeMap = (
  value: unknown,
  options?: MapComplementaryDataOptions
): string | undefined => {
  return value && typeof value === 'string'
    ? DateManager.getFormattedDateTime(value, {
        dateFormat: options?.shortDateFormat,
        timeFormat: options?.timeFormat,
      })
    : undefined;
};
