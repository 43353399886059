import { getListAction } from '@ac/library-utils/dist/redux-utils';

import { KioskDevice, KioskProperty } from 'api/KioskApi/entries';

import { SagaError } from 'types/shared';

export const fetchPropertyList = getListAction<
  undefined,
  KioskProperty[],
  SagaError
>('@login/fetchPropertyList');

export const fetchDeviceList = getListAction<
  undefined,
  KioskDevice[],
  SagaError
>('@login/fetchDeviceList');
