import moment, { MomentInput, MomentInputObject } from 'moment';

import { TimeFormat } from '@ac/library-api';

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export const DEFAULT_SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_SERVER_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const TimeFormats = {
  [TimeFormat.H12]: 'h:mm A',
  [TimeFormat.H24]: 'HH:mm',
  server: 'HH:mm:ss',
};

const getFormattedDateWithConditionalYear = (
  date: MomentInputObject,
  format: string = DEFAULT_DATE_FORMAT,
  inputFormat?: string
): string => {
  const formatWithoutYear = format.replace(/[^\w]?[yY]+[^\w]?/g, '');
  const isDataObjectMissingYear = typeof date === 'object' && !date.year;

  if (date && isDataObjectMissingYear) {
    return moment(date, inputFormat).format(formatWithoutYear);
  }

  return moment(date, inputFormat).format(format);
};

const getFormattedDate = (date: MomentInput, format?: string): string => {
  if (format) {
    return moment(date, DEFAULT_SERVER_DATE_FORMAT).format(format);
  }

  return moment(date, DEFAULT_SERVER_DATE_FORMAT).format(
    DEFAULT_SERVER_DATE_FORMAT
  );
};

const getFormattedDateTime = (
  date: MomentInput,
  formats?: {
    dateFormat?: string;
    timeFormat?: TimeFormat;
  }
): string => {
  const dateFormat = formats?.dateFormat
    ? formats.dateFormat
    : DEFAULT_DATE_FORMAT;
  const timeFormat = formats?.timeFormat
    ? TimeFormats[formats.timeFormat]
    : TimeFormats.H24;

  const dateTimeFormat = `${dateFormat} ${timeFormat}`;

  return moment(date, DEFAULT_SERVER_DATE_TIME_FORMAT).format(dateTimeFormat);
};

const getFormattedTime = (
  date: MomentInput,
  format: keyof typeof TimeFormats = 'server',
  inputFormat: keyof typeof TimeFormats = 'server'
): string => {
  return moment(date, TimeFormats[inputFormat]).format(TimeFormats[format]);
};

const getDiffBetween = (
  arrival: MomentInput,
  departure: MomentInput
): number => {
  return moment(departure).diff(arrival, 'days');
};

const getObjectDate = (
  date: string | Date,
  format: string = DEFAULT_SERVER_DATE_FORMAT
): {
  day: number;
  month: number;
  year: number | undefined;
} => {
  const momentDate = moment(date, format);

  return {
    day: momentDate.date(),
    month: momentDate.month() + 1,
    year:
      typeof date === 'string' && date.length < format.length
        ? undefined
        : momentDate.year(),
  };
};

const checkIfBefore = (
  date: MomentInput,
  compareTo: MomentInput,
  inputFormat?: string
): boolean => {
  return moment(date, inputFormat).isBefore(compareTo);
};

const checkIfAfter = (
  date: MomentInput,
  compareTo: MomentInput,
  inputFormat?: string
): boolean => {
  return moment(date, inputFormat).isAfter(compareTo);
};

const checkIfSame = (
  date: MomentInput,
  compareTo: MomentInput,
  inputFormat?: string
): boolean => {
  return moment(date, inputFormat).isSame(compareTo);
};

const checkIfSameOrAfter = (
  date: MomentInput,
  compareTo: MomentInput,
  inputFormat?: string
): boolean => {
  return moment(date, inputFormat).isSameOrAfter(compareTo);
};

const checkIfSameOrBefore = (
  date: MomentInput,
  compareTo: MomentInput,
  inputFormat?: string
): boolean => {
  return moment(date, inputFormat).isSameOrBefore(compareTo);
};

const addDays = (
  date: string | Date,
  numberOfDays: number = 1,
  dateFormat: string = DEFAULT_DATE_FORMAT
): string => {
  return moment(date).add(numberOfDays, 'days').format(dateFormat);
};

const getMomentInput = (date: string, format?: string): MomentInput => {
  return moment(date, format);
};

export const DateManager = {
  getFormattedDateWithConditionalYear,
  getFormattedDateTime,
  getFormattedDate,
  getFormattedTime,
  getDiffBetween,
  getObjectDate,
  getMomentInput,
  checkIfBefore,
  checkIfAfter,
  checkIfSame,
  checkIfSameOrAfter,
  checkIfSameOrBefore,
  addDays,
};
