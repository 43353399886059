import { isDefined } from '@ac/library-utils/dist/utils';

import { ImagesSettingsStorage } from '../interfaces';

export const revokeImageObjectUrls = (
  imagesStorage: ImagesSettingsStorage
): void => {
  const urlsToRevoke: string[] = Object.values(imagesStorage).filter(
    isDefined
  ) as string[];
  urlsToRevoke.forEach((url) => URL.revokeObjectURL(url));
};
