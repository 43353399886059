import { KioskCommunicationChannel } from 'api/KioskApi/entries';
import {
  FieldConfiguration,
  MobileSection,
} from 'store/settings/interfaces/settingTypes/sectionConfiguration';

const MOBILE_CONFIGURATION_MAP: Record<
  keyof MobileSection,
  keyof KioskCommunicationChannel | undefined
> = {
  detailsField: 'details',
  typeField: 'type',
  isEditable: undefined,
  isRequired: undefined,
  isVisible: undefined,
};

export const validateMobiles = (
  mobilesConfiguration: MobileSection,
  mobiles: KioskCommunicationChannel[]
): boolean => {
  if (mobilesConfiguration.isRequired && !mobiles?.length) return false;
  if (!mobiles?.length) return true;

  return mobiles.every((mobile) => {
    return Object.entries(mobilesConfiguration).every(
      ([key, fieldConfiguration]: [
        keyof MobileSection,
        FieldConfiguration
      ]): boolean => {
        const mobileRecordKey = MOBILE_CONFIGURATION_MAP[key];

        if (mobileRecordKey && fieldConfiguration.isRequired) {
          return !!mobile[mobileRecordKey];
        }

        return true;
      }
    );
  });
};
