export interface RawKioskProperty {
  id: string;
  name: string;
}

export class KioskProperty {
  public id: string;
  public name: string;

  constructor(raw: RawKioskProperty) {
    this.id = raw.id;
    this.name = raw.name;
  }
}
