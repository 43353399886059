import { createEmailValidator } from '@ac/react-infrastructure';

import { EmailSection } from 'store/settings/interfaces/settingTypes/sectionConfiguration';
import {
  ConfigurationFieldsMap,
  DefaltValidationRules,
  SubFormFieldsConfiguration,
  useSubFormFieldsConfiguration,
} from 'views/RegistrationCardEditPersonalV2/hooks/useSubFormFieldsConfiguration';

import {
  EmailFormProperties,
  EmailsFormValues,
  FormProperties,
  FormValues,
} from '../../../../../types';

const DEFAULT_VALIDATION_RULES: DefaltValidationRules<
  FormValues,
  EmailsFormValues
> = {
  [EmailFormProperties.details]: [
    createEmailValidator('VALIDATION.INCORRECT_EMAIL_ADDRESS'),
  ],
};

const CONFIGURATION_TO_FORM_FIELD_MAP: ConfigurationFieldsMap<
  keyof EmailSection,
  EmailsFormValues
> = {
  detailsField: EmailFormProperties.details,
  typeField: EmailFormProperties.type,
};

type EmailsFieldsConfigurationResult = SubFormFieldsConfiguration<
  FormValues,
  EmailsFormValues,
  [FormProperties.emails, number]
>;

export const useEmailsFieldsConfiguration = (
  path: [FormProperties.emails, number]
): EmailsFieldsConfigurationResult => {
  const getEmailsSubFormConfiguration = useSubFormFieldsConfiguration<
    FormValues,
    EmailsFormValues,
    [FormProperties.emails, number]
  >('emailObjectCollection', path);

  return getEmailsSubFormConfiguration(
    CONFIGURATION_TO_FORM_FIELD_MAP,
    DEFAULT_VALIDATION_RULES
  );
};
