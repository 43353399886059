import { BaseObject } from 'types/shared';

export interface DictionaryStatusData<TData extends BaseObject> {
  isFetching?: boolean;
  isFailed?: boolean;
  result?: TData;
}

export enum DictionaryStatuses {
  fetching = 'fetching',
  completed = 'completed',
  failed = 'failed',
}

export interface StoredDictionaryStructure<TData extends BaseObject> {
  status: DictionaryStatuses;
  data?: TData;
}

export interface SuccessAction<TData extends BaseObject> {
  key: string;
  data: TData;
}
