import { LogReporter } from '@ac/kiosk-components';

import { LOG_MESSAGES_TITLES, LOG_TYPES } from 'configs/logs';

export const sagaUncatchedErrorMiddleware = (
  error: Error,
  errorInfo: { sagaStack?: string }
): void => {
  const sagaActionType = errorInfo?.sagaStack?.match(/@.+(?=,)/)?.[0];

  LogReporter.log.error(
    LOG_TYPES.app,
    {
      message: LOG_MESSAGES_TITLES.sagaUncatchedError,
      details: {
        action: sagaActionType,
        message: error?.message,
      },
    },
    true
  );
};
