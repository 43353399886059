import {
  createAction,
  createAsyncAction,
  updateAction,
} from '@ac/library-utils/dist/redux-utils';

import { KioskPurchaseElementAvailabilityItemDto } from 'api/KioskApi/entries';

import { SagaError } from 'types/shared';

import { PurchaseElementsFormValues } from '../types';

import { PurchaseElementImages } from './interfaces/purchaseElementImages';
import { UpdatePurchaseElementsPayload } from './interfaces';

export const setUpEnhancements = createAsyncAction<
  undefined,
  KioskPurchaseElementAvailabilityItemDto[],
  SagaError
>('@registrationCardPurchaseElements/setUpEnhancements');

export const updateInitialAvailability = createAction<
  KioskPurchaseElementAvailabilityItemDto[]
>('@registrationCardPurchaseElements/updateInitialAvailability');

export const savePurchaseElementsPreselection = createAction<PurchaseElementsFormValues>(
  '@registrationCardPurchaseElements/savePurchaseElementsPreselection'
);

export const clearPurchaseElementsPreselection = createAction(
  '@registrationCardPurchaseElements/clearPurchaseElementsPreselection'
);

export const updatePurchaseElements = updateAction<
  UpdatePurchaseElementsPayload,
  void,
  SagaError
>('@registrationCardPurchaseElements/updatePurchaseElements');

export const setInitialPurchaseElementsAvailability = createAction<
  KioskPurchaseElementAvailabilityItemDto[]
>('@registrationCardPurchaseElements/setInitialPurchaseElementsAvailability');

export const fetchAvailability = createAsyncAction<
  undefined,
  KioskPurchaseElementAvailabilityItemDto[],
  SagaError
>('@registrationCardPurchaseElements/fetchAvailability');

export const savePurchaseElementsImages = createAction<PurchaseElementImages>(
  '@registrationCardPurchaseElements/savePurchaseElementsImages'
);

export const resetPurchaseElementSelectionProcess = createAction(
  '@registrationCardPurchaseElements/resetPurchaseElementSelectionProcess'
);
