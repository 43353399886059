import { PropsWithChildren, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  BasicModalSize,
  Button,
  ButtonPattern,
  ConfirmModal,
  Flex,
  Footer as BasicFooter,
} from '@ac/kiosk-components';

import { cancelElectronicRegistrationProcess } from 'store/electronicRegistrationProcess/actions';

import { GetComponentProps } from 'types/shared';

type BasicFooterProps = PropsWithChildren<
  GetComponentProps<typeof BasicFooter>
>;

interface FooterProps extends BasicFooterProps {
  hasCancelButton?: boolean;
  isRegistrationCardCancelling?: boolean;
}

export const Footer = ({
  children,
  hasCancelButton,
  isRegistrationCardCancelling,
  ...rest
}: PropsWithChildren<FooterProps>): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpenState] = useState(false);

  const onCancelProcess = useCallback(() => {
    dispatch(cancelElectronicRegistrationProcess.trigger());
  }, [dispatch]);

  const onShowModal = useCallback(() => {
    setModalOpenState(true);
  }, []);

  const onHideModal = useCallback(() => {
    setModalOpenState(false);
  }, []);

  return (
    <>
      {isModalOpen && !isRegistrationCardCancelling && (
        <ConfirmModal
          dataTestSelector="footer-cancel-process-confirm-modal"
          header={t('REGISTRATION_CARD.CANCEL_MODAL.HEADER')}
          description={t('REGISTRATION_CARD.CANCEL_MODAL.BODY')}
          size={BasicModalSize.lg}
          primaryButtonLabel={t(
            'MODALS.CANCEL_E_REGISTRATION_CARD_PROCESS.CONTINUE'
          )}
          secondaryButtonLabel={t(
            'MODALS.CANCEL_E_REGISTRATION_CARD_PROCESS.CANCEL'
          )}
          onPrimaryClick={onHideModal}
          onSecondaryClick={onCancelProcess}
        />
      )}
      <BasicFooter {...rest}>
        <Flex>
          {hasCancelButton && (
            <Button
              dataTestSelector="footer-cancel-button"
              disabled={isRegistrationCardCancelling}
              onClick={onShowModal}
              pattern={ButtonPattern.secondary}
              className="spacing-inline-end-lg"
            >
              {t('SHARED.CANCEL')}
            </Button>
          )}
          {children}
        </Flex>
      </BasicFooter>
    </>
  );
};
