import { ApiError } from '@ac/library-api';

import { KioskLoggedUser } from 'api/KioskApi/entries';
import { Store } from 'store/types';
import { MissingPermissionsError } from 'utils/customErrors';

import { PropertyPermissions } from './interfaces/state';

export const getIsAppInitialized = (state: Store): boolean =>
  state.app.isAppInitialized;

export const getIsEnvironmentPrepared = (state: Store): boolean =>
  state.app.isEnvironmentPrepared;

export const getAppFetchState = (state: Store): boolean =>
  Object.values(state.app.fetching).includes(true);

export const getSelectPropertyState = (state: Store): boolean =>
  state.app.fetching.propertyData;

export const getAppErrors = (
  state: Store
): Array<ApiError | Error | MissingPermissionsError> => state.app.errors;

export const getCurrentUser = (state: Store): KioskLoggedUser | undefined =>
  state.app.currentUser;

export const getCurrentPropertyId = (state: Store): string | undefined =>
  state.app.currentPropertyId;

export const getCurrentDeviceId = (state: Store): string | undefined =>
  state.app.currentDeviceId;

export const getIsSameDeviceScenario = (state: Store): boolean =>
  state.app.isSameDeviceScenario;

export const getIsPollingRegistrationCardStarted = (state: Store): boolean =>
  state.app.isPollingRegistrationCardStarted;

export const getPropertyPermissions = (
  state: Store
): PropertyPermissions | undefined => state.app.propertyPermissions;
