import i18next from 'i18next';

import {
  DATE_PICKER_DATE_FORMAT,
  DateTimePickerField,
} from '@ac/kiosk-components';
import {
  composeValidators,
  formFieldFactory,
  ValidationExpression,
} from '@ac/react-infrastructure';

import { DateManager, TimeFormats } from 'utils/DateManager';
import {
  CompareDateType,
  createDateCompareValidator,
  createFullDateTimeValidator,
  mapFieldRenderProps,
} from 'utils/form';

import { BaseObject } from 'types/shared';

import { BasicFieldProvider, ComponentsData } from './basicFieldProvider';

const FormField = formFieldFactory<BaseObject>();

export class DateTimeFieldProvider extends BasicFieldProvider<
  typeof FormField,
  typeof DateTimePickerField
> {
  private get formattedMinDateTimeValue(): string | undefined {
    const { typeConstraints } = this.reservationHeaderDefinition;

    return typeConstraints?.minValue
      ? DateManager.getFormattedDateTime(typeConstraints.minValue, {
          dateFormat: this.config.shortDateFormat,
          timeFormat: this.config.timeFormat,
        })
      : undefined;
  }

  private get formattedMaxDateTimeValue(): string | undefined {
    const { typeConstraints } = this.reservationHeaderDefinition;

    return typeConstraints?.maxValue
      ? DateManager.getFormattedDateTime(typeConstraints.maxValue, {
          dateFormat: this.config.shortDateFormat,
          timeFormat: this.config.timeFormat,
        })
      : undefined;
  }

  private get placeholder(): {
    dateField: string | undefined;
    timeField: string;
  } {
    const timeFieldPlaceholder = this.config.timeFormat
      ? TimeFormats[this.config.timeFormat] || TimeFormats.H24
      : TimeFormats.H24;

    return {
      dateField: this.config.shortDateFormat,
      timeField: timeFieldPlaceholder.toUpperCase(),
    };
  }

  getValidationSchema(): ValidationExpression<BaseObject<string>> | undefined {
    const { typeConstraints } = this.reservationHeaderDefinition;

    const validators = [
      createFullDateTimeValidator(
        i18next.t(
          'REGISTRATION_CARD_EDIT_COMPLEMENTARY.VALIDATION_MESSAGES.INVALID_DATE_TIME',
          { name: this.reservationHeaderDefinition.displayName }
        )
      ),
      ...(typeConstraints?.minValue
        ? [
            createDateCompareValidator(
              i18next.t(
                'REGISTRATION_CARD_EDIT_COMPLEMENTARY.VALIDATION_MESSAGES.MIN_VALUE',
                {
                  value: this.formattedMinDateTimeValue,
                }
              ),
              typeConstraints.minValue,
              CompareDateType.isSameOrBefore,
              DATE_PICKER_DATE_FORMAT
            ),
          ]
        : []),
      ...(typeConstraints?.maxValue
        ? [
            createDateCompareValidator(
              i18next.t(
                'REGISTRATION_CARD_EDIT_COMPLEMENTARY.VALIDATION_MESSAGES.MAX_VALUE',
                {
                  value: this.formattedMaxDateTimeValue,
                }
              ),
              typeConstraints.maxValue,
              CompareDateType.isSameOrAfter,
              DATE_PICKER_DATE_FORMAT
            ),
          ]
        : []),
    ];

    return validators.length
      ? composeValidators<BaseObject>(...validators)
      : undefined;
  }

  getComponentsData(): ComponentsData<
    typeof FormField,
    typeof DateTimePickerField
  > {
    const { typeConstraints } = this.reservationHeaderDefinition;

    return {
      FormField,
      formFieldRenderPropsMapper: mapFieldRenderProps,
      Component: DateTimePickerField,
      componentProps: {
        label: this.reservationHeaderDefinition.displayName,
        min: typeConstraints?.minValue as string | undefined,
        max: typeConstraints?.maxValue as string | undefined,
        allowClear: true,
        placeholder: this.placeholder,
        fieldsDisplayFormats: {
          dateField: this.config.shortDateFormat,
          timeField: this.config.timeFormat,
        },
      },
    };
  }
}
