import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isDefined } from '@ac/library-utils/dist/utils';

import { getProfile } from 'store/electronicRegistrationProcess/selectors/profile';
import { getFieldsConfiguration } from 'store/settings/selectors';
import {
  AlternateDetailsFormProperties,
  AlternateDetailsFormValues,
} from 'views/RegistrationCardEditPersonalV2/types';

interface AlternateDetailsInitialValuesResult {
  initialValues: AlternateDetailsFormValues | undefined;
}

export const useAlternateDetailsInitialValues = (): AlternateDetailsInitialValuesResult => {
  const profile = useSelector(getProfile);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const alternateDetails =
    profile?.personalDetails?.preferredLanguageProfileDetails;
  const alternateDetailsConfiguration =
    fieldsConfiguration?.guestAlternateNameObject;

  const initialValues = useMemo(() => {
    const alternateDetailsValues = {
      [AlternateDetailsFormProperties.language]:
        alternateDetails?.language?.code,
      [AlternateDetailsFormProperties.firstName]: alternateDetails?.firstname,
      [AlternateDetailsFormProperties.lastName]: alternateDetails?.lastname,
    };

    const hasProvidedData = !!Object.values(alternateDetailsValues).filter(
      isDefined
    ).length;

    return alternateDetailsConfiguration?.isRequired || hasProvidedData
      ? alternateDetailsValues
      : undefined;
  }, [alternateDetails, alternateDetailsConfiguration?.isRequired]);

  return {
    initialValues,
  };
};
