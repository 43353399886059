export interface RawAdditionalNames {
  value?: string;
  type: AdditionalNamesTypes;
}

export enum AdditionalNamesTypes {
  FathersName = 'FathersName',
  MaidenName = 'MaidenName',
  MothersName = 'MothersName',
  SecondSurname = 'SecondSurname',
}

export class AdditionalNames {
  public value?: string;
  public type: AdditionalNamesTypes;

  constructor(raw: RawAdditionalNames) {
    this.value = raw.value;
    this.type = raw.type;
  }
}
