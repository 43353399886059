import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonPattern,
  Checkbox,
  ExpandPanel,
  Flex,
  Grid,
  IconTypes,
  JustifyContent,
  Text,
  TextSize,
} from '@ac/kiosk-components';
import { formNestedSingleCheckboxFieldFactory } from '@ac/react-infrastructure';

import { PreferencesGroup } from 'store/settings/interfaces/preferences/preferencesGroup';
import { mapCheckboxFieldRenderProps } from 'utils/form';
import { useMatchMediaQuery } from 'utils/hooks/useMatchMediaQuery';
import {
  FormProperties,
  FormValues,
} from 'views/RegistrationCardSummary/types';

const GridTemplateProps = {
  gridTemplateColumnsSm: 'repeat(2, 1fr)',
  gridTemplateColumnsLg: 'repeat(3, 1fr)',
};

type PreferenceAttributeGroupProps = PreferencesGroup;

const CheckboxField = formNestedSingleCheckboxFieldFactory<
  Pick<FormValues, FormProperties.preferences>
>();

export const PreferenceAttributeGroup = ({
  groupId: attributeGroupCode,
  description: attributeGroupDescription,
  preferences: groupPreferences,
}: PreferenceAttributeGroupProps): JSX.Element => {
  const { t } = useTranslation();

  const [isSectionExpanded, setSectionExpanded] = useState(false);
  const isLargeDevice = useMatchMediaQuery();

  const sectionToggle = useCallback(() => {
    return setSectionExpanded((prevState) => !prevState);
  }, []);

  const visibleElements = isLargeDevice ? 6 : 4;

  const [
    nonCollapsibleCheckboxes,
    collapsibleContentCheckboxes,
  ] = useMemo(() => {
    return [
      groupPreferences.slice(0, visibleElements),
      groupPreferences.slice(visibleElements),
    ];
  }, [groupPreferences, visibleElements]);

  return (
    <div
      data-test-selector="preference-attribute-group-container"
      className="spacing-vertical-sm"
    >
      <ExpandPanel isExpanded={isSectionExpanded}>
        <Text
          dataTestSelector="preference-attribute-group-description"
          className="spacing-bottom-xlg"
          size={TextSize.lg}
        >
          {attributeGroupDescription}
        </Text>
        <Grid
          {...GridTemplateProps}
          dataTestSelector="nonCollapsible-checkboxes-grid"
        >
          {nonCollapsibleCheckboxes.map((preference) => (
            <Grid.Item key={preference.id}>
              <CheckboxField
                valuePath={[
                  FormProperties.preferences,
                  `${attributeGroupCode}`,
                  `${preference.id}`,
                ]}
              >
                {(checkboxFieldRenderProps): JSX.Element => (
                  <Checkbox
                    {...mapCheckboxFieldRenderProps(checkboxFieldRenderProps)}
                    label={preference.description}
                  />
                )}
              </CheckboxField>
            </Grid.Item>
          ))}
        </Grid>

        <ExpandPanel.ExtendableElement>
          <Grid
            dataTestSelector="collapsible-checkboxes-grid"
            {...GridTemplateProps}
          >
            {collapsibleContentCheckboxes.map((preference) => (
              <Grid.Item key={preference.id}>
                <CheckboxField
                  valuePath={[
                    FormProperties.preferences,
                    `${attributeGroupCode}`,
                    `${preference.id}`,
                  ]}
                  key={preference.id}
                >
                  {(checkboxFieldRenderProps): JSX.Element => (
                    <Checkbox
                      {...mapCheckboxFieldRenderProps(checkboxFieldRenderProps)}
                      label={preference.description}
                    />
                  )}
                </CheckboxField>
              </Grid.Item>
            ))}
          </Grid>
        </ExpandPanel.ExtendableElement>
      </ExpandPanel>
      {groupPreferences.length > visibleElements ? (
        <Flex
          className="spacing-top-xlg"
          justifyContent={JustifyContent.center}
        >
          <Button
            dataTestSelector="preference-attribute-group-show-more-button"
            icon={isSectionExpanded ? IconTypes.collapse : IconTypes.expand}
            pattern={ButtonPattern.tertiary}
            onClick={sectionToggle}
          >
            {isSectionExpanded ? t('SHARED.SHOW_LESS') : t('SHARED.SHOW_MORE')}
          </Button>
        </Flex>
      ) : null}
    </div>
  );
};
