import { BaseKioskConfigurationEntity } from 'api/KioskApi/entries';

export const sortByModeOrder = <
  InputT extends { mode?: BaseKioskConfigurationEntity }
>(
  inputData: InputT[],
  modeOrder?: string[]
): InputT[] => {
  if (!modeOrder) return inputData;

  return inputData
    .reduce<InputT[][]>((collections, input) => {
      const modeOrderIndex =
        input.mode?.code && modeOrder.indexOf(input.mode.code);

      if ((!modeOrderIndex && modeOrderIndex !== 0) || modeOrderIndex === -1) {
        if (!collections[modeOrder.length]) {
          collections[modeOrder.length] = [];
        }

        collections[modeOrder.length].push(input);

        return collections;
      }

      if (!collections[modeOrderIndex]) {
        collections[modeOrderIndex] = [];
      }

      collections[modeOrderIndex].push(input);

      return collections;
    }, [])
    .flat();
};
