import { ReducerMap } from 'redux-actions';

import { ApiError } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

import { clearProcessFlowData } from 'store/globalActions';

import * as actions from './actions';
import { RegistrationCardEditPersonalViewStateV2 } from './interfaces';

export const initialState: RegistrationCardEditPersonalViewStateV2 = {
  fetching: {
    updateProfile: false,
  },
  errors: [],
};

const reducerMap: ReducerMap<
  RegistrationCardEditPersonalViewStateV2,
  unknown
> = {
  [actions.updateProfile.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        updateProfile: true,
      },
    };
  },

  [actions.updateProfile.success]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        updateProfile: false,
      },
    };
  },

  [actions.updateProfile.failure]: (
    state,
    action: Action<ApiError | Error>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        updateProfile: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.clearRegistrationCardEdit]: () => {
    return {
      ...initialState,
    };
  },

  [clearProcessFlowData]: () => {
    return {
      ...initialState,
    };
  },
};

export const registrationCardEditPersonalViewV2 = handleActions<RegistrationCardEditPersonalViewStateV2>(
  reducerMap,
  initialState
);
