import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Flex, FlexDirection, Text, TextSize } from '@ac/kiosk-components';

import {
  getFeatureToggles,
  getFieldsConfiguration,
  getGeneralSettings,
} from 'store/settings/selectors';

import { Etd } from './components/Etd/Etd';
import { PurposeOfStay } from './components/PurposeOfStay/PurposeOfStay';

interface BasicProps {
  dataTestSelector?: string;
}

interface EditableSubFormProps {
  asSubForm: true;
  purposeOfStayValuePath: string;
  etdValuePath: string;
}

interface NonEditableSubFormProps {
  asSubForm: false;
  purposeOfStayValuePath?: never;
  etdValuePath?: never;
}

export const AdditionalDetailsSection = ({
  dataTestSelector,
  asSubForm,
  purposeOfStayValuePath,
  etdValuePath,
}: BasicProps &
  (EditableSubFormProps | NonEditableSubFormProps)): JSX.Element => {
  const { t } = useTranslation();

  const featureToggles = useSelector(getFeatureToggles);
  const generalSettings = useSelector(getGeneralSettings);
  const fieldConfiguration = useSelector(getFieldsConfiguration);

  const reservationDetailsObject = fieldConfiguration?.reservationDetailsObject;

  const isPurposeOfStayEnabled = featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
    ? reservationDetailsObject?.purposeOfStayField?.isVisible
    : generalSettings?.DISPLAY_PURPOSE_OF_STAY;

  const isEtdEnabled = featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
    ? reservationDetailsObject?.estimatedTimeOfDepartureField?.isVisible
    : true;

  return (
    <Flex direction={FlexDirection.column} className="gap-sm">
      <Text size={TextSize.xlg} className="spacing-bottom-sm">
        {t('COMPONENTS.ADDITIONAL_DETAILS_SECTION.TITLE')}
      </Text>

      {isPurposeOfStayEnabled && (
        <PurposeOfStay
          asSubForm={asSubForm}
          valuePath={purposeOfStayValuePath}
          dataTestSelector={dataTestSelector?.concat('-purpose-of-stay')}
        />
      )}

      {isEtdEnabled && (
        <Etd
          asSubForm={asSubForm}
          valuePath={etdValuePath}
          dataTestSelector={dataTestSelector?.concat('-etd')}
        />
      )}
    </Flex>
  );
};
