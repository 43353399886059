import i18next from 'i18next';

import {
  KioskUpdateAddressDetails,
  KioskUpdateAddresses,
} from 'api/KioskApi/entries';
import { AddressFormValues } from 'views/RegistrationCardEditPersonal/types';

import { prepareAddUpdateRemovePayload } from './prepareAddUpdateRemovePayload';

const mapSingleAddressToUpdatePayload = (
  addressFormData: AddressFormValues
): KioskUpdateAddressDetails => {
  return {
    id: addressFormData.id,
    typeId: addressFormData.type,
    addressLine1: addressFormData.addressLine1,
    addressLine2: addressFormData.addressLine2,
    postalCode: addressFormData.postalCode,
    city: addressFormData.city,
    stateCode: addressFormData.state,
    districtId: addressFormData.district,
    countryCode: addressFormData.country,
    languageCode:
      addressFormData.languageCode || i18next.language.toUpperCase(),
    isPrimary: addressFormData.isPrimary || false,
  };
};

export const prepareAddressesUpdatePayload = (
  formData?: AddressFormValues[],
  initialValues?: AddressFormValues[]
): KioskUpdateAddresses | undefined => {
  const payload = prepareAddUpdateRemovePayload(
    mapSingleAddressToUpdatePayload,
    formData,
    initialValues
  );

  return Object.keys(payload).length ? payload : undefined;
};
