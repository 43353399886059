import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Icon, IconTypes, Text, TextSize } from '@ac/kiosk-components';

import { sortDisplayElements } from 'utils/sorting';

import { ConsentConfirmationOption } from '../../types';

import './TermsAndConditionList.scss';

interface TermsAndConditionsListProps {
  dataTestSelector?: string;
  className?: string;
  options: ConsentConfirmationOption[];
}

export const TermsAndConditionsList = ({
  dataTestSelector,
  className,
  options,
}: TermsAndConditionsListProps): JSX.Element => {
  const [t] = useTranslation();

  const consents = useMemo(() => {
    return options && sortDisplayElements<ConsentConfirmationOption>(options);
  }, [options]);

  return (
    <div
      className={classNames('terms-and-condition-list-wrapper', className)}
      data-test-selector={dataTestSelector}
    >
      <Text size={TextSize.xlg} className="spacing-bottom-sm">
        {t('REGISTRATION_CARD.TERMS_AND_CONDITIONS.TITLE')}
      </Text>
      <Text hint className="spacing-bottom-md">
        {t('REGISTRATION_CARD.TERMS_AND_CONDITIONS.HINT')}
      </Text>
      <ul className="terms-and-condition-list">
        {consents.map((item) => (
          <li className="terms-and-condition-item" key={item.id}>
            <Icon
              className="terms-and-condition-icon"
              type={item.isGranted ? IconTypes.check : IconTypes.cancel}
            />
            <Text>{item.description}</Text>
          </li>
        ))}
      </ul>
    </div>
  );
};
