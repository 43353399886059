import { createAction, updateAction } from '@ac/library-utils/dist/redux-utils';

import { SagaError } from 'types/shared';

import { UploadProfileActionPayload } from './interfaces/actionPayloads';

export const clearRegistrationCardEdit = createAction(
  '@registrationCardEditPersonal/clearRegistrationCardEditPersonal'
);

export const updateProfile = updateAction<
  UploadProfileActionPayload,
  undefined,
  SagaError
>('@registrationCardEditPersonal/updateProfile');
