import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TextGroup } from '@ac/kiosk-components';

import { getReservation } from 'store/electronicRegistrationProcess/selectors/reservation';
import {
  getFeatureToggles,
  getFieldsConfiguration,
} from 'store/settings/selectors';

import { Editable } from './Editable';

interface PurposeOfStayProps {
  valuePath?: string;
  dataTestSelector?: string;
  asSubForm?: boolean;
}

export const PurposeOfStay = ({
  asSubForm,
  valuePath,
  dataTestSelector,
}: PurposeOfStayProps): JSX.Element => {
  const { t } = useTranslation();
  const reservation = useSelector(getReservation);
  const featureToggles = useSelector(getFeatureToggles);
  const fieldConfiguration = useSelector(getFieldsConfiguration);
  const reservationDetailsObject = fieldConfiguration?.reservationDetailsObject;

  const isEditable = featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
    ? reservationDetailsObject?.purposeOfStayField?.isEditable
    : true;

  return isEditable && asSubForm && valuePath ? (
    <Editable
      className="spacing-top-sm"
      valuePath={valuePath}
      dataTestSelector={dataTestSelector?.concat('-edit-sub-form')}
    />
  ) : (
    <TextGroup
      dataTestSelector={dataTestSelector?.concat('-preview')}
      label={t('COMPONENTS.ADDITIONAL_DETAILS_SECTION.PURPOSE_OF_STAY')}
      value={reservation?.purposeOfStay?.description}
    />
  );
};
