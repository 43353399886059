import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Checkbox, Text, TextSize } from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';

import { KioskConfigurationConsent } from 'api/KioskApi/entries';
import { sortDisplayElements } from 'utils/sorting';

import { FormProperties } from '../../types';

import { ConsentCheckbox } from './ConsentCheckbox/ConsentCheckbox';

import './TermsAndConditions.scss';

interface TermsAndConditionsProps {
  id?: string;
  dataTestSelector?: string;
  className?: string;
  options: Array<
    KioskConfigurationConsent & {
      disabled?: boolean;
      value?: boolean;
    }
  >;
  isAllChecked: boolean;
  onSelectAllClick: (key: string, value: { [key: string]: boolean }) => void;
  onConsentLinkClick?: (consentId: string) => void;
}

export const TermsAndConditions = ({
  id,
  dataTestSelector,
  className,
  options,
  isAllChecked,
  onSelectAllClick,
  onConsentLinkClick,
}: TermsAndConditionsProps): JSX.Element => {
  const [t] = useTranslation();

  const consents = useMemo(() => {
    return options && sortDisplayElements<KioskConfigurationConsent>(options);
  }, [options]);

  const handleSelectAllClick = useCallback(() => {
    const optionEntries = options
      .map((item): [string, boolean] | undefined => {
        if (!item.id) return;
        if (item.disabled) {
          return [item.id, !!item.value];
        }

        return [item.id, !isAllChecked];
      })
      .filter(isDefined);

    onSelectAllClick(
      FormProperties.termsAndCondition,
      Object.fromEntries(optionEntries)
    );
  }, [options, onSelectAllClick, isAllChecked]);

  return (
    <div
      id={id}
      className={classNames('terms-and-conditions-component', className)}
      data-test-selector={dataTestSelector}
    >
      <Text size={TextSize.xlg} className="terms-and-conditions-title">
        {t('REGISTRATION_CARD.TERMS_AND_CONDITIONS.TITLE')}
      </Text>
      <Text hint>{t('REGISTRATION_CARD.TERMS_AND_CONDITIONS.HINT')}</Text>
      {consents && (
        <div className="terms-and-conditions-consents">
          <Checkbox
            onChange={handleSelectAllClick}
            checked={isAllChecked}
            label={t('REGISTRATION_CARD.TERMS_AND_CONDITIONS.SELECT_ALL')}
          />
          {consents
            .filter((consent) => consent.id)
            .map((consent) => (
              <ConsentCheckbox
                key={`checkbox-consent2-'${consent.id}`}
                consent={consent}
                onConsentLinkClick={onConsentLinkClick}
              />
            ))}
        </div>
      )}
    </div>
  );
};
