interface InternalErrorDetails {
  title: string;
  message: string;
}

export class InternalError extends Error {
  static errorName = 'InternalError';
  public readonly name = InternalError.errorName;
  public title?: string;

  constructor(input?: string | InternalErrorDetails) {
    super(typeof input === 'object' ? input.message : input);

    if (typeof input === 'object') {
      this.title = input.title;
    }
  }
}
