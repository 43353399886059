import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Separator } from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';

import { COMMUNICATION_MODES } from 'configs';
import {
  getCommunicationModeOrder,
  getFieldsConfiguration,
} from 'store/settings/selectors';
import { useSubForm } from 'utils/form/subFormApi';

import { EmailsFormValues, FormProperties } from '../../types';
import { SubFormSectionWrapper } from '../SubFormSectionWrapper/SubFormSectionWrapper';

import { EmailsSubForm } from './components/EmailsSubForm/EmailsSubForm';
import { MobilesSubForm } from './components/MobilesSubForm/MobilesSubForm';
import { PhonesSubForm } from './components/PhonesSubForm/PhonesSubForm';

interface Props {
  dataTestSelector?: string;
  isExpanded?: boolean;
}

export const ContactSubForm = ({
  dataTestSelector,
  isExpanded,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const communicationModeOrder = useSelector(getCommunicationModeOrder);

  const emailSubFormState = useSubForm<EmailsFormValues>(
    [FormProperties.emails],
    {
      valid: true,
    }
  );
  const mobileSubFormState = useSubForm<EmailsFormValues>(
    [FormProperties.mobiles],
    {
      valid: true,
    }
  );
  const phoneSubFormState = useSubForm<EmailsFormValues>(
    [FormProperties.phones],
    {
      valid: true,
    }
  );

  const subForms = useMemo(() => {
    const isMobilesSectionVisible =
      fieldsConfiguration?.mobileObjectCollection?.isEditable;
    const isPhonesSectionVisible =
      fieldsConfiguration?.phoneObjectCollection?.isEditable;
    const isEmailsSectionVisible =
      fieldsConfiguration?.emailObjectCollection?.isEditable;

    const emailSequence = communicationModeOrder.indexOf(
      COMMUNICATION_MODES.email
    );
    const mobileSequence = communicationModeOrder.indexOf(
      COMMUNICATION_MODES.mobile
    );
    const phoneSequence = communicationModeOrder.indexOf(
      COMMUNICATION_MODES.phone
    );

    const sectionsEntries = Object.entries({
      [emailSequence]: isEmailsSectionVisible ? EmailsSubForm : undefined,
      [phoneSequence]: isPhonesSectionVisible ? PhonesSubForm : undefined,
      [mobileSequence]: isMobilesSectionVisible ? MobilesSubForm : undefined,
    });

    return sectionsEntries
      .sort(([a], [b]) => Number(a) - Number(b))
      .map(([, component]) => component)
      .filter(isDefined);
  }, [fieldsConfiguration, communicationModeOrder]);

  const isValid = useMemo(() => {
    return (
      emailSubFormState.valid &&
      mobileSubFormState.valid &&
      phoneSubFormState.valid
    );
  }, [emailSubFormState, mobileSubFormState, phoneSubFormState]);

  return (
    <SubFormSectionWrapper
      dataTestSelector={dataTestSelector}
      title={t('REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.TITLE')}
      isValid={isValid}
      isExpanded={isExpanded}
    >
      <Box>
        {subForms.map((SubFormSection, index, collection) => {
          const isLastElement = index + 1 === collection.length;

          return (
            <Fragment key={index}>
              <SubFormSection />
              {!isLastElement && <Separator className="spacing-vertical-xlg" />}
            </Fragment>
          );
        })}
      </Box>
    </SubFormSectionWrapper>
  );
};
