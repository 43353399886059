import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LoadingView } from '@ac/kiosk-components';

import { Authorizer } from 'services';
import { prepareApplication } from 'store/app/actions';
import { getIsAppInitialized } from 'store/app/selectors';

import { AppRoutes } from './Routes';

export const App = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isAppInitialized = useSelector(getIsAppInitialized);

  const [isAppAuthorized, setIsAppAuthorized] = useState(false);

  useEffect(() => {
    Authorizer.init(() => {
      if (!isAppAuthorized) {
        navigate(window.location.pathname, { replace: true });
        dispatch(prepareApplication.trigger());
        setIsAppAuthorized(true);
      }
    });

    Authorizer.authorize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isAppAuthorized || !isAppInitialized ? (
    <LoadingView
      description={t(
        !isAppAuthorized ? 'SHARED.AUTHORIZING' : 'SHARED.SETTING_UP'
      )}
    />
  ) : (
    <AppRoutes />
  );
};
