import { ApiError, isApiError } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

const PURCHASE_ELEMENT_OPERATION_FAILED = 'OperationFailed';

export const isPurchaseElementOperationFailedErrorResponse = (
  error: unknown
): boolean => {
  const isApiErrorResponse =
    typeof error === 'object' &&
    isDefined(error) &&
    'data' in error &&
    isApiError((error as { data: unknown }).data);

  if (isApiErrorResponse) {
    const errorData = (error as { data: ApiError }).data;

    return errorData.details.some(
      (detail) => detail.code === PURCHASE_ELEMENT_OPERATION_FAILED
    );
  }

  return false;
};
