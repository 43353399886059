import { Action } from 'redux-actions';
import { put, takeEvery } from 'redux-saga/effects';

import { ElectronicRegistrationApi } from 'api/KioskApi';
import {
  BaseKioskConfigurationEntity,
  KioskStateDetails,
} from 'api/KioskApi/entries';
import { handleSagaError } from 'utils/sagas';

import { SagasGenerator } from 'types/shared';

import * as actions from './actions';

function* fetchStatesList(action: Action<string>): SagasGenerator {
  try {
    const states: KioskStateDetails[] = yield ElectronicRegistrationApi.getStates(
      {
        pathParams: {
          code: action.payload,
        },
      }
    );

    yield put(
      actions.fetchCountryStates.success({
        key: action.payload,
        data: states,
      })
    );
  } catch (error) {
    yield put(actions.fetchCountryStates.failure(handleSagaError(error)));
  }
}

function* fetchDistrictsList(action: Action<string>): SagasGenerator {
  try {
    const districts: BaseKioskConfigurationEntity[] = yield ElectronicRegistrationApi.getDistricts(
      {
        pathParams: {
          code: action.payload,
        },
      }
    );

    yield put(
      actions.fetchCountryDistricts.success({
        key: action.payload,
        data: districts,
      })
    );
  } catch (error) {
    yield put(actions.fetchCountryDistricts.failure(handleSagaError(error)));
  }
}

export function* additionalDictionariesSagas(): SagasGenerator {
  yield takeEvery(actions.fetchCountryStates.trigger, fetchStatesList);
  yield takeEvery(actions.fetchCountryDistricts.trigger, fetchDistrictsList);
}
