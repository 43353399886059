const knownErrorCodesMap = {
  INVALID_CONFIGURATION_ERROR_CODE: {
    code: 'InvalidConfiguration',
  },
  ELECTRONIC_REGISTRATION_CARD_NOT_FOUND: {
    code: 'ElectronicRegistrationCardNotFound',
    message: 'KNOWN_ERRORS_MESSAGES.ELECTRONIC_REGISTRATION_CARD_NOT_FOUND',
  },
  INVALID_KIOSK_SESSION: {
    code: 'InvalidKioskSession',
    message: 'KNOWN_ERRORS_MESSAGES.INVALID_KIOSK_SESSION',
  },
};

export const knownErrorCodes = knownErrorCodesMap as {
  [key in keyof typeof knownErrorCodesMap]: {
    code: string;
    message?: string;
  };
};
