import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LogoPlaceholder } from 'assets/images';
import classNames from 'classnames';

import {
  Header as BasicHeader,
  LanguageSelector,
  SelectOption,
} from '@ac/kiosk-components';

import { changeAppLanguage } from 'store/settings/actions';
import {
  getGeneralSettings,
  getImages,
  getSupportedUiLanguages,
} from 'store/settings/selectors';

import './Header.scss';

interface HeaderProps {
  dataTestSelector?: string;
  title?: string;
  languageSelector?: boolean;
  className?: string;
}

export const Header = ({
  dataTestSelector,
  title,
  languageSelector = true,
  className,
}: HeaderProps): JSX.Element => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const images = useSelector(getImages);
  const supportedUiLanguages = useSelector(getSupportedUiLanguages);
  const languages = useSelector(getGeneralSettings)?.LANGUAGE_SETTINGS;
  const languageOptions = useMemo((): SelectOption[] => {
    if (!languages?.supportedLanguages?.length) return [];

    return languages.supportedLanguages.map((supportedLanguage) => ({
      title:
        supportedUiLanguages?.find(
          (uiLanguage) => uiLanguage.code === supportedLanguage
        )?.nativeLanguageName || supportedLanguage,
      value: supportedLanguage?.toLowerCase(),
    }));
  }, [supportedUiLanguages, languages]);

  const handleLanguageSelectChange = (language: string): void => {
    dispatch(changeAppLanguage.trigger(language));
  };

  return (
    <BasicHeader
      className={classNames('header-container', className)}
      dataTestSelector={dataTestSelector}
      logo={images?.LOGO || LogoPlaceholder}
      title={title}
    >
      {languageSelector && languageOptions?.length > 1 && (
        <LanguageSelector
          dataTestSelector="header-language-selector"
          className="padding-left-lg spacing-inline-start-auto"
          currentLanguageCode={i18n.language}
          languageOptions={languageOptions}
          onLanguageSelect={handleLanguageSelectChange}
        />
      )}
    </BasicHeader>
  );
};
