import i18next from 'i18next';

import { KioskConfigurationConsent } from 'api/KioskApi/entries';
import {
  AdditionalDetailsPresentationDataItem,
  ComplementaryPresentationDataItem,
  ConsentPresentationDataElement,
  PreferenceSectionPresentationDataItem,
  ProfilePresentationDataElement,
  ReservationPresentationDataElement,
} from 'utils/regCardPresentationDataMappers';

import registerHandlebarsHelpers from './registerHandlebarsHelpers';
import hbs from './template.hbs';

registerHandlebarsHelpers();

export interface RegistrationCardPdfData {
  reservationSubsections: ReservationPresentationDataElement;
  personalSubsection: ProfilePresentationDataElement[];
  addressSection: ProfilePresentationDataElement[];
  contactSection: ProfilePresentationDataElement[];
  documentSubsenctions: ProfilePresentationDataElement[];
  complementaryDetails: ComplementaryPresentationDataItem[];
  preferenceSection: PreferenceSectionPresentationDataItem[];
  consentsSection: ConsentPresentationDataElement[];
  additionalDetailsSection: AdditionalDetailsPresentationDataItem[];
  disclaimer: string | undefined;
  creationDateTime: string;
  signature: string;
  registrationCardNumber?: string;
  logo: string | undefined;
  consentsBodySection: KioskConfigurationConsent[] | undefined;
  purchaseElements?: string[];
}

export interface RegistrationCardPdfConfig {
  isPersonalSectionEnabled?: boolean;
  isPersonalDetilsEnabled?: boolean;
  isAddressEnabled?: boolean;
  isContactEnabled?: boolean;
  isDocumentEnabled?: boolean;
  isPurchaseElementSelectionEnabled?: boolean;
  isAdditionalDetailsEnabled?: boolean;
  isComplementaryDetailsAvailable?: boolean;
  isPrintConsentDescription?: boolean;
  isPreferencesEnabled?: boolean;
}

export const createRegistrationCardPdfContent = (
  content: RegistrationCardPdfData,
  config: RegistrationCardPdfConfig
): string => {
  const templateContext = {
    title: i18next.t('PDF.REGISTRATION_CARD'),
    logo: content.logo,
    reservationSection: {
      title: i18next.t('COMPONENTS.RESERVATION_DETAILS_SECTION.TITLE'),
      subtitle: content.reservationSubsections.subtitle,
      subsections: content.reservationSubsections.sections,
    },

    guestSection: config.isPersonalSectionEnabled
      ? {
          title: i18next.t('PDF.GUEST_DETAILS'),
          subsections: {
            personal: config.isPersonalDetilsEnabled
              ? {
                  title: i18next.t(
                    'COMPONENTS.PERSONAL_DETAILS_SECTION.PERSONAL'
                  ),
                  data: content.personalSubsection,
                }
              : undefined,

            address: config.isAddressEnabled
              ? {
                  title: i18next.t('SHARED.ADDRESS'),
                  noDataInfo: i18next.t('SHARED.NO_ADDRESS'),
                  data: content.addressSection,
                }
              : undefined,

            contact: config.isContactEnabled
              ? {
                  title: i18next.t(
                    'COMPONENTS.PERSONAL_DETAILS_SECTION.CONTACT'
                  ),
                  noDataInfo: i18next.t('SHARED.NO_CONTACT'),
                  data: content.contactSection,
                }
              : undefined,

            idAndDocuments: config.isDocumentEnabled
              ? {
                  title: i18next.t(
                    'COMPONENTS.PERSONAL_DETAILS_SECTION.ID_AND_DOCUMENTS'
                  ),
                  noDataInfo: i18next.t('SHARED.NO_DOCUMENT'),
                  data: content.documentSubsenctions,
                }
              : undefined,
          },
        }
      : undefined,

    complementaryDetailsSection:
      config.isComplementaryDetailsAvailable &&
      content.complementaryDetails.length
        ? {
            title: i18next.t('SHARED.COMPLEMENTARY_DETAILS'),
            data: content.complementaryDetails,
          }
        : undefined,

    purchaseElements:
      config.isPurchaseElementSelectionEnabled &&
      content.purchaseElements?.length
        ? {
            title: i18next.t('REGISTRATION_CARD.STAY_ENHANCEMENTS.TITLE'),
            data: content.purchaseElements,
          }
        : undefined,

    consentSection: {
      title: i18next.t('REGISTRATION_CARD.TERMS_AND_CONDITIONS.TITLE'),
      data: content.consentsSection,
    },

    additionalDetailsSection: config.isAdditionalDetailsEnabled
      ? {
          title: i18next.t('REGISTRATION_CARD.ADDITIONAL_DETAILS.TITLE'),
          data: content.additionalDetailsSection,
        }
      : undefined,

    disclaimerSection: {
      content: content.disclaimer,
    },

    registrationCardNumber: content.registrationCardNumber
      ? {
          title: i18next.t('REGISTRATION_CARD.REGISTRATION_CARD_NUMBER'),
          value: content.registrationCardNumber,
        }
      : undefined,

    signatureSection: {
      title: i18next.t('PDF.SIGNATURE'),
      signature: content.signature,
      creationDateTime: content.creationDateTime,
    },

    consentsBodySection: config.isPrintConsentDescription
      ? {
          title: i18next.t('REGISTRATION_CARD.TERMS_AND_CONDITIONS.TITLE'),
          consentsWithBody: content.consentsBodySection,
        }
      : undefined,

    preferencesSectionData:
      config.isPreferencesEnabled && content.preferenceSection.length
        ? {
            title: i18next.t('REGISTRATION_CARD.GUEST_PREFERENCES.TITLE'),
            data: content.preferenceSection,
          }
        : undefined,
  };

  return hbs(templateContext);
};
