import i18next from 'i18next';

import { isDefined } from '@ac/library-utils/dist/utils';
import { ValidationResult, ValidationSeverity } from '@ac/react-infrastructure';

export const translateValidationResult = (
  validationStatus?: ValidationResult
):
  | undefined
  | Array<{
      description: string;
      severity: ValidationSeverity;
    }> => {
  return validationStatus
    ?.map((status) => {
      if (!status.description || !status.severity) return undefined;

      return {
        ...status,
        description: i18next.t(status.description),
      };
    })
    .filter(isDefined);
};
