import classNames from 'classnames';

import { Text, TextSize, TextWeight } from '@ac/kiosk-components';

import './ScreenSaver.scss';

interface ScreenSaverProps {
  dataTestSelector?: string;
  screenSaverImage?: string;
  screenSaverMessage?: string;
  className?: string;
}

export const ScreenSaver = ({
  dataTestSelector,
  screenSaverImage,
  screenSaverMessage,
  className,
}: ScreenSaverProps): JSX.Element => {
  return (
    <div
      className={classNames('screen-saver-component', className)}
      data-test-selector={dataTestSelector}
    >
      {screenSaverImage && (
        <img
          data-test-selector="screen-saver-img"
          alt="screenSaverImage"
          src={screenSaverImage}
          className="screen-saver-image"
        />
      )}

      {screenSaverMessage && (
        <Text
          dataTestSelector="screen-saver-message"
          className="spacing-xxlg screen-saver-message"
          size={TextSize.xlg}
          weight={TextWeight.bold}
        >
          {screenSaverMessage}
        </Text>
      )}
    </div>
  );
};
