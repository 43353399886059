import { ApiError } from '@ac/library-api';

import { Store } from 'store/types';

export const getUpdateComplementaryDetailsState = (state: Store): boolean =>
  state.registrationCardEditComplementaryView.fetching
    .updateComplementaryDetails;

export const getUpdateComplementaryDetailsErrors = (
  state: Store
): Array<ApiError | Error> => {
  return state.registrationCardEditComplementaryView.errors;
};
