import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  FormHeader,
  Section,
  SectionType,
  Separator,
} from '@ac/kiosk-components';
import { Form } from '@ac/react-infrastructure';

import {
  Body,
  Footer,
  Header,
  ReservationDetailsSection,
  View,
} from 'components';
import { getRegistrationCardDetails } from 'store/electronicRegistrationProcess/selectors';
import {
  getCustomMessages,
  getEntities,
  getGeneralSettings,
  getPropertyConfiguration,
} from 'store/settings/selectors';
import { getErrorMessage, smoothScrollInToCenter } from 'utils';
import { FormValidator } from 'utils/form';
import { useRouter } from 'utils/hooks';

import { AddressFormSection } from './components/AddressFormSection/AddressFormSection';
import { ContactFormSection } from './components/ContactFormSection/ContactFormSection';
import { DocumentsFormSection } from './components/DocumentsFormSection/DocumentsFormSection';
import { PersonalDetailFormSection } from './components/PersonalDetailFormSection/PersonalDetailFormSection';
import { clearRegistrationCardEdit, updateProfile } from './store/actions';
import {
  getRegistrationCardEditFetchingErrors,
  getUpdateProfileState,
} from './store/selectors';
import {
  EditPersonalValidationSchema,
  prepareEditPersonalValidationSchema,
} from './utils/prepareEditPersonalValidationSchema';
import { prepareInitialValues } from './utils/prepareInitialValues';
import { prepareUpdateProfilePayload } from './utils/prepareUpdateProfilePayload';
import { BODY_ID } from './config';
import { FormValues } from './types';

import './RegistrationCardEditPersonal.scss';

export const RegistrationCardEditPersonal = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();

  const [initialFormValues, setInitialFormValues] = useState<FormValues>({});

  const regCardDetails = useSelector(getRegistrationCardDetails);
  const isProfileUpdating = useSelector(getUpdateProfileState);
  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const errors = useSelector(getRegistrationCardEditFetchingErrors);
  const entities = useSelector(getEntities);
  const customMessages = useSelector(getCustomMessages);
  const generalSettings = useSelector(getGeneralSettings);

  const validator = useMemo(
    () =>
      new FormValidator<FormValues, EditPersonalValidationSchema>(
        prepareEditPersonalValidationSchema(
          propertyConfiguration?.businessDate || new Date().toISOString(),
          generalSettings
        )
      ),
    [generalSettings, propertyConfiguration]
  );

  const isContactFormSectionVisible = useMemo((): boolean => {
    return Boolean(
      generalSettings?.DISPLAY_EMAIL ||
        generalSettings?.DISPLAY_MOBILE ||
        generalSettings?.DISPLAY_PHONE
    );
  }, [generalSettings]);

  const handleBack = useCallback(() => {
    router.goBack();
    dispatch(clearRegistrationCardEdit());
  }, [router, dispatch]);

  const onFormSubmit = useCallback(
    (formValues: FormValues) => {
      const updateProfilePayload = prepareUpdateProfilePayload(
        formValues,
        initialFormValues,
        entities
      );

      if (!updateProfilePayload) {
        return handleBack();
      }

      dispatch(
        updateProfile.trigger({
          data: updateProfilePayload,
          onSuccessfulUpdate: handleBack,
        })
      );
    },
    [dispatch, entities, handleBack, initialFormValues]
  );

  const scrollToFirstInvalidField = (): void => {
    const invalidFieldToScroll = validator.getInvalidFieldsPaths()?.[0] as
      | string
      | undefined;
    if (!invalidFieldToScroll) return;
    smoothScrollInToCenter(`[name="${invalidFieldToScroll}"]`, `#${BODY_ID}`);
  };

  useEffect(() => {
    if (!regCardDetails || !propertyConfiguration) return;

    setInitialFormValues(
      prepareInitialValues(
        regCardDetails?.profile,
        propertyConfiguration?.businessDate,
        generalSettings,
        entities
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View
      isLoading={isProfileUpdating}
      isError={Boolean(errors.length)}
      errorMessage={getErrorMessage(errors, t)}
      onErrorModalSubmit={handleBack}
    >
      <Header title={t('SHARED.REGISTRATION')} />

      <Form
        initialValues={initialFormValues}
        onSubmit={onFormSubmit}
        validate={validator.validate}
        subscription={{ initialValues: true }}
      >
        {(formRenderProps): JSX.Element => {
          return (
            <>
              <Body className="registration-card-edit-wrapper" id={BODY_ID}>
                {regCardDetails && (
                  <ReservationDetailsSection
                    reservationData={regCardDetails.reservation}
                  />
                )}
                <Section type={SectionType.wide}>
                  {(customMessages?.EDITING_INSTRUCTION_MAIN_TEXT ||
                    customMessages?.EDITING_INSTRUCTION_ADDITIONAL_TEXT) && (
                    <FormHeader
                      title={customMessages.EDITING_INSTRUCTION_MAIN_TEXT}
                      hint={customMessages.EDITING_INSTRUCTION_ADDITIONAL_TEXT}
                      className="spacing-bottom-lg"
                    />
                  )}

                  <PersonalDetailFormSection formApi={formRenderProps.form} />
                  <Separator className="spacing-top-xlg spacing-bottom-lg" />

                  {generalSettings?.DISPLAY_ADDRESS && (
                    <>
                      <AddressFormSection formApi={formRenderProps.form} />
                      <Separator className="spacing-top-xlg spacing-bottom-lg" />
                    </>
                  )}

                  {isContactFormSectionVisible && (
                    <ContactFormSection formApi={formRenderProps.form} />
                  )}

                  <DocumentsFormSection formApi={formRenderProps.form} />
                </Section>
              </Body>

              <Footer
                hasPrimaryButton
                hasSecondaryButton
                hasCancelButton
                primaryLabel={t('SHARED.SAVE')}
                onSecondaryClick={handleBack}
                onPrimaryClick={(): void => {
                  formRenderProps.handleSubmit();
                  if (!validator.isValid) {
                    scrollToFirstInvalidField();
                  }
                }}
              />
            </>
          );
        }}
      </Form>
    </View>
  );
};
