import { KioskCommunicationChannel } from 'api/KioskApi/entries';
import { EntitiesSettingsStorage } from 'store/settings/interfaces';
import { extendDataByDisplaySequence } from 'utils';
import { sortDisplayElements } from 'utils/sorting';
import { PhonesFormValues } from 'views/RegistrationCardEditPersonal/types';

export const preparePhonesInitialValues = (
  phones: KioskCommunicationChannel[],
  entities?: EntitiesSettingsStorage
): PhonesFormValues[] => {
  const primary = phones.filter((phone) => phone.isPrimary);
  const nonPrimary = phones.filter((phone) => !phone.isPrimary);
  const sorted = entities?.communicationTypes
    ? sortDisplayElements(
        extendDataByDisplaySequence(nonPrimary, entities.communicationTypes)
      )
    : nonPrimary;

  return [...primary, ...sorted].map(prepareSinglePhoneInitialValues);
};

export const prepareSinglePhoneInitialValues = (
  phone?: Partial<KioskCommunicationChannel>
): PhonesFormValues => ({
  id: phone?.id,
  countryCode: undefined,
  phoneNumber: phone?.details,
  type: phone?.type?.id,
  isPrimary: phone?.isPrimary || false,
});
