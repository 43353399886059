import { createAction, getOneAction } from '@ac/library-utils/dist/redux-utils';

import { KioskLoggedUser } from 'api/KioskApi/entries';

import { SagaError } from 'types/shared';

import { PrepareEnvironmentPayload } from './interfaces/prepareExternalDeviceEnvironmentPayload';
import { PropertyPermissions } from './interfaces/state';

export const selectProperty = getOneAction<string, string, SagaError>(
  '@app/selectProperty'
);

export const prepareApplication = getOneAction<undefined, undefined, SagaError>(
  '@app/prepareApplication'
);

export const prepareEnvironment = getOneAction<
  PrepareEnvironmentPayload,
  undefined,
  SagaError | null
>('@app/prepareEnvironment');

export const startPollingRegistrationCard = createAction(
  '@app/startPollingRegistrationCard'
);

export const stopPollingRegistrationCard = createAction(
  '@app/stopPollingRegistrationCard'
);

export const setCurrentDevice = createAction<null | string>(
  '@app/setCurrentDevice'
);

export const setCurrentProperty = createAction<null | string>(
  '@app/setCurrentProperty'
);

export const setPropertyPermissions = createAction<PropertyPermissions>(
  '@app/setPropertyPermissions'
);

export const setCurrentUser = createAction<KioskLoggedUser>(
  '@app/setCurrentUser'
);

export const setSameDeviceScenario = createAction('@app/setSameDeviceScenario');

export const restartApplication = createAction('@app/restartApplication');
