import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Separator,
  TextSize,
  TextWeight,
  ValidationHeader,
} from '@ac/kiosk-components';
import { FormApi, FormSpy } from '@ac/react-infrastructure';

import { COMMUNICATION_MODES } from 'configs';
import {
  getCommunicationModeOrder,
  getGeneralSettings,
} from 'store/settings/selectors';
import { doesFormContainsErrors } from 'utils/form';
import {
  FormProperties,
  FormValues,
} from 'views/RegistrationCardEditPersonal/types';

import { ContactSubSectionForm } from './ContactSubSectionForm/ContactSubSectionForm';

import './ContactFormSection.scss';

interface ContactFormSectionProps {
  formApi: FormApi<FormValues>;
}

export const ContactFormSection = ({
  formApi,
}: ContactFormSectionProps): JSX.Element => {
  const [t] = useTranslation();
  const generalSettings = useSelector(getGeneralSettings);
  const communicationModeOrder = useSelector(getCommunicationModeOrder);

  const checkIsSubFormValid = useCallback(() => {
    const { emails, mobiles, phones } = formApi.getState().errors || {};

    return !doesFormContainsErrors({ emails, mobiles, phones });
  }, [formApi]);

  const subFormsOrder = useMemo(() => {
    return {
      mobiles: communicationModeOrder.indexOf(COMMUNICATION_MODES.mobile) + 1,
      phones: communicationModeOrder.indexOf(COMMUNICATION_MODES.phone) + 1,
      emails: communicationModeOrder.indexOf(COMMUNICATION_MODES.email) + 1,
    };
  }, [communicationModeOrder]);

  return (
    <>
      <FormSpy subscription={{ errors: true }}>
        {(): JSX.Element => (
          <div className="validation-header-wrapper spacing-bottom-md">
            <ValidationHeader
              isValid={checkIsSubFormValid()}
              titleWeight={TextWeight.regular}
              titleSize={TextSize.xlg}
              title={t('COMPONENTS.PERSONAL_DETAILS_SECTION.CONTACT')}
            />
          </div>
        )}
      </FormSpy>

      <div className="reg-card-edit-contact-form-section">
        {generalSettings?.DISPLAY_MOBILE && (
          <div
            className={`reg-card-edit-contact-form-sub-section-${subFormsOrder.mobiles}`}
          >
            <ContactSubSectionForm
              formApi={formApi}
              type={FormProperties.mobiles}
            />
            <Separator className="spacing-top-xlg spacing-bottom-lg" />
          </div>
        )}

        {generalSettings?.DISPLAY_PHONE && (
          <div
            className={`reg-card-edit-contact-form-sub-section-${subFormsOrder.phones}`}
          >
            <ContactSubSectionForm
              formApi={formApi}
              type={FormProperties.phones}
            />
            <Separator className="spacing-top-xlg spacing-bottom-lg" />
          </div>
        )}

        {generalSettings?.DISPLAY_EMAIL && (
          <div
            className={`reg-card-edit-contact-form-sub-section-${subFormsOrder.emails}`}
          >
            <ContactSubSectionForm
              formApi={formApi}
              type={FormProperties.emails}
            />
            <Separator className="spacing-top-xlg spacing-bottom-lg" />
          </div>
        )}
      </div>
    </>
  );
};
