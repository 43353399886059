import { KioskCommunicationChannel } from 'api/KioskApi/entries';
import {
  FieldConfiguration,
  PhoneSection,
} from 'store/settings/interfaces/settingTypes/sectionConfiguration';

const PHONE_CONFIGURATION_MAP: Record<
  keyof PhoneSection,
  keyof KioskCommunicationChannel | undefined
> = {
  detailsField: 'details',
  typeField: 'type',
  isEditable: undefined,
  isRequired: undefined,
  isVisible: undefined,
};

export const validatePhones = (
  phonesConfiguration: PhoneSection,
  phones?: KioskCommunicationChannel[]
): boolean => {
  if (phonesConfiguration.isRequired && !phones?.length) return false;
  if (!phones?.length) return true;

  return phones.every((phone) => {
    return Object.entries(phonesConfiguration).every(
      ([key, fieldConfiguration]: [
        keyof PhoneSection,
        FieldConfiguration
      ]): boolean => {
        const phoneRecordKey = PHONE_CONFIGURATION_MAP[key];

        if (phoneRecordKey && fieldConfiguration.isRequired) {
          return !!phone[phoneRecordKey];
        }

        return true;
      }
    );
  });
};
