import { useTranslation } from 'react-i18next';

import { Flex, Loader } from '@ac/kiosk-components';

interface Props {
  dataTestSelector?: string;
  className?: string;
}

export const AvailabilityLoader = ({
  dataTestSelector,
  className,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Flex dataTestSelector={dataTestSelector} className={className} grow>
      <Loader
        className="spacing-auto"
        description={t(
          'REGISTRATION_CARD_PURCHASE_ELEMENTS.PREPARATION_MESSAGE'
        )}
      />
    </Flex>
  );
};
