import { createSelector } from 'reselect';

import {
  BaseKioskConfigurationEntity,
  KioskStateDetails,
} from 'api/KioskApi/entries';
import { Store } from 'store/types';

import {
  DictionaryStatusData,
  DictionaryStatuses,
  StoredDictionaryStructure,
} from './interfaces/dictionaryDataStatus';

export const getAllCountryStateStore = (
  state: Store
):
  | Record<string, StoredDictionaryStructure<KioskStateDetails[]>>
  | undefined => {
  return state.additionalDictionaries.countryStates;
};

export const getSingleCountryStateStore = (
  state: Store,
  countryCode: string | undefined
): StoredDictionaryStructure<KioskStateDetails[]> | undefined => {
  if (!countryCode) return undefined;

  return state.additionalDictionaries.countryStates?.[countryCode];
};

export const getCountryStatesStatus = createSelector(
  getSingleCountryStateStore,
  (countryStateStoredData): DictionaryStatusData<KioskStateDetails[]> => {
    if (!countryStateStoredData) return {};

    return {
      isFetching: countryStateStoredData.status === DictionaryStatuses.fetching,
      isFailed: countryStateStoredData.status === DictionaryStatuses.failed,
      result: countryStateStoredData.data || [],
    };
  }
);

export const getAllCountryDistrictsStore = (
  state: Store
):
  | Record<string, StoredDictionaryStructure<BaseKioskConfigurationEntity[]>>
  | undefined => {
  return state.additionalDictionaries.countryDistricts;
};

export const getSingleCountryDistrictStore = (
  state: Store,
  countryCode: string | undefined
): StoredDictionaryStructure<BaseKioskConfigurationEntity[]> | undefined => {
  if (!countryCode) return undefined;

  return state.additionalDictionaries.countryDistricts?.[countryCode];
};

export const getCountryDistrictsStatus = createSelector(
  getSingleCountryDistrictStore,
  (
    countryDistrictsStoredData
  ): DictionaryStatusData<BaseKioskConfigurationEntity[]> => {
    if (!countryDistrictsStoredData) return {};

    return {
      isFetching:
        countryDistrictsStoredData.status === DictionaryStatuses.fetching,
      isFailed: countryDistrictsStoredData.status === DictionaryStatuses.failed,
      result: countryDistrictsStoredData.data || [],
    };
  }
);
