import { CurrencyFormatter } from 'services';
import { SessionPurchaseElementDetails } from 'store/electronicRegistrationProcess/interfaces/SessionPurchaseElementDetails';
import { calculatePurchaseElementTotalPrice } from 'utils/domain/calculatePurchaseElementTotalPrice';

import { PurchaseElementPresentationDataItem } from './types';

export const mapStayEnhancements = (
  sessionPurchaseElements: SessionPurchaseElementDetails[],
  adultCount: number,
  childCount: number
): PurchaseElementPresentationDataItem[] => {
  return sessionPurchaseElements
    ?.filter((item) => item?.name)
    .map<PurchaseElementPresentationDataItem>(
      (item: Required<SessionPurchaseElementDetails>) => ({
        name: item.name,
        amount: item.quantity,
        price: CurrencyFormatter.format(
          calculatePurchaseElementTotalPrice(
            item,
            item.quantity,
            adultCount,
            childCount
          )
        ),
      })
    );
};
