import { ExpandPanel, Text, TextWeight } from '@ac/kiosk-components';

import { ReservationPresentationDataElementSectionData } from 'utils/regCardPresentationDataMappers';

interface ReservationSubSectionElementProps {
  item: ReservationPresentationDataElementSectionData;
}

export const ReservationSubSectionElement = ({
  item,
}: ReservationSubSectionElementProps): JSX.Element => {
  const content = (
    <Text weight={TextWeight.light} className="reg-card-subsection-item">
      <span>
        {item.label}
        {item.value && (
          <span className="reg-card-reservation-subsection-normal-font">
            {item.value}
          </span>
        )}
      </span>
    </Text>
  );

  return (
    <>
      {item?.isCollapsible ? (
        <ExpandPanel.ExtendableElement>{content}</ExpandPanel.ExtendableElement>
      ) : (
        content
      )}
    </>
  );
};
