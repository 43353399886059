import { AddressSection } from 'store/settings/interfaces/settingTypes/sectionConfiguration';
import {
  ConfigurationFieldsMap,
  SubFormFieldsConfiguration,
  useSubFormFieldsConfiguration,
} from 'views/RegistrationCardEditPersonalV2/hooks/useSubFormFieldsConfiguration';

import {
  AddressFormProperties,
  AddressFormValues,
  FormProperties,
  FormValues,
} from '../../../types';

const CONFIGURATION_TO_FORM_FIELD_MAP: ConfigurationFieldsMap<
  keyof AddressSection,
  AddressFormValues
> = {
  addressLine1Field: AddressFormProperties.addressLine1,
  addressLine2Field: AddressFormProperties.addressLine2,
  cityField: AddressFormProperties.city,
  countryField: AddressFormProperties.country,
  districtField: AddressFormProperties.district,
  postalCodeField: AddressFormProperties.postalCode,
  stateField: AddressFormProperties.state,
  typeField: AddressFormProperties.type,
};

type AddressesFieldsConfigurationResult = SubFormFieldsConfiguration<
  FormValues,
  AddressFormValues,
  [FormProperties.addresses, number]
>;

export const useAddressFieldsConfiguration = (
  path: [FormProperties.addresses, number]
): AddressesFieldsConfigurationResult => {
  const getAddressSubFormConfiguration = useSubFormFieldsConfiguration<
    FormValues,
    AddressFormValues,
    [FormProperties.addresses, number]
  >('addressesObjectCollection', path);

  return getAddressSubFormConfiguration(CONFIGURATION_TO_FORM_FIELD_MAP);
};
