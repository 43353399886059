import {
  AdditionalNamesTypes,
  KioskPersonalDetails,
} from 'api/KioskApi/entries';
import {
  FieldConfiguration,
  GuestPersonalDetailsSection,
} from 'store/settings/interfaces/settingTypes/sectionConfiguration';

const PERSONAL_DETAILS_CONFIGURATION_MAP: Record<
  keyof GuestPersonalDetailsSection,
  keyof KioskPersonalDetails | undefined
> = {
  dateOfBirthField: 'birthday',
  firstNameField: 'firstName',
  lastNameField: 'lastName',
  middleNameField: 'middleName',
  nationalityField: 'nationality',
  secondSurnameField: 'additionalNames',
  suffixField: 'suffix',
  titleField: 'title',
  isEditable: undefined,
  isRequired: undefined,
  isVisible: undefined,
};

export const validatePersonalDetails = (
  personalDetailsConfiguration: GuestPersonalDetailsSection,
  personalDetails: KioskPersonalDetails = {}
): boolean => {
  return Object.entries(personalDetailsConfiguration).every(
    ([key, fieldConfiguration]: [
      keyof GuestPersonalDetailsSection,
      FieldConfiguration
    ]): boolean => {
      const profileRecordKey = PERSONAL_DETAILS_CONFIGURATION_MAP[key];

      if (profileRecordKey && fieldConfiguration.isRequired) {
        switch (profileRecordKey) {
          case 'additionalNames':
            return !!personalDetails?.additionalNames?.some(
              (name) => name.type === AdditionalNamesTypes.SecondSurname
            );
          default:
            return !!personalDetails[profileRecordKey];
        }
      }

      return true;
    }
  );
};
