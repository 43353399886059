import {
  formNestedFieldArrayFactory,
  formNestedFieldFactory,
} from '@ac/react-infrastructure';

import { ContactEmailsSubFormValues } from '../types';

export const ContactEmailsFormField = formNestedFieldFactory<ContactEmailsSubFormValues>();

export const ContactEmailsFormFieldCollection = formNestedFieldArrayFactory<ContactEmailsSubFormValues>();
