import { RawState, State } from '@ac/library-api';

import {
  BaseKioskConfigurationEntity,
  RawBaseKioskConfigurationEntity,
} from 'api/KioskApi/entries';

export interface RawKioskAddress {
  id: string;
  type?: RawBaseKioskConfigurationEntity;
  isPrimary: boolean;
  isArPrimary: boolean;
  addressLine1?: string;
  addressLine2?: string;
  postalCode?: string;
  city?: string;
  state?: RawState;
  district?: RawBaseKioskConfigurationEntity;
  country?: RawBaseKioskConfigurationEntity;
  language?: RawBaseKioskConfigurationEntity;
}

export class KioskAddress {
  public id: string;
  public type?: BaseKioskConfigurationEntity;
  public isPrimary: boolean;
  public isArPrimary: boolean;
  public addressLine1?: string;
  public addressLine2?: string;
  public postalCode?: string;
  public city?: string;
  public state?: State;
  public district?: BaseKioskConfigurationEntity;
  public country?: BaseKioskConfigurationEntity;
  public language?: BaseKioskConfigurationEntity;

  constructor(raw: RawKioskAddress) {
    this.id = raw.id;
    this.type = raw.type && new BaseKioskConfigurationEntity(raw.type);
    this.isPrimary = raw.isPrimary;
    this.isArPrimary = raw.isArPrimary;
    this.addressLine1 = raw.addressLine1;
    this.addressLine2 = raw.addressLine2;
    this.postalCode = raw.postalCode;
    this.city = raw.city;
    this.state = raw.state && new State(raw.state);
    this.district =
      raw.district && new BaseKioskConfigurationEntity(raw.district);
    this.country = raw.country && new BaseKioskConfigurationEntity(raw.country);
    this.language =
      raw.language && new BaseKioskConfigurationEntity(raw.language);
  }
}
