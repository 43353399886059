import { LogReporter } from '@ac/kiosk-components';
import { Config, ConfigBuilder, Logger } from '@ac/library-api';
import { LoginService, SessionDataHost } from '@ac/library-utils/dist/services';

import { CLIENT_ID, LOG_TYPES, paths } from 'configs';

import { BaseObject } from 'types/shared';

const LOGIN_REDIRECT_URL = `${window.location.origin}${paths.LOGIN}`;

export class Authorizer {
  public static init(onSuccessAuth?: () => void): boolean {
    LoginService.overrideIdentityClientId(CLIENT_ID);
    LoginService.setRedirectUri(LOGIN_REDIRECT_URL);
    LoginService.setOnSuccessHandler(onSuccessAuth);

    return LoginService.init((message: string) => {
      const isAuthError = message.includes('Error');
      const logMethod = isAuthError
        ? LogReporter.log.error
        : LogReporter.log.information;

      logMethod(LOG_TYPES.auth, { message });

      if (isAuthError) {
        Logger.forceSend();
      }
    });
  }

  public static authorize(): void {
    LoginService.handleModuleCallBack({
      moduleLoginUrl: LOGIN_REDIRECT_URL,
      returnUrl: LOGIN_REDIRECT_URL,
    });
  }

  public static logout = (): void => {
    LoginService.logout();
    SessionDataHost.clearSessionData();
    localStorage.clear();
  };

  public static getStaticConfiguration = (
    options?: {
      propertyId?: string;
      headers?: { [key: string]: string };
    },
    skipCache: boolean = true
  ): Config<undefined> => {
    const { token: accessToken } = LoginService.authData() || { token: '' };
    const headers: BaseObject<string> = {};

    if (options?.headers) {
      Object.entries(options.headers).forEach(([headerKey, headerValue]) => {
        headers[headerKey] = headerValue;
      });
    }

    headers.Authorization = `Bearer ${accessToken}`;

    if (options?.propertyId) {
      headers['AC-Property-ID'] = options.propertyId;
    }

    return new ConfigBuilder()
      .setHeaders(headers)
      .setSkipCache(skipCache)
      .getConfig();
  };
}
