export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = (): void => {
      resolve(reader.result as string);
    };

    reader.onerror = (error: ProgressEvent<FileReader>): void => {
      reject(error);
    };

    reader.readAsDataURL(blob);
  });
};
