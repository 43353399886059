import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash.get';

import {
  Button,
  ButtonPattern,
  IconTypes,
  Separator,
  Text,
  TextSize,
  TextWeight,
  ValidationHeader,
} from '@ac/kiosk-components';
import {
  FormApi,
  formNestedFieldArrayFactory,
  FormSpy,
} from '@ac/react-infrastructure';

import { smoothScrollInToCenter } from 'utils';
import { doesFormContainsErrors } from 'utils/form';
import { BODY_ID } from 'views/RegistrationCardEditPersonal/config';
import {
  DocumentFormValues,
  FormProperties,
  FormValues,
} from 'views/RegistrationCardEditPersonal/types';
import { prepareSingleDocumentInitialValues } from 'views/RegistrationCardEditPersonal/utils/prepareInitialValues/prepareDocumentsInitialValues';

import { SingleDocumentSubForm } from './SingleDocumentSubForm/SingleDocumentSubForm';

type SubFormValues = {
  [FormProperties.documents]: DocumentFormValues[];
};

const NestedFormFieldArray = formNestedFieldArrayFactory<SubFormValues>();

interface DocumentsFormSectionProps {
  formApi: FormApi<FormValues>;
}

export const DocumentsFormSection = ({
  formApi,
}: DocumentsFormSectionProps): JSX.Element => {
  const [t] = useTranslation();

  const checkIsSubFormValid = useCallback(() => {
    const errors = formApi.getState().errors?.documents;

    return !doesFormContainsErrors(errors);
  }, [formApi]);

  const scrollToSubSection = useCallback((documentSectionId: string) => {
    window.requestAnimationFrame(() =>
      smoothScrollInToCenter(`#${documentSectionId}`, `#${BODY_ID}`)
    );
  }, []);

  const createNewDocument = useCallback(() => {
    const documents = formApi.getState().values?.documents;
    const newDocumentData = prepareSingleDocumentInitialValues();

    formApi.change(
      FormProperties.documents,
      documents ? [...documents, newDocumentData] : [newDocumentData]
    );

    if (documents?.length) {
      scrollToSubSection(`${FormProperties.documents}-${documents?.length}`);
    }
  }, [formApi, scrollToSubSection]);

  return (
    <>
      <FormSpy subscription={{ errors: true }}>
        {(): JSX.Element => {
          const isSubFormValid = checkIsSubFormValid();

          return (
            <div className="validation-header-wrapper spacing-bottom-md">
              <ValidationHeader
                isValid={isSubFormValid}
                titleWeight={TextWeight.regular}
                titleSize={TextSize.xlg}
                title={t(
                  'COMPONENTS.PERSONAL_DETAILS_SECTION.ID_AND_DOCUMENTS'
                )}
              />
              <Button
                disabled={!isSubFormValid}
                onClick={createNewDocument}
                pattern={ButtonPattern.tertiary}
                icon={IconTypes.plus}
                className="add-new-data-action-button"
              >
                {t(
                  'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.DOCUMENT.ADD_BUTTON'
                )}
              </Button>
            </div>
          );
        }}
      </FormSpy>

      <NestedFormFieldArray
        valuePath={[FormProperties.documents]}
        subscription={{}}
      >
        {(fieldFieldArrayRenderProps): JSX.Element | JSX.Element[] =>
          get(fieldFieldArrayRenderProps.fields, 'length') ? (
            fieldFieldArrayRenderProps.fields.map((path, index, name) => (
              <div key={name}>
                {!!index && <Separator key={index} />}
                <SingleDocumentSubForm
                  id={path.join('-')}
                  formApi={formApi}
                  path={path}
                  onRemoveClick={fieldFieldArrayRenderProps.fields.remove}
                />
              </div>
            ))
          ) : (
            <Text className="spacing-top-lg spacing-bottom-xs">
              {t('SHARED.NO_DOCUMENT')}
            </Text>
          )
        }
      </NestedFormFieldArray>
    </>
  );
};
