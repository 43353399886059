import { Component, PropsWithChildren } from 'react';

import {
  ErrorView,
  LogReporter,
  MagicButton,
  MagicButtonHorizontalPosition,
  MagicButtonVerticalPosition,
} from '@ac/kiosk-components';

import { EnvironmentSettingsModal } from 'components/index';
import { LOG_MESSAGES_TITLES, LOG_TYPES } from 'configs/logs';
import { isPromiseRejection } from 'utils/isPromiseRejection';

import './ErrorBoundary.scss';

interface ErrorBoundaryState {
  isUncatchedException: boolean;
  isServiceModalOpen: boolean;
}

export class ErrorBoundary extends Component<
  PropsWithChildren<unknown>,
  ErrorBoundaryState
> {
  public state: ErrorBoundaryState = {
    isUncatchedException: false,
    isServiceModalOpen: false,
  };

  public componentDidMount(): void {
    window.addEventListener('unhandledrejection', this.reportReactError);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('unhandledrejection', this.reportReactError);
  }

  public componentDidCatch(error: Error): void {
    if (error) {
      this.setState({ isUncatchedException: true });
      this.reportReactError(error);
    }
  }

  private getErrorMessage(error: Error | PromiseRejectionEvent): string {
    if (isPromiseRejection(error)) {
      const reason = error.reason as { message: string } | string | number;
      if (
        typeof reason === 'object' &&
        reason !== null &&
        typeof reason.message === 'string'
      ) {
        return reason.message;
      }
      // it can be text or error code:
      // https://developer.mozilla.org/en-US/docs/Web/API/PromiseRejectionEvent/reason
      if (typeof reason === 'string' || typeof reason === 'number') {
        return reason.toString();
      }

      // we're not sure what is in error.reason
      return 'Unknown error';
    }

    return error.message;
  }

  private reportReactError = (error: Error | PromiseRejectionEvent): void => {
    LogReporter.log.fatal(
      LOG_TYPES.app,
      {
        message: isPromiseRejection(error)
          ? LOG_MESSAGES_TITLES.reactUnhandledRefection
          : LOG_MESSAGES_TITLES.reactCriticalError,
        details: {
          message: this.getErrorMessage(error),
          location: window.location.pathname,
        },
      },
      true
    );
  };

  private toggleServiceModal = (): void => {
    const { isServiceModalOpen } = this.state;
    this.setState({ isServiceModalOpen: !isServiceModalOpen });
  };

  public render(): React.ReactNode {
    const { isUncatchedException, isServiceModalOpen } = this.state;

    return isUncatchedException ? (
      <>
        <MagicButton
          dataTestSelector="error-modal-magic-button"
          positionHorizontal={MagicButtonHorizontalPosition.left}
          positionVertical={MagicButtonVerticalPosition.top}
          width={90}
          height={90}
          pressTimeout={5000}
          onLongPress={this.toggleServiceModal}
          className="error-modal-magic-button"
        />

        {isServiceModalOpen && (
          <EnvironmentSettingsModal onCancelClick={this.toggleServiceModal} />
        )}

        <ErrorView />
      </>
    ) : (
      this.props.children
    );
  }
}
