import { getFactory } from '@ac/library-api';

import { KioskLoggedUser, RawKioskLoggedUser } from './entries';

const path = {
  me: '/kiosk/v0/users/me',
};

export const ApiCacheKeyLoggedUser = 'UsersApiLoggedUser';

export const getLoggedUser = getFactory<
  undefined,
  RawKioskLoggedUser,
  KioskLoggedUser
>({
  path: path.me,
  cacheKey: ApiCacheKeyLoggedUser,
  ctor: KioskLoggedUser,
});
