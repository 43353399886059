import { getChanges } from '@ac/react-infrastructure';

import {
  AdditionalNamesTypes,
  KioskUpdatePersonalDetails,
} from 'api/KioskApi/entries';
import { DateManager } from 'utils';
import { PersonalDetailsFormValues } from 'views/RegistrationCardEditPersonal/types';

export const preparePersonalDetailsUpdatePayload = (
  formData?: PersonalDetailsFormValues,
  initialValues?: PersonalDetailsFormValues
): KioskUpdatePersonalDetails | undefined => {
  const { alternateDetails: formAlternateDetails, ...restPersonalDetails } =
    formData || {};
  const {
    alternateDetails: initialAlternateDetails,
    ...restPersonalDetailsInitialValues
  } = initialValues || {};

  const alternateDetailsChanges = initialAlternateDetails
    ? getChanges(initialAlternateDetails, formAlternateDetails)
    : formAlternateDetails;

  const personalDetailsChanges = restPersonalDetailsInitialValues
    ? getChanges(restPersonalDetailsInitialValues, restPersonalDetails)
    : restPersonalDetails;

  const isAnyDataChanged = Boolean(
    (alternateDetailsChanges && Object.keys(alternateDetailsChanges).length) ||
      (personalDetailsChanges && Object.keys(personalDetailsChanges).length)
  );

  return isAnyDataChanged
    ? (formData || formAlternateDetails) && {
        titleId: restPersonalDetails.title,
        firstName: restPersonalDetails.firstName,
        middleName: restPersonalDetails.middleName,
        additionalNames: restPersonalDetails.secondSurname
          ? [
              {
                type: AdditionalNamesTypes.SecondSurname,
                value: restPersonalDetails.secondSurname,
              },
            ]
          : undefined,
        suffixId: restPersonalDetails.suffix,
        nationalityCode: restPersonalDetails.nationality,
        birthday: restPersonalDetails.birthday
          ? DateManager.getObjectDate(restPersonalDetails.birthday)
          : undefined,
        preferredLanguageProfileDetails: formAlternateDetails && {
          firstname: formAlternateDetails.firstName,
          lastname: formAlternateDetails.lastName,
          languageCode: formAlternateDetails.language,
        },
      }
    : undefined;
};
