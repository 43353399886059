export interface RawKioskConfigurationConsentType {
  id?: string;
  code?: string;
  description?: string;
}

export class KioskConfigurationConsentType {
  public id?: string;
  public code?: string;
  public description?: string;

  constructor(raw: RawKioskConfigurationConsentType) {
    this.id = raw.id;
    this.code = raw.code;
    this.description = raw.description;
  }
}
