import {
  formNestedFieldArrayFactory,
  formNestedFieldFactory,
} from '@ac/react-infrastructure';

import { ContactPhonesSubFormValues } from '../types';

export const ContactPhonesFormField = formNestedFieldFactory<ContactPhonesSubFormValues>();

export const ContactPhonesFormFieldCollection = formNestedFieldArrayFactory<ContactPhonesSubFormValues>();
