import {
  KioskReservationHeaderEnumItemDefinition,
  RawKioskReservationHeaderEnumItemDefinition,
} from './kioskReservationHeaderEnumItemDefinition';

export interface RawKioskReservationHeaderTypeConstraints {
  maxLength?: number;
  minLength?: number;
  regex?: string;
  maxValue?: number | string;
  minValue?: number | string;
  itemDefinitions?: RawKioskReservationHeaderEnumItemDefinition[];
}

export class KioskReservationHeaderTypeConstraints {
  public maxLength?: number;
  public minLength?: number;
  public regex?: string;
  public maxValue?: number | string;
  public minValue?: number | string;
  public itemDefinitions?: KioskReservationHeaderEnumItemDefinition[];

  constructor(raw: RawKioskReservationHeaderTypeConstraints) {
    this.maxLength = raw.maxLength;
    this.minLength = raw.minLength;
    this.regex = raw.regex;
    this.maxValue = raw.maxValue;
    this.minValue = raw.minValue;
    this.itemDefinitions =
      raw.itemDefinitions &&
      raw.itemDefinitions.map(
        (definition) => new KioskReservationHeaderEnumItemDefinition(definition)
      );
  }
}
