import { useCallback, useEffect, useState } from 'react';

const DEFAULT_BREAKPOINT = '(min-width: 1024px)';

export const useMatchMediaQuery = (
  queryBreakpoint: string = DEFAULT_BREAKPOINT
): boolean => {
  const [largeDevice, setLargeDevice] = useState(false);

  const setLargeDeviceFlag = useCallback(
    (e: MediaQueryList | MediaQueryListEvent) => {
      setLargeDevice(e.matches);
    },
    []
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia(queryBreakpoint);
    setLargeDeviceFlag(mediaQuery);
    mediaQuery.addEventListener('change', setLargeDeviceFlag);

    return (): void => {
      mediaQuery.removeEventListener('change', setLargeDeviceFlag);
    };
  }, [queryBreakpoint, setLargeDeviceFlag]);

  return largeDevice;
};
