import {
  KioskReservationHeaderFieldConstraints,
  RawKioskReservationHeaderFieldConstraints,
} from './kioskReservationHeaderFieldConstraints';
import { KioskReservationHeaderType } from './kioskReservationHeaderType';
import {
  KioskReservationHeaderTypeConstraints,
  RawKioskReservationHeaderTypeConstraints,
} from './kioskReservationHeaderTypeConstraints';

export interface RawKioskReservationHeaderDefinition {
  name?: string;
  displayName?: string;
  description?: string;
  type?: KioskReservationHeaderType;
  typeConstraints?: RawKioskReservationHeaderTypeConstraints;
  fieldConstraints?: RawKioskReservationHeaderFieldConstraints;
}

export class KioskReservationHeaderDefinition {
  public name?: string;
  public displayName?: string;
  public description?: string;
  public type?: KioskReservationHeaderType;
  public typeConstraints?: KioskReservationHeaderTypeConstraints;
  public fieldConstraints?: KioskReservationHeaderFieldConstraints;

  constructor(raw: RawKioskReservationHeaderDefinition) {
    this.name = raw.name;
    this.displayName = raw.displayName;
    this.description = raw.description;
    this.type = raw.type;
    this.typeConstraints =
      raw.typeConstraints &&
      new KioskReservationHeaderTypeConstraints(raw.typeConstraints);
    this.fieldConstraints =
      raw.fieldConstraints &&
      new KioskReservationHeaderFieldConstraints(raw.fieldConstraints);
  }
}
