import { ReservationPresentationDataElementSections } from 'utils/regCardPresentationDataMappers';

import { ReservationSubSectionElement } from './ReservationSubSectionElement/ReservationSubSectionElement';
import { ReservationSubSectionInlineCollection } from './ReservationSubSectionInlineCollection/ReservationSubSectionInlineCollection';

import './ReservationSubSection.scss';

interface ReservationSubSectionProps {
  data: ReservationPresentationDataElementSections;
}

export const ReservationSubSection = ({
  data,
}: ReservationSubSectionProps): JSX.Element => {
  return (
    <div className="reg-card-reservation-subsection">
      {data.sectionData?.map((item, index) =>
        item.label ? (
          <ReservationSubSectionElement item={item} key={index} />
        ) : (
          <ReservationSubSectionInlineCollection item={item} key={index} />
        )
      )}
    </div>
  );
};
