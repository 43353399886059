/* eslint-disable eqeqeq */
import Handlebars, {
  HelperOptions,
  TemplateDelegate,
} from 'handlebars/runtime';

export default (): void => {
  Handlebars.registerHelper('isArray', (data: unknown) => {
    return Array.isArray(data);
  });

  Handlebars.registerHelper(
    'isLastArrayItem',
    (index: number, array: unknown[]): boolean => {
      return array.length >= index + 1;
    }
  );

  Handlebars.registerHelper('ifCond', function (
    this: TemplateDelegate,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    v1: any,
    operator: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    v2: any,
    options: HelperOptions
  ) {
    switch (operator) {
      case '==':
        return v1 == v2 ? options.fn(this) : options.inverse(this);
      case '===':
        return v1 === v2 ? options.fn(this) : options.inverse(this);
      case '!=':
        return v1 != v2 ? options.fn(this) : options.inverse(this);
      case '!==':
        return v1 !== v2 ? options.fn(this) : options.inverse(this);
      case '<':
        return v1 < v2 ? options.fn(this) : options.inverse(this);
      case '<=':
        return v1 <= v2 ? options.fn(this) : options.inverse(this);
      case '>':
        return v1 > v2 ? options.fn(this) : options.inverse(this);
      case '>=':
        return v1 >= v2 ? options.fn(this) : options.inverse(this);
      case '&&':
        return v1 && v2 ? options.fn(this) : options.inverse(this);
      case '||':
        return v1 || v2 ? options.fn(this) : options.inverse(this);
      default:
        return options.inverse(this);
    }
  });
};
