import {
  formNestedFieldArrayFactory,
  formNestedFieldFactory,
} from '@ac/react-infrastructure';

import { DocumentsSubFormValues } from '../types';

export const DocumentsFormField = formNestedFieldFactory<DocumentsSubFormValues>();

export const DocumentsFormFieldCollection = formNestedFieldArrayFactory<DocumentsSubFormValues>();
