export interface KioskLayoutFileValue {
  contentType: string;
  fileId: string;
  fileName: string;
}

export interface KioskCustomMessageTranslationCollectionItem {
  content: string;
  languageCode: string;
}

export type KioskLayoutSettingNestedValue =
  | string
  | string[]
  | boolean
  | KioskCustomMessageTranslationCollectionItem[];

export type KioskLayoutSettingValue =
  | string
  | boolean
  | {
      value: KioskLayoutSettingNestedValue;
    }
  | KioskLayoutFileValue;

export interface RawKioskLayoutSetting {
  code?: string;
  value?: KioskLayoutSettingValue;
}

export class KioskLayoutSetting {
  public code?: string;
  public value?: KioskLayoutSettingValue;

  constructor(raw: RawKioskLayoutSetting) {
    this.code = raw.code;
    this.value = raw.value;
  }
}
