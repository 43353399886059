import { useCallback } from 'react';

import { Checkbox, Link } from '@ac/kiosk-components';
import { formNestedSingleCheckboxFieldFactory } from '@ac/react-infrastructure';

import { KioskConfigurationConsent } from 'api/KioskApi/entries';
import { mapCheckboxFieldRenderProps } from 'utils/form';

import { FormProperties, FormValues } from '../../../types';

const CheckboxField = formNestedSingleCheckboxFieldFactory<
  Pick<FormValues, FormProperties.termsAndCondition>
>();

interface ConsentCheckboxProps {
  dataTestSelector?: string;
  consent: KioskConfigurationConsent & { disabled?: boolean };
  onConsentLinkClick?: (consentId: string) => void;
}

export const ConsentCheckbox = ({
  dataTestSelector,
  consent,
  onConsentLinkClick,
}: ConsentCheckboxProps): JSX.Element | null => {
  const handleConsentLinkClick = useCallback(() => {
    if (!consent.id) return;

    onConsentLinkClick?.(consent.id);
  }, [onConsentLinkClick, consent.id]);

  return consent.id ? (
    <CheckboxField valuePath={[FormProperties.termsAndCondition, consent.id]}>
      {(checkboxFieldRenderProps): JSX.Element => (
        <Checkbox
          {...mapCheckboxFieldRenderProps(checkboxFieldRenderProps)}
          data-test-selector={dataTestSelector}
          disabled={consent.disabled}
          label={
            <Link
              className="terms-and-conditions-link"
              onClick={handleConsentLinkClick}
            >
              {consent.description}
            </Link>
          }
        />
      )}
    </CheckboxField>
  ) : null;
};
