import { ReducerMap } from 'redux-actions';

import { ApiError } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

import { clearProcessFlowData } from 'store/globalActions';

import * as actions from './actions';
import { RegistrationCardEditComplementaryViewState } from './interfaces';

export const initialState: RegistrationCardEditComplementaryViewState = {
  fetching: {
    updateComplementaryDetails: false,
  },
  errors: [],
};

const reducerMap: ReducerMap<
  RegistrationCardEditComplementaryViewState,
  unknown
> = {
  [actions.updateComplementaryDetails.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        updateComplementaryDetails: true,
      },
    };
  },

  [actions.updateComplementaryDetails.success]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        updateComplementaryDetails: false,
      },
    };
  },

  [actions.updateComplementaryDetails.failure]: (
    state,
    action: Action<ApiError | Error>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        updateProfile: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.clearRegistrationCardEditComplementary]: () => {
    return {
      ...initialState,
    };
  },

  [clearProcessFlowData]: () => {
    return {
      ...initialState,
    };
  },
};

export const registrationCardEditComplementaryView = handleActions<RegistrationCardEditComplementaryViewState>(
  reducerMap,
  initialState
);
