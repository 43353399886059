import { isDefined } from '@ac/library-utils/dist/utils';

import { KioskLayoutSetting } from 'api/KioskApi/entries';

import { BaseObject } from 'types/shared';

import { getSettingValue } from './getSettingValue';

type SettingsCodeMap<Type> = { [key in keyof Type]: string };
type MappedSettingsType<Type> = { [key in keyof Type]: Type[key] };

export const mapLayoutSettings = <SettingsStoreType extends BaseObject = never>(
  objToMap: SettingsCodeMap<SettingsStoreType>,
  settings: KioskLayoutSetting[]
): MappedSettingsType<SettingsStoreType> => {
  const mappedSettings = Object.entries(objToMap).map(([key, code]) => {
    const matchedSetting = settings.find((item) => item.code === code);
    if (!matchedSetting) return undefined;

    const newValue = getSettingValue(matchedSetting);

    return [key, newValue];
  });

  const filteredSettings = mappedSettings
    .filter(isDefined)
    .filter(([key, value]) => key && (value || value === false));

  return Object.fromEntries(
    filteredSettings
  ) as MappedSettingsType<SettingsStoreType>;
};
