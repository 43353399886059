export interface RawKioskReservationHeaderFieldConstraints {
  isAutogenerated: boolean;
  isRequired: boolean;
  isEditable: boolean;
}

export class KioskReservationHeaderFieldConstraints {
  public isAutogenerated: boolean;
  public isRequired: boolean;
  public isEditable: boolean;

  constructor(raw: RawKioskReservationHeaderFieldConstraints) {
    this.isAutogenerated = raw.isAutogenerated;
    this.isRequired = raw.isRequired;
    this.isEditable = raw.isEditable;
  }
}
