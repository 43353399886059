import {
  createRequiredValidator,
  ValidationResult,
  ValidationSchema,
} from '@ac/react-infrastructure';

export interface AddressValidationSchema {
  type: ValidationResult;
  country: ValidationResult;
}

export const addressValidationSchema = (): ValidationSchema<AddressValidationSchema> => ({
  type: createRequiredValidator('VALIDATION.SELECT_TYPE'),
  country: createRequiredValidator('VALIDATION.FIELD_IS_REQUIRED'),
});
