import { KioskConfigurationConsent, KioskConsent } from 'api/KioskApi/entries';

import { ConsentPresentationDataElement } from './types';

export const mapConsentsData = (
  data?: KioskConsent[],
  consentsList?: KioskConfigurationConsent[]
): ConsentPresentationDataElement[] => {
  if (!data) return [];

  return (consentsList || []).map(({ id, description }) => {
    const guestConsent = data.find(({ consentId }) => consentId === id);

    return {
      id,
      description,
      isGranted: guestConsent?.isGranted || false,
    };
  });
};
