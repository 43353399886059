import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Section,
  SectionType,
  Text,
  TextSize,
  TextWeight,
} from '@ac/kiosk-components';

import { KioskConfigurationConsent } from 'api/KioskApi/entries';
import { Body, Footer, Header, View } from 'components';
import { CROSS_BORDER_CONSENT_CODE } from 'configs/constants';
import { cacheCheckedConsents } from 'store/electronicRegistrationProcess/actions';
import {
  getCachedAppliedConsents,
  getRegistrationCardDetails,
} from 'store/electronicRegistrationProcess/selectors';
import { getConsentsEntities } from 'store/settings/selectors';
import { useRouter } from 'utils/hooks';

import { ConsentPathParams } from 'types/pathParams';

import './Consent.scss';

export const Consent = (): JSX.Element => {
  const router = useRouter<ConsentPathParams>();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cachedAppliedConsents = useSelector(getCachedAppliedConsents);
  const regCardDetails = useSelector(getRegistrationCardDetails);
  const consents = useSelector(getConsentsEntities);

  const consent = useMemo(():
    | (KioskConfigurationConsent & {
        disabled?: boolean;
      })
    | undefined => {
    const consentId = router.params.consentId;
    const selectedConsent = (consents || []).find(
      (element) => element.id === consentId
    );

    if (selectedConsent?.consentType?.code === CROSS_BORDER_CONSENT_CODE) {
      return {
        ...selectedConsent,
        disabled: regCardDetails?.profile.consents?.find(
          (item) => item.consentId === selectedConsent.id
        )?.isGranted,
      };
    }

    return selectedConsent;
  }, [router.params.consentId, consents, regCardDetails]);

  const isAccepted = consent?.id && cachedAppliedConsents?.[consent.id];

  const onAction = useCallback(() => {
    const consentId = consent?.id;
    if (!consentId) return;

    dispatch(
      cacheCheckedConsents({
        ...cachedAppliedConsents,
        [consentId]: !isAccepted,
      })
    );

    router.goBack();
  }, [cachedAppliedConsents, consent, dispatch, isAccepted, router]);

  return (
    <View>
      <Header title={t('REGISTRATION_CARD.TERMS_AND_CONDITIONS.TITLE')} />

      <Body>
        <div className="consent-page-wrapper">
          <Section type={SectionType.narrow}>
            {consent && (
              <>
                <Text
                  className="consent-page-title"
                  size={TextSize.xlg}
                  weight={TextWeight.bold}
                >
                  {consent.description}
                </Text>
                {consent.consentBody && (
                  <Text className="consent-page-body" size={TextSize.lg}>
                    {consent.consentBody}
                  </Text>
                )}
              </>
            )}
          </Section>
        </div>
      </Body>

      {consent && (
        <Footer
          hasPrimaryButton
          hasSecondaryButton
          primaryLabel={t(isAccepted ? 'SHARED.DECLINE' : 'SHARED.ACCEPT')}
          secondaryLabel={t('SHARED.CLOSE')}
          onPrimaryClick={onAction}
          onSecondaryClick={router.goBack}
          isPrimaryDisabled={consent.disabled}
        />
      )}
    </View>
  );
};
