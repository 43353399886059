import { KioskPreferenceDetailsDto } from 'api/KioskApi/entries/electronicRegistrationProcess/kioskPreferenceDetails';

export interface PreferenceFormGroupDictionary {
  [attributeGroupKey: string]: {
    [preferenceKey: string]: boolean;
  };
}

export const preparePreferencesFormInitialValues = (
  chosenPreferences: KioskPreferenceDetailsDto[]
): PreferenceFormGroupDictionary => {
  const response: PreferenceFormGroupDictionary = {};

  chosenPreferences.forEach((preference) => {
    if (!preference.group) {
      return;
    }
    response[preference.group.id] = {
      ...(response[preference.group.id] || {}),
      [preference.attributeId]: true,
    };
  });

  return response;
};
