import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Field, FieldMode, SelectField } from '@ac/kiosk-components';
import {
  ArrayFieldStateErrors,
  FormApi,
  formNestedFieldFactory,
  formNestedSingleCheckboxFieldFactory,
  FormSpy,
  generateFieldValuePath,
  getChanges,
} from '@ac/react-infrastructure';

import {
  fetchCountryDistricts,
  fetchCountryStates,
} from 'store/additionalDictionaries/actions';
import {
  getCountryDistrictsStatus,
  getCountryStatesStatus,
} from 'store/additionalDictionaries/selectors';
import {
  getAddressTypeEntities,
  getCountryEntities,
  getGeneralSettings,
} from 'store/settings/selectors';
import { Store } from 'store/types';
import {
  doesFormContainsErrors,
  mapFieldRenderProps,
  mapSelectOptions,
  setFieldAsTouch,
} from 'utils/form';
import {
  AddressFormProperties,
  AddressFormValues,
  FormProperties,
  FormValues,
} from 'views/RegistrationCardEditPersonal/types';

import { SubFormActionHeader } from '../../SubFormActionHeader/SubFormActionHeader';

import './SingleAddressSubForm.scss';

type SubFormValues = {
  [FormProperties.addresses]: AddressFormValues[];
};

const NestedFormField = formNestedFieldFactory<SubFormValues>();
const NestedCheckboxFormField = formNestedSingleCheckboxFieldFactory<SubFormValues>();

interface SingleAddressSubFormProps {
  id?: string;
  reorderControl?: string;
  path: [FormProperties.addresses, number];
  formApi: FormApi<FormValues>;
  onSetAsMainClick?: (identifier: number) => void;
  onRemoveClick?: (identifier: number) => void;
}

export const SingleAddressSubForm = ({
  id,
  reorderControl,
  path,
  formApi,
  onSetAsMainClick,
  onRemoveClick,
}: SingleAddressSubFormProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [isInactiveTypeRemoved, setInactiveTypeRemoved] = useState(false);

  const countries = useSelector(getCountryEntities);
  const addressTypes = useSelector(getAddressTypeEntities);
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>();

  const states = useSelector((state: Store) =>
    getCountryStatesStatus(state, selectedCountryCode)
  );

  const districts = useSelector((state: Store) =>
    getCountryDistrictsStatus(state, selectedCountryCode)
  );

  const generalSettings = useSelector(getGeneralSettings);

  const [addressPropertyKey, addressIndex] = useMemo(() => path, [path]);

  const getArrayValues = useCallback(() => {
    return formApi.getState().values?.[addressPropertyKey];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressPropertyKey, formApi, reorderControl]);

  const getCurrentPhoneValues = useCallback(() => {
    return formApi.getState().values?.[addressPropertyKey]?.[addressIndex];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressPropertyKey, addressIndex, formApi, reorderControl]);

  const checkIsSubFormValid = useCallback(() => {
    const addressesErrors = formApi.getState().errors?.addresses as
      | ArrayFieldStateErrors<AddressFormValues>
      | undefined;

    const subFormErrors = addressesErrors?.valuesErrors?.[addressIndex];

    return !doesFormContainsErrors(subFormErrors);
  }, [addressIndex, formApi]);

  const checkIsTypeInactive = useCallback(() => {
    const currentType = getCurrentPhoneValues()?.type;
    const selectedTypeDictionary = addressTypes?.find(
      (type) => type.id === currentType
    );

    return !selectedTypeDictionary ? false : !selectedTypeDictionary.isActive;
  }, [addressTypes, getCurrentPhoneValues]);

  const isTypeInactive = useMemo(() => {
    return checkIsTypeInactive();
  }, [checkIsTypeInactive]);

  useEffect(() => {
    const currentCountryCode = getCurrentPhoneValues()?.country;
    setSelectedCountryCode(currentCountryCode);
  }, [getCurrentPhoneValues]);

  useEffect(() => {
    if (!selectedCountryCode) return;
    if (!states?.result) {
      dispatch(fetchCountryStates.trigger(selectedCountryCode));
    }
    if (!districts?.result) {
      dispatch(fetchCountryDistricts.trigger(selectedCountryCode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedCountryCode, reorderControl]);

  useEffect(() => {
    if (selectedCountryCode) {
      dispatch(fetchCountryDistricts.trigger(selectedCountryCode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const typeOptions = useMemo(() => {
    return mapSelectOptions(addressTypes, 'description', 'id');
  }, [addressTypes]);

  const countryOptions = useMemo(() => {
    return mapSelectOptions(countries, 'name', 'code');
  }, [countries]);

  const statesOptions = useMemo(() => {
    return mapSelectOptions(states?.result, 'name', 'code');
  }, [states?.result]);

  const districtsOptions = useMemo(() => {
    return mapSelectOptions(districts?.result, 'description', 'id');
  }, [districts?.result]);

  const suggestedOptions = useMemo(() => {
    if (!generalSettings?.POP_COUNTRY_LIST) return;

    return countryOptions
      ?.filter((element) =>
        generalSettings?.POP_COUNTRY_LIST?.includes(element.value)
      )
      .sort((a, b) => a.title.localeCompare(b.title))
      .map((element) => element.value);
  }, [generalSettings?.POP_COUNTRY_LIST, countryOptions]);

  const handleDirtyStatusChange = useCallback(() => {
    const isTypeInactiveCheck = checkIsTypeInactive();
    if (isInactiveTypeRemoved || !isTypeInactiveCheck) return;

    const currentValues = formApi.getState().values?.[addressPropertyKey]?.[
      addressIndex
    ];
    const dataId = currentValues?.id;

    const initialValues = formApi
      .getState()
      .initialValues?.[addressPropertyKey]?.find(
        (element) => element.id === dataId
      );

    if (!initialValues) return;

    const addresses = getArrayValues();
    const changes = getChanges(initialValues, currentValues);

    if (
      !isTypeInactiveCheck ||
      !changes ||
      !Object.keys(changes).length ||
      !addresses
    ) {
      return;
    }

    const newAddresses = addresses.map((address, index) => {
      if (index === addressIndex) return { ...address, type: undefined };

      return address;
    });

    formApi.change(addressPropertyKey, newAddresses);
    setFieldAsTouch(formApi, AddressFormProperties.type, path);
    setInactiveTypeRemoved(true);
  }, [
    checkIsTypeInactive,
    isInactiveTypeRemoved,
    getArrayValues,
    formApi,
    addressPropertyKey,
    path,
    addressIndex,
  ]);

  const removeStateFieldValue = useCallback(() => {
    const addresses = getArrayValues();
    if (!addresses) return;

    const newAddresses = addresses.map((address, index) => {
      if (index === addressIndex) return { ...address, state: undefined };

      return address;
    });

    formApi.change(addressPropertyKey, newAddresses);
  }, [addressIndex, addressPropertyKey, formApi, getArrayValues]);

  const handleSetAsMainClick = useCallback(() => {
    onSetAsMainClick?.(addressIndex);
  }, [addressIndex, onSetAsMainClick]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick?.(addressIndex);
  }, [addressIndex, onRemoveClick]);

  return (
    <div className="spacing-top-lg" id={id}>
      {isTypeInactive && !isInactiveTypeRemoved && (
        <FormSpy
          onChange={handleDirtyStatusChange}
          subscription={{ dirtyFields: true }}
        />
      )}

      <FormSpy subscription={{ errors: true }}>
        {(): JSX.Element => (
          <NestedCheckboxFormField
            valuePath={generateFieldValuePath(path)([
              AddressFormProperties.isPrimary,
            ])}
          >
            {(checkboxFieldRenderProps): JSX.Element => (
              <SubFormActionHeader
                deleteControlEnabled
                mainControlEnabled
                isMain={checkboxFieldRenderProps.input.checked}
                setAsMainButtonDisabled={!checkIsSubFormValid()}
                title={`${t('SHARED.ADDRESS')} ${addressIndex + 1}`}
                onSetAsMainClick={handleSetAsMainClick}
                onRemoveClick={handleRemoveClick}
              />
            )}
          </NestedCheckboxFormField>
        )}
      </FormSpy>

      <div className="reg-card-edit-address">
        <div className="red-card-edit-address-row-grid">
          <NestedFormField
            valuePath={generateFieldValuePath(path)([
              AddressFormProperties.type,
            ])}
          >
            {(fieldFieldRenderProps): JSX.Element => (
              <SelectField
                {...mapFieldRenderProps(fieldFieldRenderProps)}
                className="reg-card-edit-address-type"
                label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.TYPE')}
                placeholder={t('SHARED.SELECT')}
                modalTitle={t(
                  'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.ADDRESS.SELECT_TYPE_MODAL_TITLE'
                )}
                options={typeOptions}
                searchable={false}
              />
            )}
          </NestedFormField>

          <div className="red-card-edit-address-row-subgrid">
            <NestedFormField
              valuePath={generateFieldValuePath(path)([
                AddressFormProperties.addressLine1,
              ])}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  className="reg-card-edit-address-address-line-1"
                  label={t(
                    'COMPONENTS.PERSONAL_DETAILS_SECTION.ADDRESS_LINE_1'
                  )}
                  placeholder={t('SHARED.FILL')}
                  optional
                />
              )}
            </NestedFormField>

            <NestedFormField
              valuePath={generateFieldValuePath(path)([
                AddressFormProperties.addressLine2,
              ])}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  className="reg-card-edit-address-address-line-2"
                  label={t(
                    'COMPONENTS.PERSONAL_DETAILS_SECTION.ADDRESS_LINE_2'
                  )}
                  placeholder={t('SHARED.FILL')}
                  optional
                />
              )}
            </NestedFormField>
          </div>
        </div>

        <div className="red-card-edit-address-row-grid">
          <NestedFormField
            valuePath={generateFieldValuePath(path)([
              AddressFormProperties.country,
            ])}
          >
            {(fieldFieldRenderProps): JSX.Element => {
              const fieldProps = mapFieldRenderProps(fieldFieldRenderProps);

              return (
                <SelectField
                  {...fieldProps}
                  className="reg-card-edit-address-country"
                  label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.COUNTRY')}
                  placeholder={t('SHARED.SELECT')}
                  suggestedOptionValues={suggestedOptions}
                  options={countryOptions}
                  onChange={(value: string): void => {
                    removeStateFieldValue();
                    setSelectedCountryCode(value);
                    fieldProps.onChange(value);
                  }}
                />
              );
            }}
          </NestedFormField>

          <div className="red-card-edit-address-row-subgrid">
            <NestedFormField
              valuePath={generateFieldValuePath(path)([
                AddressFormProperties.postalCode,
              ])}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.POST_CODE')}
                  placeholder={t('SHARED.FILL')}
                  mode={FieldMode.numeric}
                  optional
                />
              )}
            </NestedFormField>

            <NestedFormField
              valuePath={generateFieldValuePath(path)([
                AddressFormProperties.city,
              ])}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.CITY')}
                  placeholder={t('SHARED.FILL')}
                  optional
                />
              )}
            </NestedFormField>

            <NestedFormField
              valuePath={generateFieldValuePath(path)([
                AddressFormProperties.state,
              ])}
            >
              {(fieldFieldRenderProps): JSX.Element => {
                return (
                  <SelectField
                    {...mapFieldRenderProps(fieldFieldRenderProps)}
                    label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.STATE')}
                    placeholder={t('SHARED.SELECT')}
                    disabled={!selectedCountryCode}
                    options={statesOptions}
                    optionsLoading={states?.isFetching}
                    optional
                    allowClear
                  />
                );
              }}
            </NestedFormField>
          </div>
        </div>
        <div className="red-card-edit-address-row-grid">
          {generalSettings?.DISTRICT && (
            <NestedFormField
              valuePath={generateFieldValuePath(path)([
                AddressFormProperties.district,
              ])}
            >
              {(fieldFieldRenderProps): JSX.Element => {
                return (
                  <SelectField
                    {...mapFieldRenderProps(fieldFieldRenderProps)}
                    className="reg-card-edit-address-district"
                    label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.DISTRICT')}
                    placeholder={t('SHARED.SELECT')}
                    disabled={!selectedCountryCode}
                    options={districtsOptions}
                    optionsLoading={districts?.isFetching}
                    optional
                    allowClear
                  />
                );
              }}
            </NestedFormField>
          )}
        </div>
      </div>
    </div>
  );
};
