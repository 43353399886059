import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  Button,
  ButtonPattern,
  DATE_PICKER_DATE_FORMAT,
  DatePickerField,
  Field,
  FieldMode,
  IconTypes,
  SelectField,
} from '@ac/kiosk-components';
import {
  FormApi,
  formNestedFieldFactory,
  generateFieldValuePath,
} from '@ac/react-infrastructure';

import {
  getDateTimeFormats,
  getDocumentTypeEntities,
  getPropertyConfiguration,
} from 'store/settings/selectors';
import { DateManager } from 'utils';
import { mapFieldRenderProps, mapSelectOptions } from 'utils/form';
import {
  DocumentFormProperties,
  DocumentFormValues,
  FormProperties,
  FormValues,
} from 'views/RegistrationCardEditPersonal/types';

import './SingleDocumentSubForm.scss';

type SubFormValues = {
  [FormProperties.documents]: DocumentFormValues[];
};

const NestedFormField = formNestedFieldFactory<SubFormValues>();

interface SingleDocumentSubFormProps {
  id?: string;
  path: [FormProperties.documents, number];
  formApi: FormApi<FormValues>;
  onRemoveClick?: (identifier: number) => void;
}

export const SingleDocumentSubForm = ({
  id,
  path,
  formApi,
  onRemoveClick,
}: SingleDocumentSubFormProps): JSX.Element => {
  const [t] = useTranslation();
  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const documentTypes = useSelector(getDocumentTypeEntities);
  const dateTimeFormats = useSelector(getDateTimeFormats);

  const documentTypeOptions = useMemo(() => {
    return mapSelectOptions(documentTypes, 'description', 'id');
  }, [documentTypes]);

  const [documentPropertyKey, documentIndex] = useMemo(() => path, [path]);

  const isNewDocument = useMemo(() => {
    return !formApi.getState().values?.[documentPropertyKey]?.[documentIndex]
      ?.id;
  }, [documentIndex, documentPropertyKey, formApi]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick?.(documentIndex);
  }, [documentIndex, onRemoveClick]);

  return (
    <div className="reg-card-edit-document" id={id}>
      <NestedFormField
        valuePath={generateFieldValuePath(path)([DocumentFormProperties.type])}
      >
        {(fieldFieldRenderProps): JSX.Element => (
          <SelectField
            {...mapFieldRenderProps(fieldFieldRenderProps)}
            label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.TYPE')}
            placeholder={t('SHARED.SELECT')}
            modalTitle={t(
              'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.DOCUMENT.SELECT_TYPE_MODAL_TITLE'
            )}
            options={documentTypeOptions}
            disabled={!isNewDocument}
            searchable={false}
          />
        )}
      </NestedFormField>

      <div
        className={classNames('reg-card-edit-document-subgrid', {
          'reg-card-edit-document-removable': isNewDocument,
        })}
      >
        <NestedFormField
          valuePath={generateFieldValuePath(path)([
            DocumentFormProperties.number,
          ])}
        >
          {(fieldFieldRenderProps): JSX.Element => (
            <>
              <Field
                {...mapFieldRenderProps(fieldFieldRenderProps)}
                label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.NUMBER')}
                placeholder={t('SHARED.FILL')}
                mode={FieldMode.numeric}
              />
            </>
          )}
        </NestedFormField>

        <NestedFormField
          valuePath={generateFieldValuePath(path)([
            DocumentFormProperties.expiryDate,
          ])}
        >
          {(fieldFieldRenderProps): JSX.Element => (
            <DatePickerField
              {...mapFieldRenderProps(fieldFieldRenderProps)}
              label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.EXPIRY_DATE')}
              fieldDisplayFormat={dateTimeFormats?.shortDateFormat}
              placeholder={dateTimeFormats?.shortDateFormat}
              defaultPickerValue={DateManager.getFormattedDate(
                propertyConfiguration?.businessDate,
                DATE_PICKER_DATE_FORMAT
              )}
              optional
              allowClear
            />
          )}
        </NestedFormField>

        {isNewDocument && (
          <Button
            icon={IconTypes.trash}
            pattern={ButtonPattern.tertiary}
            onClick={handleRemoveClick}
          />
        )}
      </div>
    </div>
  );
};
