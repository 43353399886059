import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  ButtonPattern,
  Flex,
  FormHeader,
  Section,
  SectionType,
  TextWeight,
} from '@ac/kiosk-components';
import { Form } from '@ac/react-infrastructure';

import { Body, Footer, Header, View } from 'components';
import { paths } from 'configs/paths';
import { getErrorMessage } from 'utils';
import { useRouter } from 'utils/hooks';

import { AbortChangesWarningModal } from './components/AbortChangesWarningModal/AbortChangesWarningModal';
import { AvailabilityErrorModal } from './components/AvailabilityErrorModal/AvailabilityErrorModal';
import { AvailabilityLoader } from './components/AvailabilityLoader/AvailabilityLoader';
import { AvailableEnhancementsFormSection } from './components/AvailableEnhancementsFormSection/AvailableEnhancementsFormSection';
import { IncludedPurchaseElementsSection } from './components/IncludedPurchaseElementsSection/IncludedPurchaseElementsSection';
import {
  clearPurchaseElementsPreselection,
  fetchAvailability,
  resetPurchaseElementSelectionProcess,
  setUpEnhancements,
  updatePurchaseElements,
} from './store/actions';
import {
  getCalculatedInitialAvailability,
  getHasPurchaseElementUpdateOperationFailedError,
  getIsEhancementProcessInitialized,
  getIsEnhancementsSetUpInProgress,
  getIsPurchaseElementsUprateInProgress,
  getIsSomePurchaseElementSelected,
  getPurchaseElementsPreselection,
  getRegistrationCardPurchaseElementsErrors,
} from './store/selectors';
import { PurchaseElementsFormValues } from './types';

import './RegistrationCardPurchaseElements.scss';

interface Props {
  dataTestSelector?: string;
}

export const RegistrationCardPurchaseElements = ({
  dataTestSelector = 'registration-card-purchase-elements-view',
}: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();

  const [
    isAbortChangesWarningModalVisible,
    setAbortChangesWarningModalVisible,
  ] = useState(false);

  const initialValues = useSelector(getPurchaseElementsPreselection);
  const isEhancementProcessInitialized = useSelector(
    getIsEhancementProcessInitialized
  );
  const initialAvailability = useSelector(getCalculatedInitialAvailability);
  const isSomePurchaseElementSelected = useSelector(
    getIsSomePurchaseElementSelected
  );
  const isEnhancementsSetUpInProgress = useSelector(
    getIsEnhancementsSetUpInProgress
  );
  const isPurchaseElementsUprateInProgress = useSelector(
    getIsPurchaseElementsUprateInProgress
  );
  const errors = useSelector(getRegistrationCardPurchaseElementsErrors);
  const hasPurchaseElementUpdateOperationFailedError = useSelector(
    getHasPurchaseElementUpdateOperationFailedError
  );

  useEffect(() => {
    if (!isEhancementProcessInitialized) {
      dispatch(setUpEnhancements.trigger());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToNextPage = useCallback(
    (replace?: boolean) => {
      router.goTo(paths.REGISTRATION_CARD_CONFIRMATION, { replace });
    },
    [router]
  );

  const handleContinueWithoutChanges = useCallback(
    (confirmedAbortChanges?: boolean) => {
      if (isSomePurchaseElementSelected && !confirmedAbortChanges) {
        setAbortChangesWarningModalVisible(true);
      } else {
        redirectToNextPage();
        dispatch(clearPurchaseElementsPreselection());
      }
    },
    [dispatch, isSomePurchaseElementSelected, redirectToNextPage]
  );

  const handleAvailibilityErrorConfirm = useCallback(() => {
    dispatch(resetPurchaseElementSelectionProcess());
    dispatch(fetchAvailability.trigger());
  }, [dispatch]);

  const onFormSubmit = useCallback(
    (formValues: PurchaseElementsFormValues) => {
      dispatch(
        updatePurchaseElements.trigger({
          formValues,
          onSuccess: redirectToNextPage,
        })
      );
    },
    [dispatch, redirectToNextPage]
  );

  if (isEhancementProcessInitialized && !initialAvailability.length) {
    redirectToNextPage(true);

    return null;
  }

  return (
    <>
      {hasPurchaseElementUpdateOperationFailedError && (
        <AvailabilityErrorModal
          onConfirm={handleAvailibilityErrorConfirm}
          dataTestSelector={dataTestSelector?.concat(
            '-availability-error-modal'
          )}
        />
      )}

      {isAbortChangesWarningModalVisible && (
        <AbortChangesWarningModal
          onCancel={(): void => setAbortChangesWarningModalVisible(false)}
          onConfirm={(): void => handleContinueWithoutChanges(true)}
          dataTestSelector={dataTestSelector?.concat(
            '-abort-changes-warning-modal'
          )}
        />
      )}

      <View
        dataTestSelector={dataTestSelector}
        isLoading={isPurchaseElementsUprateInProgress}
        isError={
          !hasPurchaseElementUpdateOperationFailedError && !!errors.length
        }
        errorMessage={getErrorMessage(errors, t)}
        onErrorModalSubmit={handleAvailibilityErrorConfirm}
        className="registration-card-purchase-elements-view"
      >
        <Header title={t('REGISTRATION_CARD_PURCHASE_ELEMENTS.TITLE')} />
        <Form
          initialValues={initialValues}
          onSubmit={onFormSubmit}
          keepDirtyOnReinitialize
          destroyOnUnregister={false}
          subscription={{ values: true }}
        >
          {(formRenderProps): JSX.Element => {
            return (
              <>
                <Body>
                  {isEnhancementsSetUpInProgress ? (
                    <AvailabilityLoader
                      dataTestSelector={dataTestSelector?.concat(
                        '-availability-loader'
                      )}
                      className="full-height"
                    />
                  ) : (
                    <Section
                      className="registration-card-purchase-elements-view-section"
                      type={SectionType.wide}
                    >
                      <FormHeader
                        title={t('REGISTRATION_CARD_PURCHASE_ELEMENTS.TITLE')}
                        titleWeight={TextWeight.regular}
                        className="spacing-bottom-xxlg"
                      />

                      <IncludedPurchaseElementsSection
                        dataTestSelector={dataTestSelector?.concat(
                          '-included-purchase-elements-section'
                        )}
                      />
                      <AvailableEnhancementsFormSection
                        dataTestSelector={dataTestSelector?.concat(
                          '-available-purchase-elements-section'
                        )}
                      />
                    </Section>
                  )}
                </Body>

                <Footer
                  dataTestSelector={dataTestSelector?.concat('-footer')}
                  hasPrimaryButton
                  primaryLabel={t('SHARED.CONFIRM')}
                  isPrimaryDisabled={!isSomePurchaseElementSelected}
                  onPrimaryClick={formRenderProps.handleSubmit}
                  hasCancelButton
                >
                  <Flex className="spacing-left-auto gap-sm">
                    <Button
                      onClick={router.goBack}
                      pattern={ButtonPattern.secondary}
                      dataTestSelector={dataTestSelector?.concat(
                        '-footer-back-button'
                      )}
                    >
                      {t('SHARED.BACK')}
                    </Button>
                    <Button
                      onClick={(): void => handleContinueWithoutChanges()}
                      pattern={ButtonPattern.secondary}
                      dataTestSelector={dataTestSelector?.concat(
                        '-footer-skip-button'
                      )}
                    >
                      {t('REGISTRATION_CARD_PURCHASE_ELEMENTS.NO_THANK_YOU')}
                    </Button>
                  </Flex>
                </Footer>
              </>
            );
          }}
        </Form>
      </View>
    </>
  );
};
