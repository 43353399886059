import { isDefined } from '@ac/library-utils/dist/utils';

type WithSequenceItem<T> = {
  displaySequence: number;
  description?: string;
} & T;

type WithDescriptionItem<T> = {
  description: string;
} & T;

interface CategorizedItems<T> {
  withSequence: Array<WithSequenceItem<T>>;
  withDescription: Array<WithDescriptionItem<T>>;
  restOfItems: T[];
}

const comparer = (a: string | number, b: string | number): 1 | -1 | 0 => {
  return a === b ? 0 : a > b ? 1 : -1;
};

export const sortDisplayElements = <
  T extends {
    displaySequence?: number;
    description?: string;
  }
>(
  items: T[]
): T[] => {
  const { withDescription, restOfItems, withSequence } = items.reduce(
    (acc, item) => {
      if ('displaySequence' in item && isDefined(item.displaySequence)) {
        acc.withSequence.push(item as WithSequenceItem<T>);
      } else if ('description' in item && isDefined(item.description)) {
        acc.withDescription.push(item as WithDescriptionItem<T>);
      } else {
        acc.restOfItems.push(item);
      }

      return acc;
    },
    {
      withSequence: [],
      withDescription: [],
      restOfItems: [],
    } as CategorizedItems<T>
  );

  withSequence.sort((a, b) => {
    if (a.displaySequence === b.displaySequence) {
      if (a.description && b.description) {
        return comparer(a.description, b.description);
      }

      return 0;
    }

    return comparer(a.displaySequence, b.displaySequence);
  });

  withDescription.sort((a, b) => {
    return comparer(a.description, b.description);
  });

  return [...withSequence, ...withDescription, ...restOfItems];
};
