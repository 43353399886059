export interface RawKioskPreferredTitleDetails {
  preferredLanguageDescription?: string;
  id?: string;
  code?: string;
  name?: string;
  description?: string;
}

export class KioskPreferredTitleDetails {
  public preferredLanguageDescription?: string;
  public id?: string;
  public code?: string;
  public name?: string;
  public description?: string;

  constructor(raw: RawKioskPreferredTitleDetails) {
    this.preferredLanguageDescription = raw.preferredLanguageDescription;
    this.id = raw.id;
    this.code = raw.code;
    this.name = raw.name;
    this.description = raw.description;
  }
}
