import {
  KioskLayoutSetting,
  KioskLayoutSettingNestedValue,
  KioskLayoutSettingValue,
} from 'api/KioskApi/entries';

export const getSettingValue = (
  element?: KioskLayoutSetting
): KioskLayoutSettingNestedValue | KioskLayoutSettingValue | undefined => {
  return element?.value &&
    typeof element.value === 'object' &&
    'value' in element.value
    ? element.value?.value
    : element?.value;
};
