import { DateManager } from 'utils';

import { MapComplementaryDataOptions } from '../types';

export const localDateMap = (
  value: unknown,
  options?: MapComplementaryDataOptions
): string | undefined => {
  return value && typeof value === 'string'
    ? DateManager.getFormattedDate(value, options?.shortDateFormat)
    : undefined;
};
