import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Field, FieldMode, SelectField } from '@ac/kiosk-components';
import {
  ArrayFieldStateErrors,
  FormApi,
  formNestedFieldFactory,
  formNestedSingleCheckboxFieldFactory,
  FormSpy,
  generateFieldValuePath,
  getChanges,
} from '@ac/react-infrastructure';

import { getEmailCommunicationTypes } from 'store/settings/selectors';
import {
  doesFormContainsErrors,
  mapFieldRenderProps,
  mapSelectOptions,
  setFieldAsTouch,
} from 'utils/form';
import { SubFormActionHeader } from 'views/RegistrationCardEditPersonal/components/SubFormActionHeader/SubFormActionHeader';
import {
  EmailFormProperties,
  EmailsFormValues,
  FormProperties,
  FormValues,
} from 'views/RegistrationCardEditPersonal/types';

import './SingleEmailSubForm.scss';

type SubFormValues = {
  [key: string]: EmailsFormValues[];
};

const NestedFormField = formNestedFieldFactory<SubFormValues>();
const NestedCheckboxFormField = formNestedSingleCheckboxFieldFactory<SubFormValues>();

interface SingleEmailSubFormProps {
  id?: string;
  reorderControl?: string;
  className?: string;
  title: string;
  path: [string, number];
  formApi: FormApi<FormValues>;
  dataTestSelector?: string;
  onSetAsMainClick?: (identifier: number) => void;
  onRemoveClick?: (identifier: number) => void;
}

export const SingleEmailSubForm = ({
  id,
  reorderControl,
  className,
  title,
  path,
  formApi,
  dataTestSelector,
  onSetAsMainClick,
  onRemoveClick,
}: SingleEmailSubFormProps): JSX.Element => {
  const [t] = useTranslation();
  const [isInactiveTypeRemoved, setInactiveTypeRemoved] = useState(false);
  const communicationTypes = useSelector(getEmailCommunicationTypes);

  const [emailPropertyKey, emailIndex] = useMemo(
    () => path as [FormProperties.emails, number],
    [path]
  );

  const getArrayValues = useCallback(() => {
    return formApi.getState().values?.[emailPropertyKey];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailPropertyKey, formApi, reorderControl]);

  const getCurrentEmailValues = useCallback(() => {
    return formApi.getState().values?.[emailPropertyKey]?.[emailIndex];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailPropertyKey, emailIndex, formApi, reorderControl]);

  const checkIsSubFormValid = useCallback(() => {
    const addressesErrors = formApi.getState().errors?.[emailPropertyKey] as
      | ArrayFieldStateErrors<EmailsFormValues>
      | undefined;

    const subFormErrors = addressesErrors?.valuesErrors?.[emailIndex];

    return !doesFormContainsErrors(subFormErrors);
  }, [emailIndex, emailPropertyKey, formApi]);

  const checkIsTypeInactive = useCallback(() => {
    const currentType = getCurrentEmailValues()?.type;
    const selectedTypeDictionary = communicationTypes?.find(
      (type) => type.id === currentType
    );

    return !selectedTypeDictionary ? false : !selectedTypeDictionary.isActive;
  }, [communicationTypes, getCurrentEmailValues]);

  const communicationTypeOptions = useMemo(() => {
    return mapSelectOptions(communicationTypes, 'description', 'id');
  }, [communicationTypes]);

  const isTypeInactive = useMemo(() => {
    return checkIsTypeInactive();
  }, [checkIsTypeInactive]);

  const handleDirtyStatusChange = useCallback((): void => {
    const isTypeInactiveCheck = checkIsTypeInactive();
    if (isInactiveTypeRemoved || !isTypeInactiveCheck) return;

    const currentValues = formApi.getState().values?.[emailPropertyKey]?.[
      emailIndex
    ];
    const dataId = currentValues?.id;

    const initialValues = formApi
      .getState()
      .initialValues?.[emailPropertyKey]?.find(
        (element) => element.id === dataId
      );

    if (!initialValues) return;

    const emails = getArrayValues();
    const changes = getChanges(initialValues, currentValues);

    if (
      !isTypeInactiveCheck ||
      !changes ||
      !Object.keys(changes).length ||
      !emails
    )
      return;

    const newEmails = emails.map((email, index) => {
      if (index === emailIndex) return { ...email, type: undefined };

      return email;
    });

    formApi.change(emailPropertyKey, newEmails);
    setFieldAsTouch(formApi, EmailFormProperties.type, path);
    setInactiveTypeRemoved(true);
  }, [
    checkIsTypeInactive,
    emailIndex,
    emailPropertyKey,
    formApi,
    getArrayValues,
    isInactiveTypeRemoved,
    path,
  ]);

  const handleSetAsMainClick = useCallback(() => {
    onSetAsMainClick?.(emailIndex);
  }, [emailIndex, onSetAsMainClick]);

  const handleRemoveClick = useCallback(() => {
    onRemoveClick?.(emailIndex);
  }, [emailIndex, onRemoveClick]);

  return (
    <div
      className={classNames('reg-card-edit-email-sub-form', className)}
      id={id}
    >
      {isTypeInactive && !isInactiveTypeRemoved && (
        <FormSpy
          onChange={handleDirtyStatusChange}
          subscription={{ dirtyFields: true }}
        />
      )}

      <FormSpy subscription={{ errors: true }}>
        {(): JSX.Element => (
          <NestedCheckboxFormField
            valuePath={generateFieldValuePath(path)([
              EmailFormProperties.isPrimary,
            ])}
          >
            {(checkboxFieldRenderProps): JSX.Element => (
              <SubFormActionHeader
                deleteControlEnabled
                mainControlEnabled
                isMain={checkboxFieldRenderProps.input.checked}
                setAsMainButtonDisabled={!checkIsSubFormValid()}
                title={`${title} ${emailIndex + 1}`}
                onSetAsMainClick={handleSetAsMainClick}
                onRemoveClick={handleRemoveClick}
              />
            )}
          </NestedCheckboxFormField>
        )}
      </FormSpy>

      <div
        data-test-selector={dataTestSelector}
        className="reg-card-edit-email-sub-form-fields-wrapper"
      >
        <NestedFormField
          valuePath={generateFieldValuePath(path)([EmailFormProperties.type])}
        >
          {(fieldFieldRenderProps): JSX.Element => (
            <SelectField
              {...mapFieldRenderProps(fieldFieldRenderProps)}
              label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.TYPE')}
              placeholder={t('SHARED.SELECT')}
              modalTitle={t(
                'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.EMAIL.SELECT_TYPE_MODAL_TITLE'
              )}
              options={communicationTypeOptions}
              searchable={false}
            />
          )}
        </NestedFormField>

        <NestedFormField
          valuePath={generateFieldValuePath(path)([
            EmailFormProperties.details,
          ])}
        >
          {(fieldFieldRenderProps): JSX.Element => (
            <Field
              {...mapFieldRenderProps(fieldFieldRenderProps)}
              label={t('SHARED.EMAIL')}
              placeholder={t('SHARED.FILL')}
              mode={FieldMode.email}
            />
          )}
        </NestedFormField>
      </div>
    </div>
  );
};
