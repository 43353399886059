import i18next from 'i18next';

import { isDefined } from '@ac/library-utils/dist/utils';

import {
  KioskCustomMessageTranslationCollectionItem,
  KioskLayoutSetting,
} from 'api/KioskApi/entries';
import { getTranslationContentFromCollection } from 'utils';

import {
  CustomMessagesCodesMap,
  CustomMessagesSettingsStorage,
} from '../interfaces';

import { getSettingValue } from './getSettingValue';

export const mapCustomMessagesSettings = (
  objToMap: typeof CustomMessagesCodesMap,
  settings: KioskLayoutSetting[]
): CustomMessagesSettingsStorage => {
  const mappedSettings = Object.entries(objToMap).map((customMessage) => {
    const [key, settingMap] = customMessage;

    const matchedSettingKey = settings.find(
      (item) => item.code === settingMap?.key
    );
    if (!matchedSettingKey) return undefined;
    const matchedSettingSwitch = settings.find(
      (item) => item.code === settingMap?.switch
    );
    if (!getSettingValue(matchedSettingSwitch)) return undefined;

    let newValue = getSettingValue(matchedSettingKey);

    if (Array.isArray(newValue)) {
      newValue = getTranslationContentFromCollection(
        newValue as KioskCustomMessageTranslationCollectionItem[],
        i18next.language
      );
    }

    return [key, newValue];
  });

  const filteredSettings = mappedSettings
    .filter(isDefined)
    .filter(([key, value]) => key && (value || value === false));

  return Object.fromEntries(filteredSettings) as CustomMessagesSettingsStorage;
};
