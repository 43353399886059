import { Grid, PurchaseElementFieldSelector } from '@ac/kiosk-components';

import {
  PURCHASE_ELEMENT_GRID_TEMPLATE_LG,
  PURCHASE_ELEMENT_GRID_TEMPLATE_SM,
} from '../../constants';
import { useIncludedPurchaseElementsData } from '../../hooks/useIncludedPurchaseElementsData';

interface Props {
  dataTestSelector?: string;
}

export const IncludedPurchaseElementsSection = ({
  dataTestSelector,
}: Props): JSX.Element => {
  const includedPurchaseElements = useIncludedPurchaseElementsData();

  return (
    <Grid
      dataTestSelector={dataTestSelector}
      className="gap-lg"
      gridTemplateColumnsSm={PURCHASE_ELEMENT_GRID_TEMPLATE_SM}
      gridTemplateColumnsLg={PURCHASE_ELEMENT_GRID_TEMPLATE_LG}
    >
      {includedPurchaseElements.map((element) => (
        <Grid.Item key={element.id}>
          <PurchaseElementFieldSelector
            dataTestSelector={dataTestSelector?.concat('-purchase-element')}
            {...element}
          />
        </Grid.Item>
      ))}
    </Grid>
  );
};
