export interface RawKioskTelephoneRegionPrefixes {
  code?: string;
  regionName?: string;
  prefix?: string;
}

export class KioskTelephoneRegionPrefixes {
  public code?: string;
  public regionName?: string;
  public prefix?: string;

  constructor(raw: RawKioskTelephoneRegionPrefixes) {
    this.code = raw.code;
    this.regionName = raw.regionName;
    this.prefix = raw.prefix;
  }
}
