export interface RawKioskBusinessDate {
  businessDate: string;
}

export class KioskBusinessDate {
  public businessDate: string;

  constructor(raw: RawKioskBusinessDate) {
    this.businessDate = raw.businessDate;
  }
}
