export interface BaseKioskConfigurationEntityDto {
  id?: string;
  code?: string;
  name?: string;
  description?: string;
}

export interface RawBaseKioskConfigurationEntity {
  id?: string;
  code?: string;
  name?: string;
  description?: string;
}

export class BaseKioskConfigurationEntity {
  public id?: string;
  public code?: string;
  public name?: string;
  public description?: string;

  constructor(raw: RawBaseKioskConfigurationEntity) {
    this.id = raw.id;
    this.code = raw.code;
    this.name = raw.name;
    this.description = raw.description;
  }
}
