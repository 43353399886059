import {
  formNestedFieldArrayFactory,
  formNestedFieldFactory,
} from '@ac/react-infrastructure';

import { AddressSubFormValues } from '../types';

export const AddressFormField = formNestedFieldFactory<AddressSubFormValues>();

export const AddressFormFieldCollection = formNestedFieldArrayFactory<AddressSubFormValues>();
