import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  Flex,
  FlexDirection,
  Section,
  SectionType,
  Separator,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { PreferencesGroup } from 'store/settings/interfaces/preferences/preferencesGroup';

import { PreferenceAttributeGroup } from './PreferenceAttributeGroup/PreferenceAttributeGroup';

type PreferenceSectionProps = {
  preferenceList: PreferencesGroup[];
};

export const PreferenceSection = ({
  preferenceList,
}: PreferenceSectionProps): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <Section
      dataTestSelector="registration-summary-preferences-section"
      type={SectionType.wide}
    >
      <Text className="spacing-bottom-lg" size={TextSize.xlg}>
        {t('REGISTRATION_CARD.GUEST_PREFERENCES.TITLE')}
      </Text>
      {preferenceList.map((preferenceGroup, index) => {
        const isLastElement = index === preferenceList.length - 1;

        return (
          <Flex
            key={preferenceGroup.groupId}
            direction={FlexDirection.column}
            className={classNames({
              'spacing-bottom-sm': isLastElement,
            })}
          >
            <PreferenceAttributeGroup
              groupId={preferenceGroup.groupId}
              description={preferenceGroup.description || ''}
              preferences={preferenceGroup.preferences}
            />
            {!isLastElement && (
              <Separator dataTestSelector="preference-section-separator" />
            )}
          </Flex>
        );
      })}
    </Section>
  );
};
