import i18next from 'i18next';

import { isDefined } from '@ac/library-utils/dist/utils';

import {
  KioskAddress,
  KioskDictionaryEntryDetails,
} from 'api/KioskApi/entries';
import { extendDataByDisplaySequence } from 'utils';
import { sortDisplayElements } from 'utils/sorting';

import {
  AddressVisibilityConfig,
  ProfilePresentationDataElement,
  RawSectionData,
} from './types';

export const mapAddressData = (
  addresses?: KioskAddress[],
  addressTypes?: KioskDictionaryEntryDetails[],
  visibilityConfig?: AddressVisibilityConfig
): ProfilePresentationDataElement[] => {
  if (!addressTypes?.length || !addresses?.length) return [];
  const primaryAddress = addresses.find((address) => address.isPrimary);
  const nonPrimaryAddresses = visibilityConfig?.showOnlyPrimary
    ? []
    : addresses.filter((address) => !address.isPrimary);

  const addressesWithDisplaySequence = extendDataByDisplaySequence(
    nonPrimaryAddresses,
    addressTypes
  );
  const sortedAddresses = sortDisplayElements(addressesWithDisplaySequence);

  const {
    addressLine1,
    addressLine2,
    city,
    country,
    type,
    postalCode,
    state,
    district,
  } = primaryAddress || {};
  const addressLine = [addressLine1, addressLine2].filter(isDefined);

  const rawSectionData: RawSectionData[] = [
    {
      value: addressLine.length ? addressLine : undefined,
      label: type?.description,
    },
    {
      value: city,
      label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.CITY'),
    },
    {
      value: postalCode,
      label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.POST_CODE'),
    },
    {
      value: state?.name,
      label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.STATE'),
    },
    {
      value: district?.description,
      label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.DISTRICT'),
      isHidden: visibilityConfig?.hideDistrict,
    },
    {
      value: country?.name,
      label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.COUNTRY'),
    },
  ];

  return [
    ...(primaryAddress
      ? [
          {
            isCollapsible: false,
            sectionData: rawSectionData.filter((section) => !section.isHidden),
          },
        ]
      : []),
    ...sortedAddresses.map((address) => {
      const sortedAddressLine = [
        address.addressLine1,
        address.addressLine2,
      ].filter(isDefined);

      return {
        isCollapsible: true,
        sectionData: [
          {
            label: address.type?.description,
            value: [
              ...sortedAddressLine,
              address.city,
              address.postalCode,
              address.state?.name,
              address.district?.description,
              address.country?.name,
            ].filter(isDefined),
          },
        ],
      };
    }),
  ];
};
