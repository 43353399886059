import { FormApi } from '@ac/react-infrastructure';

import { BaseObject } from 'types/shared';

export const setFieldAsTouch = (
  formApi: FormApi<BaseObject>,
  key: string,
  nestedPath: Array<string | number> = []
): void => {
  formApi.focusIn([...nestedPath, key].join('.'));
  formApi.focusOut([...nestedPath, key].join('.'));
};
