import { ReducerMap } from 'redux-actions';

import { ApiError } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';
import { handleActions } from '@ac/library-utils/dist/redux-utils';

import { KioskPurchaseElementAvailabilityItemDto } from 'api/KioskApi/entries';
import { clearProcessFlowData } from 'store/globalActions';

import { PurchaseElementImages } from './interfaces/purchaseElementImages';
import * as actions from './actions';
import { RegistrationCardPurchaseElementsViewState } from './interfaces';

export const initialState: RegistrationCardPurchaseElementsViewState = {
  fetching: {
    updatePurchaseElements: false,
    fetchAvailability: false,
    setUpEnhancements: false,
  },
  errors: [],
  isInitialized: false,
  preSelectedPurchaseElements: undefined,
  initialAvailability: [],
  currentAvailability: [],
  images: {},
};

const reducerMap: ReducerMap<
  RegistrationCardPurchaseElementsViewState,
  unknown
> = {
  [actions.savePurchaseElementsPreselection]: (
    state,
    action: Action<Record<string, number>>
  ) => {
    return {
      ...state,
      preSelectedPurchaseElements: action.payload,
    };
  },

  [actions.clearPurchaseElementsPreselection]: (state) => {
    return {
      ...state,
      preSelectedPurchaseElements: undefined,
    };
  },

  [actions.updatePurchaseElements.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        updatePurchaseElements: true,
      },
    };
  },

  [actions.updatePurchaseElements.success]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        updatePurchaseElements: false,
      },
    };
  },

  [actions.updatePurchaseElements.failure]: (
    state,
    action: Action<ApiError | Error>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        updatePurchaseElements: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.fetchAvailability.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        fetchAvailability: true,
      },
    };
  },

  [actions.fetchAvailability.success]: (
    state,
    action: Action<KioskPurchaseElementAvailabilityItemDto[]>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        fetchAvailability: false,
      },
      currentAvailability: [...action.payload],
    };
  },

  [actions.fetchAvailability.failure]: (
    state,
    action: Action<ApiError | Error>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        fetchAvailability: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.setUpEnhancements.trigger]: (state) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        setUpEnhancements: true,
      },
    };
  },

  [actions.setUpEnhancements.success]: (
    state,
    action: Action<KioskPurchaseElementAvailabilityItemDto[]>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        setUpEnhancements: false,
      },
      isInitialized: true,
      initialAvailability: [...action.payload],
    };
  },

  [actions.setUpEnhancements.failure]: (
    state,
    action: Action<ApiError | Error>
  ) => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        setUpEnhancements: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.updateInitialAvailability]: (
    state,
    action: Action<KioskPurchaseElementAvailabilityItemDto[]>
  ) => {
    return {
      ...state,
      initialAvailability: [...action.payload],
    };
  },

  [actions.savePurchaseElementsImages]: (
    state,
    action: Action<PurchaseElementImages>
  ) => {
    return {
      ...state,
      images: {
        ...state.images,
        ...action.payload,
      },
    };
  },

  [actions.resetPurchaseElementSelectionProcess]: (state) => {
    return {
      ...state,
      preSelectedPurchaseElements: undefined,
      errors: [],
    };
  },

  [clearProcessFlowData]: (state) => {
    Object.values(state.images).forEach((image): void =>
      URL.revokeObjectURL(image)
    );

    return {
      ...initialState,
    };
  },
};

export const registrationCardPurchaseElementsView = handleActions<RegistrationCardPurchaseElementsViewState>(
  reducerMap,
  initialState
);
