import { isDefined } from '@ac/library-utils/dist/utils';
import {
  ArrayFieldStateErrors,
  ArrayValidationErrors,
  FormValidationStatuses,
  ValidationObject,
  ValidationResult,
} from '@ac/react-infrastructure';

import { BaseObject } from 'types/shared';

type FormErrorValues =
  | FormValidationStatuses<unknown>
  | ValidationResult
  | ArrayFieldStateErrors<unknown>
  | Required<ArrayValidationErrors<unknown, true>>;

export const doesFormContainsErrors = (
  errors: FormErrorValues | undefined
): boolean => {
  return !!getErrorList(errors).filter(isDefined).length;
};

export const isValidationObject = (
  error: BaseObject
): error is ValidationObject => {
  return (
    error &&
    typeof error === 'object' &&
    'severity' in error &&
    'description' in error
  );
};

export const isValidationResult = (
  error: BaseObject
): error is ValidationResult => {
  return Array.isArray(error) && error.some(isValidationObject);
};

const getErrorList = (
  errors: FormErrorValues | ValidationObject | undefined
): ValidationResult => {
  if (!errors) return [];

  if (isValidationResult(errors)) {
    return errors;
  }

  if (Array.isArray(errors)) {
    return errors.filter(isDefined).map(getErrorList).flat();
  }

  if (typeof errors === 'object') {
    return isValidationObject(errors)
      ? [errors]
      : Object.values(errors).filter(isDefined).map(getErrorList).flat();
  }

  return [];
};
