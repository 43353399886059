import { isDefined } from '@ac/library-utils/dist/utils';

import { NumberFieldProvider } from './numberFieldProvider';

const MAX_INTEGER_NUMBER = 2147483647;
const MIN_INTEGER_NUMBER = -2147483648;

export class IntegerFieldProvider extends NumberFieldProvider {
  protected get minValue(): number {
    const { typeConstraints } = this.reservationHeaderDefinition;
    const isMinNumberValid =
      (typeConstraints?.minValue as number) > MIN_INTEGER_NUMBER;

    return isDefined(typeConstraints?.minValue) && isMinNumberValid
      ? (typeConstraints?.minValue as number)
      : MIN_INTEGER_NUMBER;
  }

  protected get maxValue(): number {
    const { typeConstraints } = this.reservationHeaderDefinition;
    const isMaxNumberValid =
      (typeConstraints?.minValue as number) < MAX_INTEGER_NUMBER;

    return isDefined(typeConstraints?.maxValue) && isMaxNumberValid
      ? (typeConstraints?.maxValue as number)
      : MAX_INTEGER_NUMBER;
  }
}
