import { getChanges } from '@ac/react-infrastructure';

import { KioskUpdatePreferredLanguageProfileDetails } from 'api/KioskApi/entries';
import { AlternateDetailsFormValues } from 'views/RegistrationCardEditPersonal/types';

export const prepareAlternateDetailsUpdatePayload = (
  formData?: AlternateDetailsFormValues,
  initialValues?: AlternateDetailsFormValues
): KioskUpdatePreferredLanguageProfileDetails | undefined => {
  if (!initialValues && !formData) return undefined;
  if (initialValues && !formData) {
    return {
      firstname: null,
      lastname: null,
      languageCode: null,
    };
  }

  const alternateDetailsChanges = initialValues
    ? getChanges(initialValues, formData)
    : formData;

  const isAnyDataChanged = Boolean(
    alternateDetailsChanges && Object.keys(alternateDetailsChanges).length
  );

  return isAnyDataChanged && formData
    ? {
        firstname: formData.firstName || null,
        lastname: formData.lastName || null,
        languageCode: formData.language || null,
      }
    : undefined;
};
