var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  .pdf-signature-title {\n    font-size: 9px;\n    margin: 8px 0;\n    font-weight: 400;\n  }\n\n  .pdf-signature-image {\n    max-width: 100%;\n  }\n\n  .pdf-signature-date {\n    font-size: 8px;\n    margin-top: 8px;\n  }\n</style>\n\n<div data-avoid-break-page-inside=\"true\">\n  <h4 class=\"pdf-signature-title\">\n    "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":20,"column":15}}}) : helper)))
    + "\n  </h4>\n  <img src="
    + alias4(((helper = (helper = lookupProperty(helpers,"signatureImage") || (depth0 != null ? lookupProperty(depth0,"signatureImage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"signatureImage","hash":{},"data":data,"loc":{"start":{"line":22,"column":11},"end":{"line":22,"column":31}}}) : helper)))
    + " class=\"pdf-signature-image\" alt=\"signature image\" />\n  <p class=\"pdf-signature-date\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"date") || (depth0 != null ? lookupProperty(depth0,"date") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data,"loc":{"start":{"line":23,"column":32},"end":{"line":23,"column":42}}}) : helper)))
    + "</p>\n</div>\n\n";
},"useData":true});