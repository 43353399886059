import i18next from 'i18next';

import { RawKioskReservationHeaderEnumItemDefinition } from 'api/KioskApi/entries';
import { getTranslationContentFromCollection } from 'utils';

import { MapComplementaryDataOptions } from '../types';

export const enumMap = (
  value: unknown,
  _options?: MapComplementaryDataOptions,
  types?: RawKioskReservationHeaderEnumItemDefinition[]
): string | undefined => {
  const nameTranslations = types?.find((type) => type.code === value)?.name;

  return (
    nameTranslations &&
    getTranslationContentFromCollection(nameTranslations, i18next.language)
  );
};
