import { useCallback } from 'react';

import { isDefined } from '@ac/library-utils/dist/utils';
import { NestedFieldArrayRenderProps } from '@ac/react-infrastructure';

import { SubFormState } from 'utils/form/subFormApi';

type SetEntityAsMainResult = (
  state: SubFormState<Array<{ isPrimary?: boolean }>>,
  fieldFieldArrayRenderProps: NestedFieldArrayRenderProps<
    Record<string, Array<{ isPrimary?: boolean }> | undefined>,
    string
  >,
  entityIndex: number
) => void;

export const useSetEntityAsMain = (): SetEntityAsMainResult => {
  return useCallback((state, fieldFieldArrayRenderProps, entityIndex) => {
    const entities = state.values || [];

    const currentPrimaryEntityIndex = entities.findIndex(
      (entity) => entity.isPrimary
    );

    fieldFieldArrayRenderProps.fields.update(entityIndex, {
      ...entities[entityIndex],
      isPrimary: true,
    });

    if (isDefined(currentPrimaryEntityIndex)) {
      fieldFieldArrayRenderProps.fields.update(currentPrimaryEntityIndex, {
        ...entities[currentPrimaryEntityIndex],
        isPrimary: false,
      });
    }
  }, []);
};
