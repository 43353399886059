import { LocalizedString } from '@ac/library-api';

import { DEFAULT_APP_LANGUAGE } from 'configs/constants';

export const getLocalizedContent = (
  localization: LocalizedString[],
  language: string
): string | undefined => {
  const translationContent = localization.find(
    ({ languageCode }) => languageCode.toUpperCase() === language.toUpperCase()
  )?.content;

  return (
    translationContent ||
    localization.find(
      ({ languageCode }) =>
        languageCode.toUpperCase() === DEFAULT_APP_LANGUAGE.toUpperCase()
    )?.content
  );
};
