import i18next from 'i18next';

import { TimeFormat } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

import { KioskReservation } from 'api/KioskApi/entries';
import { CurrencyFormatter } from 'services';
import { ReservationDetailsSection } from 'store/settings/interfaces/settingTypes/sectionConfiguration';
import { DateManager } from 'utils';

import { ReservationPresentationDataElement } from './types';

export interface MapReservationDataOptions {
  longDateFormat?: string;
  timeFormat?: TimeFormat | undefined;
  isMembershipEnabled?: boolean;
  fieldsConfiguration?: ReservationDetailsSection;
}

export const mapReservationData = (
  reservationData: KioskReservation,
  options?: MapReservationDataOptions
): ReservationPresentationDataElement => {
  const {
    etd,
    arrivalDate,
    departureDate,
    linkedPurchaseElements,
    promotionalPurchaseElements,
    totalPrice,
    room,
    roomType,
    breakdown,
    memberships,
  } = reservationData;

  const fieldsConfiguration = options?.fieldsConfiguration;
  const etdTime = etd?.split('T')[1];
  const isRatePlanConfidential = !breakdown[0]?.totalDailyPrice?.isConfidential;
  const nights = DateManager.getDiffBetween(arrivalDate, departureDate);
  const ratePlan = breakdown[0]?.ratePlan;
  const adultCount = breakdown[0]?.adultCount;
  const childCount = breakdown[0]?.childCount;
  const inclusions = [
    ...linkedPurchaseElements,
    ...promotionalPurchaseElements,
  ].map((item) => item.name);

  const period = `${DateManager.getFormattedDate(
    arrivalDate,
    options?.longDateFormat
  )} - ${DateManager.getFormattedDate(departureDate, options?.longDateFormat)}`;

  const averageCost =
    !totalPrice?.isConfidential && totalPrice?.gross
      ? CurrencyFormatter.format(
          nights ? totalPrice.gross / nights : totalPrice.gross,
          totalPrice.currency
        )
      : undefined;

  const totalCost =
    !totalPrice?.isConfidential && totalPrice?.gross
      ? CurrencyFormatter.format(totalPrice.gross, totalPrice.currency)
      : undefined;

  const membership =
    memberships?.[0] &&
    [memberships[0].level?.description, memberships[0].number].join(' - ');

  return {
    subtitle: period,
    sections: [
      {
        sectionData: [
          fieldsConfiguration?.estimatedTimeOfDepartureField?.isVisible ?? true
            ? {
                label: i18next.t('COMPONENTS.RESERVATION_DETAILS_SECTION.ETD'),
                value:
                  etdTime &&
                  DateManager.getFormattedTime(etdTime, options?.timeFormat),
                isCollapsible: true,
              }
            : undefined,
          {
            inlineCollection: [
              {
                label: i18next.t(
                  'COMPONENTS.RESERVATION_DETAILS_SECTION.AMOUNT_OF_NIGHTS',
                  {
                    count: nights,
                  }
                ),
                value: nights,
              },
              {
                label: i18next.t(
                  'COMPONENTS.RESERVATION_DETAILS_SECTION.ADULT',
                  {
                    count: adultCount,
                  }
                ),
                value: adultCount,
              },
              {
                label: i18next.t(
                  'COMPONENTS.RESERVATION_DETAILS_SECTION.CHILD',
                  {
                    count: childCount,
                  }
                ),
                value: childCount,
              },
            ],
            isCollapsible: true,
          },
          options?.isMembershipEnabled && membership
            ? {
                label: i18next.t(
                  'COMPONENTS.RESERVATION_DETAILS_SECTION.MEMBERSHIP'
                ),
                value: membership,
                isCollapsible: true,
              }
            : undefined,
        ].filter(isDefined),
      },
      {
        sectionData: [
          {
            label: i18next.t('COMPONENTS.RESERVATION_DETAILS_SECTION.ROOM_NO'),
            value: room?.code,
          },
          {
            label: i18next.t(
              'COMPONENTS.RESERVATION_DETAILS_SECTION.ROOM_TYPE'
            ),
            value: roomType?.name,
            isCollapsible: true,
          },
          {
            label: i18next.t(
              'COMPONENTS.RESERVATION_DETAILS_SECTION.RATE_PLAN'
            ),
            value: isRatePlanConfidential ? ratePlan?.description : undefined,
            isCollapsible: true,
          },
        ],
      },
      isRatePlanConfidential
        ? {
            sectionData: [
              {
                label: i18next.t(
                  'COMPONENTS.RESERVATION_DETAILS_SECTION.AVERAGE_RATE'
                ),
                value: averageCost,
              },
              {
                label: i18next.t(
                  'COMPONENTS.RESERVATION_DETAILS_SECTION.TOTAL_COST'
                ),
                value: totalCost,
                isCollapsible: true,
              },
              {
                label: i18next.t(
                  'COMPONENTS.RESERVATION_DETAILS_SECTION.INCLUSIONS'
                ),
                value: inclusions?.join(', '),
                isCollapsible: true,
              },
            ],
          }
        : undefined,
    ].filter(isDefined),
  };
};
