import { FeatureTogglesCodeMap } from '../utils/normalizeFeatureToggles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FeatureTogglesStorage {
  /**
   * Add feature toggles that have to be stored from configuration response.
   */
  INIT_3946_REG_CARD_CUSTOMIZATION: boolean;
}

export const featureTogglesCodesMap: FeatureTogglesCodeMap<FeatureTogglesStorage> = {
  INIT_3946_REG_CARD_CUSTOMIZATION: {
    key: 'INIT-3946_E-reg-card-customization',
  },
};
