import {
  BaseKioskConfigurationEntity,
  RawBaseKioskConfigurationEntity,
} from '../shared';

export interface RawKioskCommunicationChannel {
  id: string;
  type?: RawBaseKioskConfigurationEntity;
  mode?: RawBaseKioskConfigurationEntity;
  details?: string;
  isPrimary: boolean;
}

export class KioskCommunicationChannel {
  public id: string;
  public type?: BaseKioskConfigurationEntity;
  public mode?: BaseKioskConfigurationEntity;
  public details?: string;
  public isPrimary: boolean;

  constructor(raw: RawKioskCommunicationChannel) {
    this.id = raw.id;
    this.type = raw.type && new BaseKioskConfigurationEntity(raw.type);
    this.mode = raw.mode && new BaseKioskConfigurationEntity(raw.mode);
    this.details = raw.details;
    this.isPrimary = raw.isPrimary;
  }
}
