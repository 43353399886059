import { useMemo } from 'react';

const SCROLL_POSITION_PREFIX = 'ScrollPositionRestoration';

interface ScrollPositionRestoration {
  saveScrollPosition: () => void;
  restoreScrollPosition: (removeStoredScrollPosition?: boolean) => void;
}

export const useScrollPositionRestoration = (
  scrollableElementSelector: string
): ScrollPositionRestoration => {
  const getScrollableElement = (): HTMLDivElement | null => {
    return document.querySelector(scrollableElementSelector);
  };

  const saveScrollPosition = (): void => {
    const element = getScrollableElement();
    if (!element) return;

    const scrollPosition = element.scrollTop - element.offsetTop;
    sessionStorage.setItem(
      `${SCROLL_POSITION_PREFIX}_${window.location.pathname}`,
      String(scrollPosition)
    );
  };

  const restoreScrollPosition = (
    removeStoredScrollPosition?: boolean
  ): void => {
    const scrollPosition = sessionStorage.getItem(
      `${SCROLL_POSITION_PREFIX}_${window.location.pathname}`
    );

    if (!removeStoredScrollPosition) return;
    sessionStorage.removeItem(
      `${SCROLL_POSITION_PREFIX}_${window.location.pathname}`
    );

    const element = getScrollableElement();
    if (!(element && scrollPosition)) return;

    requestAnimationFrame(() => {
      element.scrollTo(0, Number(scrollPosition));
    });
  };

  return useMemo(
    () => ({
      saveScrollPosition,
      restoreScrollPosition,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrollableElementSelector]
  );
};
