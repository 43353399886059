import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CurrencyFormatter } from 'services';
import {
  getAdultCount,
  getChildCount,
} from 'store/electronicRegistrationProcess/selectors';
import { calculatePurchaseElementTotalPrice } from 'utils/domain/calculatePurchaseElementTotalPrice';

import {
  getCalculatedInitialAvailability,
  getPurchaseElementsPreselection,
} from '../store/selectors';

export const useTotalPriceOfSelectedPurchaseElements = ():
  | string
  | undefined => {
  const purchaseElementsPreselection = useSelector(
    getPurchaseElementsPreselection
  );
  const initialAvailability = useSelector(getCalculatedInitialAvailability);
  const adultCount = useSelector(getAdultCount);
  const childCount = useSelector(getChildCount);

  return useMemo(() => {
    let currency: string | undefined;
    const totalPrice = Object.entries(purchaseElementsPreselection).reduce(
      (sumPrice, [purchaseElementId, quantity]) => {
        const purchaseElementDetails = initialAvailability?.find(
          (element) => element.id === purchaseElementId
        );
        if (!purchaseElementDetails || !quantity) {
          return sumPrice;
        }

        const purchaseElementPrice = calculatePurchaseElementTotalPrice(
          purchaseElementDetails,
          quantity,
          adultCount,
          childCount
        );

        if (!currency) {
          currency = purchaseElementPrice?.currency;
        }

        return sumPrice + (purchaseElementPrice?.amount ?? 0);
      },
      0
    );

    return totalPrice
      ? CurrencyFormatter.format(totalPrice, currency)
      : undefined;
  }, [
    adultCount,
    childCount,
    initialAvailability,
    purchaseElementsPreselection,
  ]);
};
