import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { isDefined } from '@ac/library-utils/dist/utils';

import {
  getEmailCommunicationTypes,
  getFieldsConfiguration,
} from 'store/settings/selectors';

import { EmailsFormValues } from '../../../../../types';

type EmailFormatter = (email?: EmailsFormValues) => string | undefined;

export const useEmailFormatter = (): EmailFormatter => {
  const emailTypes = useSelector(getEmailCommunicationTypes);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const emailFieldsConfiguration = fieldsConfiguration?.emailObjectCollection;

  const stringifyEmail = useCallback(
    (email?: EmailsFormValues): string | undefined => {
      if (!email) return;

      return [
        emailFieldsConfiguration?.typeField?.isVisible
          ? emailTypes?.find((option) => option.id === email?.type)?.description
          : undefined,
        emailFieldsConfiguration?.detailsField?.isVisible
          ? email?.details
          : undefined,
      ]
        .filter(isDefined)
        .join(', ');
    },
    [emailTypes, emailFieldsConfiguration]
  );

  return stringifyEmail;
};
