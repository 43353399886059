import i18next from 'i18next';

import { KioskIdentityDocument } from 'api/KioskApi/entries';
import { DateManager } from 'utils';

import { ProfilePresentationDataElement } from './types';

export interface MapDocumentsDataOptions {
  shortDateFormat?: string;
  businessDate?: string;
}

export const mapDocumentsData = (
  data?: KioskIdentityDocument[],
  options?: MapDocumentsDataOptions
): ProfilePresentationDataElement[] => {
  if (!data) return [];

  const validDocuments = data.filter((doc) =>
    doc.expiryDate
      ? !DateManager.checkIfBefore(doc.expiryDate, options?.businessDate)
      : true
  );

  return validDocuments.map((doc) => ({
    header: doc.documentType?.description,
    sectionData: [
      {
        value: doc.number,
        label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.NUMBER'),
      },
      {
        value:
          doc.expiryDate &&
          DateManager.getFormattedDate(
            doc.expiryDate,
            options?.shortDateFormat
          ),
        label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.EXPIRY_DATE'),
      },
    ],
  }));
};
