import { Text } from '@ac/kiosk-components';

import './RadioButtonLabel.scss';

interface RadioButtonLabelProps {
  title?: string;
  description?: string;
}

export const RadioButtonLabel = ({
  title,
  description,
}: RadioButtonLabelProps): JSX.Element => (
  <div className="delete-modal-radio-button-label">
    <Text>
      <span className="delete-modal-radio-button-label-title spacing-inline-end-s">
        {title}:
      </span>
      {description}
    </Text>
  </div>
);
