import { BaseObject } from 'types/shared';

import {
  BaseKioskConfigurationEntity,
  KioskPricingEntity,
  RawBaseKioskConfigurationEntity,
  RawKioskPricingEntity,
} from '../shared';

import { KioskAddedPurchaseElementsDto } from './kioskAddedPurchaseElements';
import { KioskBreakdown, RawKioskBreakdown } from './kioskBreakdown';
import { KioskLinkedCompanyDto } from './kioskLinkedCompany';
import {
  KioskMembershipDetails,
  RawKioskMembershipDetails,
} from './kioskMembershipDetails';
import { KioskPreferenceDetailsDto } from './kioskPreferenceDetails';
import { KioskPurchaseElementsDto } from './kioskPurchaseElements';

export interface RawKioskReservation {
  id: string;
  version: number;
  confirmationNumber?: string;
  status?: RawBaseKioskConfigurationEntity;
  purposeOfStay?: RawBaseKioskConfigurationEntity;
  eta?: string;
  etd?: string;
  etaEtdGuaranteed: boolean;
  arrivalDate: string;
  departureDate: string;
  roomType?: RawBaseKioskConfigurationEntity;
  room?: RawBaseKioskConfigurationEntity;
  basePrice?: RawKioskPricingEntity;
  totalPrice?: RawKioskPricingEntity;
  breakdown: RawKioskBreakdown[];
  addedPurchaseElements?: KioskAddedPurchaseElementsDto[];
  includedAddOnPurchaseElements?: KioskPurchaseElementsDto[];
  linkedPurchaseElements?: KioskPurchaseElementsDto[];
  promotionalPurchaseElements?: KioskPurchaseElementsDto[];
  preferences?: KioskPreferenceDetailsDto[];
  memberships?: RawKioskMembershipDetails[];
  reservationHeader?: BaseObject<unknown>;
  linkedCompany?: KioskLinkedCompanyDto;
}

export class KioskReservation {
  public id: string;
  public version: number;
  public confirmationNumber?: string;
  public status?: BaseKioskConfigurationEntity;
  public purposeOfStay?: BaseKioskConfigurationEntity;
  public eta?: string;
  public etd?: string;
  public etaEtdGuaranteed: boolean;
  public arrivalDate: string;
  public departureDate: string;
  public roomType?: BaseKioskConfigurationEntity;
  public room?: BaseKioskConfigurationEntity;
  public basePrice?: KioskPricingEntity;
  public totalPrice?: KioskPricingEntity;
  public breakdown: KioskBreakdown[];
  public addedPurchaseElements: KioskAddedPurchaseElementsDto[];
  public includedAddOnPurchaseElements: KioskPurchaseElementsDto[];
  public linkedPurchaseElements: KioskPurchaseElementsDto[];
  public promotionalPurchaseElements: KioskPurchaseElementsDto[];
  public preferences: KioskPreferenceDetailsDto[];
  public memberships: KioskMembershipDetails[];
  public reservationHeader?: BaseObject<unknown>;
  public linkedCompany?: KioskLinkedCompanyDto;

  constructor(raw: RawKioskReservation) {
    this.id = raw.id;
    this.version = raw.version;
    this.confirmationNumber = raw.confirmationNumber;
    this.status = raw.status && new BaseKioskConfigurationEntity(raw.status);
    this.purposeOfStay =
      raw.purposeOfStay && new BaseKioskConfigurationEntity(raw.purposeOfStay);
    this.eta = raw.eta;
    this.etd = raw.etd;
    this.etaEtdGuaranteed = raw.etaEtdGuaranteed;
    this.arrivalDate = raw.arrivalDate;
    this.departureDate = raw.departureDate;
    this.roomType =
      raw.roomType && new BaseKioskConfigurationEntity(raw.roomType);
    this.room = raw.room && new BaseKioskConfigurationEntity(raw.room);
    this.basePrice = raw.basePrice && new KioskPricingEntity(raw.basePrice);
    this.totalPrice = raw.totalPrice && new KioskPricingEntity(raw.totalPrice);
    this.breakdown =
      raw.breakdown && raw.breakdown.map((item) => new KioskBreakdown(item));
    this.addedPurchaseElements = raw.addedPurchaseElements || [];
    this.includedAddOnPurchaseElements =
      raw.includedAddOnPurchaseElements || [];
    this.linkedPurchaseElements = raw.linkedPurchaseElements || [];
    this.promotionalPurchaseElements = raw.promotionalPurchaseElements || [];
    this.preferences = raw.preferences || [];
    this.memberships = (raw.memberships || []).map(
      (item) => new KioskMembershipDetails(item)
    );
    this.reservationHeader = raw.reservationHeader;
    this.linkedCompany = raw.linkedCompany;
  }
}
