import {
  formNestedFieldArrayFactory,
  formNestedFieldFactory,
} from '@ac/react-infrastructure';

import { ContactMobilesSubFormValues } from '../types';

export const ContactMobilesFormField = formNestedFieldFactory<ContactMobilesSubFormValues>();

export const ContactMobilesFormFieldCollection = formNestedFieldArrayFactory<ContactMobilesSubFormValues>();
