import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';

import { View } from 'components';
import { TO_HOME_SCREEN_TIMEOUT } from 'configs/timers';
import { getIsSameDeviceScenario } from 'store/app/selectors';
import {
  getIsAnotherSessionOpenedInNewTab,
  getIsRegistrationCardCompleted,
} from 'store/electronicRegistrationProcess/selectors';
import { clearProcessFlowData } from 'store/globalActions';
import { changeAppLanguage } from 'store/settings/actions';
import {
  getCustomMessages,
  getGeneralSettings,
} from 'store/settings/selectors';

import './Finish.scss';

export const Finish = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const goHomeScreenTimeout = useRef<number | undefined>();

  const isRegistrationCardCompleted = useSelector(
    getIsRegistrationCardCompleted
  );
  const isAnotherSessionOpenedInNewTab = useSelector(
    getIsAnotherSessionOpenedInNewTab
  );
  const isSameDeviceScenario = useSelector(getIsSameDeviceScenario);
  const generalSettings = useSelector(getGeneralSettings);
  const customMessages = useSelector(getCustomMessages);

  const registrationCardCompletedMessage = useMemo(() => {
    return isSameDeviceScenario
      ? customMessages?.CONFIRMATION_MESSAGE
      : customMessages?.CONFIRMATION_MESSAGE_SEPARATE_DEVICE;
  }, [isSameDeviceScenario, customMessages]);

  const finishText = useMemo(() => {
    if (isRegistrationCardCompleted) {
      return registrationCardCompletedMessage;
    }

    return isAnotherSessionOpenedInNewTab
      ? t('FINISH.ANOTHER_SESSION_OPENED_IN_NEW_TAB')
      : t('FINISH.PROCESS_CANCELLED');
  }, [
    isRegistrationCardCompleted,
    registrationCardCompletedMessage,
    isAnotherSessionOpenedInNewTab,
    t,
  ]);

  const restartElectronicRegistrationCardProcess = (): void => {
    const defaultLanguage = generalSettings?.LANGUAGE_SETTINGS?.languageCode.toLowerCase();

    if (defaultLanguage && i18n.language !== defaultLanguage) {
      dispatch(changeAppLanguage.trigger(defaultLanguage));
    }

    dispatch(clearProcessFlowData());
  };

  useEffect(() => {
    if (isSameDeviceScenario) {
      return;
    }

    if (!customMessages?.CONFIRMATION_MESSAGE_SEPARATE_DEVICE) {
      return restartElectronicRegistrationCardProcess();
    }

    goHomeScreenTimeout.current = window.setTimeout(
      restartElectronicRegistrationCardProcess,
      TO_HOME_SCREEN_TIMEOUT
    );

    return (): void => {
      if (goHomeScreenTimeout.current) {
        clearTimeout(goHomeScreenTimeout.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View>
      <div data-test-selector="finish-screen-text" className="finish-page">
        {finishText}
      </div>
    </View>
  );
};
