import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingView } from '@ac/kiosk-components';

import { View } from 'components';
import { LOGIN_VIEW_REFRESH_DEVICE_LIST_TIMER, paths } from 'configs';
import { Authorizer, Storage } from 'services';
import {
  prepareEnvironment,
  restartApplication,
  selectProperty,
  stopPollingRegistrationCard,
} from 'store/app/actions';
import {
  getCurrentDeviceId,
  getCurrentPropertyId,
  getIsEnvironmentPrepared,
  getSelectPropertyState,
} from 'store/app/selectors';
import { useRouter } from 'utils/hooks';

import { LoginForm, LoginFormData } from './LoginForm/LoginForm';
import { fetchDeviceList, fetchPropertyList } from './store/actions';
import {
  getDeviceOptions,
  getFetchingDeviceState,
  getFetchingPropertiesState,
  getHasLoginStateErrors,
  getPropertyOptions,
} from './store/selectors';

import './Login.scss';

export const Login = (): JSX.Element => {
  const router = useRouter();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const refreshDevicesInterval = useRef<number | undefined>();
  const [settingUp, setSettingUp] = useState(true);
  const initialValues = useRef<LoginFormData>({
    property: '',
    device: '',
  });

  const isEnvironmentPrepared = useSelector(getIsEnvironmentPrepared);
  const propertyOptions = useSelector(getPropertyOptions);
  const deviceOptions = useSelector(getDeviceOptions);
  const areDevicesLoading = useSelector(getFetchingDeviceState);
  const arePropertiesLoading = useSelector(getFetchingPropertiesState);
  const areSelectedPropertyDataLoading = useSelector(getSelectPropertyState);
  const currentPropertyId = useSelector(getCurrentPropertyId);
  const currentDeviceId = useSelector(getCurrentDeviceId);
  const hasLoginStateErrors = useSelector(getHasLoginStateErrors);

  const handleSubmit = useCallback(
    ({ property, device }: LoginFormData) => {
      dispatch(
        prepareEnvironment.trigger({
          propertyId: property,
          deviceId: device,
        })
      );
    },
    [dispatch]
  );

  const handleError = useCallback(() => {
    dispatch(restartApplication());
  }, [dispatch]);

  const handleSelectProperty = useCallback(
    (property: string) => {
      dispatch(selectProperty.trigger(property));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(stopPollingRegistrationCard());

    if (isEnvironmentPrepared && currentPropertyId && currentDeviceId) {
      return router.goTo(paths.SETUP);
    }

    const cachedDeviceId = Storage.getDeviceCookie();
    const cachedPropertyId = Storage.getPropertyId();

    if (cachedDeviceId && cachedPropertyId) {
      dispatch(
        prepareEnvironment.trigger({
          deviceId: cachedDeviceId,
          propertyId: cachedPropertyId,
        })
      );
    } else {
      dispatch(fetchPropertyList.trigger());
      setSettingUp(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!areSelectedPropertyDataLoading && currentPropertyId) {
      dispatch(fetchDeviceList.trigger());

      if (refreshDevicesInterval.current) {
        clearInterval(refreshDevicesInterval.current);
        refreshDevicesInterval.current = undefined;
      }

      refreshDevicesInterval.current = window.setInterval(
        () => dispatch(fetchDeviceList.trigger()),
        LOGIN_VIEW_REFRESH_DEVICE_LIST_TIMER
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPropertyId]);

  useEffect(() => {
    if (isEnvironmentPrepared && currentPropertyId && currentDeviceId) {
      router.goTo(paths.SETUP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnvironmentPrepared]);

  useEffect(() => {
    return (): void => {
      if (refreshDevicesInterval.current) {
        clearInterval(refreshDevicesInterval.current);
      }
    };
  }, []);

  return settingUp ? (
    <LoadingView description={t('SHARED.SETTING_UP')} />
  ) : (
    <View isError={hasLoginStateErrors} onErrorModalSubmit={handleError}>
      <div className="login-form-wrapper">
        <LoginForm
          initialValues={initialValues.current}
          properties={propertyOptions}
          devices={deviceOptions}
          deviceSelectLoading={areDevicesLoading}
          propertySelectLoading={arePropertiesLoading}
          onCancel={Authorizer.logout}
          onSubmit={handleSubmit}
          onSelectProperty={handleSelectProperty}
        />
      </div>
    </View>
  );
};
