import { PropsWithChildren, useCallback, useState } from 'react';

import {
  Box,
  Button,
  ButtonPattern,
  ExpandPanel,
  Flex,
  IconTypes,
  JustifyContent,
  TextSize,
  TextWeight,
  ValidationHeader,
} from '@ac/kiosk-components';

interface Props {
  isExpanded?: boolean;
  title?: string;
  isValid?: boolean;
  dataTestSelector?: string;
  className?: string;
}

export const SubFormSectionWrapper = ({
  isExpanded = false,
  title,
  isValid,
  children,
  dataTestSelector,
  className,
}: PropsWithChildren<Props>): JSX.Element => {
  const [isSectionExpanded, setSectionExpanded] = useState(isExpanded);

  const toggleSection = useCallback(() => {
    setSectionExpanded((prevState) => !prevState);
  }, []);

  return (
    <Box className={className} dataTestSelector={dataTestSelector}>
      <ExpandPanel isExpanded={isSectionExpanded}>
        <Flex justifyContent={JustifyContent.spaceBetween}>
          <ValidationHeader
            hiddenSuccessStatus
            title={title}
            isValid={isValid}
            titleSize={TextSize.xlg}
            titleWeight={TextWeight.regular}
            dataTestSelector={dataTestSelector?.concat('-validation-header')}
          />
          <Button
            icon={isSectionExpanded ? IconTypes.collapse : IconTypes.expand}
            pattern={ButtonPattern.tertiary}
            onClick={toggleSection}
            dataTestSelector={dataTestSelector?.concat('-expand-button')}
          />
        </Flex>

        <ExpandPanel.ExtendableElement>
          <Box
            dataTestSelector={dataTestSelector?.concat('-expandable-content')}
            className="spacing-top-xlg"
          >
            {children}
          </Box>
        </ExpandPanel.ExtendableElement>
      </ExpandPanel>
    </Box>
  );
};
