export const mapEntities = <
  Type extends {
    [key: string]: Array<{
      code?: string;
      isActive?: boolean;
    }>;
  }
>(
  entities: Type
): Type => {
  const entries = Object.entries(entities);

  return Object.fromEntries(
    entries.filter(([, value]) => {
      const validEntries = value.filter((item) => item.code);
      const activeEntities = validEntries.filter((item) =>
        'isActive' in item ? item.isActive : true
      );

      return activeEntities?.length;
    })
  ) as Type;
};
