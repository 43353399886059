import { useTranslation } from 'react-i18next';

import { Alert, Button, ButtonPattern } from '@ac/kiosk-components';
import { ValidationSeverity } from '@ac/react-infrastructure';

interface Props {
  dataTestSelector?: string;
  className?: string;
  onConfirm: () => void;
}

export const MissingRequiredDataAlert = ({
  dataTestSelector,
  className,
  onConfirm,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Alert
      dataTestSelector={dataTestSelector}
      className={className}
      message={t('REGISTRATION_CARD.MISSING_REQUIRED_ALERT.MESSAGE')}
      severity={ValidationSeverity.warn}
    >
      <Button
        pattern={ButtonPattern.tertiary}
        onClick={onConfirm}
        dataTestSelector={dataTestSelector?.concat('-confirm-button')}
        className="spacing-inline-start-auto overflow-visible"
      >
        {t('REGISTRATION_CARD.MISSING_REQUIRED_ALERT.CONFIRM_BUTTON')}
      </Button>
    </Alert>
  );
};
