export interface RawKioskDevice {
  id: string;
  code?: string;
  description?: string;
  displaySequence?: number;
  deviceId?: string;
  isInUse: boolean;
  isOnline: boolean;
}

export class KioskDevice {
  public id: string;
  public code?: string;
  public description?: string;
  public displaySequence?: number;
  public deviceId?: string;
  public isInUse: boolean;
  public isOnline: boolean;

  constructor(raw: RawKioskDevice) {
    this.id = raw.id;
    this.code = raw.code;
    this.description = raw.description;
    this.displaySequence = raw.displaySequence;
    this.deviceId = raw.deviceId;
    this.isInUse = raw.isInUse;
    this.isOnline = raw.isOnline;
  }
}
