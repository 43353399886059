import { PDFCreatorOptions } from '@ac/kiosk-components';

export const PDF_SETUP: PDFCreatorOptions = {
  margin: 24,
  standardFont: 'ArialUnicode',
  fonts: [
    {
      family: 'ArialUnicode',
      style: 'normal',
      weight: 400,
      source: '/fonts/arial-unicode/arial-unicode-regular.ttf',
      prefetch: true,
    },
    {
      family: 'ArialUnicode',
      style: 'normal',
      weight: 700,
      source: '/fonts/arial-unicode/arial-unicode-bold.ttf',
      prefetch: true,
    },
  ],
};
