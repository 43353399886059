import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Flex,
  Grid,
  JustifyContent,
  PurchaseElementFieldSelector,
  Text,
  TextGroup,
  TextSize,
} from '@ac/kiosk-components';
import { formFieldFactory, FormSpy, FormState } from '@ac/react-infrastructure';

import { getPropertyConfiguration } from 'store/settings/selectors';
import { mapPurchaseElementFieldSelectorRenderProps } from 'utils/form';

import {
  PURCHASE_ELEMENT_GRID_TEMPLATE_LG,
  PURCHASE_ELEMENT_GRID_TEMPLATE_SM,
} from '../../constants';
import { useAvailablePurchaseElementsData } from '../../hooks/useAvailablePurchaseElementsData';
import { useTotalPriceOfSelectedPurchaseElements } from '../../hooks/useTotalPriceOfSelectedPurchaseElements';
import { savePurchaseElementsPreselection } from '../../store/actions';
import { getIsFetchingAvailablePurchaseElements } from '../../store/selectors';
import { PurchaseElementsFormValues } from '../../types';
import { AvailabilityLoader } from '../AvailabilityLoader/AvailabilityLoader';

const FormField = formFieldFactory<PurchaseElementsFormValues>();

interface Props {
  dataTestSelector?: string;
}

export const AvailableEnhancementsFormSection = ({
  dataTestSelector,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const isFetchingAvailablePurchaseElements = useSelector(
    getIsFetchingAvailablePurchaseElements
  );

  const availablePurchaseElements = useAvailablePurchaseElementsData();
  const totalPreselectionPrice = useTotalPriceOfSelectedPurchaseElements();

  const handleFormChange = useCallback(
    (formState: FormState<PurchaseElementsFormValues>): void => {
      if (formState.dirty) {
        dispatch(savePurchaseElementsPreselection(formState.values));
      }
    },
    [dispatch]
  );

  return (
    <>
      <FormSpy
        subscription={{ values: true, dirty: true }}
        onChange={handleFormChange}
      />

      <Flex
        className="spacing-top-xxlg spacing-bottom-lg"
        justifyContent={JustifyContent.spaceBetween}
        dataTestSelector={dataTestSelector}
      >
        <Text size={TextSize.lg}>
          {t('REGISTRATION_CARD_PURCHASE_ELEMENTS.AVAILABLE_ENHANCEMENTS')}
        </Text>
        {totalPreselectionPrice ? (
          <TextGroup
            size={TextSize.lg}
            label={t('REGISTRATION_CARD_PURCHASE_ELEMENTS.TOTAL')}
            value={totalPreselectionPrice}
            dataTestSelector={dataTestSelector?.concat('-total-price')}
          />
        ) : (
          <TextGroup
            size={TextSize.lg}
            label={t('REGISTRATION_CARD_PURCHASE_ELEMENTS.CURRENCY')}
            value={propertyConfiguration?.currencyCode}
            dataTestSelector={dataTestSelector?.concat(
              '-total-price-placeholder'
            )}
          />
        )}
      </Flex>

      {isFetchingAvailablePurchaseElements ? (
        <AvailabilityLoader
          dataTestSelector={dataTestSelector?.concat('-availability-loader')}
        />
      ) : (
        <Grid
          dataTestSelector={dataTestSelector}
          className="gap-lg"
          gridTemplateColumnsSm={PURCHASE_ELEMENT_GRID_TEMPLATE_SM}
          gridTemplateColumnsLg={PURCHASE_ELEMENT_GRID_TEMPLATE_LG}
        >
          {availablePurchaseElements.map((element) => (
            <Grid.Item key={element.id}>
              <FormField valuePath={element.id}>
                {(fieldRenderProps): JSX.Element => (
                  <PurchaseElementFieldSelector
                    dataTestSelector={dataTestSelector?.concat(
                      '-purchase-element'
                    )}
                    {...mapPurchaseElementFieldSelectorRenderProps(
                      fieldRenderProps
                    )}
                    {...element}
                  />
                )}
              </FormField>
            </Grid.Item>
          ))}
        </Grid>
      )}
    </>
  );
};
