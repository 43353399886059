import { createSelector } from 'reselect';

import { KioskReservation } from 'api/KioskApi/entries';

import { getRegistrationCardDetails } from './';

export const getReservation = createSelector(
  getRegistrationCardDetails,
  (regCardDetails): KioskReservation | undefined => {
    return regCardDetails?.reservation;
  }
);

export const getEtd = createSelector(getReservation, (reservation):
  | string
  | undefined => {
  return reservation?.etd?.split('T')[1];
});
