import { GuestAlternateNameSection } from 'store/settings/interfaces/settingTypes/sectionConfiguration';
import {
  ConfigurationFieldsMap,
  SubFormFieldsConfiguration,
  useSubFormFieldsConfiguration,
} from 'views/RegistrationCardEditPersonalV2/hooks/useSubFormFieldsConfiguration';

import {
  AlternateDetailsFormProperties,
  AlternateDetailsFormValues,
  FormProperties,
  FormValues,
  PersonalDetailsFormProperties,
} from '../../../types';

const CONFIGURATION_TO_FORM_FIELD_MAP: ConfigurationFieldsMap<
  keyof GuestAlternateNameSection,
  AlternateDetailsFormValues
> = {
  languageField: AlternateDetailsFormProperties.language,
  firstNameField: AlternateDetailsFormProperties.firstName,
  lastNameField: AlternateDetailsFormProperties.lastName,
};

type AlternateDetailsFieldsConfigurationResult = SubFormFieldsConfiguration<
  FormValues,
  AlternateDetailsFormValues,
  [
    FormProperties.personalDetails,
    PersonalDetailsFormProperties.alternateDetails
  ]
>;

export const useAlternateDetailsFieldsConfiguration = (): AlternateDetailsFieldsConfigurationResult => {
  const getDocumentsSubFormConfiguration = useSubFormFieldsConfiguration<
    FormValues,
    AlternateDetailsFormValues,
    [
      FormProperties.personalDetails,
      PersonalDetailsFormProperties.alternateDetails
    ]
  >('guestAlternateNameObject', [
    FormProperties.personalDetails,
    PersonalDetailsFormProperties.alternateDetails,
  ]);

  return getDocumentsSubFormConfiguration(CONFIGURATION_TO_FORM_FIELD_MAP);
};
