/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */

import { Dispatch } from 'redux';

import { LogReporter } from '@ac/kiosk-components';
import { isApiError } from '@ac/library-api';
import { Action } from '@ac/library-utils/dist/declarations';

import { knownErrorCodes, LOG_MESSAGES_TITLES, LOG_TYPES } from 'configs';
import { InternalError } from 'utils/customErrors/InternalError';

export const sagaFailureActionMiddleware = () => (next: Dispatch) => {
  return async (
    action: Action<any>
  ): Promise<
    Action<any> & {
      payload: any;
    }
  > => {
    const actionResult = next(action);
    const isMissingPermissionError = Boolean(
      actionResult?.payload instanceof InternalError
    );

    if (isMissingPermissionError) return actionResult;

    if (actionResult?.error || actionResult.type.includes('FAILURE')) {
      const errorData = actionResult?.payload?.data;

      if (isApiError(errorData)) {
        const errorsToSkip = Object.values(knownErrorCodes).map(
          ({ code }) => code
        );
        const loggingShouldBeSkip = errorData.details.some(({ code }) =>
          errorsToSkip.includes(code)
        );

        if (loggingShouldBeSkip) return actionResult;

        LogReporter.log.error(
          LOG_TYPES.app,
          {
            message: LOG_MESSAGES_TITLES.apiFailureAction,
            details: {
              action: actionResult.type,
              details: errorData.details.map((detail) => ({
                code: detail.code,
                message: detail.message,
              })),
              url: actionResult?.payload?.config?.url,
              method: actionResult?.payload?.request?.method,
              status: actionResult?.payload?.status,
              statusTest: actionResult?.payload?.statusText,
            },
          },
          true
        );
      } else {
        LogReporter.log.error(
          LOG_TYPES.app,
          {
            message: LOG_MESSAGES_TITLES.sagaFailureAction,
            details: {
              action: actionResult.type,
              message: actionResult?.payload?.message,
            },
          },
          true
        );
      }
    }

    return actionResult;
  };
};
