import {
  createAsyncAction,
  getListAction,
} from '@ac/library-utils/dist/redux-utils';

import { KioskStateDetails } from 'api/KioskApi/entries';

import { SagaError } from 'types/shared';

import { SuccessAction } from './interfaces/dictionaryDataStatus';

export const fetchCountryStates = createAsyncAction<
  string,
  SuccessAction<KioskStateDetails[]>,
  SagaError
>('@additionalDictionaries/countryStates');

export const fetchCountryDistricts = getListAction<
  string,
  SuccessAction<KioskStateDetails[]>,
  SagaError
>('@additionalDictionaries/countryDistricts');
