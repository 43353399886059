import {
  composeValidators,
  createEmailValidator,
  createRequiredValidator,
  ValidationResult,
  ValidationSchema,
} from '@ac/react-infrastructure';

export interface EmailValidationSchema {
  type: ValidationResult;
  details: ValidationResult;
}

export const emailValidationSchema = (): ValidationSchema<EmailValidationSchema> => ({
  type: createRequiredValidator('VALIDATION.SELECT_TYPE'),
  details: composeValidators(
    createRequiredValidator('VALIDATION.FIELD_IS_REQUIRED'),
    createEmailValidator('VALIDATION.INCORRECT_EMAIL_ADDRESS')
  ),
});
