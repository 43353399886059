import { LocalizedString, RawLocalizedString } from '@ac/library-api';

export interface RawKioskReservationHeaderEnumItemDefinition {
  code: string;
  name: RawLocalizedString[];
}

export class KioskReservationHeaderEnumItemDefinition {
  public code: string;
  public name: LocalizedString[];

  constructor(raw: RawKioskReservationHeaderEnumItemDefinition) {
    this.code = raw.code;
    this.name = (raw.name || []).map(
      (localization) => new LocalizedString(localization)
    );
  }
}
