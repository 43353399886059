import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Button,
  ButtonPattern,
  DATE_PICKER_DATE_FORMAT,
  DatePickerField,
  Field,
  IconTypes,
  SelectField,
  TextSize,
  TextWeight,
  ValidationHeader,
} from '@ac/kiosk-components';
import {
  FormApi,
  formNestedFieldFactory,
  FormSpy,
  ValidationStatuses,
} from '@ac/react-infrastructure';

import {
  getDateTimeFormats,
  getGeneralSettings,
  getNationalitiesEntities,
  getProfileSuffixesEntities,
  getPropertyConfiguration,
  getTitlesEntities,
} from 'store/settings/selectors';
import { DateManager } from 'utils';
import {
  doesFormContainsErrors,
  mapFieldRenderProps,
  mapSelectOptions,
} from 'utils/form';
import {
  FormProperties,
  FormValues,
  PersonalDetailsFormProperties,
  PersonalDetailsFormValues,
} from 'views/RegistrationCardEditPersonal/types';

import { AlternateDetailsSubSection } from './AlternateDetailsSubSection/AlternateDetailsSubSection';

import './PersonalDetailFormSection.scss';

type SubFormValues = {
  [FormProperties.personalDetails]: PersonalDetailsFormValues;
};

const NestedFormField = formNestedFieldFactory<SubFormValues>();

interface PersonalDetailFormSectionProps {
  formApi: FormApi<FormValues>;
}

export const PersonalDetailFormSection = ({
  formApi,
}: PersonalDetailFormSectionProps): JSX.Element => {
  const [t] = useTranslation();
  const [
    isAlternateDetailsVisible,
    setAlternateDetailsVisible,
  ] = useState<boolean>(false);

  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const nationalities = useSelector(getNationalitiesEntities);
  const titles = useSelector(getTitlesEntities);
  const profileSuffixes = useSelector(getProfileSuffixesEntities);
  const generalSettings = useSelector(getGeneralSettings);
  const dateTimeFormats = useSelector(getDateTimeFormats);

  const initialAlternateDetails = useMemo(
    () => formApi.getState().initialValues?.personalDetails?.alternateDetails,
    [formApi]
  );

  useEffect(() => {
    if (!initialAlternateDetails) return;
    setAlternateDetailsVisible(true);
  }, [formApi, initialAlternateDetails]);

  const nationalitiesOptions = useMemo(() => {
    return mapSelectOptions(nationalities, 'name', 'code');
  }, [nationalities]);

  const titlesOptions = useMemo(() => {
    return mapSelectOptions(titles, 'description', 'id');
  }, [titles]);

  const suffixesOptions = useMemo(() => {
    return mapSelectOptions(profileSuffixes, 'description', 'id');
  }, [profileSuffixes]);

  const checkIsSubFormValid = useCallback(() => {
    const errors = (formApi.getState().errors
      ?.personalDetails as ValidationStatuses)?.alternateDetails;

    return !doesFormContainsErrors(errors);
  }, [formApi]);

  const enableAlternateDetails = useCallback(() => {
    const personalDetailsValues = formApi.getState().values?.personalDetails;
    formApi.change(FormProperties.personalDetails, {
      ...personalDetailsValues,
      alternateDetails: {},
    });

    setAlternateDetailsVisible(true);
  }, [formApi]);

  const removeAlternateDetails = useCallback(() => {
    const personalDetailsValues = formApi.getState().values?.personalDetails;
    formApi.change(FormProperties.personalDetails, {
      ...personalDetailsValues,
      alternateDetails: undefined,
    });

    setAlternateDetailsVisible(false);
  }, [formApi]);

  return (
    <>
      <FormSpy subscription={{ errors: true }}>
        {(): JSX.Element => {
          const isSubFormValid = checkIsSubFormValid();

          return (
            <div className="validation-header-wrapper spacing-bottom-md">
              <ValidationHeader
                isValid={isSubFormValid}
                titleWeight={TextWeight.regular}
                titleSize={TextSize.xlg}
                title={t(
                  'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.PERSONAL_DETAILS.TITLE'
                )}
              />
              <Button
                disabled={isAlternateDetailsVisible}
                onClick={enableAlternateDetails}
                pattern={ButtonPattern.tertiary}
                icon={IconTypes.plus}
                className="add-new-data-action-button"
              >
                {t(
                  'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.PERSONAL_DETAILS.ADD_ALTERNATE'
                )}
              </Button>
            </div>
          );
        }}
      </FormSpy>

      <div className="reg-card-edit-personal-details">
        <NestedFormField
          valuePath={[
            FormProperties.personalDetails,
            PersonalDetailsFormProperties.title,
          ]}
        >
          {(fieldFieldRenderProps): JSX.Element => (
            <SelectField
              {...mapFieldRenderProps(fieldFieldRenderProps)}
              className="reg-card-edit-personal-details-title"
              label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.PERSON_TITLE')}
              placeholder={t('SHARED.SELECT')}
              options={titlesOptions}
            />
          )}
        </NestedFormField>

        <div className="reg-card-edit-personal-details-subgrid">
          <NestedFormField
            valuePath={[
              FormProperties.personalDetails,
              PersonalDetailsFormProperties.lastName,
            ]}
          >
            {(fieldFieldRenderProps): JSX.Element => (
              <Field
                {...mapFieldRenderProps(fieldFieldRenderProps)}
                label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.LAST_NAME')}
                placeholder={t('SHARED.FILL')}
                readonly
              />
            )}
          </NestedFormField>

          <NestedFormField
            valuePath={[
              FormProperties.personalDetails,
              PersonalDetailsFormProperties.secondSurname,
            ]}
          >
            {(fieldFieldRenderProps): JSX.Element => (
              <Field
                {...mapFieldRenderProps(fieldFieldRenderProps)}
                label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.SECOND_SURNAME')}
                placeholder={t('SHARED.FILL')}
              />
            )}
          </NestedFormField>

          <NestedFormField
            valuePath={[
              FormProperties.personalDetails,
              PersonalDetailsFormProperties.firstName,
            ]}
          >
            {(fieldFieldRenderProps): JSX.Element => (
              <Field
                {...mapFieldRenderProps(fieldFieldRenderProps)}
                label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.FIRST_NAME')}
                placeholder={t('SHARED.FILL')}
              />
            )}
          </NestedFormField>

          {generalSettings?.MIDDLENAME && (
            <NestedFormField
              valuePath={[
                FormProperties.personalDetails,
                PersonalDetailsFormProperties.middleName,
              ]}
            >
              {(fieldFieldRenderProps): JSX.Element => (
                <Field
                  {...mapFieldRenderProps(fieldFieldRenderProps)}
                  dataTestSelector="edit-personal-details-middle-name"
                  label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.MIDDLE_NAME')}
                  placeholder={t('SHARED.FILL')}
                />
              )}
            </NestedFormField>
          )}
        </div>

        {generalSettings?.SUFFIX && (
          <NestedFormField
            valuePath={[
              FormProperties.personalDetails,
              PersonalDetailsFormProperties.suffix,
            ]}
          >
            {(fieldFieldRenderProps): JSX.Element => (
              <SelectField
                {...mapFieldRenderProps(fieldFieldRenderProps)}
                className="reg-card-edit-personal-details-suffix"
                dataTestSelector="edit-personal-details-suffix"
                label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.SUFFIX')}
                placeholder={t('SHARED.SELECT')}
                options={suffixesOptions}
              />
            )}
          </NestedFormField>
        )}
        <div className="reg-card-edit-personal-details-subgrid">
          <NestedFormField
            valuePath={[
              FormProperties.personalDetails,
              PersonalDetailsFormProperties.birthday,
            ]}
          >
            {(fieldFieldRenderProps): JSX.Element => (
              <DatePickerField
                {...mapFieldRenderProps(fieldFieldRenderProps)}
                label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.DATE_OF_BIRTH')}
                placeholder={dateTimeFormats?.shortDateFormat}
                fieldDisplayFormat={dateTimeFormats?.shortDateFormat}
                defaultPickerValue={DateManager.getFormattedDate(
                  propertyConfiguration?.businessDate,
                  DATE_PICKER_DATE_FORMAT
                )}
              />
            )}
          </NestedFormField>
          <NestedFormField
            valuePath={[
              FormProperties.personalDetails,
              PersonalDetailsFormProperties.nationality,
            ]}
          >
            {(fieldFieldRenderProps): JSX.Element => (
              <SelectField
                {...mapFieldRenderProps(fieldFieldRenderProps)}
                label={t('COMPONENTS.PERSONAL_DETAILS_SECTION.NATIONALITY')}
                placeholder={t('SHARED.SELECT')}
                options={nationalitiesOptions}
              />
            )}
          </NestedFormField>
        </div>
      </div>

      {isAlternateDetailsVisible && (
        <AlternateDetailsSubSection
          removable={!initialAlternateDetails}
          className="spacing-top-xlg"
          onRemoveClick={removeAlternateDetails}
        />
      )}
    </>
  );
};
