import { BaseApi } from '@ac/library-api';
import { SessionDataHost } from '@ac/library-utils/dist/services';

import { initI18nTranslations } from 'i18n';

export const initializeApp = (): void => {
  SessionDataHost.setupSessionData();
  SessionDataHost.startSessionDataUpdater();
  BaseApi.host = acConfig.apiUrl;
  initI18nTranslations();
};
