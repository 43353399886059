import { useSelector } from 'react-redux';

import { FeatureTogglesStorage } from 'store/settings/interfaces';
import { getFeatureToggles } from 'store/settings/selectors';

interface Setup<Props> {
  featureToggle: keyof FeatureTogglesStorage;
  enabled: React.ComponentType<Props>;
  disabled: React.ComponentType<Props>;
}

export const withFeatureToggleSwitch = <Props extends Record<string, unknown>>({
  featureToggle,
  enabled,
  disabled,
}: Setup<Props>): React.ComponentType<Props> => {
  const FeatureToggleValidation = (props: Props): JSX.Element => {
    const featureToggles = useSelector(getFeatureToggles);
    const isEnabled = !!featureToggles?.[featureToggle];
    const WrappedComponent = isEnabled ? enabled : disabled;

    return <WrappedComponent {...props} />;
  };

  return FeatureToggleValidation;
};
