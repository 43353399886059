import { createSelector } from 'reselect';

import { ApiError } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

import { KioskPurchaseElementAvailabilityItemDto } from 'api/KioskApi/entries';
import {
  getAddedInSessionPurchaseElements,
  getGuestCount,
  getRegistrationCardDetails,
} from 'store/electronicRegistrationProcess/selectors';
import { Store } from 'store/types';

import { isPurchaseElementAvailableForReservationOccupancy } from '../utils/isPurchaseElementAvailableForReservationOccupancy';
import { PurchaseElementOperationFailedError } from '../utils/PurchaseElementOperationFailedError';

import { IncludedInventoryItems } from './interfaces/IncludedInventoryItems';

export const getIsEhancementProcessInitialized = (state: Store): boolean =>
  state.registrationCardPurchaseElementsView.isInitialized;

export const getIsEnhancementsSetUpInProgress = (state: Store): boolean =>
  state.registrationCardPurchaseElementsView.fetching.setUpEnhancements;

export const getIsFetchingAvailablePurchaseElements = (state: Store): boolean =>
  state.registrationCardPurchaseElementsView.fetching.fetchAvailability;

export const getIsPurchaseElementsUprateInProgress = (state: Store): boolean =>
  state.registrationCardPurchaseElementsView.fetching.updatePurchaseElements;

export const getRegistrationCardPurchaseElementsErrors = (
  state: Store
): Array<ApiError | Error> => state.registrationCardPurchaseElementsView.errors;

export const getInitialAvailability = (
  state: Store
): KioskPurchaseElementAvailabilityItemDto[] =>
  state.registrationCardPurchaseElementsView.initialAvailability;

export const getCurrentAvailability = (
  state: Store
): KioskPurchaseElementAvailabilityItemDto[] =>
  state.registrationCardPurchaseElementsView.currentAvailability;

export const getHasPurchaseElementUpdateOperationFailedError = createSelector(
  getRegistrationCardPurchaseElementsErrors,
  (errors) =>
    errors.some(
      (error) =>
        error &&
        'name' in error &&
        error.name === PurchaseElementOperationFailedError.errorName
    )
);

export const getPurchaseElementsImages = (
  state: Store
): Record<string, string> => state.registrationCardPurchaseElementsView.images;

export const getIncludedPurchaseElements = createSelector(
  getRegistrationCardDetails,
  (regCardDetails) => {
    if (!regCardDetails?.reservation) return [];

    return [
      ...regCardDetails.reservation.promotionalPurchaseElements,
      ...regCardDetails.reservation.linkedPurchaseElements,
      ...regCardDetails.reservation.includedAddOnPurchaseElements,
      ...regCardDetails.reservation.addedPurchaseElements,
    ];
  }
);

export const getIncludedInventoryItems = createSelector(
  getIncludedPurchaseElements,
  (purchaseElements): Record<string, IncludedInventoryItems> => {
    return Object.fromEntries(
      purchaseElements
        .map((element) => {
          if (!element.inventoryItemId) return;

          return [
            element.inventoryItemId,
            { quantity: element.totalQuantity, purchaseElementId: element.id },
          ];
        })
        .filter(isDefined) as Array<[string, IncludedInventoryItems]>
    );
  }
);

export const getCalculatedInitialAvailability = createSelector(
  getGuestCount,
  getAddedInSessionPurchaseElements,
  getIncludedPurchaseElements,
  getInitialAvailability,
  (
    guestCount,
    addedInSessionPurchaseElements,
    includedPurchaseElements,
    initialAvailability
  ) => {
    if (!initialAvailability) return [];

    const includedPurchaseElementsOutOfTheSession = includedPurchaseElements.filter(
      (element) => {
        return !addedInSessionPurchaseElements.some(
          (item) => item.id === element.id
        );
      }
    );

    const includedInventoryItemOutOfTheSession = includedPurchaseElementsOutOfTheSession
      .map((item) => item.inventoryItemId)
      .filter(isDefined);

    const purchaseElementsWithNoInventoryItemsAddedOutOfTheSession = initialAvailability.filter(
      (item) => {
        return item.inventoryItemId
          ? !includedInventoryItemOutOfTheSession.includes(item.inventoryItemId)
          : true;
      }
    );

    const purchaseElementsWithEnoughtQuantity = purchaseElementsWithNoInventoryItemsAddedOutOfTheSession.filter(
      (purchaseElement) =>
        isPurchaseElementAvailableForReservationOccupancy(
          purchaseElement,
          guestCount
        )
    );

    return purchaseElementsWithEnoughtQuantity;
  }
);

export const getCalculatedCurrentAvailability = createSelector(
  getGuestCount,
  getCalculatedInitialAvailability,
  getCurrentAvailability,
  (guestCount, initialAvailability, currentAvailability = []) => {
    return currentAvailability
      .filter((item) => initialAvailability?.some(({ id }) => id === item.id))
      .filter((purchaseElement) =>
        isPurchaseElementAvailableForReservationOccupancy(
          purchaseElement,
          guestCount
        )
      );
  }
);

export const getPurchaseElementsPreselection = (
  state: Store
): Record<string, number> =>
  state.registrationCardPurchaseElementsView.preSelectedPurchaseElements || {};

export const getInventoryItemsPreselection = createSelector(
  getCalculatedInitialAvailability,
  getPurchaseElementsPreselection,
  (
    initialAvailability,
    purchaseElements
  ): Record<string, IncludedInventoryItems> => {
    return Object.fromEntries(
      Object.entries(purchaseElements)
        .map(([purchaseElementId, quantity]) => {
          const purchaseElementsDetails = initialAvailability?.find(
            (item) => item.id === purchaseElementId
          );

          if (!purchaseElementsDetails?.inventoryItemId) return;

          return [
            purchaseElementsDetails.inventoryItemId,
            { quantity, purchaseElementId },
          ];
        })
        .filter(isDefined) as Array<[string, IncludedInventoryItems]>
    );
  }
);

export const getIsSomePurchaseElementSelected = createSelector(
  getPurchaseElementsPreselection,
  (preselections) => {
    return preselections
      ? !!Object.values(preselections).filter(Boolean).length
      : false;
  }
);
