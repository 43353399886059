export enum ImagesCodesMap {
  LOGO = 'EREGCARDLOGO',
  SCREEN_SAVER = 'EREGCARDSCREENSAVERIMAGE',
  BACKGROUND_IMAGE = 'EREGCARDBACKGROUNDIMAGE',
}

export interface ImagesSettingsStorage {
  LOGO?: string;
  SCREEN_SAVER?: string;
  BACKGROUND_IMAGE?: string;
}
