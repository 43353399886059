import { DateTimeFormat } from '@ac/library-api';

export interface RawKioskConfigurationProperty {
  id: string;
  code?: string;
  name?: string;
  currencyCode?: string;
  currencyFormat?: string;
  timeZoneCode?: string;
  dateTimeFormat?: DateTimeFormat;
  defaultLanguageCode?: string;
  supportedLanguageCodes?: string[];
  nonProduction: boolean;
  businessDate: string;
}

export class KioskConfigurationProperty {
  public id: string;
  public code?: string;
  public name?: string;
  public currencyCode?: string;
  public currencyFormat?: string;
  public timeZoneCode?: string;
  public dateTimeFormat?: DateTimeFormat;
  public defaultLanguageCode?: string;
  public supportedLanguageCodes: string[];
  public nonProduction: boolean;
  public businessDate: string;

  constructor(raw: RawKioskConfigurationProperty) {
    this.id = raw.id;
    this.code = raw.code;
    this.name = raw.name;
    this.currencyCode = raw.currencyCode;
    this.currencyFormat = raw.currencyFormat;
    this.timeZoneCode = raw.timeZoneCode;
    this.dateTimeFormat =
      raw.dateTimeFormat && new DateTimeFormat(raw.dateTimeFormat);
    this.defaultLanguageCode = raw.defaultLanguageCode?.toLowerCase();
    this.supportedLanguageCodes = (
      raw.supportedLanguageCodes || []
    ).map((lng) => lng.toLowerCase());
    this.nonProduction = raw.nonProduction;
    this.businessDate = raw.businessDate;
  }
}
