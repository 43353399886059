import { useTranslation } from 'react-i18next';

import {
  Flex,
  FlexDirection,
  Section,
  SectionType,
  Text,
  TextSize,
  TextWeight,
} from '@ac/kiosk-components';

export interface EhancementItem {
  name: string;
  amount?: number;
  price: string;
}

interface Props {
  items: EhancementItem[];
  dataTestSelector?: string;
}

export const StayEnhancements = ({
  items,
  dataTestSelector,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Section type={SectionType.wide} dataTestSelector={dataTestSelector}>
      <Text size={TextSize.xlg} className="spacing-bottom-sm">
        {t('REGISTRATION_CARD.STAY_ENHANCEMENTS.TITLE')}
      </Text>
      <ul className="padding-left-xxlg spacing-top-xlg">
        {items.map((item, index) => (
          <li
            key={index}
            className="spacing-top-sm"
            data-test-selector={dataTestSelector?.concat('-ehancement-item')}
          >
            <Flex className="gap-s" direction={FlexDirection.row}>
              <Text>{item.name}</Text>
              <Flex>
                {'('}
                {!!item.amount && item.amount > 1 && (
                  <Text
                    dataTestSelector={dataTestSelector?.concat(
                      '-amount-counter'
                    )}
                    className="spacing-inline-end-xs"
                  >
                    {`${t('REGISTRATION_CARD_PURCHASE_ELEMENTS.ITEM', {
                      count: item.amount,
                    })},`}
                  </Text>
                )}
                <Text weight={TextWeight.light}>
                  {t('REGISTRATION_CARD_PURCHASE_ELEMENTS.TOTAL_PRICE')}
                </Text>
                <Text className="spacing-inline-start-xs">{item.price}</Text>
                {')'}
              </Flex>
            </Flex>
          </li>
        ))}
      </ul>
    </Section>
  );
};
