import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { isDefined } from '@ac/library-utils/dist/utils';

import { KioskUpdateProfile } from 'api/KioskApi/entries';
import { getTelephoneRegionPrefixesEntities } from 'store/settings/selectors';

import { FormValues } from '../../types';
import { useFormInitialValues } from '../useFormInitialValues';

import { prepareAddressesUpdatePayload } from './prepareAddressesUpdatePayload';
import { prepareDocumentsUpdatePayload } from './prepareDocumentsUpdatePayload';
import { prepareEmailsUpdatePayload } from './prepareEmailsUpdatePayload';
import { preparePersonalDetailsUpdatePayload } from './preparePersonalDetailsUpdatePayload';
import { preparePhonesUpdatePayload } from './preparePhonesUpdatePayload';

type UpdateProfilePayloadResult = (
  formValues: FormValues
) => KioskUpdateProfile | undefined;

export const useUpdateProfilePayload = (): UpdateProfilePayloadResult => {
  const initialValues = useFormInitialValues();
  const telephoneRegionPrefixes = useSelector(
    getTelephoneRegionPrefixesEntities
  );

  const {
    addresses: initialAddresses,
    emails: initialEmails,
    mobiles: initialMobiles,
    phones: initialPhones,
    documents: initialDocuments,
    personalDetails: initialPersonalDetails,
  } = initialValues;

  return useCallback(
    (formValues: FormValues): KioskUpdateProfile | undefined => {
      const {
        addresses,
        emails,
        mobiles,
        phones,
        documents,
        personalDetails,
      } = formValues;

      const updatePayload: KioskUpdateProfile = {
        personalDetails: preparePersonalDetailsUpdatePayload(
          personalDetails,
          initialPersonalDetails
        ),
        addresses: prepareAddressesUpdatePayload(addresses, initialAddresses),
        emails: prepareEmailsUpdatePayload(emails, initialEmails),
        mobiles: preparePhonesUpdatePayload(
          mobiles,
          initialMobiles,
          telephoneRegionPrefixes
        ),
        phones: preparePhonesUpdatePayload(
          phones,
          initialPhones,
          telephoneRegionPrefixes
        ),
        identityDocuments: prepareDocumentsUpdatePayload(
          documents,
          initialDocuments
        ),
      };

      const areAnyPayloadDataUpdated = Boolean(
        Object.values(updatePayload).filter(isDefined).length
      );

      return areAnyPayloadDataUpdated ? updatePayload : undefined;
    },
    [
      initialAddresses,
      initialDocuments,
      initialEmails,
      initialMobiles,
      initialPersonalDetails,
      initialPhones,
      telephoneRegionPrefixes,
    ]
  );
};
