import i18next from 'i18next';
import { createSelector } from 'reselect';

import {
  ApiError,
  mapLongDateFormat,
  mapShortDateFormat,
} from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

import {
  BaseKioskConfigurationEntityDto,
  KioskAttributeDetailsDto,
  KioskAttributeGroupDetailsDto,
  KioskConfigurationProperty,
  KioskReservationHeaderDefinition,
  KioskSupportedUiLanguages,
  KioskTelephoneRegionPrefixes,
} from 'api/KioskApi/entries';
import { COMMUNICATION_MODES, DEFAULT_CONTACT_MODE_ORDER } from 'configs';
import { CHINESE_HARDCODED_DATE_FORMAT } from 'configs/constants';
import { ApplicationLanguages } from 'i18n';
import { PreferencesGroup } from 'store/settings/interfaces/preferences/preferencesGroup';
import { Store } from 'store/types';
import { sortDisplayElements } from 'utils/sorting';

import { SectionConfiguration } from './interfaces/settingTypes/sectionConfiguration';
import { processSectionsConfiguration } from './utils/processSectionsConfiguration';
import {
  CustomMessagesSettingsStorage,
  EntitiesSettingsStorage,
  FeatureTogglesStorage,
  GeneralSettingsStorage,
  ImagesSettingsStorage,
  StylesSettingsStorage,
} from './interfaces';

export const getAreSettingsInitialized = (state: Store): boolean =>
  state.settings.areSettingsInitialized;

export const getSettingsErrors = (state: Store): Array<ApiError | Error> =>
  state.settings.errors;

export const getSettingsFetchState = (state: Store): boolean =>
  Object.values(state.settings.fetching).includes(true);

export const getRefetchingConfigurationFetchState = (state: Store): boolean =>
  state.settings.fetching.refetchingConfiguration;

export const getStyles = (state: Store): StylesSettingsStorage | undefined =>
  state.settings.styles;

export const getImages = (state: Store): ImagesSettingsStorage | undefined =>
  state.settings.images;

export const getSupportedUiLanguages = (
  state: Store
): KioskSupportedUiLanguages[] | undefined =>
  state.settings.entities?.supportedUiLanguages;

export const getCustomMessages = (
  state: Store
): CustomMessagesSettingsStorage | undefined => state.settings.customMessages;

export const getReservationHeaderDefinition = (
  state: Store
): KioskReservationHeaderDefinition[] | undefined =>
  state.settings.reservationHeaderDefinition;

export const getGeneralSettings = (
  state: Store
): GeneralSettingsStorage | undefined => state.settings.general;

export const getFeatureToggles = (
  state: Store
): FeatureTogglesStorage | undefined => state.settings.featureToggles;

export const getPropertyConfiguration = (
  state: Store
): KioskConfigurationProperty | undefined => state.settings.property;

export const getEntities = (
  state: Store
): EntitiesSettingsStorage | undefined => state.settings.entities;

export const getCountryEntities = createSelector(
  [getEntities],
  (entities) => entities?.countries
);

export const getAddressTypeEntities = createSelector(
  [getEntities],
  (entities) => entities?.addressTypes
);

export const getDocumentTypeEntities = createSelector(
  [getEntities],
  (entities) => entities?.documentTypes
);

export const getCommunicationTypeEntities = createSelector(
  [getEntities],
  (entities) => entities?.communicationTypes
);

export const getConsentsEntities = createSelector(
  [getEntities],
  (entities) => entities?.consents
);

export const getPurposeOfStayEntities = createSelector(
  [getEntities],
  (entities) => entities?.purposesOfStay
);

export const getTelephoneRegionPrefixesEntities = createSelector(
  [getEntities],
  (entities) => entities?.telephoneRegionPrefixes
);

export const getNationalitiesEntities = createSelector(
  [getEntities],
  (entities) => entities?.nationalities
);

export const getTitlesEntities = createSelector(
  [getEntities],
  (entities) => entities?.titles
);

export const getProfileSuffixesEntities = createSelector(
  [getEntities],
  (entities) => entities?.profileSuffixes
);

export const getLanguagesEntities = createSelector(
  [getEntities],
  (entities) => entities?.languages
);

export const getAttributeGroups = createSelector(
  [getEntities],
  (entities): KioskAttributeGroupDetailsDto[] => entities?.attributeGroups || []
);

export const getAttributes = createSelector(
  [getEntities],
  (entities): KioskAttributeDetailsDto[] => entities?.attributes || []
);

export const getPreferencesGroups = createSelector(
  getAttributeGroups,
  getAttributes,
  (attributeGroups, attributes): PreferencesGroup[] => {
    const result = attributeGroups
      .map((group) => {
        const allAttributesPerGroup = attributes
          .filter((attr) => attr.attributeGroupId === group.id)
          .map((attr) => ({
            description: attr.description,
            id: attr.id,
          }));

        return {
          groupId: group.id,
          description: group.description,
          preferences: allAttributesPerGroup,
        };
      })
      .filter((group) => group.preferences.length);

    return result;
  }
);

export const getDefaultEmailType = createSelector(
  getGeneralSettings,
  getCommunicationTypeEntities,
  (generalSettings, communicationTypes) => {
    const defaultEmailTypeId = generalSettings?.DEFAULT_EMAIL_TYPE_ID;

    const defaultType =
      communicationTypes && defaultEmailTypeId
        ? communicationTypes.find(
            (addressType) => addressType.id === defaultEmailTypeId
          )
        : undefined;

    return defaultType?.isActive ? defaultType : undefined;
  }
);

export const getDefaultAddressType = createSelector(
  getGeneralSettings,
  getAddressTypeEntities,
  (generalSettings, addressTypes) => {
    const defaultAddressTypeId = generalSettings?.DEFAULT_ADDRESS_TYPE_ID;

    const defaultType =
      addressTypes && defaultAddressTypeId
        ? addressTypes.find(
            (addressType) => addressType.id === defaultAddressTypeId
          )
        : undefined;

    return defaultType?.isActive ? defaultType : undefined;
  }
);

export const getDefaultMobileType = createSelector(
  getGeneralSettings,
  getCommunicationTypeEntities,
  (generalSettings, communicationTypes) => {
    const defaultMobileTypeId = generalSettings?.DEFAULT_MOBILE_TYPE_ID;

    const defaultType =
      communicationTypes && defaultMobileTypeId
        ? communicationTypes.find(
            (addressType) => addressType.id === defaultMobileTypeId
          )
        : undefined;

    return defaultType?.isActive ? defaultType : undefined;
  }
);

export const getDefaultPhoneType = createSelector(
  getGeneralSettings,
  getCommunicationTypeEntities,
  (generalSettings, communicationTypes) => {
    const defaultPhoneTypeId = generalSettings?.DEFAULT_PHONE_TYPE_ID;

    const defaultType =
      communicationTypes && defaultPhoneTypeId
        ? communicationTypes.find(
            (addressType) => addressType.id === defaultPhoneTypeId
          )
        : undefined;

    return defaultType?.isActive ? defaultType : undefined;
  }
);

export const getEmailCommunicationTypes = createSelector(
  getCommunicationTypeEntities,
  (communicationTypes) => {
    return communicationTypes?.filter(
      (type) => type.communicationMode?.code === COMMUNICATION_MODES.email
    );
  }
);

export const getMobileCommunicationTypes = createSelector(
  getCommunicationTypeEntities,
  (communicationTypes) => {
    return communicationTypes?.filter(
      (type) => type.communicationMode?.code === COMMUNICATION_MODES.mobile
    );
  }
);

export const getPhoneCommunicationTypes = createSelector(
  getCommunicationTypeEntities,
  (communicationTypes) => {
    return communicationTypes?.filter(
      (type) => type.communicationMode?.code === COMMUNICATION_MODES.phone
    );
  }
);

export const getCommunicationModeOrder = createSelector(
  [getCommunicationTypeEntities],
  (communicationTypes) => {
    if (!communicationTypes) return DEFAULT_CONTACT_MODE_ORDER;
    const sortedCommunicationTypes = sortDisplayElements(communicationTypes);
    const modeOrder = sortedCommunicationTypes
      .map((channel) => channel.communicationMode?.code)
      .reduce((modeCollection, item) => {
        if (
          item &&
          Object.values(COMMUNICATION_MODES).includes(item) &&
          !modeCollection.includes(item)
        ) {
          modeCollection.push(item);
        }

        return modeCollection;
      }, [] as string[])
      .filter(isDefined);

    DEFAULT_CONTACT_MODE_ORDER.forEach((mode) => {
      if (modeOrder.includes(mode)) return;
      modeOrder.push(mode);
    });

    return modeOrder;
  }
);

export const getDateTimeFormats = createSelector(
  getGeneralSettings,
  getPropertyConfiguration,
  (generalSettings, property) => {
    const longDateFormat =
      generalSettings?.LONG_DATE_FORMAT ||
      property?.dateTimeFormat?.longDateFormat;
    const shortDateFormat =
      generalSettings?.SHORT_DATE_FORMAT ||
      property?.dateTimeFormat?.shortDateFormat;

    const adjustedLongDateFormat =
      i18next.language === ApplicationLanguages.ChineseSimplified ||
      i18next.language === ApplicationLanguages.ChineseTraditional
        ? mapLongDateFormat(CHINESE_HARDCODED_DATE_FORMAT)
        : longDateFormat && mapLongDateFormat(longDateFormat);

    const adjustedShortDateFormat =
      shortDateFormat && mapShortDateFormat(shortDateFormat);

    return {
      shortDateFormat: adjustedShortDateFormat,
      longDateFormat: adjustedLongDateFormat,
      timeFormat: property?.dateTimeFormat?.timeFormat,
    };
  }
);

export const getSuggestedPhonePrefixes = createSelector(
  getGeneralSettings,
  getTelephoneRegionPrefixesEntities,
  (generalSettings, telephoneRegionPrefixes) => {
    if (
      !generalSettings?.SUGGESTED_PHONE_PREFIXES ||
      !telephoneRegionPrefixes
    ) {
      return;
    }

    const suggestedTelephoneRegionPrefixes = telephoneRegionPrefixes.filter(
      (element) =>
        Boolean(
          element.code &&
            element.regionName &&
            generalSettings?.SUGGESTED_PHONE_PREFIXES?.includes(element.code)
        )
    ) as Array<Required<KioskTelephoneRegionPrefixes>>;

    return suggestedTelephoneRegionPrefixes.sort((a, b) =>
      a.regionName.localeCompare(b.regionName)
    );
  }
);

export const getSuggestedCountries = createSelector(
  getGeneralSettings,
  getCountryEntities,
  (generalSettings, countries) => {
    if (!generalSettings?.POP_COUNTRY_LIST || !countries) {
      return;
    }

    const suggestedCountries = countries.filter((country) =>
      Boolean(
        country.code &&
          country.name &&
          generalSettings?.POP_COUNTRY_LIST?.includes(country.code)
      )
    ) as Array<Required<BaseKioskConfigurationEntityDto>>;

    return suggestedCountries.sort((a, b) => a.name.localeCompare(b.name));
  }
);

export const getFieldsConfiguration = createSelector(
  getGeneralSettings,
  (generalSettings): SectionConfiguration | undefined => {
    return (
      generalSettings?.SECTIONS_CONFIGURATION &&
      processSectionsConfiguration<SectionConfiguration>(
        generalSettings?.SECTIONS_CONFIGURATION
      )
    );
  }
);

export const getIsPersonalEditModeEnabled = createSelector(
  getGeneralSettings,
  getFieldsConfiguration,
  getFeatureToggles,
  (generalSettings, fieldsConfiguration, featureToggles) => {
    return featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
      ? generalSettings?.EDIT_MODE &&
          (fieldsConfiguration?.addressesObjectCollection?.isEditable ||
            fieldsConfiguration?.emailObjectCollection?.isEditable ||
            fieldsConfiguration?.guestPersonalDetailsObject?.isEditable ||
            fieldsConfiguration?.identityDocumentObjectCollection?.isEditable ||
            fieldsConfiguration?.mobileObjectCollection?.isEditable ||
            fieldsConfiguration?.phoneObjectCollection?.isEditable)
      : generalSettings?.EDIT_MODE;
  }
);

export const getIsPersonalDetailsVisible = createSelector(
  getFieldsConfiguration,
  getFeatureToggles,
  (fieldsConfiguration, featureToggles) => {
    return featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
      ? fieldsConfiguration?.addressesObjectCollection?.isVisible ||
          fieldsConfiguration?.emailObjectCollection?.isVisible ||
          fieldsConfiguration?.guestPersonalDetailsObject?.isVisible ||
          fieldsConfiguration?.identityDocumentObjectCollection?.isVisible ||
          fieldsConfiguration?.mobileObjectCollection?.isVisible ||
          fieldsConfiguration?.phoneObjectCollection?.isVisible
      : true;
  }
);
