import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import get from 'lodash.get';

import {
  FormState,
  FormValidationStatuses,
  StatusFlags,
  useForm,
  ValidationResult,
} from '@ac/react-infrastructure';

import { BaseObject } from 'types/shared';

type ArraySubFormStatusFlags<FormValues> = Partial<
  Record<keyof FormValues, boolean>
>;

interface SubFormApi<FormValues> {
  getState: () => SubFormState<FormValues>;
  change: <Key extends Extract<keyof FormValues, string>>(
    name: Key,
    value: FormValues[Key] | undefined
  ) => void;
  changeMany: (value: Partial<FormValues> | undefined) => void;
  setFieldAsTouched: <Key extends Extract<keyof FormValues, string>>(
    name: Key
  ) => void;
  focusField: <Key extends Extract<keyof FormValues, string>>(
    name: Key
  ) => void;
}

export interface SubFormState<FormValues> {
  active?: string;
  values?: FormValues;
  initialValues?: FormValues;
  errors?: FormValidationStatuses<FormValues> | ValidationResult;
  invalid?: boolean;
  valid?: boolean;
  dirty?: boolean;
  touched?: boolean;
  modified?: boolean;
  submitFailed?: boolean;
  dirtyFields?: ArraySubFormStatusFlags<FormValues>;
  touchedFields?: ArraySubFormStatusFlags<FormValues>;
  modifiedFields?: ArraySubFormStatusFlags<FormValues>;
}

interface SubForm<FormValues> extends SubFormState<FormValues> {
  api: SubFormApi<FormValues>;
}

interface SubFormSubscription {
  active?: boolean;
  values?: boolean;
  initialValues?: boolean;
  errors?: boolean;
  invalid?: boolean;
  valid?: boolean;
  dirtyFields?: boolean;
  touchedFields?: boolean;
  modifiedFields?: boolean;
  dirty?: boolean;
  modified?: boolean;
  submitFailed?: boolean;
  touched?: boolean;
}

const DEFAULT_SUBSCRIPTIONS: SubFormSubscription = {
  active: true,
  values: true,
  initialValues: true,
  errors: true,
  invalid: true,
  valid: true,
  dirtyFields: true,
  touchedFields: true,
  modifiedFields: true,
  dirty: true,
  modified: true,
  submitFailed: true,
  touched: true,
};

export const useSubForm = <FormValues extends BaseObject>(
  path: Array<number | string>,
  subscription: SubFormSubscription = DEFAULT_SUBSCRIPTIONS
): SubForm<FormValues> => {
  const subFormPrefix = `${path.join('.')}`;
  const subFormSubscriptions = useRef(subscription);

  const formApi = useForm<Record<string | number, FormValues>>();
  const [subFormState, setSubFormState] = useState<SubFormState<FormValues>>();

  const parsedSubscriptions = useMemo(() => {
    const subscriptionList = subFormSubscriptions.current;

    return {
      active: subscriptionList.active,
      values: subscriptionList.values,
      initialValues: subscriptionList.initialValues,
      errors:
        subscriptionList.errors ||
        subscriptionList.invalid ||
        subscriptionList.valid,
      dirtyFields: subscriptionList.dirtyFields || subscriptionList.dirty,
      modified: subscriptionList.modified || subscriptionList.modifiedFields,
      touched: subscriptionList.touched || subscriptionList.touchedFields,
      submitFailed: subscriptionList.submitFailed,
    };
  }, []);

  const getFieldStatusFlags = useCallback(
    (
      formFieldsState?: StatusFlags<Record<string | number, FormValues>>
    ): ArraySubFormStatusFlags<FormValues> | undefined => {
      if (!formFieldsState) return;

      return Object.fromEntries(
        Object.entries(formFieldsState)
          .filter(([key]) => key.startsWith(`${subFormPrefix}.`))
          .map(([key, value]): [string, boolean] => {
            return [key.replace(`${subFormPrefix}.`, ''), value];
          })
      ) as ArraySubFormStatusFlags<FormValues>;
    },
    [subFormPrefix]
  );

  const getActiveField = useCallback(
    (active?: string | number): string | undefined => {
      if (!active || typeof active === 'number') return;

      return active.startsWith(`${subFormPrefix}.`)
        ? active.replace(`${subFormPrefix}.`, '')
        : undefined;
    },
    [subFormPrefix]
  );

  const checkIsInvalid = useCallback(
    (errors: BaseObject | BaseObject[] | undefined): boolean => {
      if (Array.isArray(errors)) {
        return !errors.length
          ? false
          : errors.map(checkIsInvalid).some(Boolean);
      } else if (typeof errors === 'object') {
        return 'severity' in errors
          ? true
          : Object.values(errors).map(checkIsInvalid).some(Boolean);
      } else {
        return false;
      }
    },
    []
  );

  const prepareSubFormState = useCallback(
    (
      formState: FormState<Record<string | number, FormValues>> | undefined,
      omitSubscriptions?: boolean
    ): SubFormState<FormValues> | undefined => {
      if (!formState) return;

      const {
        active,
        values,
        initialValues,
        errors,
        dirtyFields,
        touched,
        modified,
        submitFailed,
      } = formState;

      const subFormActive = getActiveField(active);
      const subFormValues = get(values, subFormPrefix);
      const subFormInitialValues = get(initialValues, subFormPrefix);
      const subFormErrors = get(
        errors,
        subFormPrefix
      ) as FormValidationStatuses<FormValues>;
      const subFormDirtyFields = getFieldStatusFlags(dirtyFields);
      const subFormTouchedFields = getFieldStatusFlags(touched);
      const subFormModifiedFields = getFieldStatusFlags(modified);
      const subFormInvalid = checkIsInvalid(subFormErrors);

      return {
        active:
          subFormSubscriptions.current.active || omitSubscriptions
            ? subFormActive
            : undefined,
        values:
          subFormSubscriptions.current.values || omitSubscriptions
            ? subFormValues
            : undefined,
        initialValues:
          subFormSubscriptions.current.initialValues || omitSubscriptions
            ? subFormInitialValues
            : undefined,
        errors:
          subFormSubscriptions.current.values || omitSubscriptions
            ? subFormErrors
            : undefined,
        invalid:
          subFormSubscriptions.current.invalid || omitSubscriptions
            ? subFormInvalid
            : undefined,
        valid:
          subFormSubscriptions.current.valid || omitSubscriptions
            ? !subFormInvalid
            : undefined,
        dirtyFields:
          subFormSubscriptions.current.dirtyFields || omitSubscriptions
            ? subFormDirtyFields
            : undefined,
        touchedFields:
          subFormSubscriptions.current.touchedFields || omitSubscriptions
            ? subFormTouchedFields
            : undefined,
        modifiedFields:
          subFormSubscriptions.current.modifiedFields || omitSubscriptions
            ? subFormModifiedFields
            : undefined,
        submitFailed:
          subFormSubscriptions.current.submitFailed || omitSubscriptions
            ? submitFailed
            : undefined,
        dirty:
          subFormSubscriptions.current.dirty || omitSubscriptions
            ? !!Object.values(subFormDirtyFields || {}).filter(Boolean).length
            : undefined,
        touched:
          subFormSubscriptions.current.touched || omitSubscriptions
            ? !!Object.values(subFormTouchedFields || {}).filter(Boolean).length
            : undefined,
        modified:
          subFormSubscriptions.current.modified || omitSubscriptions
            ? !!Object.values(subFormModifiedFields || {}).filter(Boolean)
                .length
            : undefined,
      };
    },
    [checkIsInvalid, getActiveField, getFieldStatusFlags, subFormPrefix]
  );

  const change = useCallback(
    <Key extends Extract<keyof FormValues, string>>(
      name: Key,
      value: FormValues[Key] | undefined
    ) => {
      formApi.change(`${subFormPrefix}.${name}`, value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subFormPrefix]
  );

  const changeMany = useCallback(
    (value: Partial<FormValues> | undefined) => {
      const subFormValues = get(formApi.getState().values, subFormPrefix);

      formApi.change(subFormPrefix, {
        ...subFormValues,
        ...value,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subFormPrefix]
  );

  const getState = useCallback((): SubFormState<FormValues> => {
    return prepareSubFormState(
      formApi.getState(),
      true
    ) as SubFormState<FormValues>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prepareSubFormState]);

  const setFieldAsTouched = useCallback(
    <Key extends Extract<keyof FormValues, string>>(name: Key) => {
      formApi.focusIn(`${subFormPrefix}.${name}`);
      formApi.focusOut(`${subFormPrefix}.${name}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subFormPrefix]
  );

  const focusField = useCallback(
    <Key extends Extract<keyof FormValues, string>>(name: Key) => {
      document
        .querySelector<HTMLElement>(`[name="${subFormPrefix}.${name}"]`)
        ?.focus();
    },
    [subFormPrefix]
  );

  useEffect(() => {
    const unsubscribe = formApi.subscribe((state) => {
      const newState = prepareSubFormState(
        state as FormState<Record<string | number, FormValues>>
      );

      setSubFormState((prevValue) => {
        return JSON.stringify(newState) !== JSON.stringify(prevValue)
          ? newState
          : prevValue;
      });
    }, parsedSubscriptions);

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subFormPrefix]);

  return useMemo((): SubForm<FormValues> => {
    return {
      ...subFormState,
      api: {
        getState,
        change,
        changeMany,
        setFieldAsTouched,
        focusField,
      },
    };
  }, [
    change,
    changeMany,
    focusField,
    getState,
    setFieldAsTouched,
    subFormState,
  ]);
};
