import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  FormHeader,
  Section,
  SectionType,
  Separator,
  TextWeight,
} from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';
import { Form } from '@ac/react-infrastructure';

import {
  Body,
  Footer,
  Header,
  ReservationDetailsSection,
  View,
} from 'components';
import { getRegistrationCardDetails } from 'store/electronicRegistrationProcess/selectors';
import {
  getIsProfileDataRequiresCompletion,
  getValidatedProfileData,
} from 'store/electronicRegistrationProcess/selectors/profile';
import {
  getCustomMessages,
  getFieldsConfiguration,
} from 'store/settings/selectors';
import { scrollToInvalidFormField } from 'utils/form/scrollToInvalidFormField';
import { useRouter } from 'utils/hooks';

import { AddressSubForm } from './components/AddressSubForm/AddressSubForm';
import { ContactSubForm } from './components/ContactSubForm/ContactSubForm';
import { DocumentsSubForm } from './components/DocumentsSubForm/DocumentsSubForm';
import { PersonalDetailsSubForm } from './components/PersonalDetailsSubForm/PersonalDetailsSubForm';
import { useFormInitialValues } from './hooks/useFormInitialValues';
import { useUpdateProfilePayload } from './hooks/useUpdateProfilePayload';
import { clearRegistrationCardEdit, updateProfile } from './store/actions';
import { BODY_ID } from './config';
import { FormValues } from './types';

interface Props {
  dataTestSelector?: string;
}

export const RegistrationCardEditPersonalV2 = ({
  dataTestSelector = 'registration-card-edit-profile',
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const initialValues = useFormInitialValues();
  const prepareUpdateProfilePayload = useUpdateProfilePayload();
  const regCardDetails = useSelector(getRegistrationCardDetails);
  const customMessages = useSelector(getCustomMessages);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const isProfileDataRequiresCompletion = useSelector(
    getIsProfileDataRequiresCompletion
  );
  const validatedProfileData = useSelector(getValidatedProfileData);

  const subForms = useMemo(() => {
    const isPesonalDetailsSectionVisible =
      fieldsConfiguration?.guestPersonalDetailsObject?.isEditable;
    const isAddressesSectionVisible =
      fieldsConfiguration?.addressesObjectCollection?.isEditable;
    const isContactSectionVisible =
      fieldsConfiguration?.emailObjectCollection?.isEditable ||
      fieldsConfiguration?.mobileObjectCollection?.isEditable ||
      fieldsConfiguration?.phoneObjectCollection?.isEditable;
    const isDocumentSectionVisible =
      fieldsConfiguration?.identityDocumentObjectCollection?.isEditable;

    return [
      isPesonalDetailsSectionVisible
        ? {
            Component: PersonalDetailsSubForm,
            props: {
              dataTestSelector: dataTestSelector?.concat('-personal-subform'),
              isExpanded:
                !isProfileDataRequiresCompletion ||
                !validatedProfileData?.personalDetails ||
                !validatedProfileData?.alternateDetails,
            },
          }
        : undefined,
      isAddressesSectionVisible
        ? {
            Component: AddressSubForm,
            props: {
              dataTestSelector: dataTestSelector?.concat('-address-subform'),
              isExpanded:
                !isProfileDataRequiresCompletion ||
                !validatedProfileData?.addresses,
            },
          }
        : undefined,
      isContactSectionVisible
        ? {
            Component: ContactSubForm,
            props: {
              dataTestSelector: dataTestSelector?.concat('-contact-subform'),
              isExpanded:
                !isProfileDataRequiresCompletion ||
                !validatedProfileData?.emails ||
                !validatedProfileData?.mobiles ||
                !validatedProfileData?.phones,
            },
          }
        : undefined,
      isDocumentSectionVisible
        ? {
            Component: DocumentsSubForm,
            props: {
              dataTestSelector: dataTestSelector?.concat('-documents-subform'),
              isExpanded:
                !isProfileDataRequiresCompletion ||
                !validatedProfileData?.documents,
            },
          }
        : undefined,
    ].filter(isDefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsConfiguration]);

  const handleBack = useCallback(() => {
    router.goBack();
    dispatch(clearRegistrationCardEdit());
  }, [router, dispatch]);

  const onFormSubmit = useCallback(
    (formValues: FormValues) => {
      const updateProfilePayload = prepareUpdateProfilePayload(formValues);

      if (!updateProfilePayload) {
        return handleBack();
      }

      dispatch(
        updateProfile.trigger({
          data: updateProfilePayload,
          onSuccessfulUpdate: handleBack,
        })
      );
    },
    [dispatch, handleBack, prepareUpdateProfilePayload]
  );

  return (
    <View dataTestSelector={dataTestSelector}>
      <Header title={t('SHARED.REGISTRATION')} />

      <Form
        initialValues={initialValues}
        subscription={{}}
        onSubmit={onFormSubmit}
      >
        {(formRenderProps): JSX.Element => {
          return (
            <>
              <Body id={BODY_ID}>
                {regCardDetails && (
                  <ReservationDetailsSection
                    reservationData={regCardDetails.reservation}
                  />
                )}
                <Section type={SectionType.wide}>
                  {(customMessages?.EDITING_INSTRUCTION_MAIN_TEXT ||
                    customMessages?.EDITING_INSTRUCTION_ADDITIONAL_TEXT) && (
                    <FormHeader
                      title={customMessages.EDITING_INSTRUCTION_MAIN_TEXT}
                      hint={customMessages.EDITING_INSTRUCTION_ADDITIONAL_TEXT}
                      className="spacing-bottom-lg"
                      titleWeight={TextWeight.regular}
                    />
                  )}

                  {subForms.map(({ Component, props }, index, collection) => {
                    const isLastElement = index + 1 === collection.length;

                    return (
                      <Fragment key={index}>
                        <Component {...props} />
                        {!isLastElement && (
                          <Separator className="spacing-vertical-xlg" />
                        )}
                      </Fragment>
                    );
                  })}
                </Section>
              </Body>

              <Footer
                hasPrimaryButton
                hasCancelButton
                hasSecondaryButton
                onSecondaryClick={handleBack}
                onPrimaryClick={(): void => {
                  formRenderProps.handleSubmit();
                  const state = formRenderProps.form.getState();
                  if (state.invalid) {
                    scrollToInvalidFormField(state.errors, BODY_ID);
                  }
                }}
                primaryLabel={t('SHARED.CONFIRM')}
              />
            </>
          );
        }}
      </Form>
    </View>
  );
};
