import { Reducer } from 'react';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { ForkEffect } from 'redux-saga/effects';

import {
  reducerUncatchedErrorMiddleware,
  sagaFailureActionMiddleware,
  sagaUncatchedErrorMiddleware,
} from 'utils/middlewares';

import { BaseObject } from 'types/shared';

import { createRootReducer } from './reducers';
import { rootSagas } from './sagas';

export type ReduxStore = ReturnType<typeof createStore>;

export const configureStore = (
  additionalReducers?: BaseObject<Reducer<unknown, unknown>>,
  additionalSagas?: ForkEffect[]
): ReduxStore => {
  const sagaMiddleware = createSagaMiddleware({
    onError: sagaUncatchedErrorMiddleware,
  });
  const store: ReduxStore = createStore(
    createRootReducer(additionalReducers),
    composeWithDevTools(
      applyMiddleware(
        sagaMiddleware,
        reducerUncatchedErrorMiddleware,
        sagaFailureActionMiddleware
      )
    )
  );

  sagaMiddleware.run(() => rootSagas(additionalSagas));

  return store;
};
