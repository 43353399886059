import {
  createRequiredValidator,
  ValidationResult,
} from '@ac/react-infrastructure';

import { FormValue } from 'utils/form/types';

import { BaseObject } from 'types/shared';

export const createConditionalRequire = (
  validationMessage: string,
  condition: (value: FormValue, model: BaseObject, index?: number) => boolean
): ((
  value: FormValue,
  model: BaseObject,
  index?: number
) => ValidationResult) => (
  value: FormValue,
  model: BaseObject,
  index?: number
): ValidationResult => {
  const requireValidator = createRequiredValidator(validationMessage);

  return condition(value, model, index) ? requireValidator(value) : [];
};
