import { isDefined } from '@ac/library-utils/dist/utils';
import { formatMoneyValue } from '@ac/library-utils/dist/utils/formatMoneyValue';

import {
  KioskConfigurationProperty,
  KioskPriceDto,
} from 'api/KioskApi/entries';

interface CurrencyConfig {
  currencyCode?: string;
  currencyFormat?: string;
}

export class CurrencyFormatter {
  private static config: CurrencyConfig = {};

  public static setCurrencyConfig(
    propertyDetails?: KioskConfigurationProperty
  ): void {
    if (!propertyDetails) return;

    CurrencyFormatter.config = {
      currencyCode: propertyDetails.currencyCode,
      currencyFormat: propertyDetails.currencyFormat,
    };
  }

  public static getCurrencyConfig(): CurrencyConfig {
    return CurrencyFormatter.config;
  }

  public static format(value?: KioskPriceDto): string;
  public static format(value?: number, currency?: string): string;
  public static format(
    value?: number | KioskPriceDto,
    currency?: string
  ): string {
    if (isDefined(value) && typeof value === 'object') {
      return formatMoneyValue({
        value: value.amount,
        currency: value.currency,
        format: CurrencyFormatter.config.currencyFormat,
      });
    }

    return formatMoneyValue({
      value,
      currency,
      format: CurrencyFormatter.config.currencyFormat,
    });
  }

  public static formatWithPropertyCurrency(value?: number): string {
    return CurrencyFormatter.format(
      value,
      CurrencyFormatter.config.currencyCode
    );
  }
}
