import {
  BaseKioskConfigurationEntity,
  RawBaseKioskConfigurationEntity,
} from '../shared';

import {
  KioskMembershipLevel,
  RawKioskMembershipLevel,
} from './kioskMembershipLevel';

export interface RawKioskMembershipDetails {
  id: string;
  level?: RawKioskMembershipLevel;
  type?: RawBaseKioskConfigurationEntity;
  number?: string;
}

export class KioskMembershipDetails {
  public id: string;
  public level?: BaseKioskConfigurationEntity;
  public type?: BaseKioskConfigurationEntity;
  public number?: string;

  constructor(raw: RawKioskMembershipDetails) {
    this.id = raw.id;
    this.level = raw.level && new KioskMembershipLevel(raw.level);
    this.type = raw.type && new BaseKioskConfigurationEntity(raw.type);
    this.number = raw.number;
  }
}
