import {
  KioskDictionaryEntryDetails,
  RawKioskDictionaryEntryDetails,
} from '../shared';

import { RawKioskConfigurationConsentType } from './kioskConfigurationConsentType';
import { KioskConfigurationConsentType } from '.';

export interface RawKioskConfigurationConsent
  extends RawKioskDictionaryEntryDetails {
  consentBody?: string;
  consentType?: RawKioskConfigurationConsentType;
  defaultExpiryPeriod?: number;
}

export class KioskConfigurationConsent extends KioskDictionaryEntryDetails {
  public consentBody?: string;
  public consentType?: KioskConfigurationConsentType;
  public defaultExpiryPeriod?: number;

  constructor(raw: RawKioskConfigurationConsent) {
    super(raw);
    this.consentBody = raw.consentBody;
    this.consentType =
      raw.consentType && new KioskConfigurationConsentType(raw.consentType);
    this.defaultExpiryPeriod = raw.defaultExpiryPeriod;
  }
}
