import { ExpandPanel, Text, TextWeight } from '@ac/kiosk-components';

import { ReservationPresentationDataElementSectionData } from 'utils/regCardPresentationDataMappers';

interface ReservationSubSectionInlineCollectionProps {
  item: ReservationPresentationDataElementSectionData;
}

export const ReservationSubSectionInlineCollection = ({
  item,
}: ReservationSubSectionInlineCollectionProps): JSX.Element => {
  const textComponent = (
    <Text weight={TextWeight.light} className="reg-card-subsection-item">
      <span>
        {item.inlineCollection?.map(
          (element) =>
            !!element.value && (
              <span key={element.label} className="spacing-inline-end-sm">
                <span className="reg-card-reservation-subsection-normal-font">
                  {`${element.value} `}
                </span>
                {element.label}
              </span>
            )
        )}
      </span>
    </Text>
  );

  return (
    <>
      {item.inlineCollection && item.isCollapsible ? (
        <ExpandPanel.ExtendableElement>
          {textComponent}
        </ExpandPanel.ExtendableElement>
      ) : (
        { textComponent }
      )}
    </>
  );
};
