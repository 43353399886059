import { isDefined } from '@ac/library-utils/dist/utils';
import {
  ArrayValidationErrors,
  DefaultErrorType,
  FormValidationErrors,
  isError,
  isPublicArrayFieldValidation,
  NestedValidationErrors,
} from '@ac/react-infrastructure';

import { BaseObject } from 'types/shared';

type FormErrors =
  | FormValidationErrors<BaseObject>
  | ArrayValidationErrors<BaseObject>
  | NestedValidationErrors<BaseObject>
  | DefaultErrorType
  | unknown;

const preparePathOfInvalidField = (
  errors: FormErrors,
  parentKey?: string
): string | string[] | undefined => {
  let processedErrors = errors;

  if (processedErrors && isPublicArrayFieldValidation(processedErrors)) {
    processedErrors = [
      ...(processedErrors.arrayErrors ?? []),
      ...(processedErrors.valuesErrors ?? []),
    ] as DefaultErrorType;
  }

  if (!(processedErrors && typeof processedErrors === 'object')) {
    return undefined;
  }

  if (Array.isArray(processedErrors) && isError(processedErrors)) {
    return parentKey;
  }

  const errorsArray = Object.entries(processedErrors);

  return errorsArray.reduce<string[]>(
    (path, [key, statuses]: [string, FormErrors]) => {
      if (statuses) {
        const invalidPaths = preparePathOfInvalidField(
          statuses,
          `${parentKey ? parentKey + '.' : ''}${key}`
        );

        if (invalidPaths) {
          const invalidPathsArray = [invalidPaths].flat().filter(isDefined);

          path.push(...invalidPathsArray);
        }
      }

      return path;
    },
    []
  );
};

export const getInvalidFieldsPaths = (
  errors: unknown
): string[] | undefined => {
  const invalidPaths = preparePathOfInvalidField(errors);

  if (typeof invalidPaths === 'string') {
    return [invalidPaths];
  }

  return invalidPaths?.filter(isDefined);
};
