import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Button,
  ButtonPattern,
  Flex,
  FlexDirection,
  IconTypes,
  Text,
} from '@ac/kiosk-components';

import { getFieldsConfiguration } from 'store/settings/selectors';
import { useSubForm } from 'utils/form/subFormApi';
import { useAddressesInitialValues } from 'views/RegistrationCardEditPersonalV2/hooks/useFormInitialValues/useAddressesInitialValues';

import { EntityType, useRemoveEntity } from '../../hooks/useRemoveEntity';
import { useSetEntityAsMain } from '../../hooks/useSetEntityAsMain';
import { AddressFormValues, FormProperties } from '../../types';
import { SubFormSectionWrapper } from '../SubFormSectionWrapper/SubFormSectionWrapper';

import { AddressFormFieldCollection } from './components/AddressFormFields';
import { SingleAddressSubForm } from './components/SingleAddressSubForm';

const ADDRESS_PATH: [FormProperties.addresses] = [FormProperties.addresses];

interface Props {
  dataTestSelector?: string;
  isExpanded?: boolean;
}

export const AddressSubForm = ({
  dataTestSelector,
  isExpanded,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const setAsMain = useSetEntityAsMain();
  const removeEntity = useRemoveEntity(EntityType.Address);
  const { prepareInitialEmptyAddress } = useAddressesInitialValues();
  const subFormState = useSubForm<AddressFormValues[]>(ADDRESS_PATH, {
    valid: true,
  });

  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const isAddressRequired =
    fieldsConfiguration?.addressesObjectCollection?.isRequired;

  return (
    <SubFormSectionWrapper
      dataTestSelector={dataTestSelector}
      title={t('REGISTRATION_CARD_EDIT_PERSONAL.ADDRESS.TITLE')}
      isValid={subFormState.valid}
      isExpanded={isExpanded}
    >
      <AddressFormFieldCollection subscription={{}} valuePath={ADDRESS_PATH}>
        {(fieldFieldArrayRenderProps): JSX.Element => {
          const addressesAmount =
            subFormState.api.getState()?.values?.length ?? 0;
          const isTheOnlyOneDocument = addressesAmount === 1;

          return (
            <>
              {addressesAmount === 0 ? (
                <Text
                  dataTestSelector={dataTestSelector?.concat(
                    '-not-provided-description'
                  )}
                  className="spacing-bottom-xlg"
                >
                  {t('REGISTRATION_CARD_EDIT_PERSONAL.ADDRESS.NOT_PROVIDED')}
                </Text>
              ) : (
                <Flex direction={FlexDirection.column} className="gap-xlg">
                  {fieldFieldArrayRenderProps.fields.map(
                    (path, index, name) => (
                      <SingleAddressSubForm
                        key={name}
                        path={path}
                        dataTestSelector={dataTestSelector?.concat(
                          '-single-address'
                        )}
                        isHeaderHidden={
                          isTheOnlyOneDocument && isAddressRequired
                        }
                        onRemoveClick={(): void => {
                          removeEntity(
                            subFormState.api.getState(),
                            fieldFieldArrayRenderProps,
                            index
                          );
                        }}
                        onSetAsMainClick={(): void => {
                          setAsMain(
                            subFormState.api.getState(),
                            fieldFieldArrayRenderProps,
                            index
                          );
                        }}
                      />
                    )
                  )}
                </Flex>
              )}
              <Button
                icon={IconTypes.plus}
                pattern={ButtonPattern.tertiary}
                disabled={!subFormState.valid}
                dataTestSelector={dataTestSelector?.concat(
                  '-add-additional-button'
                )}
                onClick={(): void =>
                  fieldFieldArrayRenderProps.fields.push(
                    prepareInitialEmptyAddress(!addressesAmount)
                  )
                }
              >
                {t(
                  !addressesAmount
                    ? 'REGISTRATION_CARD_EDIT_PERSONAL.ADDRESS.ADD_NEW_BUTTON'
                    : 'REGISTRATION_CARD_EDIT_PERSONAL.SHARED.ADD_NEXT'
                )}
              </Button>
            </>
          );
        }}
      </AddressFormFieldCollection>
    </SubFormSectionWrapper>
  );
};
