import i18next from 'i18next';

import { SelectField, SelectOption } from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';
import { formFieldFactory } from '@ac/react-infrastructure';

import { getLocalizedContent } from 'utils';
import { mapFieldRenderProps } from 'utils/form';

import { BaseObject } from 'types/shared';

import { BasicFieldProvider, ComponentsData } from './basicFieldProvider';

const FormField = formFieldFactory<BaseObject>();

export class EnumFieldProvider extends BasicFieldProvider<
  typeof FormField,
  typeof SelectField
> {
  getComponentsData(): ComponentsData<typeof FormField, typeof SelectField> {
    const { typeConstraints } = this.reservationHeaderDefinition;
    const selectOptions = typeConstraints?.itemDefinitions
      ?.map((definition) => {
        return {
          value: definition.code,
          title: getLocalizedContent(definition.name, i18next.language),
        };
      })
      .filter((option) => {
        return isDefined(option?.title) && isDefined(option?.value);
      }) as SelectOption[] | undefined;

    return {
      FormField,
      formFieldRenderPropsMapper: mapFieldRenderProps,
      Component: SelectField,
      componentProps: {
        label: this.reservationHeaderDefinition.displayName,
        options: selectOptions,
        allowClear: true,
        placeholder: i18next.t('SHARED.SELECT'),
      },
    };
  }
}
