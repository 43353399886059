export const EXPORT_AUTH_LOGS_FILE_NAME = 'e-registration-card-auth-logs';
export const EXPORT_ERROR_LOGS_FILE_NAME = 'e-registration-card-error-logs';

export const LOG_TYPES = {
  app: 'registration-card-logs_app',
  auth: 'registration-card-logs_auth',
};

export const LOG_MESSAGES_TITLES = {
  connectionLostLogMessage: '[OFFLINE] Connection lost',
  connectionReceivedLogMessage: '[ONLINE] Connection returned',
  reactCriticalError: '[REACT CRITICAL ERROR]',
  reactUnhandledRefection: '[REACT UNHANDLED REJECTION]',
  reducerUncatchedError: '[REDUCER UNCATCHED ERROR]',
  sagaUncatchedError: '[SAGA UNCATCHED ERROR]',
  apiFailureAction: '[API FAILURE ACTION]',
  sagaFailureAction: '[SAGA FAILURE ACTION]',
  refetchConfigurationJob: '[JOB FAILURE: Refetch configuration]',
};
