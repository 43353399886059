import i18next from 'i18next';
import { Task } from 'redux-saga';
import {
  all,
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { KioskComponentsConfigurator, LogReporter } from '@ac/kiosk-components';
import { BaseApi, Config, isApiError } from '@ac/library-api';
import { ApiError } from '@ac/library-api/dist/types/entities/shared/errors/apiError';
import { Action } from '@ac/library-utils/dist/declarations';

import { ElectronicRegistrationApi } from 'api/KioskApi';
import { KioskConfiguration, KioskLayoutSetting } from 'api/KioskApi/entries';
import {
  ACCEPT_LANGUAGE_HEADER,
  EREGCARD_REFETCH_CONFIGURATION_INTERVAL,
  LIMITED_IMAGE_SIZES,
  LOG_MESSAGES_TITLES,
  LOG_TYPES,
  MAX_DIMENSION_OF_IPAD_SCREEN,
} from 'configs';
import { changeLanguage } from 'i18n';
import { Authorizer, CurrencyFormatter } from 'services';
import { getCurrentPropertyId } from 'store/app/selectors';
import { getIsElectronicRegistrationProcessInitialized } from 'store/electronicRegistrationProcess/selectors';
import { clearProcessFlowData } from 'store/globalActions';
import { handleSagaError, retrySaga } from 'utils/sagas';

import { SagasGenerator } from 'types/shared';

import { normalizeFeatureToggles } from './utils/normalizeFeatureToggles';
import * as actions from './actions';
import {
  CustomMessagesCodesMap,
  featureTogglesCodesMap,
  FeatureTogglesStorage,
  GeneralSettingsCodesMap,
  GeneralSettingsStorage,
  ImagesCodesMap,
  ImagesSettingsStorage,
  PrepareSetupPayload,
  Settings,
  StylesCodesMap,
  StylesSettingsStorage,
} from './interfaces';
import { getImages, getStyles } from './selectors';
import {
  createCustomThemeVariables,
  mapCustomMessagesSettings,
  mapEntities,
  mapLayoutSettings,
  revokeImageObjectUrls,
} from './utils';

function* replaceImageMetadataByObjectUrl(
  settings: KioskLayoutSetting[]
): SagasGenerator<KioskLayoutSetting[]> {
  const imagesCodesArray: string[] = yield Object.values(ImagesCodesMap);
  const changedLayoutSettings: KioskLayoutSetting[] = yield Promise.all(
    settings.map(async (setting) => {
      const { code, value } = setting;
      if (!(code && value && imagesCodesArray.includes(code))) {
        return setting;
      }

      const imageBlob = await ElectronicRegistrationApi.getEffectiveValuesContentImage(
        {
          pathParams: { code },
          queryParams: LIMITED_IMAGE_SIZES[code] || {
            width: MAX_DIMENSION_OF_IPAD_SCREEN,
            height: MAX_DIMENSION_OF_IPAD_SCREEN,
          },
        }
      );

      return {
        ...setting,
        value: URL.createObjectURL(imageBlob as Blob),
      };
    })
  );

  return changedLayoutSettings;
}

function* fetchConfiguration(): SagasGenerator<Settings> {
  const {
    property,
    layoutSettings,
    features,
    reservationHeaderDefinition,
    ...entities
  }: KioskConfiguration = yield ElectronicRegistrationApi.getConfiguration();

  const preparedLayoutSettings: KioskLayoutSetting[] = yield call(
    replaceImageMetadataByObjectUrl,
    layoutSettings
  );

  const preparedEntities = mapEntities<
    Omit<
      KioskConfiguration,
      'property' | 'layoutSettings' | 'features' | 'reservationHeaderDefinition'
    >
  >(entities);

  const styles = mapLayoutSettings<StylesSettingsStorage>(
    StylesCodesMap,
    preparedLayoutSettings
  );
  const images = mapLayoutSettings<ImagesSettingsStorage>(
    ImagesCodesMap,
    preparedLayoutSettings
  );
  const general = mapLayoutSettings<GeneralSettingsStorage>(
    GeneralSettingsCodesMap,
    preparedLayoutSettings
  );
  const customMessages = mapCustomMessagesSettings(
    CustomMessagesCodesMap,
    preparedLayoutSettings
  );
  const featureToggles = normalizeFeatureToggles<FeatureTogglesStorage>(
    featureTogglesCodesMap,
    features
  );

  return {
    property,
    styles,
    images,
    general,
    customMessages,
    reservationHeaderDefinition,
    entities: preparedEntities,
    featureToggles,
  };
}

function* prepareSettingsState(
  setupConfig: PrepareSetupPayload
): SagasGenerator<Settings> {
  const previousLanguage = i18next.language;
  try {
    const { withDefaultLanguage } = setupConfig;
    const settingsStorage: Settings = yield call(fetchConfiguration);
    const defaultLanguageCode = settingsStorage?.general?.LANGUAGE_SETTINGS?.languageCode.toLowerCase();
    if (
      withDefaultLanguage &&
      defaultLanguageCode &&
      defaultLanguageCode !== i18next.language.toLowerCase()
    ) {
      const unusedImages = settingsStorage.images;
      if (unusedImages) {
        revokeImageObjectUrls(unusedImages);
      }
      yield call(applyAppLanguage, defaultLanguageCode);

      const settingsStorageWithNewDefaultLanguage: Settings = yield call(
        fetchConfiguration
      );

      return settingsStorageWithNewDefaultLanguage;
    } else {
      return settingsStorage;
    }
  } catch (error) {
    yield call(applyAppLanguage, previousLanguage);
    throw error;
  } finally {
    const wasCancelled: boolean = yield cancelled();
    if (wasCancelled) {
      yield call(applyAppLanguage, previousLanguage);
    }
  }
}

function* prepareSetup(action: Action<PrepareSetupPayload>): SagasGenerator {
  try {
    const { withDefaultLanguage } = action.payload;
    const preparedSettingsState: Settings = yield call(prepareSettingsState, {
      withDefaultLanguage,
    });
    yield call(setApplicationSettings, preparedSettingsState);
    yield put(actions.prepareSetup.success());
    yield put(actions.refetchConfigurationJob.reset());
  } catch (error) {
    yield put(actions.prepareSetup.failure(handleSagaError(error)));
  }
}

function* setApplicationSettings(newSettingsState: Settings): SagasGenerator {
  const oldImagesStorage: ImagesSettingsStorage | undefined = yield select(
    getImages
  );
  yield put(actions.applySetup(newSettingsState));
  if (oldImagesStorage) {
    revokeImageObjectUrls(oldImagesStorage);
  }

  CurrencyFormatter.setCurrencyConfig(newSettingsState.property);
}

function* setCustomThemeOfKioskComponents(): SagasGenerator {
  const styles: StylesSettingsStorage | undefined = yield select(getStyles);

  yield KioskComponentsConfigurator.createThemeVariables(
    'custom',
    createCustomThemeVariables(styles)
  );

  return KioskComponentsConfigurator.setTheme('custom');
}

export function* applyAppLanguage(language: string): SagasGenerator {
  const propertyId: string = yield select(getCurrentPropertyId);

  yield changeLanguage(language.toLowerCase());

  BaseApi.defaultConfig = (): Promise<Config<undefined>> =>
    Promise.resolve(
      Authorizer.getStaticConfiguration({
        propertyId,
        headers: { [ACCEPT_LANGUAGE_HEADER]: i18next.language },
      })
    );
}

function* handleAppLanguageChange(action: Action<string>): SagasGenerator {
  try {
    yield call(applyAppLanguage, action.payload);
    yield put(
      actions.prepareSetup.trigger({
        withDefaultLanguage: false,
      })
    );
    yield put(actions.changeAppLanguage.success());
  } catch (error) {
    yield put(actions.changeAppLanguage.failure(handleSagaError(error)));
  }
}

function* refetchConfiguration(): SagasGenerator {
  yield put(actions.refetchConfiguration.trigger());
  try {
    const fetchedConfiguration: Settings = yield retrySaga(3, () =>
      prepareSettingsState({ withDefaultLanguage: true })
    );
    yield setApplicationSettings(fetchedConfiguration);
  } catch (error) {
    const errorData = (error as { data: ApiError })?.data;

    const details = isApiError(errorData)
      ? errorData.details.map((errorDetails) => ({
          code: errorDetails.code,
          message: errorDetails.message,
        }))
      : (error as Error).message;

    LogReporter.log.warning(LOG_TYPES.app, {
      message: LOG_MESSAGES_TITLES.refetchConfigurationJob,
      details,
    });
  }
}

function* refetchConfigurationWorker(): SagasGenerator {
  try {
    yield delay(EREGCARD_REFETCH_CONFIGURATION_INTERVAL);

    const registrationStage: boolean = yield select(
      getIsElectronicRegistrationProcessInitialized
    );
    if (registrationStage) {
      yield take(clearProcessFlowData);
    }

    yield call(refetchConfiguration);
  } finally {
    yield put(actions.refetchConfiguration.done());
  }
}

function* refetchConfigurationJob(): SagasGenerator {
  while (true) {
    yield take(actions.refetchConfigurationJob.start);

    const refetchTask: Task = yield fork(function* () {
      while (true) {
        yield race([
          call(refetchConfigurationWorker),
          take(actions.refetchConfigurationJob.reset),
        ]);
      }
    });

    yield take(actions.refetchConfigurationJob.stop);
    yield cancel(refetchTask);
  }
}

export function* settingsSagas(): SagasGenerator {
  yield takeLatest(actions.changeAppLanguage.trigger, handleAppLanguageChange);
  yield takeLatest(actions.prepareSetup.trigger, prepareSetup);
  yield takeLatest(actions.applySetup, setCustomThemeOfKioskComponents);
  yield all([call(refetchConfigurationJob)]);
}
