import { smoothScrollInToCenter } from 'utils/smoothScrollInToCenter';

import { getInvalidFieldsPaths } from './getInvalidFieldsPaths';

export const scrollToInvalidFormField = (
  formErrors: unknown,
  scrollableComponentSelector: string
): void => {
  const invalidFieldPaths = getInvalidFieldsPaths(formErrors);
  const firstInvalidField = invalidFieldPaths?.[0];

  if (!firstInvalidField) return;

  smoothScrollInToCenter(
    `[name="${firstInvalidField}"]`,
    `#${scrollableComponentSelector}`
  );
};
