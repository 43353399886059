export const CLIENT_ID = 'ElectronicRegistrationCard-KioskApp';

export const APP_NAME = 'registration-card';

export const APP_ROOT_CONTAINER_ID = 'root';
export const CONSOLE_SIMULATOR_ROOT_CONTAINER_ID = 'console-simulator';

export const CROSS_BORDER_CONSENT_CODE = 'CBP';

export const KIOSK_SESSION_ID_HEADER = 'AC-KioskSession-ID';
export const ACCEPT_LANGUAGE_HEADER = 'Accept-Language';

export const CROSSTAB_COMMUNICATION_CHANNEL_NAME =
  'e-registration-crosstab-communication-channel';

export const DEFAULT_APP_LANGUAGE = 'en';

export const MAX_DIMENSION_OF_IPAD_SCREEN = 2732;

export const CHINESE_HARDCODED_DATE_FORMAT = 'LL, dddd';
