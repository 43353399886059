const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * 60;
const ONE_HOUR = ONE_MINUTE * 60;

export const SEARCH_DEBOUNCE_TIMEOUT = 0.5 * ONE_SECOND;
export const EREGCARD_POLLING_TIMEOUT = 5 * ONE_SECOND;
export const EREGCARD_EXTERNAL_DEVICE_SCENARIO_INACTIVITY_TIMEOUT = ONE_MINUTE;
export const EREGCARD_SAME_DEVICE_SCENARIO_INACTIVITY_TIMEOUT = ONE_MINUTE * 5;
export const EREGCARD_INACTIVITY_MODAL_PROLONG_TIMEOUT = 10 * ONE_SECOND;
export const TO_HOME_SCREEN_TIMEOUT = 10 * ONE_SECOND;
export const EREGCARD_REFETCH_CONFIGURATION_INTERVAL = ONE_HOUR * 24;
export const LOGIN_VIEW_REFRESH_DEVICE_LIST_TIMER = ONE_MINUTE;
