import { isDefined } from '@ac/library-utils/dist/utils';

import { BaseObject } from 'types/shared';

import { FieldConfiguration } from '../interfaces/settingTypes/sectionConfiguration';

const FIELD_CONFIDURATION_KEYS = [
  'isVisible',
  'isEditable',
  'isRequired',
] as const;

type ConfigurationKeyType = typeof FIELD_CONFIDURATION_KEYS[number] & string;

const checkIsContainingChildFields = <
  ConfigurationType extends BaseObject & FieldConfiguration
>(
  configuration: ConfigurationType
): boolean => {
  return Object.keys(configuration).some(
    (key: ConfigurationKeyType) => !FIELD_CONFIDURATION_KEYS.includes(key)
  );
};

const checkIsSomeChildFieldsIs = <
  ConfigurationType extends BaseObject & FieldConfiguration
>(
  configuration: ConfigurationType,
  status: typeof FIELD_CONFIDURATION_KEYS[number]
): boolean => {
  return Object.entries(configuration).some(
    ([key, value]: [ConfigurationKeyType, FieldConfiguration]) => {
      if (FIELD_CONFIDURATION_KEYS.includes(key)) {
        return false;
      }

      return value[status];
    }
  );
};

const getValidatedFieldConfiguration = <
  ConfigurationType extends BaseObject & FieldConfiguration
>(
  configuration: ConfigurationType,
  parentConfiguration?: FieldConfiguration
): FieldConfiguration | undefined => {
  const isParentConfigurationDefined =
    isDefined(parentConfiguration) && Object.keys(parentConfiguration).length;
  const isContainingChildFields = checkIsContainingChildFields(configuration);

  const config = {
    ...(isDefined(configuration.isVisible) && {
      isVisible: configuration.isVisible,
    }),
    ...(isDefined(configuration.isEditable) && {
      isEditable: configuration.isEditable,
    }),
    ...(isDefined(configuration.isRequired) && {
      isRequired: configuration.isRequired,
    }),
  };

  if (isParentConfigurationDefined) {
    const { isVisible: isParentVisible, isEditable: isParentEditable } =
      parentConfiguration || {};

    if (config.isVisible && !isParentVisible) {
      config.isVisible = false;
      config.isEditable = false;
      config.isRequired = false;
    } else if (config.isEditable && !isParentEditable) {
      config.isEditable = false;
      config.isRequired = false;
    }
  }

  if (isContainingChildFields) {
    const isSomeChildFieldVisible = checkIsSomeChildFieldsIs(
      configuration,
      'isVisible'
    );
    const isSomeChildFieldEditable = checkIsSomeChildFieldsIs(
      configuration,
      'isEditable'
    );

    if (config.isVisible && !isSomeChildFieldVisible) {
      config.isVisible = false;
      config.isEditable = false;
      config.isRequired = false;
    } else if (config.isEditable && !isSomeChildFieldEditable) {
      config.isEditable = false;
      config.isRequired = false;
    }
  }

  return config;
};

export const processSectionsConfiguration = <
  ConfigurationType extends BaseObject & FieldConfiguration
>(
  configuration: ConfigurationType,
  parentConfiguration?: FieldConfiguration
): ConfigurationType => {
  const config = getValidatedFieldConfiguration(
    configuration,
    parentConfiguration
  );

  const entries = Object.entries(configuration).filter(
    ([key]: [ConfigurationKeyType, FieldConfiguration]): boolean => {
      return !FIELD_CONFIDURATION_KEYS.includes(key);
    }
  );

  const nestedFieldsConfiguration = entries.reduce<
    BaseObject & FieldConfiguration
  >((acc, [key, value]) => {
    acc[key] =
      value && typeof value === 'object'
        ? processSectionsConfiguration<BaseObject & FieldConfiguration>(
            value as ConfigurationType,
            config
          )
        : {};

    return acc;
  }, {});

  return {
    ...config,
    ...nestedFieldsConfiguration,
  } as ConfigurationType;
};
