import React from 'react';
import { TFunction } from 'i18next';

import {
  FieldRenderProps,
  RadioButtonFieldRenderProps,
  SingleCheckboxFieldRenderProps,
  ValidationExpression,
} from '@ac/react-infrastructure';

import { KioskReservationHeaderDefinition } from 'api/KioskApi/entries';
import { DateTimeFormats } from 'store/settings/interfaces';
import { MappedFormElementRenderProps } from 'utils/form';

import { BaseObject, GetComponentProps } from 'types/shared';

type RenderPropsType =
  | RadioButtonFieldRenderProps<unknown>
  | SingleCheckboxFieldRenderProps<boolean>
  | FieldRenderProps<unknown>;

type BasicFormFieldType = new (props: unknown) => React.Component<BaseObject>;
type BasicComponentType =
  | React.VoidFunctionComponent
  | React.ComponentClass<BaseObject>;

export type FieldConfigOptions = Pick<
  DateTimeFormats,
  'shortDateFormat' | 'timeFormat'
>;

export interface ComponentsData<
  FormFieldType extends BasicFormFieldType = BasicFormFieldType,
  ComponentType extends BasicComponentType = BasicComponentType
> {
  FormField: FormFieldType;
  formFieldRenderPropsMapper: (
    renderProps: RenderPropsType,
    t?: TFunction
  ) => MappedFormElementRenderProps;
  Component: ComponentType;
  componentProps: GetComponentProps<ComponentType>;
}

export abstract class BasicFieldProvider<
  FormFieldType extends BasicFormFieldType = BasicFormFieldType,
  ComponentType extends BasicComponentType = BasicComponentType
> {
  constructor(
    protected value: unknown,
    protected reservationHeaderDefinition: KioskReservationHeaderDefinition,
    protected config: FieldConfigOptions
  ) {}

  public getInitialFieldValue(): unknown {
    return this.value;
  }

  public getValidationSchema():
    | ValidationExpression<BaseObject<unknown>>
    | undefined {
    return undefined;
  }

  public abstract getComponentsData(): ComponentsData<
    FormFieldType,
    ComponentType
  >;

  public prepareUpdatePayloadValue = (currentValue: unknown): unknown => {
    if (this.value === currentValue) return undefined;
    if (!currentValue && this.value !== currentValue) return null;

    return currentValue;
  };
}
