import { MomentInput } from 'moment';

import { ValidationResult, ValidationSeverity } from '@ac/react-infrastructure';

import { DateManager } from 'utils/DateManager';

export enum CompareDateType {
  isBefore = 'isBefore',
  isAfter = 'isAfter',
  isSameOrAfter = 'isSameOrAfter',
  isSameOrBefore = 'isSameOrBefore',
}

const dateComparerMap = {
  [CompareDateType.isBefore]: DateManager.checkIfBefore,
  [CompareDateType.isAfter]: DateManager.checkIfAfter,
  [CompareDateType.isSameOrAfter]: DateManager.checkIfSameOrAfter,
  [CompareDateType.isSameOrBefore]: DateManager.checkIfSameOrBefore,
};

export const createDateCompareValidator = (
  description: string,
  compareDate: MomentInput,
  compareType: CompareDateType,
  inputDateFormat?: string
) => (value: string): ValidationResult => {
  const year = DateManager.getObjectDate(value, inputDateFormat).year;
  const compareFunction = dateComparerMap[compareType];

  if (
    !value ||
    !year ||
    compareFunction?.(compareDate, value, inputDateFormat)
  ) {
    return [];
  }

  return [
    {
      description,
      severity: ValidationSeverity.error,
    },
  ];
};

export const createTimeCompareValidator = (
  description: string,
  compareDate: string,
  compareType: CompareDateType,
  inputTimeFormat?: string
) => (value: string): ValidationResult => {
  const compareFunction = dateComparerMap[compareType];
  const momentCompareDate = DateManager.getMomentInput(
    compareDate,
    inputTimeFormat
  );
  const momentValue = DateManager.getMomentInput(value, inputTimeFormat);

  return !value || compareFunction?.(momentCompareDate, momentValue)
    ? []
    : [
        {
          description,
          severity: ValidationSeverity.error,
        },
      ];
};
