import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TextGroup } from '@ac/kiosk-components';

import { getEtd } from 'store/electronicRegistrationProcess/selectors/reservation';
import {
  getDateTimeFormats,
  getFeatureToggles,
  getFieldsConfiguration,
} from 'store/settings/selectors';
import { DateManager } from 'utils/DateManager';

import { Editable } from './Editable';

interface Props {
  valuePath?: string;
  dataTestSelector?: string;
  asSubForm?: boolean;
}

export const Etd = ({
  asSubForm,
  valuePath,
  dataTestSelector,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const featureToggles = useSelector(getFeatureToggles);
  const dateTimeFormats = useSelector(getDateTimeFormats);
  const fieldConfiguration = useSelector(getFieldsConfiguration);
  const etd = useSelector(getEtd);
  const reservationDetailsObject = fieldConfiguration?.reservationDetailsObject;

  const isEditable = featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
    ? reservationDetailsObject?.estimatedTimeOfDepartureField?.isEditable
    : true;

  const etdValue = useMemo(() => {
    return (
      etd && DateManager.getFormattedTime(etd, dateTimeFormats?.timeFormat)
    );
  }, [dateTimeFormats?.timeFormat, etd]);

  return isEditable && asSubForm && valuePath ? (
    <Editable
      className="spacing-top-sm"
      valuePath={valuePath}
      dataTestSelector={dataTestSelector?.concat('-etd-field')}
    />
  ) : (
    <TextGroup
      dataTestSelector={dataTestSelector?.concat('-etd-description')}
      label={t('COMPONENTS.ADDITIONAL_DETAILS_SECTION.ETD')}
      value={etdValue}
    />
  );
};
