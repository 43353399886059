import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import get from 'lodash.get';

import {
  Button,
  ButtonPattern,
  IconTypes,
  Text,
  TextSize,
  TextWeight,
  ValidationHeader,
} from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';
import {
  ArrayFieldStateErrors,
  FormApi,
  formNestedFieldArrayFactory,
  FormSpy,
} from '@ac/react-infrastructure';

import {
  getAllCountryDistrictsStore,
  getAllCountryStateStore,
} from 'store/additionalDictionaries/selectors';
import {
  getCountryEntities,
  getDefaultAddressType,
} from 'store/settings/selectors';
import { generateRandomString, smoothScrollInToCenter } from 'utils';
import { doesFormContainsErrors } from 'utils/form';
import { BODY_ID } from 'views/RegistrationCardEditPersonal/config';
import {
  AddressFormValues,
  FormProperties,
  FormValues,
} from 'views/RegistrationCardEditPersonal/types';

import { prepareSingleAddressInitialValues } from '../../utils/prepareInitialValues/prepareAddressesInitialValues';
import { DeleteConfirmationModal } from '../DeleteConfirmationModal/DeleteConfirmationModal';

import { SingleAddressSubForm } from './SingleAddressSubForm/SingleAddressSubForm';

type SubFormValues = {
  [FormProperties.addresses]: AddressFormValues[];
};

const NestedFormFieldArray = formNestedFieldArrayFactory<SubFormValues>();

interface AddressFormSectionProps {
  formApi: FormApi<FormValues>;
}

export const AddressFormSection = ({
  formApi,
}: AddressFormSectionProps): JSX.Element => {
  const [t] = useTranslation();
  const countries = useSelector(getCountryEntities);
  const states = useSelector(getAllCountryStateStore);
  const districts = useSelector(getAllCountryDistrictsStore);

  const defaultAddressType = useSelector(getDefaultAddressType);
  const [
    requestedDeleteAddress,
    setRequestedDeleteAddress,
  ] = useState<number>();

  const checkIsSubFormValid = useCallback(() => {
    const errors = formApi.getState().errors?.addresses;

    return !doesFormContainsErrors(errors);
  }, [formApi]);

  const scrollToSubSection = useCallback((addressSubSectionId: string) => {
    window.requestAnimationFrame(() =>
      smoothScrollInToCenter(`#${addressSubSectionId}`, `#${BODY_ID}`)
    );
  }, []);

  const stringifyAddressData = useCallback(
    (address: AddressFormValues): string => {
      const stateDescription =
        address.country && address.state
          ? states?.[address.country]?.data?.find(
              ({ code }) => code === address.state
            )?.name
          : undefined;

      const districtDescription =
        address.country && address.district
          ? districts?.[address.country]?.data?.find(
              ({ id }) => id === address.district
            )?.description
          : undefined;

      const countryDescription = address.country
        ? countries?.find(({ code }) => code === address.country)?.name
        : undefined;

      return [
        address.addressLine1,
        address.addressLine2,
        address.city,
        address.postalCode,
        stateDescription,
        districtDescription,
        countryDescription,
      ]
        .filter(isDefined)
        .join(', ');
    },
    [countries, states, districts]
  );

  const prepareMainReplaceOptions = useCallback(
    (excludeAddressIndex: number) => {
      const addresses = formApi.getState().values?.addresses;
      const errors = formApi.getState().errors?.addresses as
        | ArrayFieldStateErrors<AddressFormValues>
        | undefined;

      const mappedAddresses = addresses?.map((address, index) => {
        const nestedSubformErrors = errors?.valuesErrors?.[index];
        const isValid = nestedSubformErrors
          ? !doesFormContainsErrors(nestedSubformErrors)
          : true;

        return isValid ? address : undefined;
      });

      return (mappedAddresses || [])
        ?.map((address, addressIndex) => {
          if (!address || addressIndex === excludeAddressIndex) {
            return undefined;
          }

          return {
            identifier: addressIndex,
            title: t(
              'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.DELETE_OPTION_LABEL',
              { name: `${t('SHARED.ADDRESS')} ${addressIndex + 1}` }
            ),
            description: address && stringifyAddressData(address),
          };
        })
        .filter(isDefined);
    },
    [formApi, stringifyAddressData, t]
  );

  const closeDeleteModal = useCallback(() => {
    setRequestedDeleteAddress(undefined);
  }, []);

  const setAddressAsMain = useCallback(
    (addressIndex: number) => {
      const addresses = formApi.getState().values?.addresses;
      if (!addresses) return;

      const oldPrimaryIndex = addresses.findIndex(({ isPrimary }) => isPrimary);
      const newAddresses = addresses.map((address, index) => {
        if (index === oldPrimaryIndex) return { ...address, isPrimary: false };
        if (index === addressIndex) return { ...address, isPrimary: true };

        return address;
      });

      formApi.change(FormProperties.addresses, [...newAddresses]);
    },
    [formApi]
  );

  const removeSelectedAddress = useCallback(
    (newMainAddressIndex?: number) => {
      const addresses = formApi.getState().values?.addresses;
      if (!addresses) return;

      const newAddresses = addresses
        .map((address, index) => {
          if (index === requestedDeleteAddress) return undefined;
          if (index === newMainAddressIndex)
            return { ...address, isPrimary: true };

          return address;
        })
        .filter(isDefined);

      formApi.change(FormProperties.addresses, newAddresses);
      closeDeleteModal();
    },
    [closeDeleteModal, formApi, requestedDeleteAddress]
  );

  const createNewAddress = useCallback(() => {
    const addresses = formApi.getState().values?.addresses;
    const newAddressData = prepareSingleAddressInitialValues(
      addresses?.length
        ? { type: defaultAddressType }
        : { type: defaultAddressType, isPrimary: true }
    );

    formApi.change(
      FormProperties.addresses,
      addresses ? [...addresses, newAddressData] : [newAddressData]
    );

    scrollToSubSection(`${FormProperties.addresses}-${addresses?.length || 0}`);
  }, [defaultAddressType, formApi, scrollToSubSection]);

  const requestedDeleteAddressModalData = useMemo(() => {
    if (!isDefined(requestedDeleteAddress)) return;
    const values = formApi.getState().values;
    const address: AddressFormValues | undefined = get(values, [
      FormProperties.addresses,
      requestedDeleteAddress,
    ]) as AddressFormValues | undefined;

    if (!address) return;

    const replacementRequired = Boolean(
      address.isPrimary &&
        values.addresses?.length &&
        values.addresses.length > 1
    );

    const replacementOptions = replacementRequired
      ? prepareMainReplaceOptions(requestedDeleteAddress)
      : [];

    return {
      isMain: replacementRequired,
      title: replacementOptions.length
        ? t(
            'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.ADDRESS.DELETE_MAIN'
          )
        : t(
            'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.ADDRESS.DELETE_ADDITIONAL'
          ),
      description: replacementOptions.length
        ? t(
            'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.ADDRESS.DELETE_HINT'
          )
        : stringifyAddressData(address),
      options: replacementOptions,
    };
  }, [
    formApi,
    prepareMainReplaceOptions,
    requestedDeleteAddress,
    stringifyAddressData,
    t,
  ]);

  return (
    <>
      <FormSpy subscription={{ values: true, errors: true }}>
        {(): JSX.Element => {
          const isSubFormValid = checkIsSubFormValid();

          return (
            <div className="validation-header-wrapper spacing-bottom-md">
              <ValidationHeader
                isValid={isSubFormValid}
                titleWeight={TextWeight.regular}
                titleSize={TextSize.xlg}
                title={t('SHARED.ADDRESS')}
              />
              <Button
                disabled={!isSubFormValid}
                onClick={createNewAddress}
                pattern={ButtonPattern.tertiary}
                icon={IconTypes.plus}
                className="add-new-data-action-button"
              >
                {t(
                  'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.ADDRESS.ADD_BUTTON'
                )}
              </Button>

              {requestedDeleteAddressModalData && (
                <DeleteConfirmationModal
                  withReplacementOptions={Boolean(
                    requestedDeleteAddressModalData.isMain &&
                      requestedDeleteAddressModalData.options.length
                  )}
                  onCancelClick={closeDeleteModal}
                  onConfirmClick={removeSelectedAddress}
                  header={requestedDeleteAddressModalData.title}
                  description={requestedDeleteAddressModalData.description}
                  replaceOptions={requestedDeleteAddressModalData.options}
                />
              )}
            </div>
          );
        }}
      </FormSpy>

      <NestedFormFieldArray
        valuePath={[FormProperties.addresses]}
        subscription={{}}
      >
        {(fieldArray): JSX.Element | JSX.Element[] =>
          get(fieldArray.fields, 'length') ? (
            fieldArray.fields.map((path, _, name) => (
              <SingleAddressSubForm
                id={path.join('-')}
                reorderControl={generateRandomString()}
                key={name}
                path={path}
                formApi={formApi}
                onSetAsMainClick={setAddressAsMain}
                onRemoveClick={setRequestedDeleteAddress}
              />
            ))
          ) : (
            <Text className="spacing-top-xlg spacing-bottom-xxlg">
              {t('SHARED.NO_ADDRESS')}
            </Text>
          )
        }
      </NestedFormFieldArray>
    </>
  );
};
