import { DATE_PICKER_DATE_FORMAT } from '@ac/kiosk-components';

import {
  AdditionalNamesTypes,
  KioskPersonalDetails,
} from 'api/KioskApi/entries';
import { GeneralSettingsStorage } from 'store/settings/interfaces';
import { DateManager } from 'utils';
import { PersonalDetailsFormValues } from 'views/RegistrationCardEditPersonal/types';

export const preparePersonalDetailsInitialValues = (
  personalDetails?: KioskPersonalDetails,
  appSettings?: GeneralSettingsStorage
): PersonalDetailsFormValues => {
  const isMiddleNameEnabled = appSettings?.MIDDLENAME;
  const isSuffixEnabled = appSettings?.SUFFIX;
  const doesAlternateDetailsExist = Boolean(
    personalDetails?.preferredLanguageProfileDetails
  );

  const birthday =
    personalDetails?.birthday &&
    DateManager.getFormattedDateWithConditionalYear(
      {
        ...personalDetails?.birthday,
        month: personalDetails?.birthday.month - 1,
      },
      DATE_PICKER_DATE_FORMAT
    );

  const secondSurname = personalDetails?.additionalNames?.find(
    (name) => name.type === AdditionalNamesTypes.SecondSurname
  );

  return {
    title: personalDetails?.title?.id,
    lastName: personalDetails?.lastName,
    firstName: personalDetails?.firstName,
    ...(isMiddleNameEnabled && {
      middleName: personalDetails?.middleName,
    }),
    secondSurname: secondSurname?.value,
    ...(isSuffixEnabled && {
      suffix: personalDetails?.suffix?.id,
    }),
    nationality: personalDetails?.nationality?.code,
    birthday,

    ...(doesAlternateDetailsExist && {
      alternateDetails: {
        firstName: personalDetails?.preferredLanguageProfileDetails?.firstname,
        lastName: personalDetails?.preferredLanguageProfileDetails?.lastname,
        language:
          personalDetails?.preferredLanguageProfileDetails?.language?.code,
      },
    }),
  };
};
