import { TFunction } from 'i18next';

import { FormProperties } from 'views/RegistrationCardEditPersonal/types';

type TranslationType =
  | FormProperties.emails
  | FormProperties.mobiles
  | FormProperties.phones;

const title = {
  [FormProperties.emails]:
    'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.EMAIL.TITLE',
  [FormProperties.mobiles]:
    'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.MOBILE.TITLE',
  [FormProperties.phones]:
    'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.PHONE.TITLE',
};

const dataType = {
  [FormProperties.emails]: 'SHARED.EMAIL',
  [FormProperties.mobiles]: 'SHARED.MOBILE',
  [FormProperties.phones]: 'SHARED.PHONE',
};

const addNewButton = {
  [FormProperties.emails]:
    'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.EMAIL.ADD_BUTTON',
  [FormProperties.mobiles]:
    'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.MOBILE.ADD_BUTTON',
  [FormProperties.phones]:
    'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.PHONE.ADD_BUTTON',
};

const deleteMainTitle = {
  [FormProperties.emails]:
    'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.EMAIL.DELETE_MAIN',
  [FormProperties.mobiles]:
    'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.MOBILE.DELETE_MAIN',
  [FormProperties.phones]:
    'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.PHONE.DELETE_MAIN',
};

const deleteAdditionalTitle = {
  [FormProperties.emails]:
    'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.EMAIL.DELETE_ADDITIONAL',
  [FormProperties.mobiles]:
    'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.MOBILE.DELETE_ADDITIONAL',
  [FormProperties.phones]:
    'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.PHONE.DELETE_ADDITIONAL',
};

const deleteMainHint = {
  [FormProperties.emails]:
    'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.EMAIL.DELETE_HINT',
  [FormProperties.mobiles]:
    'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.MOBILE.DELETE_HINT',
  [FormProperties.phones]:
    'REGISTRATION_CARD_EDIT_PERSONAL.FORM.DATA_SECTION.PHONE.DELETE_HINT',
};

interface GetTypeRelatedTranslationsInterface {
  title: string;
  dataType: string;
  addNewButton: string;
  deleteMainTitle: string;
  deleteAdditionalTitle: string;
  deleteMainHint: string;
}

export const getTypeRelatedTranslations = (
  t: TFunction,
  type: TranslationType
): GetTypeRelatedTranslationsInterface => {
  return {
    title: t(title[type]),
    dataType: t(dataType[type]),
    addNewButton: t(addNewButton[type]),
    deleteMainTitle: t(deleteMainTitle[type]),
    deleteAdditionalTitle: t(deleteAdditionalTitle[type]),
    deleteMainHint: t(deleteMainHint[type]),
  };
};
