import { DATE_PICKER_DATE_FORMAT } from '@ac/kiosk-components';

import { KioskIdentityDocument } from 'api/KioskApi/entries';
import { DateManager } from 'utils';
import { DocumentFormValues } from 'views/RegistrationCardEditPersonal/types';

export const prepareDocumentsInitialValues = (
  documents: KioskIdentityDocument[],
  businessDate: string
): DocumentFormValues[] => {
  const validDocuments = documents.filter((doc) =>
    doc.expiryDate
      ? DateManager.checkIfSameOrAfter(doc.expiryDate, businessDate)
      : true
  );

  return validDocuments.map(prepareSingleDocumentInitialValues);
};

export const prepareSingleDocumentInitialValues = (
  document?: Partial<KioskIdentityDocument>
): DocumentFormValues => {
  return {
    id: document?.id,
    expiryDate: document?.expiryDate
      ? DateManager.getFormattedDate(
          document.expiryDate,
          DATE_PICKER_DATE_FORMAT
        )
      : undefined,
    number: document?.number,
    type: document?.documentType?.id,
  };
};
