import { TFunction } from 'i18next';

import { ApiError, isApiError } from '@ac/library-api';

import { knownErrorCodes } from 'configs';

import { MissingPermissionsError } from './customErrors';

export const getErrorMessage = (
  errors: Array<ApiError | Error | MissingPermissionsError>,
  t: TFunction
): string | undefined => {
  const missingPermissions = (errors as MissingPermissionsError[]).find(
    (error) => error instanceof MissingPermissionsError
  );

  if (missingPermissions) {
    return missingPermissions.title
      ? `${t(missingPermissions.title)}:\n${missingPermissions.message}`
      : missingPermissions.message;
  }

  let errorMessage: string | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const apiErrors = (errors as any[])
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    .map((error) => error?.data)
    .filter(isApiError);

  const knownErrors = Object.values(knownErrorCodes);

  apiErrors?.forEach((error) =>
    error.details?.some((element) => {
      const recognizedError = knownErrors.find(
        (item) => item.code === element.code
      );

      if (recognizedError) {
        errorMessage = recognizedError.message
          ? t(recognizedError.message)
          : element.message;

        return true;
      }

      return false;
    })
  );

  return errorMessage;
};
