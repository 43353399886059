import { DATE_PICKER_DATE_FORMAT } from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';
import {
  createRequiredValidator,
  ValidationResult,
  ValidationSchema,
} from '@ac/react-infrastructure';

import {
  CompareDateType,
  createConditionalRequire,
  createDateCompareValidator,
} from 'utils/form';
import { FormValues } from 'views/RegistrationCardEditPersonal/types';

export interface PersonalDetailsValidationSchema {
  firstName: ValidationResult;
  birthday: ValidationResult;
  alternateDetails: {
    language: ValidationResult;
  };
}

export const personalDetailsValidationSchema = (
  businessDate: string
): ValidationSchema<PersonalDetailsValidationSchema> => ({
  firstName: createRequiredValidator('VALIDATION.FIELD_IS_REQUIRED'),

  birthday: createDateCompareValidator(
    'VALIDATION.INCORRECT_DATE',
    businessDate,
    CompareDateType.isSameOrAfter,
    DATE_PICKER_DATE_FORMAT
  ),

  alternateDetails: {
    language: createConditionalRequire(
      'VALIDATION.FIELD_IS_REQUIRED',
      (_, model: FormValues) =>
        isDefined(model.personalDetails?.alternateDetails)
    ),
  },
});
