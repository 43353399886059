import {
  Nationality,
  ProfileAnniversaryDateDto,
  RawNationality,
} from '@ac/library-api';

import {
  AdditionalNames,
  BaseKioskConfigurationEntity,
  KioskPreferredLanguageDetails,
  RawAdditionalNames,
  RawBaseKioskConfigurationEntity,
  RawKioskPreferredLanguageDetails,
} from 'api/KioskApi/entries';

export interface RawKioskPersonalDetails {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  additionalNames?: RawAdditionalNames[];
  preferredLanguageProfileDetails?: RawKioskPreferredLanguageDetails;
  title?: RawBaseKioskConfigurationEntity;
  suffix?: RawBaseKioskConfigurationEntity;
  gender?: RawBaseKioskConfigurationEntity;
  nationality?: RawNationality;
  birthday?: ProfileAnniversaryDateDto;
}

export class KioskPersonalDetails {
  public firstName?: string;
  public lastName?: string;
  public middleName?: string;
  public additionalNames?: AdditionalNames[];
  public preferredLanguageProfileDetails?: KioskPreferredLanguageDetails;
  public title?: BaseKioskConfigurationEntity;
  public suffix?: BaseKioskConfigurationEntity;
  public gender?: BaseKioskConfigurationEntity;
  public nationality?: Nationality;
  public birthday?: ProfileAnniversaryDateDto;

  constructor(raw: RawKioskPersonalDetails) {
    this.firstName = raw.firstName;
    this.lastName = raw.lastName;
    this.middleName = raw.middleName;
    this.additionalNames =
      raw.additionalNames &&
      (raw.additionalNames || []).map(
        (additionalName) => new AdditionalNames(additionalName)
      );
    this.preferredLanguageProfileDetails =
      raw.preferredLanguageProfileDetails &&
      new KioskPreferredLanguageDetails(raw.preferredLanguageProfileDetails);
    this.title = raw.title && new BaseKioskConfigurationEntity(raw.title);
    this.suffix = raw.suffix && new BaseKioskConfigurationEntity(raw.suffix);
    this.gender = raw.gender && new BaseKioskConfigurationEntity(raw.gender);
    this.nationality = raw.nationality && new Nationality(raw.nationality);
    this.birthday = raw.birthday;
  }
}
