import { createSelector } from 'reselect';

import { ApiError, Nullable, ReservationStatus } from '@ac/library-api';

import {
  KioskConfigurationConsent,
  KioskRegCardDetails,
} from 'api/KioskApi/entries';
import { KioskPreferenceDetailsDto } from 'api/KioskApi/entries/electronicRegistrationProcess/kioskPreferenceDetails';
import { CROSS_BORDER_CONSENT_CODE } from 'configs/constants';
import {
  getConsentsEntities,
  getGeneralSettings,
  getPreferencesGroups,
  getPropertyConfiguration,
} from 'store/settings/selectors';
import { Store } from 'store/types';
import { DateManager } from 'utils/DateManager';

import { BaseObject } from 'types/shared';

import { InitialStateData } from '../interfaces';
import { PreferenceOptionsGroup } from '../interfaces/preferenceOptions/preferenceOptionsGroup';
import { SessionPurchaseElementDetails } from '../interfaces/SessionPurchaseElementDetails';

export const getInitialStateData = (state: Store): Nullable<InitialStateData> =>
  state.electronicRegistrationProcess.initialStateData;

export const getWasCBDPInitiallyGranted = (state: Store): boolean =>
  !!state.electronicRegistrationProcess.initialStateData?.wasCBDPGranted;

export const getElectronicRegistrationProcessFetchState = (
  state: Store
): boolean =>
  Object.values(state.electronicRegistrationProcess.fetching).includes(true);

export const getElectronicRegistrationProcessErrors = (
  state: Store
): Array<ApiError | Error> => state.electronicRegistrationProcess.errors;

export const getIsRegistrationCardReceived = (state: Store): boolean =>
  Boolean(state.electronicRegistrationProcess.registrationCardId);

export const getRegistrationCardId = (state: Store): string | undefined =>
  state.electronicRegistrationProcess.registrationCardId;

export const getElectronicRegistrationProcessId = (
  state: Store
): string | undefined => state.electronicRegistrationProcess.processId;

export const getRegistrationCardDetails = (
  state: Store
): KioskRegCardDetails | undefined =>
  state.electronicRegistrationProcess.regCardDetails;

export const getIsRegistrationCardCancelling = (state: Store): boolean =>
  !!state.electronicRegistrationProcess.fetching.regCardCancelling;

export const getIsRegistrationCardCancelled = (state: Store): boolean =>
  state.electronicRegistrationProcess.regCardCancelled;

export const getIsRegistrationCardCompleted = (state: Store): boolean =>
  state.electronicRegistrationProcess.regCardCompleted;

export const getIsAnotherSessionOpenedInNewTab = (state: Store): boolean =>
  state.electronicRegistrationProcess.regCardAnotherSessionOpenedInNewTab;

export const getAddedInSessionPurchaseElements = (
  state: Store
): SessionPurchaseElementDetails[] =>
  state.electronicRegistrationProcess.addedInSessionPurchaseElements;

export const getEditableConsents = createSelector(
  getConsentsEntities,
  getRegistrationCardDetails,
  (consents, regCardDetails) => {
    return (consents || []).filter((consent) => {
      if (consent.consentType?.code === CROSS_BORDER_CONSENT_CODE) {
        const cbpConsent = regCardDetails?.profile.consents?.find(
          ({ consentId }) => consentId === consent.id
        );

        if (cbpConsent?.isGranted) {
          return false;
        }
      }

      return true;
    });
  }
);

const EMPTY_ARRAY: ReadonlyArray<
  KioskConfigurationConsent & {
    disabled?: boolean;
    value?: boolean;
  }
> = [];

export const getDisabledConsents = createSelector(
  getConsentsEntities,
  getRegistrationCardDetails,
  getGeneralSettings,
  (
    consents,
    regCardDetails,
    generalSettings
  ): ReadonlyArray<
    KioskConfigurationConsent & {
      disabled?: boolean;
      value?: boolean;
    }
  > => {
    const cbdpConsentDefinition = (consents || []).find(
      (consent) => consent.consentType?.code === CROSS_BORDER_CONSENT_CODE
    );

    if (!cbdpConsentDefinition) {
      return EMPTY_ARRAY;
    }

    const profilesCBDPConsent = regCardDetails?.profile.consents?.find(
      ({ consentId }) => consentId === cbdpConsentDefinition.id
    );

    if (
      !profilesCBDPConsent?.isGranted ||
      !generalSettings?.SHOW_GRANTED_CBDP_CONSENT
    ) {
      return EMPTY_ARRAY;
    }

    return [
      {
        ...cbdpConsentDefinition,
        disabled: true,
        value: profilesCBDPConsent.isGranted,
      },
    ];
  }
);

export const getTermAndConditionOptions = createSelector(
  getDisabledConsents,
  getEditableConsents,
  (disabledConsents, editableConsents) => {
    return [...disabledConsents, ...editableConsents];
  }
);

export const getSummaryConsents = createSelector(
  getConsentsEntities,
  getRegistrationCardDetails,
  getWasCBDPInitiallyGranted,
  getGeneralSettings,
  (consents, regCardDetails, wasCBDPGranted, generalSettings) => {
    return (consents || []).filter((consent) => {
      if (consent.consentType?.code === CROSS_BORDER_CONSENT_CODE) {
        const cbpConsent = regCardDetails?.profile.consents?.find(
          ({ consentId }) => consentId === consent.id
        );

        const hideCBDPOnPrintedERegcard =
          wasCBDPGranted && cbpConsent?.isGranted;

        if (
          hideCBDPOnPrintedERegcard &&
          !generalSettings?.SHOW_GRANTED_CBDP_CONSENT
        ) {
          return false;
        }
      }

      return true;
    });
  }
);

export const getCachedAppliedConsents = (
  state: Store
): BaseObject<boolean> | undefined =>
  state.electronicRegistrationProcess.cachedAppliedConsents;

export const getCachedAppliedVisibleConsents = createSelector(
  getEditableConsents,
  getDisabledConsents,
  getCachedAppliedConsents,
  (editableConsents, disableVisibleConsents, cachedAppliedConsents) => {
    const allConsents = [...disableVisibleConsents, ...editableConsents];
    const visibleConsentsIds = allConsents.map((consent) => consent.id);

    const visibleAppliedConsents = Object.fromEntries(
      Object.entries(cachedAppliedConsents || []).filter(([key]) =>
        visibleConsentsIds.includes(key)
      )
    );

    return visibleAppliedConsents;
  }
);

export const getCachedPurposeOfStay = (state: Store): string | undefined =>
  state.electronicRegistrationProcess.cachedPurposeOfStay;

export const getCachedEtd = (state: Store): string | undefined =>
  state.electronicRegistrationProcess.cachedEtd;

export const getIsElectronicRegistrationProcessInitialized = createSelector(
  getRegistrationCardId,
  getElectronicRegistrationProcessId,
  getRegistrationCardDetails,
  (regCardId, processId, regCardDetails): boolean =>
    Boolean(regCardId && processId && regCardDetails)
);

export const getUniqueChosenPreferences = createSelector(
  getRegistrationCardDetails,
  (regCardDetails): KioskPreferenceDetailsDto[] => {
    const reservationPreferences =
      regCardDetails?.reservation.preferences || [];
    const profilePreferences = regCardDetails?.profile.preferences || [];

    const allChosenPreferences: {
      [key: string]: KioskPreferenceDetailsDto;
    } = reservationPreferences.reduce(
      (acc, pref) => ({ ...acc, [pref.attributeId]: pref }),
      {}
    );

    profilePreferences.forEach((pref) => {
      const foundReservationPreference = allChosenPreferences[pref.attributeId];
      if (!foundReservationPreference) {
        allChosenPreferences[pref.attributeId] = pref;
      }
    });

    return Object.values(allChosenPreferences);
  }
);

export const getPreferencesGroupOptions = createSelector(
  getPreferencesGroups,
  getUniqueChosenPreferences,
  getGeneralSettings,
  (
    groupedPreferences,
    chosenPreferences,
    generalSettings
  ): PreferenceOptionsGroup[] => {
    return groupedPreferences
      .reduce((acc, groupWithPreferences) => {
        return [
          ...acc,
          {
            ...groupWithPreferences,
            preferences: groupWithPreferences.preferences.filter((pref) =>
              generalSettings?.SELECTABLE_ATTRIBUTES?.includes(pref.id)
            ),
          },
        ];
      }, [])
      .map((groupWithPreferences) => {
        return {
          ...groupWithPreferences,
          preferences: groupWithPreferences.preferences.map((preference) => {
            const isSelected = chosenPreferences.find(
              (selectedPreference) =>
                selectedPreference.attributeId === preference.id
            );

            return { ...preference, isSelected: Boolean(isSelected) };
          }),
        };
      })
      .filter((pref) => pref.preferences.length);
  }
);

export const getIsPurchaseElementSelectionEnabled = createSelector(
  getGeneralSettings,
  getPropertyConfiguration,
  getRegistrationCardDetails,
  (generalSettings, propertyConfiguration, regCardDetails) => {
    const isPurchaseElementStepEnabled =
      generalSettings?.PURCHASE_ELEMENTS_STEP;

    const isReservationInProperStatus =
      regCardDetails?.reservation.status?.code === ReservationStatus.IH ||
      regCardDetails?.reservation.status?.code === ReservationStatus.DI;

    const isArrivalDay = DateManager.checkIfSame(
      regCardDetails?.reservation.arrivalDate,
      propertyConfiguration?.businessDate
    );

    return (
      isPurchaseElementStepEnabled &&
      isReservationInProperStatus &&
      isArrivalDay
    );
  }
);

export const getAdultCount = createSelector(
  getRegistrationCardDetails,
  (regCardDetails) => {
    return regCardDetails?.reservation.breakdown[0]?.adultCount ?? 0;
  }
);

export const getChildCount = createSelector(
  getRegistrationCardDetails,
  (regCardDetails) => {
    return regCardDetails?.reservation.breakdown[0]?.childCount ?? 0;
  }
);

export const getGuestCount = createSelector(
  getAdultCount,
  getChildCount,
  (adultCount, childCount) => {
    return adultCount + childCount;
  }
);
