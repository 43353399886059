/* eslint-disable @typescript-eslint/no-explicit-any */

import { isDefined } from '@ac/library-utils/dist/utils';
import { getChanges } from '@ac/react-infrastructure';

interface CollectionData {
  id?: string;
  [key: string]: any;
}

type ReturnedTypeData<MapFunction> = MapFunction extends (
  ...args: any[]
) => infer R
  ? R
  : unknown;

interface AddUpdateRemovePayload<T> {
  add?: T[];
  update?: T[];
  remove?: string[];
}

export const prepareAddUpdateRemovePayload = <
  InputData extends CollectionData,
  DataMapperT extends (value: InputData) => any
>(
  dataMapper: DataMapperT,
  formData?: InputData[],
  initialValues?: InputData[]
): AddUpdateRemovePayload<ReturnedTypeData<DataMapperT>> => {
  const validFormData = formData?.filter(
    (data) => data && Object.keys(data).length
  );

  const oldData = validFormData?.filter((data) => data.id);

  const addedData = validFormData?.filter(
    (data) => !data.id && Object.values(data).filter(isDefined).length
  );
  const removedData = initialValues?.filter(
    (data) =>
      data.id && !oldData?.find((formElement) => formElement.id === data.id)
  );
  const changedData = oldData?.filter((data) => {
    const initialData = initialValues?.find(
      (formElement) => formElement.id === data.id
    );

    const changes = !initialData ? data : getChanges(initialData, data);

    return Boolean(changes && Object.keys(changes).length);
  });

  return {
    ...(addedData?.length ? { add: addedData.map(dataMapper) } : undefined),

    ...(removedData?.length
      ? { remove: removedData.map(({ id }) => id).filter(isDefined) }
      : undefined),

    ...(changedData?.length
      ? { update: changedData.map(dataMapper) }
      : undefined),
  };
};
