import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { isDefined } from '@ac/library-utils/dist/utils';

import {
  getAllCountryDistrictsStore,
  getAllCountryStateStore,
} from 'store/additionalDictionaries/selectors';
import {
  getAddressTypeEntities,
  getCountryEntities,
} from 'store/settings/selectors';

import { AddressFormValues } from '../../../types';

type AddressFormatter = (address?: AddressFormValues) => string | undefined;

export const useAddressFormatter = (): AddressFormatter => {
  const addressTypes = useSelector(getAddressTypeEntities);
  const countries = useSelector(getCountryEntities);
  const states = useSelector(getAllCountryStateStore);
  const districts = useSelector(getAllCountryDistrictsStore);

  const stringifyAddress = useCallback(
    (address?: AddressFormValues): string | undefined => {
      if (!address) return;
      const stateOptions = address?.country
        ? states?.[address.country]
        : undefined;
      const districtsOptions = address?.country
        ? districts?.[address.country]
        : undefined;

      return [
        addressTypes?.find((option) => option.id === address?.type)
          ?.description,
        address?.addressLine1,
        address?.addressLine2,
        address?.postalCode,
        address?.city,
        countries?.find((option) => option.code === address?.country)?.name,
        stateOptions?.data?.find((option) => option.code === address?.state)
          ?.name,
        districtsOptions?.data?.find(
          (option) => option.id === address?.district
        )?.description,
      ]
        .filter(isDefined)
        .join(', ');
    },
    [addressTypes, countries, districts, states]
  );

  return stringifyAddress;
};
