import {
  CookiesService,
  LoginService,
  SessionService,
} from '@ac/library-utils/dist/services';

export class Storage {
  private static propertyRefreshTimer: number;
  private static deviceRefreshTimer: number;
  private static propertyRefreshTimeout = 1000 * 60 * 60 * 23;
  private static lifetimeOfDeviceCookie = 1000 * 60;
  private static lifetimeOfClosedRegistrationCardCookie = 1000 * 30;
  private static keys = {
    DEVICE: 'aboveCloud.device',
    PREVIOUSLY_CLOSED_REGISTRATION_CARD: 'aboveCloud.previouslyClosedRegCard',
  };

  public static savePropertyId(property: string): void {
    if (this.propertyRefreshTimer) {
      clearInterval(this.propertyRefreshTimer);
    }

    SessionService.setPropertyId(property);

    this.propertyRefreshTimer = window.setInterval(() => {
      SessionService.setPropertyId(property);
    }, this.propertyRefreshTimeout);
  }

  public static saveDeviceCookie(device: string): void {
    Storage.setCookie(
      Storage.keys.DEVICE,
      device,
      Date.now() + Storage.lifetimeOfDeviceCookie
    );
  }

  public static savePreviouslyClosedRegistrationCard(
    registrationCardId: string
  ): void {
    Storage.setCookie(
      Storage.keys.PREVIOUSLY_CLOSED_REGISTRATION_CARD,
      registrationCardId,
      Date.now() + Storage.lifetimeOfClosedRegistrationCardCookie
    );
  }

  public static deletePropertyId(): void {
    if (this.propertyRefreshTimer) {
      clearInterval(this.propertyRefreshTimer);
    }
    SessionService.setPropertyId(undefined);
  }

  public static deleteDeviceCookie(): void {
    if (this.deviceRefreshTimer) {
      clearInterval(this.deviceRefreshTimer);
    }
    Storage.deleteCookie(Storage.keys.DEVICE);
  }

  public static getPropertyId(): string | undefined {
    return SessionService.getPropertyId();
  }

  public static getDeviceCookie(): string | undefined {
    return Storage.getCookie(Storage.keys.DEVICE);
  }

  public static getPreviouslyClosedRegistrationCard(): string | undefined {
    return Storage.getCookie(Storage.keys.PREVIOUSLY_CLOSED_REGISTRATION_CARD);
  }

  private static setCookie(
    key: string,
    value: string,
    expirationTime?: Date | number
  ): void {
    CookiesService.setCookie(key, value, expirationTime, this.domain);
  }

  private static getCookie(key: string): string | undefined {
    return CookiesService.getCookie(key);
  }

  private static deleteCookie(key: string): void {
    return CookiesService.deleteCookie(key, this.domain);
  }

  private static get domain(): string | undefined {
    return LoginService.domain();
  }
}
