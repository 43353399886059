import { call } from 'redux-saga/effects';

export function* retrySaga<T>(
  repeatCount: number,
  repeatedCallback: () => T
): Generator<unknown, T, T> {
  let lastErrorOccurred: Error | undefined;

  for (let i = 0; i < repeatCount; i++) {
    try {
      const result: T = yield call(repeatedCallback);

      return result;
    } catch (error) {
      lastErrorOccurred = error as Error | undefined;
    }
  }

  throw lastErrorOccurred;
}
