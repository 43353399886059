import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  ExternalHTMLContent,
  Flex,
  Section,
  SectionType,
} from '@ac/kiosk-components';
import { Form, FormApi } from '@ac/react-infrastructure';

import {
  Body,
  ComplementaryDetailsSection,
  Footer,
  Header,
  PersonalDetailsSection,
  ReservationDetailsSection,
  View,
} from 'components';
import { AdditionalDetailsSection } from 'components/AdditionalDetailsSection/AdditionalDetailsSection';
import { paths } from 'configs/paths';
import {
  cacheCheckedConsents,
  cacheEtd,
  cachePurposeOfStay,
  updateSummaryData,
} from 'store/electronicRegistrationProcess/actions';
import {
  getCachedAppliedVisibleConsents,
  getCachedEtd,
  getCachedPurposeOfStay,
  getIsPurchaseElementSelectionEnabled,
  getIsRegistrationCardCancelling,
  getPreferencesGroupOptions,
  getRegistrationCardDetails,
  getTermAndConditionOptions,
  getUniqueChosenPreferences,
} from 'store/electronicRegistrationProcess/selectors';
import { getIsProfileDataRequiresCompletion } from 'store/electronicRegistrationProcess/selectors/profile';
import { getPreferencesDiff } from 'store/electronicRegistrationProcess/utils/preferences/getPreferencesDiff';
import {
  getConsentsEntities,
  getCustomMessages,
  getFeatureToggles,
  getFieldsConfiguration,
  getGeneralSettings,
  getIsPersonalDetailsVisible,
  getIsPersonalEditModeEnabled,
  getPropertyConfiguration,
} from 'store/settings/selectors';
import { scrollToInvalidFormField } from 'utils/form/scrollToInvalidFormField';
import { useRouter, useScrollPositionRestoration } from 'utils/hooks';
import { PreferenceSection } from 'views/RegistrationCardSummary/components/PreferenceSection/PreferenceSection';

import { MissingRequiredDataAlert } from './components/MissingRequiredDataAlert/MissingRequiredDataAlert';
import { TermsAndConditions } from './components/TermsAndConditions/TermsAndConditions';
import { preparePreferencesFormInitialValues } from './utils/preparePreferencesFormInitialValues';
import { prepareUpdateTermsAndConditionPayload } from './utils/prepareUpdateTermsAndConditionPayload';
import { BODY_ID } from './config';
import { FormProperties, FormValues } from './types';

import './RegistrationCardSummary.scss';

interface Props {
  dataTestSelector?: string;
}

export const RegistrationCardSummary = ({
  dataTestSelector = 'registration-card-summary',
}: Props): JSX.Element => {
  const router = useRouter();
  const formApi = useRef<FormApi<FormValues>>();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    restoreScrollPosition,
    saveScrollPosition,
  } = useScrollPositionRestoration(`#${BODY_ID}`);

  const regCardDetails = useSelector(getRegistrationCardDetails);
  const isProfileDataRequiresCompletion = useSelector(
    getIsProfileDataRequiresCompletion
  );
  const fieldConfiguration = useSelector(getFieldsConfiguration);
  const featureToggles = useSelector(getFeatureToggles);
  const isPersonalEditModeEnabled = useSelector(getIsPersonalEditModeEnabled);
  const isPersonalDetailsVisible = useSelector(getIsPersonalDetailsVisible);
  const isRegistrationCardCancelling = useSelector(
    getIsRegistrationCardCancelling
  );
  const visibleCachedAppliedConsents = useSelector(
    getCachedAppliedVisibleConsents
  );

  const preferencesGroups = useSelector(getPreferencesGroupOptions);
  const sortedPreferenceList = useMemo(
    () =>
      preferencesGroups.map((preferencesGroup) => ({
        ...preferencesGroup,
        preferences: preferencesGroup.preferences.sort(
          (preferenceA, preferenceB) =>
            preferenceA.isSelected === preferenceB.isSelected
              ? 0
              : preferenceA.isSelected
              ? -1
              : 1
        ),
      })),
    [preferencesGroups]
  );

  const uniqueChosenPreferences = useSelector(getUniqueChosenPreferences);

  const initialFormPreferenceOptionsValues = useMemo(() => {
    return preparePreferencesFormInitialValues(uniqueChosenPreferences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const termAndConditionOptions = useSelector(getTermAndConditionOptions);

  const cachedPurposeOfStay = useSelector(getCachedPurposeOfStay);
  const cachedEtd = useSelector(getCachedEtd);
  const generalSettings = useSelector(getGeneralSettings);
  const customMessages = useSelector(getCustomMessages);
  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const consents = useSelector(getConsentsEntities);
  const isPurchaseElementSelectionEnabled = useSelector(
    getIsPurchaseElementSelectionEnabled
  );

  const isComplementaryDetailsSectionVisible = Boolean(
    generalSettings?.RESERVATION_HEADER_CUSTOM_FIELDS?.length
  );

  const isPreferencesSectionOn = Boolean(generalSettings?.DISPLAY_PREFERENCES);

  const isAdditionalDetailsEnabled = featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
    ? fieldConfiguration?.reservationDetailsObject?.isVisible
    : true;

  const redirectToNextPage = useCallback(() => {
    if (isPurchaseElementSelectionEnabled) {
      router.goTo(paths.REGISTRATION_CARD_PURCHASE_ELEMENTS);
    } else {
      router.goTo(paths.REGISTRATION_CARD_CONFIRMATION);
    }
  }, [isPurchaseElementSelectionEnabled, router]);

  const handleEditPersonalButtonClick = useCallback(() => {
    router.goTo(paths.REGISTRATION_CARD_EDIT_PERSONAL);
  }, [router]);

  const handleEditComplementaryButtonClick = useCallback(() => {
    router.goTo(paths.REGISTRATION_CARD_EDIT_COMPLEMENTARY_DETAILS);
  }, [router]);

  const handleDisclaimerLinkClick = useCallback(
    (link: string) => {
      saveScrollPosition();
      router.goTo(paths.EXTERNAL_LINK, {
        params: { link: encodeURIComponent(link) },
      });
    },
    [router, saveScrollPosition]
  );

  const handleConsentLinkClick = useCallback(
    (consentId: string) => {
      saveScrollPosition();
      router.goTo(paths.CONSENT, {
        params: {
          consentId,
        },
      });
    },
    [router, saveScrollPosition]
  );

  const onFormSubmit = useCallback(
    (values: FormValues) => {
      const updateTermsAndConditionPayload = prepareUpdateTermsAndConditionPayload(
        values.termsAndCondition,
        regCardDetails?.profile.consents,
        consents,
        propertyConfiguration?.businessDate
      );

      const parsedEtd =
        values.etd &&
        `${regCardDetails?.reservation.departureDate}T${values.etd}`;

      const ifPurposeOfStayChanged =
        values.purposeOfStay !== regCardDetails?.reservation.purposeOfStay?.id;
      const idEtdChanged = parsedEtd !== regCardDetails?.reservation.etd;
      const ifConsentsChanged =
        updateTermsAndConditionPayload &&
        Object.keys(updateTermsAndConditionPayload).length;

      const profilePreferencesDiff = getPreferencesDiff(
        uniqueChosenPreferences,
        values.preferences
      );
      const reservationPreferencesDiff = getPreferencesDiff(
        uniqueChosenPreferences,
        values.preferences
      );

      const preferencesChanged =
        profilePreferencesDiff.changed || reservationPreferencesDiff.changed;

      if (
        ifPurposeOfStayChanged ||
        idEtdChanged ||
        ifConsentsChanged ||
        preferencesChanged
      ) {
        dispatch(
          updateSummaryData.trigger({
            purposeOfStayId: ifPurposeOfStayChanged
              ? values.purposeOfStay
              : undefined,
            etd: idEtdChanged ? parsedEtd : undefined,
            consents: ifConsentsChanged
              ? updateTermsAndConditionPayload
              : undefined,
            profilePreferencesDiff: profilePreferencesDiff.changed
              ? {
                  preferenceIds: {
                    set: profilePreferencesDiff.set,
                    remove: profilePreferencesDiff.remove,
                  },
                }
              : undefined,
            reservationPreferencesDiff: reservationPreferencesDiff.changed
              ? {
                  preferenceIds: {
                    set: reservationPreferencesDiff.set,
                    remove: reservationPreferencesDiff.remove,
                  },
                }
              : undefined,
            onSuccessfulUpdate: redirectToNextPage,
          })
        );
      } else {
        redirectToNextPage();
      }
    },
    [
      consents,
      dispatch,
      propertyConfiguration?.businessDate,
      redirectToNextPage,
      regCardDetails?.profile.consents,
      regCardDetails?.reservation.departureDate,
      regCardDetails?.reservation.etd,
      regCardDetails?.reservation.purposeOfStay?.id,
      uniqueChosenPreferences,
    ]
  );

  const cacheFormValues = (): void => {
    const fromValues = formApi.current?.getState()?.values;

    if (fromValues?.purposeOfStay) {
      dispatch(cachePurposeOfStay(fromValues?.purposeOfStay));
    }

    if (fromValues?.termsAndCondition) {
      dispatch(cacheCheckedConsents(fromValues?.termsAndCondition));
    }

    if (fromValues?.etd) {
      dispatch(cacheEtd(fromValues?.etd));
    }
  };

  const scrollToInvalidSection = (): void => {
    const formState = formApi.current?.getState();
    if (!formState?.invalid) return;
    scrollToInvalidFormField(formState.errors, BODY_ID);
  };

  useEffect(() => {
    restoreScrollPosition(true);

    return (): void => {
      cacheFormValues();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View>
      <Header title={t('REGISTRATION_CARD.TITLE')} />
      <Form
        initialValues={{
          [FormProperties.purposeOfStay]: cachedPurposeOfStay,
          [FormProperties.etd]: cachedEtd,
          [FormProperties.termsAndCondition]: visibleCachedAppliedConsents,
          [FormProperties.preferences]: initialFormPreferenceOptionsValues,
        }}
        onSubmit={onFormSubmit}
        keepDirtyOnReinitialize
        destroyOnUnregister={false}
      >
        {(formRenderProps): JSX.Element => {
          if (!formApi.current) {
            formApi.current = formRenderProps.form;
          }

          const allCheckboxValues = formRenderProps.values.termsAndCondition
            ? Object.values(formRenderProps.values.termsAndCondition)
            : [];

          const isAllConsentsChecked =
            !allCheckboxValues.includes(false) &&
            allCheckboxValues.length === termAndConditionOptions.length;

          return (
            <>
              <Body id={BODY_ID}>
                {regCardDetails && (
                  <>
                    <ReservationDetailsSection
                      reservationData={regCardDetails.reservation}
                    />

                    {isProfileDataRequiresCompletion && (
                      <MissingRequiredDataAlert
                        dataTestSelector={dataTestSelector?.concat(
                          '-missing-required-alert'
                        )}
                        onConfirm={handleEditPersonalButtonClick}
                        className="spacing-top-xlg spacing-horizontal-xxlg"
                      />
                    )}

                    {isPersonalDetailsVisible && (
                      <PersonalDetailsSection
                        editButtonVisible={isPersonalEditModeEnabled}
                        onEditButtonClick={handleEditPersonalButtonClick}
                        enableCollapsible
                      />
                    )}
                  </>
                )}

                {isComplementaryDetailsSectionVisible && (
                  <ComplementaryDetailsSection
                    complementaryDetails={
                      regCardDetails?.reservation.reservationHeader
                    }
                    editButtonVisible={generalSettings?.EDIT_MODE}
                    onEditButtonClick={handleEditComplementaryButtonClick}
                  />
                )}

                {isPreferencesSectionOn && preferencesGroups.length ? (
                  <PreferenceSection preferenceList={sortedPreferenceList} />
                ) : null}

                <Section type={SectionType.wide}>
                  <Flex>
                    <Box sizeSm={6}>
                      <TermsAndConditions
                        id={FormProperties.termsAndCondition}
                        options={termAndConditionOptions}
                        onSelectAllClick={formRenderProps.form.change}
                        isAllChecked={isAllConsentsChecked}
                        onConsentLinkClick={handleConsentLinkClick}
                      />
                    </Box>

                    {isAdditionalDetailsEnabled && (
                      <Box sizeSm={6}>
                        <AdditionalDetailsSection
                          asSubForm={true}
                          purposeOfStayValuePath={FormProperties.purposeOfStay}
                          etdValuePath={FormProperties.etd}
                        />
                      </Box>
                    )}
                  </Flex>

                  {customMessages?.DISCLAIMER && (
                    <Box className="spacing-top-xlg spacing-bottom-xlg">
                      <ExternalHTMLContent
                        onLinkClick={handleDisclaimerLinkClick}
                        content={customMessages.DISCLAIMER}
                      />
                    </Box>
                  )}
                </Section>
              </Body>

              <Footer
                hasPrimaryButton
                isPrimaryDisabled={isProfileDataRequiresCompletion}
                onPrimaryClick={(): void => {
                  formRenderProps.handleSubmit();
                  scrollToInvalidSection();
                }}
                hasCancelButton
                isRegistrationCardCancelling={isRegistrationCardCancelling}
              />
            </>
          );
        }}
      </Form>
    </View>
  );
};
