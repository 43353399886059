import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getSortedProfileCommunicationChannels,
  getSortedProfileEmails,
  getSortedProfileMobiles,
  getSortedProfilePhones,
  getValidatedProfileData,
} from 'store/electronicRegistrationProcess/selectors/profile';
import {
  getCommunicationModeOrder,
  getCommunicationTypeEntities,
  getFeatureToggles,
  getFieldsConfiguration,
  getGeneralSettings,
} from 'store/settings/selectors';
import { mapContactsData } from 'utils/regCardPresentationDataMappers';
import { mapContactsData as mapContactsDataV2 } from 'utils/regCardPresentationDataMappers/v2/mapContactsData';

import { ProfilePresentationSectionData } from '../types';

interface Props {
  showOnlyPrimary?: boolean;
}

export const useContactsConfiguration = ({
  showOnlyPrimary,
}: Props): ProfilePresentationSectionData => {
  const { i18n } = useTranslation();
  const featureToggles = useSelector(getFeatureToggles);
  const validatedProfileData = useSelector(getValidatedProfileData);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const communicationTypes = useSelector(getCommunicationTypeEntities);
  const generalSettings = useSelector(getGeneralSettings);
  const communicationModeOrder = useSelector(getCommunicationModeOrder);
  const emails = useSelector(getSortedProfileEmails);
  const mobiles = useSelector(getSortedProfileMobiles);
  const phones = useSelector(getSortedProfilePhones);
  const communicationChannels = useSelector(
    getSortedProfileCommunicationChannels
  );

  const contactsData = useMemo(
    () =>
      featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
        ? mapContactsDataV2(communicationChannels, {
            showOnlyPrimary,
            emailFieldsConfiguration:
              fieldsConfiguration?.emailObjectCollection,
            mobileFieldsConfiguration:
              fieldsConfiguration?.mobileObjectCollection,
            phoneFieldsConfiguration:
              fieldsConfiguration?.phoneObjectCollection,
          })
        : mapContactsData(
            { phones, mobiles, emails },
            {
              modeOrder: communicationModeOrder,
              isEmailEnabled: generalSettings?.DISPLAY_EMAIL,
              isMobileEnabled: generalSettings?.DISPLAY_MOBILE,
              isPhoneEnabled: generalSettings?.DISPLAY_PHONE,
              communicationTypes,
              showOnlyPrimary,
            }
          ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      communicationChannels,
      communicationModeOrder,
      communicationTypes,
      emails,
      featureToggles,
      fieldsConfiguration,
      generalSettings,
      mobiles,
      phones,
      showOnlyPrimary,
      i18n.language,
    ]
  );

  const contactsAreValid = useMemo(() => {
    return validatedProfileData
      ? validatedProfileData.emails &&
          validatedProfileData.mobiles &&
          validatedProfileData.phones
      : true;
  }, [validatedProfileData]);

  const isContactSectionVisible = useMemo(() => {
    return featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
      ? (fieldsConfiguration?.emailObjectCollection?.isVisible ||
          fieldsConfiguration?.phoneObjectCollection?.isVisible ||
          fieldsConfiguration?.mobileObjectCollection?.isVisible) ??
          true
      : (generalSettings?.DISPLAY_PHONE ||
          generalSettings?.DISPLAY_MOBILE ||
          generalSettings?.DISPLAY_EMAIL) ??
          false;
  }, [featureToggles, fieldsConfiguration, generalSettings]);

  return useMemo(
    () => ({
      data: contactsData,
      isVisible: isContactSectionVisible,
      isMissingRequiredInformation: featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
        ? !contactsAreValid
        : false,
    }),
    [contactsData, isContactSectionVisible, featureToggles, contactsAreValid]
  );
};
