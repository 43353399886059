import { matchPath } from 'react-router-dom';
import i18next from 'i18next';
import { Action } from 'redux-actions';
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { LogReporter, PDFCreator } from '@ac/kiosk-components';
import {
  ApiError,
  BaseApi,
  buildFIQLFilter,
  Config,
  EffectiveUserPermissionIdData,
  FIQLOperators,
  Logger,
  PageResponse,
  PermissionDetails,
  RawEffectiveUserPermissionIdData,
  RawPermissionDetails,
} from '@ac/library-api';
import { PermissionsApi } from '@ac/library-api/dist/api/v0/permissionManagement';
import { EffectiveUserPermissionApi } from '@ac/library-api/dist/api/v0/permissionManagement/users';
import { SessionDataHost } from '@ac/library-utils/dist/services';

import { ElectronicRegistrationApi, KioskUsersApi } from 'api/KioskApi';
import { KioskLoggedUser, KioskRegistrationCard } from 'api/KioskApi/entries';
import {
  ACCEPT_LANGUAGE_HEADER,
  APP_NAME,
  EREGCARD_POLLING_TIMEOUT,
  paths,
  PDF_SETUP,
} from 'configs';
import { PROPERTY_PERMISSIONS } from 'configs/permissions';
import { Authorizer, Storage } from 'services';
import {
  cancelElectronicRegistrationProcess,
  receivedRegistrationCardForDevice,
} from 'store/electronicRegistrationProcess/actions';
import {
  getIsElectronicRegistrationProcessInitialized,
  getRegistrationCardId,
} from 'store/electronicRegistrationProcess/selectors';
import { MissingPermissionsError } from 'utils/customErrors';
import { getLocalizedContent } from 'utils/getLocalizedContent';
import { getMissingRequiredPermissionNames } from 'utils/getMissingRequiredPermissionNames';
import { handleSagaError } from 'utils/sagas';

import { Awaited, BaseObject, SagasGenerator } from 'types/shared';

import { PermissionData } from './interfaces/permissionData';
import { PrepareEnvironmentPayload } from './interfaces/prepareExternalDeviceEnvironmentPayload';
import { PropertyPermissions } from './interfaces/state';
import * as actions from './actions';
import { getAppErrors, getCurrentDeviceId } from './selectors';

function* fetchPermissions(
  unitId: string,
  permissionsMap: { [key: string]: { id: string; isRequired: boolean } }
): SagasGenerator<BaseObject<PermissionData>> {
  const neededPermissionIds = Object.values(permissionsMap).map(({ id }) => id);

  const permissionsDictionary: PageResponse<
    RawPermissionDetails,
    PermissionDetails
  > = yield PermissionsApi.getList({
    queryParams: {
      pageSize: neededPermissionIds.length,
      filter: buildFIQLFilter('id', FIQLOperators.equal, neededPermissionIds),
    },
    customConfig: {
      skipCache: false,
    },
  });

  const tenantGrantedPermissions: PageResponse<
    RawEffectiveUserPermissionIdData,
    EffectiveUserPermissionIdData
  > = yield EffectiveUserPermissionApi.getMyInUnit({
    pathParams: { unitId },
    queryParams: {
      pageSize: neededPermissionIds.length,
      filter: buildFIQLFilter(
        'permissionId',
        FIQLOperators.equal,
        neededPermissionIds
      ),
    },
  });

  return Object.entries(permissionsMap).reduce((acc, [key, permission]) => {
    const permissionName = permissionsDictionary.results.find(
      ({ id }) => id === permission.id
    );
    const isGranted = tenantGrantedPermissions.results.some(
      ({ permissionId }) => permissionId === permission.id
    );

    const permissionData: PermissionData = {
      ...permission,
      isGranted,
      name:
        permissionName?.name &&
        getLocalizedContent(permissionName?.name, i18next.language),
    };

    return {
      ...acc,
      [key]: permissionData,
    };
  }, {});
}

function* selectProperty(action: Action<string> | string): SagasGenerator {
  try {
    const propertyId = typeof action === 'string' ? action : action.payload;
    const mappedPermissions: PropertyPermissions = yield fetchPermissions(
      propertyId,
      PROPERTY_PERMISSIONS
    );
    const missingPermissionNames = getMissingRequiredPermissionNames(
      mappedPermissions
    );

    if (missingPermissionNames.length) {
      throw new MissingPermissionsError({
        title: i18next.t('ERROR.TITLE.MISSING_PROPERTY_PERMISSIONS'),
        message: missingPermissionNames.join('\n'),
      });
    }

    BaseApi.defaultConfig = (): Promise<Config<undefined>> =>
      Promise.resolve(
        Authorizer.getStaticConfiguration({
          propertyId,
          headers: { [ACCEPT_LANGUAGE_HEADER]: i18next.language },
        })
      );

    yield put(actions.setPropertyPermissions(mappedPermissions));
    yield put(actions.selectProperty.success(propertyId));
    yield put(actions.setCurrentProperty(propertyId));
  } catch (error) {
    yield put(actions.selectProperty.failure(handleSagaError(error)));
  }
}

function* prepareApplication(): SagasGenerator {
  try {
    const isSameDeviceScenario = Boolean(
      matchPath(
        {
          path: paths.SAME_DEVICE_ENTRY_PATH,
          end: false,
        },
        window.location.pathname
      )
    );

    if (isSameDeviceScenario) {
      yield put(actions.setSameDeviceScenario());
    }

    if (!BaseApi.defaultConfig) {
      BaseApi.host = acConfig.apiUrl;
      BaseApi.defaultConfig = (): Promise<Config<undefined>> =>
        Promise.resolve(Authorizer.getStaticConfiguration());
    }

    try {
      const remoteLoggerInstance: Awaited<
        ReturnType<typeof Logger.init>
      > = yield Logger.init(APP_NAME);
      LogReporter.assignRemoteLogger(remoteLoggerInstance);
    } catch (error) {
      /**
       * Logger is optional for application.
       * It throws error for local environment because of no version.json file
       */
    }

    const user: KioskLoggedUser = yield KioskUsersApi.getLoggedUser();
    yield put(actions.setCurrentUser(user));

    PDFCreator.configure(PDF_SETUP);

    yield put(actions.prepareApplication.success());
  } catch (error) {
    yield put(actions.prepareApplication.failure(handleSagaError(error)));
  }
}

function* prepareEnvironment(
  action: Action<PrepareEnvironmentPayload>
): SagasGenerator {
  try {
    const { propertyId, deviceId } = action.payload;
    yield call(selectProperty, propertyId);

    const error: Array<
      ApiError | Error | MissingPermissionsError
    > = yield select(getAppErrors);

    if (error.length) {
      yield put(actions.prepareEnvironment.failure(null));

      return;
    }

    if (deviceId) {
      yield put(actions.setCurrentDevice(deviceId));
    }

    yield put(actions.prepareEnvironment.success());
  } catch (error) {
    yield put(actions.prepareEnvironment.failure(handleSagaError(error)));
  }
}

function* fetchRegistrationCardIdForDevice(): SagasGenerator {
  try {
    const currentDeviceId: string = yield select(getCurrentDeviceId);
    const savedRegCardId: string = yield select(getRegistrationCardId);
    const processInitializeState: boolean = yield select(
      getIsElectronicRegistrationProcessInitialized
    );

    const regCard: KioskRegistrationCard = yield ElectronicRegistrationApi.getRegistrationCard(
      {
        pathParams: {
          id: currentDeviceId,
        },
      }
    );

    if (
      processInitializeState &&
      savedRegCardId &&
      savedRegCardId !== regCard.id
    ) {
      yield put(cancelElectronicRegistrationProcess.success());
    }

    const actualDeviceId: string = yield select(getCurrentDeviceId);
    const wasRegistrationCardAlreadyClosed: boolean =
      regCard.id === Storage.getPreviouslyClosedRegistrationCard();
    const didDeviceIdChangedInTheMeantime = currentDeviceId !== actualDeviceId;

    if (
      !savedRegCardId &&
      regCard.id &&
      !didDeviceIdChangedInTheMeantime &&
      !wasRegistrationCardAlreadyClosed
    ) {
      yield put(receivedRegistrationCardForDevice(regCard.id));
    }
  } catch {
    /**
     * Prevents startPollingRegistrationCard process stop on network connection lost
     */
  }
}

function* pollingTask(): SagasGenerator {
  while (true) {
    try {
      const currentDeviceId: string = yield select(getCurrentDeviceId);
      yield Storage.saveDeviceCookie(currentDeviceId);

      yield call(fetchRegistrationCardIdForDevice);
      yield delay(EREGCARD_POLLING_TIMEOUT);
    } catch (err) {
      yield put(actions.stopPollingRegistrationCard());
    }
  }
}

function* startPollingRegistrationCard(): SagasGenerator {
  while (true) {
    yield take(actions.startPollingRegistrationCard);
    yield race([call(pollingTask), take(actions.stopPollingRegistrationCard)]);
  }
}

function* updatePropertyId(action: Action<string | null>): SagasGenerator {
  if (action.payload) {
    yield Storage.savePropertyId(action.payload);
  } else {
    yield Storage.deletePropertyId();
  }
}

function* updateDeviceCookie(action: Action<string | null>): SagasGenerator {
  if (action.payload) {
    yield Storage.saveDeviceCookie(action.payload);
  } else {
    yield Storage.deleteDeviceCookie();
  }
}

function* restartApplication(): SagasGenerator {
  try {
    yield put(actions.setCurrentProperty(null));
    yield put(actions.setCurrentDevice(null));
    yield Logger.forceSend();

    SessionDataHost.clearSessionData();
  } finally {
    window.location.reload();
  }
}

export function* appSagas(): SagasGenerator {
  yield takeLatest(actions.setCurrentDevice, updateDeviceCookie);
  yield takeLatest(actions.setCurrentProperty, updatePropertyId);
  yield takeLatest(actions.selectProperty.trigger, selectProperty);
  yield takeLatest(actions.prepareApplication.trigger, prepareApplication);
  yield takeLatest(actions.prepareEnvironment.trigger, prepareEnvironment);
  yield takeLatest(actions.restartApplication, restartApplication);
  yield all([call(startPollingRegistrationCard)]);
}
