import i18next from 'i18next';

import { DATE_PICKER_DATE_FORMAT, DatePickerField } from '@ac/kiosk-components';
import {
  composeValidators,
  formFieldFactory,
  ValidationExpression,
} from '@ac/react-infrastructure';

import { DateManager } from 'utils/DateManager';
import {
  CompareDateType,
  createDateCompareValidator,
  mapFieldRenderProps,
} from 'utils/form';

import { BaseObject } from 'types/shared';

import { BasicFieldProvider, ComponentsData } from './basicFieldProvider';

const FormField = formFieldFactory<BaseObject>();

export class DateFieldProvider extends BasicFieldProvider<
  typeof FormField,
  typeof DatePickerField
> {
  private get formattedMinDateValue(): string | undefined {
    const { typeConstraints } = this.reservationHeaderDefinition;

    return typeConstraints?.minValue
      ? DateManager.getFormattedDate(
          typeConstraints.minValue,
          this.config.shortDateFormat
        )
      : undefined;
  }

  private get formattedMaxDateValue(): string | undefined {
    const { typeConstraints } = this.reservationHeaderDefinition;

    return typeConstraints?.maxValue
      ? DateManager.getFormattedDate(
          typeConstraints.maxValue,
          this.config.shortDateFormat
        )
      : undefined;
  }

  getValidationSchema(): ValidationExpression<BaseObject<string>> | undefined {
    const { typeConstraints } = this.reservationHeaderDefinition;

    const validators = [
      ...(typeConstraints?.minValue
        ? [
            createDateCompareValidator(
              i18next.t(
                'REGISTRATION_CARD_EDIT_COMPLEMENTARY.VALIDATION_MESSAGES.MIN_VALUE',
                {
                  value: this.formattedMinDateValue,
                }
              ),
              typeConstraints.minValue,
              CompareDateType.isSameOrBefore,
              DATE_PICKER_DATE_FORMAT
            ),
          ]
        : []),
      ...(typeConstraints?.maxValue
        ? [
            createDateCompareValidator(
              i18next.t(
                'REGISTRATION_CARD_EDIT_COMPLEMENTARY.VALIDATION_MESSAGES.MAX_VALUE',
                {
                  value: this.formattedMaxDateValue,
                }
              ),
              typeConstraints.maxValue,
              CompareDateType.isSameOrAfter,
              DATE_PICKER_DATE_FORMAT
            ),
          ]
        : []),
    ];

    return validators.length
      ? composeValidators<BaseObject>(...validators)
      : undefined;
  }

  getComponentsData(): ComponentsData<
    typeof FormField,
    typeof DatePickerField
  > {
    const { typeConstraints } = this.reservationHeaderDefinition;

    return {
      FormField,
      formFieldRenderPropsMapper: mapFieldRenderProps,
      Component: DatePickerField,
      componentProps: {
        label: this.reservationHeaderDefinition.displayName,
        fieldDisplayFormat: this.config.shortDateFormat,
        min: typeConstraints?.minValue as string | undefined,
        max: typeConstraints?.maxValue as string | undefined,
        allowClear: true,
        placeholder: this.config.shortDateFormat,
      },
    };
  }
}
