import { ThemeOptions } from '@ac/kiosk-components/dist/utils/public/KioskComponentsConfigurator/types';

import { StylesSettingsStorage } from '../interfaces';

import { getFontFamily } from './getFontFamily';

export const createCustomThemeVariables = (
  styles?: StylesSettingsStorage
): ThemeOptions => {
  const mainFont: string = getFontFamily(styles?.MAIN_FONT);

  return {
    '--primary-color': styles?.MAIN_COLOR,
    '--main-font': mainFont,

    '--body-background-color': styles?.BODY_BACKGROUND_COLOR,
    '--body-font-color': styles?.BODY_FONT_COLOR,

    '--footer-background-color': styles?.FOOTER_BACKGROUND_COLOR,
    '--footer-font-color': styles?.FOOTER_FONT_COLOR,

    '--header-background-color': styles?.HEADER_BACKGROUND_COLOR,
    '--header-font-color': styles?.HEADER_FONT_COLOR,

    '--hint-font-color': styles?.HINT_FONT_COLOR,

    '--screen-saver-message-font-color':
      styles?.SCREEN_SAVER_MESSAGE_FONT_COLOR,

    '--primary-button-active-background-color':
      styles?.PRIMARY_ACTIVE_BACKGROUND_COLOR,
    '--primary-button-active-border-color': styles?.PRIMARY_ACTIVE_BORDER_COLOR,
    '--primary-button-active-text-color': styles?.PRIMARY_ACTIVE_TEXT_COLOR,
    '--primary-button-clicked-background-color':
      styles?.PRIMARY_CLICKED_BACKGROUND_COLOR,
    '--primary-button-clicked-border-color':
      styles?.PRIMARY_CLICKED_BORDER_COLOR,
    '--primary-button-clicked-text-color': styles?.PRIMARY_CLICKED_TEXT_COLOR,
    '--primary-button-disabled-background-color':
      styles?.PRIMARY_DISABLED_BACKGROUND_COLOR,
    '--primary-button-disabled-border-color':
      styles?.PRIMARY_DISABLED_BORDER_COLOR,
    '--primary-button-disabled-text-color': styles?.PRIMARY_DISABLED_TEXT_COLOR,

    '--secondary-button-active-background-color':
      styles?.SECONDARY_ACTIVE_BACKGROUND_COLOR,
    '--secondary-button-active-border-color':
      styles?.SECONDARY_ACTIVE_BORDER_COLOR,
    '--secondary-button-active-text-color': styles?.SECONDARY_ACTIVE_TEXT_COLOR,
    '--secondary-button-clicked-background-color':
      styles?.SECONDARY_CLICKED_BACKGROUND_COLOR,
    '--secondary-button-clicked-border-color':
      styles?.SECONDARY_CLICKED_BORDER_COLOR,
    '--secondary-button-clicked-text-color':
      styles?.SECONDARY_CLICKED_TEXT_COLOR,
    '--secondary-button-disabled-background-color':
      styles?.SECONDARY_DISABLED_BACKGROUND_COLOR,
    '--secondary-button-disabled-border-color':
      styles?.SECONDARY_DISABLED_BORDER_COLOR,
    '--secondary-button-disabled-text-color':
      styles?.SECONDARY_DISABLED_TEXT_COLOR,

    '--tertiary-button-active-text-color':
      styles?.TERTIARY_ACTIVE_ACTION_FONT_COLOR,
    '--tertiary-button-clicked-text-color':
      styles?.TERTIARY_CLICKED_ACTION_FONT_COLOR,
    '--tertiary-button-disabled-text-color':
      styles?.TERTIARY_DISABLED_ACTION_FONT_COLOR,
  };
};
