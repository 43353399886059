import {
  KioskUpdateCommunicationChannels,
  KioskUpdateCommunicationDetails,
} from 'api/KioskApi/entries';
import { EmailsFormValues } from 'views/RegistrationCardEditPersonal/types';

import { prepareAddUpdateRemovePayload } from './prepareAddUpdateRemovePayload';

const mapSingleEmailToUpdatePayload = (
  emailFormData: EmailsFormValues
): KioskUpdateCommunicationDetails => {
  return {
    id: emailFormData.id,
    typeId: emailFormData.type,
    details: emailFormData.details,
    isPrimary: emailFormData.isPrimary || false,
  };
};

export const prepareEmailsUpdatePayload = (
  formData?: EmailsFormValues[],
  initialValues?: EmailsFormValues[]
): KioskUpdateCommunicationChannels | undefined => {
  const payload = prepareAddUpdateRemovePayload(
    mapSingleEmailToUpdatePayload,
    formData,
    initialValues
  );

  return Object.keys(payload).length ? payload : undefined;
};
