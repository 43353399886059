import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  PriceDetailsItem,
  PurchaseElementFieldType,
} from '@ac/kiosk-components';
import { AgeQualifyingCode } from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

import {
  KioskPriceDto,
  KioskPurchaseElementAvailabilityItemDto,
  KioskPurchaseElementCalculationRule,
  KioskPurchaseElementDailyPriceDto,
  KioskPurchaseElementPriceItemDto,
} from 'api/KioskApi/entries';
import { CurrencyFormatter } from 'services';
import {
  getAdultCount,
  getChildCount,
} from 'store/electronicRegistrationProcess/selectors';
import { getDateTimeFormats } from 'store/settings/selectors';
import { DateManager } from 'utils';
import { calculatePurchaseElementTotalPrice } from 'utils/domain/calculatePurchaseElementTotalPrice';

import {
  getCalculatedCurrentAvailability,
  getCalculatedInitialAvailability,
  getIncludedInventoryItems,
  getInventoryItemsPreselection,
  getPurchaseElementsImages,
} from '../store/selectors';
import { PurchaseElementsSelectorDataItem } from '../types';

export const useAvailablePurchaseElementsData = (): PurchaseElementsSelectorDataItem[] => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'REGISTRATION_CARD_PURCHASE_ELEMENTS.PRICE_DETAILS',
  });
  const purchaseElementImages = useSelector(getPurchaseElementsImages);
  const initialAvailability = useSelector(getCalculatedInitialAvailability);
  const currentAvailability = useSelector(getCalculatedCurrentAvailability);
  const includedInventoryItems = useSelector(getIncludedInventoryItems);
  const inventoryItemsPreselection = useSelector(getInventoryItemsPreselection);
  const dateTimeFormats = useSelector(getDateTimeFormats);
  const adultCount = useSelector(getAdultCount);
  const childCount = useSelector(getChildCount);

  const findGrossPrice = (
    dailyPrice?: KioskPurchaseElementDailyPriceDto,
    ageQualifyingCode?: AgeQualifyingCode
  ): KioskPriceDto | undefined => {
    if (!ageQualifyingCode) {
      return dailyPrice?.prices?.[0]?.price?.priceGross;
    }

    return dailyPrice?.prices?.find((ageBucketItem) => {
      return ageBucketItem.ageBucket?.ageQualifyingCode === ageQualifyingCode;
    })?.price?.priceGross;
  };

  const checkIsPriceTheSameEachDay = (
    purchaseElement: KioskPurchaseElementAvailabilityItemDto
  ): boolean => {
    let isPriceTheSameEachDay = false;

    if (
      purchaseElement.calculationRule ===
      KioskPurchaseElementCalculationRule.PerPerson
    ) {
      const firstDailyAdultPrice = findGrossPrice(
        purchaseElement.dailyPrices?.[0],
        AgeQualifyingCode.Adult
      )?.amount;

      const firstDailyChildPrice = findGrossPrice(
        purchaseElement.dailyPrices?.[0],
        AgeQualifyingCode.Child
      )?.amount;

      isPriceTheSameEachDay =
        purchaseElement.dailyPrices?.every((dailyPrice) => {
          const priceForAdult = findGrossPrice(
            dailyPrice,
            AgeQualifyingCode.Adult
          )?.amount;
          const priceForChild = findGrossPrice(
            dailyPrice,
            AgeQualifyingCode.Child
          )?.amount;

          const isAdultPriceTheSameAsFirstDay =
            priceForAdult === firstDailyAdultPrice;
          const isChildPriceTheSameAsFirstDay =
            priceForChild === firstDailyChildPrice;

          return isAdultPriceTheSameAsFirstDay || isChildPriceTheSameAsFirstDay;
        }) ?? false;
    } else {
      const allDailyPrices = purchaseElement.dailyPrices?.map(
        (dailyPrice) => findGrossPrice(dailyPrice)?.amount
      );
      isPriceTheSameEachDay =
        allDailyPrices?.every((price) => price === allDailyPrices[0]) ?? false;
    }

    return isPriceTheSameEachDay;
  };

  const checkIsPriceTheSameEachAgeBucket = (
    ageBucketPrices: KioskPurchaseElementPriceItemDto[]
  ): boolean => {
    const allPrices = ageBucketPrices.map(
      (price) => price.price?.priceGross?.amount
    );

    return allPrices.every((price) => price === allPrices[0]);
  };

  const getPerReservationPriceDetails = (
    purchaseElement: KioskPurchaseElementAvailabilityItemDto
  ): PriceDetailsItem[] | undefined => {
    if (!purchaseElement.dailyPrices) return;

    const postingNights = purchaseElement.postingDays?.length.toString();
    const isTheSamePricePerDay = checkIsPriceTheSameEachDay(purchaseElement);

    if (isTheSamePricePerDay) {
      const firstDayPrice = findGrossPrice(purchaseElement.dailyPrices[0]);

      return [
        {
          title: t('UNIT_PRICE'),
          value: CurrencyFormatter.format(firstDayPrice?.amount),
        },
        { title: t('NIGHTS'), value: postingNights },
      ];
    }

    return [
      {
        title: t('UNIT_PRICE'),
        details: purchaseElement.dailyPrices.map((dailyPrice) => ({
          title: dailyPrice.day,
          value: CurrencyFormatter.format(findGrossPrice(dailyPrice)?.amount),
        })),
      },
    ];
  };

  const getPerProfilePriceDetails = (
    purchaseElement: KioskPurchaseElementAvailabilityItemDto
  ): PriceDetailsItem[] | undefined => {
    if (!purchaseElement.dailyPrices?.[0]?.prices) return;

    const postingNights = purchaseElement.postingDays?.length.toString();
    const isTheSamePricePerDay = checkIsPriceTheSameEachDay(purchaseElement);
    const isSamePriceForEachAgeBucket = checkIsPriceTheSameEachAgeBucket(
      purchaseElement.dailyPrices[0].prices
    );

    if (isTheSamePricePerDay && isSamePriceForEachAgeBucket) {
      const firstDayPrice = findGrossPrice(
        purchaseElement.dailyPrices[0],
        AgeQualifyingCode.Adult
      )?.amount;

      return [
        {
          title: t('PRICE_PER_PERSON'),
          value: CurrencyFormatter.format(firstDayPrice),
        },
        { title: t('AGE_BUCKETS'), value: `${adultCount} / ${childCount}` },
        { title: t('NIGHTS'), value: postingNights },
      ];
    } else if (isTheSamePricePerDay) {
      const firstDayPriceForAdult = findGrossPrice(
        purchaseElement.dailyPrices[0],
        AgeQualifyingCode.Adult
      )?.amount;

      const firstDayPriceForChild = findGrossPrice(
        purchaseElement.dailyPrices[0],
        AgeQualifyingCode.Child
      )?.amount;

      return [
        {
          title: t('PRICE_PER_AGE_BUCKETS'),
          value: `${CurrencyFormatter.format(
            firstDayPriceForAdult
          )} / ${CurrencyFormatter.format(firstDayPriceForChild)}`,
        },
        { title: t('AGE_BUCKETS'), value: `${adultCount} / ${childCount}` },
        { title: t('NIGHTS'), value: postingNights },
      ];
    } else if (isSamePriceForEachAgeBucket) {
      return [
        {
          title: t('PRICE_PER_PERSON'),
          details: purchaseElement.dailyPrices.map((dailyPrice) => ({
            title: dailyPrice.day,
            value: CurrencyFormatter.format(findGrossPrice(dailyPrice)?.amount),
          })),
        },
        { title: t('AGE_BUCKETS'), value: `${adultCount} / ${childCount}` },
      ];
    } else {
      return [
        {
          title: t('PRICE_PER_AGE_BUCKETS'),
          details: purchaseElement.dailyPrices.map((dailyPrice) => {
            const adultPrice = CurrencyFormatter.format(
              findGrossPrice(dailyPrice, AgeQualifyingCode.Adult)?.amount
            );
            const childPrice = CurrencyFormatter.format(
              findGrossPrice(dailyPrice, AgeQualifyingCode.Child)?.amount
            );
            const priceValue =
              childPrice && childPrice !== adultPrice
                ? `${adultPrice} / ${childPrice}`
                : adultPrice;

            return {
              title: DateManager.getFormattedDate(
                dailyPrice.day,
                dateTimeFormats.shortDateFormat
              ),
              value: priceValue,
            };
          }),
        },
        { title: t('AGE_BUCKETS'), value: `${adultCount} / ${childCount}` },
      ];
    }
  };

  const getPriceDetails = (
    purchaseElement: KioskPurchaseElementAvailabilityItemDto
  ): PriceDetailsItem[] | undefined => {
    if (
      purchaseElement.calculationRule ===
      KioskPurchaseElementCalculationRule.PerPerson
    ) {
      return getPerProfilePriceDetails(purchaseElement);
    } else {
      return getPerReservationPriceDetails(purchaseElement);
    }
  };

  const getBasePrice = (
    purchaseElement: KioskPurchaseElementAvailabilityItemDto
  ): string | undefined => {
    const firstDayPrices = purchaseElement.dailyPrices?.[0];
    if (!firstDayPrices) return;

    const firstDayGrossPrice = findGrossPrice(
      firstDayPrices,
      purchaseElement.calculationRule ===
        KioskPurchaseElementCalculationRule.PerPerson
        ? AgeQualifyingCode.Adult
        : undefined
    );

    return CurrencyFormatter.format(firstDayGrossPrice?.amount);
  };

  return useMemo<PurchaseElementsSelectorDataItem[]>(() => {
    return (initialAvailability || []).map((element) => {
      const hasInventoryItemThatWasIncludedAlready =
        isDefined(element.inventoryItemId) &&
        Boolean(includedInventoryItems[element.inventoryItemId]?.quantity);

      const hasInventoryItemThatWasPreselectedAlready =
        isDefined(element.inventoryItemId) &&
        Boolean(
          inventoryItemsPreselection[element.inventoryItemId]?.quantity
        ) &&
        inventoryItemsPreselection[element.inventoryItemId]
          ?.purchaseElementId !== element.id;

      const currentAvailibilityData = currentAvailability.find(
        (item) => item.id === element.id
      );

      const notAvailableAlready =
        !currentAvailibilityData ||
        hasInventoryItemThatWasIncludedAlready ||
        hasInventoryItemThatWasPreselectedAlready;

      return {
        id: element.id,
        title: element.name,
        description: element.description,
        type:
          element.calculationRule ===
          KioskPurchaseElementCalculationRule.PerPerson
            ? PurchaseElementFieldType.perPerson
            : PurchaseElementFieldType.perReservation,
        hasImageInCard: true,
        image: purchaseElementImages[element.id],
        disabled: notAvailableAlready,
        basePrice: getBasePrice(element),
        max: currentAvailibilityData?.quantityAvailable,
        priceDetails: getPriceDetails(element),
        handleTotalPriceCalculation: element.dailyPrices
          ? (quantity: number): string => {
              return CurrencyFormatter.format(
                calculatePurchaseElementTotalPrice(
                  element,
                  quantity,
                  adultCount,
                  childCount
                )
              );
            }
          : undefined,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentAvailability,
    initialAvailability,
    purchaseElementImages,
    includedInventoryItems,
    inventoryItemsPreselection,
    adultCount,
    childCount,
  ]);
};
