import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, Text } from '@ac/kiosk-components';

import { View } from 'components';
import { paths } from 'configs';
import { prepareEnvironment } from 'store/app/actions';
import { getIsEnvironmentPrepared } from 'store/app/selectors';
import {
  receivedRegistrationCardForDevice,
  startElectronicRegistrationProcess,
} from 'store/electronicRegistrationProcess/actions';
import { getIsElectronicRegistrationProcessInitialized } from 'store/electronicRegistrationProcess/selectors';
import { prepareSetup } from 'store/settings/actions';
import { getAreSettingsInitialized } from 'store/settings/selectors';
import { useRouter } from 'utils/hooks';

import { SameDevicePathParams } from 'types/pathParams';

import './SameDeviceEnvSetup.scss';

export const SameDeviceEnvSetup = (): JSX.Element => {
  const router = useRouter<SameDevicePathParams>();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isEnvironmentPrepared = useSelector(getIsEnvironmentPrepared);
  const areSettingsInitialized = useSelector(getAreSettingsInitialized);
  const isElectronicRegistrationProcessInitialized = useSelector(
    getIsElectronicRegistrationProcessInitialized
  );

  useEffect(() => {
    if (isEnvironmentPrepared) {
      dispatch(prepareSetup.trigger({ withDefaultLanguage: true }));
    } else {
      dispatch(
        prepareEnvironment.trigger({ propertyId: router.params.propertyId })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnvironmentPrepared]);

  useEffect(() => {
    if (!areSettingsInitialized) return;
    dispatch(receivedRegistrationCardForDevice(router.params.cardId));
    dispatch(startElectronicRegistrationProcess.trigger());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areSettingsInitialized]);

  useEffect(() => {
    if (!isElectronicRegistrationProcessInitialized) return;
    router.goTo(paths.REGISTRATION_CARD_SUMMARY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isElectronicRegistrationProcessInitialized]);

  return (
    <View>
      <div className="setup-view">
        <Loader />
        <Text className="spacing-top-xlg">{t('SHARED.AUTHORIZING')}</Text>
      </div>
    </View>
  );
};
