export interface RawKioskDictionaryEntryDetails {
  isActive: boolean;
  displaySequence?: number;
  id?: string;
  code?: string;
  name?: string;
  description?: string;
}

export class KioskDictionaryEntryDetails {
  public isActive: boolean;
  public displaySequence?: number;
  public id?: string;
  public code?: string;
  public name?: string;
  public description?: string;

  constructor(raw: RawKioskDictionaryEntryDetails) {
    this.isActive = raw.isActive;
    this.displaySequence = raw.displaySequence;
    this.id = raw.id;
    this.code = raw.code;
    this.name = raw.name;
    this.description = raw.description;
  }
}
