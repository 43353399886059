import { KioskIdentityDocument, KioskProfile } from 'api/KioskApi/entries';
import { SectionConfiguration } from 'store/settings/interfaces/settingTypes/sectionConfiguration';

import { validateAddresses } from './validateAddresses';
import { validateAlternateDetails } from './validateAlternateDetails';
import { validateDocuments } from './validateDocuments';
import { validateEmails } from './validateEmails';
import { validateMobiles } from './validateMobiles';
import { validatePersonalDetails } from './validatePersonalDetails';
import { validatePhones } from './validatePhones';

export interface ProfileDataValidationResult {
  personalDetails: boolean;
  alternateDetails: boolean;
  addresses: boolean;
  emails: boolean;
  mobiles: boolean;
  phones: boolean;
  documents: boolean;
}

export const validateProfileData = (
  profile: KioskProfile,
  nonExpiredDocuments: KioskIdentityDocument[] | undefined,
  fieldsConfiguration: SectionConfiguration
): ProfileDataValidationResult => {
  const {
    addressesObjectCollection,
    guestPersonalDetailsObject,
    guestAlternateNameObject,
    emailObjectCollection,
    mobileObjectCollection,
    phoneObjectCollection,
    identityDocumentObjectCollection,
  } = fieldsConfiguration;

  return {
    personalDetails: guestPersonalDetailsObject
      ? validatePersonalDetails(
          guestPersonalDetailsObject,
          profile.personalDetails
        )
      : true,
    alternateDetails:
      guestPersonalDetailsObject?.isEditable &&
      guestAlternateNameObject?.isEditable
        ? validateAlternateDetails(
            guestAlternateNameObject,
            profile.personalDetails?.preferredLanguageProfileDetails
          )
        : true,
    addresses: addressesObjectCollection
      ? validateAddresses(addressesObjectCollection, profile.addresses)
      : true,
    emails: emailObjectCollection
      ? validateEmails(emailObjectCollection, profile.emails)
      : true,
    mobiles: mobileObjectCollection
      ? validateMobiles(mobileObjectCollection, profile.mobiles)
      : true,
    phones: phoneObjectCollection
      ? validatePhones(phoneObjectCollection, profile.phones)
      : true,
    documents: identityDocumentObjectCollection
      ? validateDocuments(identityDocumentObjectCollection, nonExpiredDocuments)
      : true,
  };
};
