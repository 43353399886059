import { isDefined } from '@ac/library-utils/dist/utils';

import { KioskUpdateProfile } from 'api/KioskApi/entries';
import { EntitiesSettingsStorage } from 'store/settings/interfaces';

import { FormValues } from '../../types';

import { prepareAddressesUpdatePayload } from './prepareAddressesUpdatePayload';
import { prepareDocumentsUpdatePayload } from './prepareDocumentsUpdatePayload';
import { prepareEmailsUpdatePayload } from './prepareEmailsUpdatePayload';
import { preparePersonalDetailsUpdatePayload } from './preparePersonalDetailsUpdatePayload';
import { preparePhonesUpdatePayload } from './preparePhonesUpdatePayload';

export const prepareUpdateProfilePayload = (
  formValues: FormValues,
  initialFormValues: FormValues,
  entitiesDictionary?: EntitiesSettingsStorage
): KioskUpdateProfile | undefined => {
  const {
    addresses,
    emails,
    mobiles,
    phones,
    documents,
    personalDetails,
  } = formValues;

  const {
    addresses: initialAddress,
    emails: initialEmails,
    mobiles: initialMobiles,
    phones: initialPhones,
    documents: initialDocuments,
    personalDetails: initialPersonalDetails,
  } = initialFormValues;

  const updatePayload: KioskUpdateProfile = {
    personalDetails: preparePersonalDetailsUpdatePayload(
      personalDetails,
      initialPersonalDetails
    ),
    addresses: prepareAddressesUpdatePayload(addresses, initialAddress),
    emails: prepareEmailsUpdatePayload(emails, initialEmails),
    mobiles: preparePhonesUpdatePayload(
      mobiles,
      initialMobiles,
      entitiesDictionary?.telephoneRegionPrefixes
    ),
    phones: preparePhonesUpdatePayload(
      phones,
      initialPhones,
      entitiesDictionary?.telephoneRegionPrefixes
    ),
    identityDocuments: prepareDocumentsUpdatePayload(
      documents,
      initialDocuments
    ),
  };

  const areAnyPayloadDataUpdated = Boolean(
    Object.values(updatePayload).filter(isDefined).length
  );

  return areAnyPayloadDataUpdated ? updatePayload : undefined;
};
