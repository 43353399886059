import i18next from 'i18next';

import { Field } from '@ac/kiosk-components';
import {
  composeValidators,
  createExpressionValidator,
  formFieldFactory,
  ValidationExpression,
} from '@ac/react-infrastructure';

import {
  createLengthValidator,
  LengthValidityType,
  mapFieldRenderProps,
} from 'utils/form';

import { BaseObject } from 'types/shared';

import { BasicFieldProvider, ComponentsData } from './basicFieldProvider';

const FormField = formFieldFactory<BaseObject>();

export class StringFieldProvider extends BasicFieldProvider<
  typeof FormField,
  typeof Field
> {
  getValidationSchema(): ValidationExpression<BaseObject<string>> | undefined {
    const { typeConstraints } = this.reservationHeaderDefinition;

    const validators = [
      ...(typeConstraints?.regex
        ? [
            createExpressionValidator(
              i18next.t(
                'REGISTRATION_CARD_EDIT_COMPLEMENTARY.VALIDATION_MESSAGES.INVALID_PATTERN'
              ),
              typeConstraints.regex
            ),
          ]
        : []),
      ...(typeConstraints?.minLength
        ? [
            createLengthValidator(
              LengthValidityType.minLength,
              typeConstraints.minLength,
              i18next.t(
                'REGISTRATION_CARD_EDIT_COMPLEMENTARY.VALIDATION_MESSAGES.MIN_LENGTH',
                {
                  count: typeConstraints.minLength,
                }
              )
            ),
          ]
        : []),
      ...(typeConstraints?.maxLength
        ? [
            createLengthValidator(
              LengthValidityType.maxLength,
              typeConstraints.maxLength,
              i18next.t(
                'REGISTRATION_CARD_EDIT_COMPLEMENTARY.VALIDATION_MESSAGES.MAX_LENGTH',
                {
                  count: typeConstraints.maxLength,
                }
              )
            ),
          ]
        : []),
    ];

    return validators.length
      ? composeValidators<BaseObject>(...validators)
      : undefined;
  }

  getComponentsData(): ComponentsData<typeof FormField, typeof Field> {
    return {
      FormField,
      formFieldRenderPropsMapper: mapFieldRenderProps,
      Component: Field,
      componentProps: {
        label: this.reservationHeaderDefinition.displayName,
        placeholder: i18next.t('SHARED.FILL'),
        maxLength: 'none',
      },
    };
  }
}
