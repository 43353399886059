import { AgeQualifyingCode } from '@ac/library-api';

import {
  KioskPriceDto,
  KioskPurchaseElementAvailabilityItemDto,
  KioskPurchaseElementCalculationRule,
} from 'api/KioskApi/entries';

export const calculatePurchaseElementTotalPrice = (
  purchaseElement: KioskPurchaseElementAvailabilityItemDto,
  quantity: number,
  adultsAmount: number,
  childAmount: number
): KioskPriceDto => {
  const dailyPrices = purchaseElement.dailyPrices || [];
  const currency: string | undefined =
    dailyPrices[0]?.prices?.[0].price?.priceGross?.currency;
  const isPerReservation =
    purchaseElement.calculationRule ===
    KioskPurchaseElementCalculationRule.PerReservation;

  const purchaseElementUnitSum = dailyPrices.reduce(
    (totalSumPrice, dayPriceDetails) => {
      const dayPrice = dayPriceDetails.prices?.reduce(
        (daySumPrice, ageBucketPriceDetails) => {
          const guestOfAgeBucketAmount = isPerReservation
            ? 1
            : ageBucketPriceDetails.ageBucket?.ageQualifyingCode ===
              AgeQualifyingCode.Child
            ? childAmount
            : adultsAmount;

          const ageBucketPrice =
            ageBucketPriceDetails.price?.priceGross?.amount;

          return daySumPrice + (ageBucketPrice ?? 0) * guestOfAgeBucketAmount;
        },
        0
      );

      return totalSumPrice + (dayPrice ?? 0);
    },
    0
  );

  return {
    amount: purchaseElementUnitSum * quantity,
    currency,
  };
};
