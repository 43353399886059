import i18next from 'i18next';
import { call, select } from 'redux-saga/effects';

import {
  convertImageTo,
  ImageMimeTypes,
  PDFCreator,
} from '@ac/kiosk-components';
import {
  getSafeExternalContent,
  isDefined,
} from '@ac/library-utils/dist/utils';

import {
  KioskAddress,
  KioskCommunicationChannel,
  KioskCommunicationType,
  KioskConfigurationConsent,
  KioskConfigurationProperty,
  KioskDictionaryEntryDetails,
  KioskIdentityDocument,
  KioskRegCardDetails,
  KioskReservation,
  KioskReservationHeaderDefinition,
} from 'api/KioskApi/entries';
import {
  CustomMessagesSettingsStorage,
  DateTimeFormats,
  FeatureTogglesStorage,
  GeneralSettingsStorage,
  ImagesSettingsStorage,
} from 'store/settings/interfaces';
import { SectionConfiguration } from 'store/settings/interfaces/settingTypes/sectionConfiguration';
import {
  getAddressTypeEntities,
  getCommunicationModeOrder,
  getCommunicationTypeEntities,
  getCustomMessages,
  getDateTimeFormats,
  getFeatureToggles,
  getFieldsConfiguration,
  getGeneralSettings,
  getImages,
  getIsPersonalDetailsVisible,
  getPropertyConfiguration,
  getReservationHeaderDefinition,
} from 'store/settings/selectors';
import { blobToBase64, DateManager, objectUrlToBlob } from 'utils';
import {
  AdditionalDetailsPresentationDataItem,
  mapAddressData,
  mapComplementaryData,
  mapConsentsData,
  mapContactsData,
  mapDocumentsData,
  mapPersonalData,
  mapPreferenceSectionData,
  mapReservationData,
  ProfilePresentationDataElement,
  ReservationPresentationDataElement,
} from 'utils/regCardPresentationDataMappers';
import { mapStayEnhancements } from 'utils/regCardPresentationDataMappers/mapStayEnhancements';
import { mapAddressData as mapAddressDataV2 } from 'utils/regCardPresentationDataMappers/v2/mapAddressData';
import { mapContactsData as mapContactsDataV2 } from 'utils/regCardPresentationDataMappers/v2/mapContactsData';
import { mapDocumentsData as mapDocumentsDataV2 } from 'utils/regCardPresentationDataMappers/v2/mapDocumentsData';
import { mapPersonalData as mapPersonalDataV2 } from 'utils/regCardPresentationDataMappers/v2/mapPersonalData';

import { SagasGenerator } from 'types/shared';

import { PreferenceOptionsGroup } from '../interfaces/preferenceOptions/preferenceOptionsGroup';
import { SessionPurchaseElementDetails } from '../interfaces/SessionPurchaseElementDetails';
import {
  getAddedInSessionPurchaseElements,
  getAdultCount,
  getChildCount,
  getIsPurchaseElementSelectionEnabled,
  getPreferencesGroupOptions,
  getRegistrationCardDetails,
  getSummaryConsents,
} from '../selectors';
import {
  getSortedProfileAddresses,
  getSortedProfileCommunicationChannels,
  getValidProfileDocuments,
} from '../selectors/profile';
import { getEtd, getReservation } from '../selectors/reservation';
import {
  createRegistrationCardPdfContent,
  RegistrationCardPdfConfig,
  RegistrationCardPdfData,
} from '../utils/createRegistrationCardPdfContent';

function* getReservationData(): SagasGenerator<ReservationPresentationDataElement> {
  const reservation: KioskReservation | undefined = yield select(
    getReservation
  );
  const dateTimeFormats: DateTimeFormats = yield select(getDateTimeFormats);
  const generalSettings: GeneralSettingsStorage | undefined = yield select(
    getGeneralSettings
  );
  const fieldsConfiguration: SectionConfiguration | undefined = yield select(
    getFieldsConfiguration
  );
  const featureToggles: FeatureTogglesStorage | undefined = yield select(
    getFeatureToggles
  );

  if (!reservation) {
    return {
      sections: [],
    };
  }

  const reservationData = mapReservationData(reservation, {
    longDateFormat: dateTimeFormats?.longDateFormat,
    timeFormat: dateTimeFormats?.timeFormat,
    isMembershipEnabled: generalSettings?.DISPLAY_MEMBERSHIP,
    fieldsConfiguration: featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
      ? fieldsConfiguration?.reservationDetailsObject
      : undefined,
  });

  return reservationData;
}

function* getPersonalData(): SagasGenerator<ProfilePresentationDataElement[]> {
  const cardDetails: KioskRegCardDetails = yield select(
    getRegistrationCardDetails
  );
  const dateTimeFormats: DateTimeFormats = yield select(getDateTimeFormats);
  const generalSettings: GeneralSettingsStorage | undefined = yield select(
    getGeneralSettings
  );
  const fieldsConfiguration: SectionConfiguration | undefined = yield select(
    getFieldsConfiguration
  );
  const featureToggles: FeatureTogglesStorage | undefined = yield select(
    getFeatureToggles
  );

  const personalData = featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
    ? mapPersonalDataV2(cardDetails, {
        fieldsConfiguration,
        shortDateFormat: dateTimeFormats?.shortDateFormat,
      })
    : mapPersonalData(cardDetails, {
        shortDateFormat: dateTimeFormats?.shortDateFormat,
        isMiddleNameEnabled: generalSettings?.MIDDLENAME,
        isSuffixEnabled: generalSettings?.SUFFIX,
      });

  return personalData;
}

function* getAddressesData(): SagasGenerator<ProfilePresentationDataElement[]> {
  const addresses: KioskAddress[] | undefined = yield select(
    getSortedProfileAddresses
  );
  const addressTypes: KioskDictionaryEntryDetails[] | undefined = yield select(
    getAddressTypeEntities
  );
  const generalSettings: GeneralSettingsStorage | undefined = yield select(
    getGeneralSettings
  );
  const fieldsConfiguration: SectionConfiguration | undefined = yield select(
    getFieldsConfiguration
  );
  const addressfieldsConfiguration =
    fieldsConfiguration?.addressesObjectCollection;
  const featureToggles: FeatureTogglesStorage | undefined = yield select(
    getFeatureToggles
  );

  const addressesData = featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
    ? mapAddressDataV2(addresses, {
        fieldConfiguration: addressfieldsConfiguration,
        showOnlyPrimary: true,
      })
    : mapAddressData(addresses, addressTypes, {
        hideDistrict: !generalSettings?.DISTRICT,
        showOnlyPrimary: true,
      });

  return addressesData;
}

function* getContactsData(): SagasGenerator<ProfilePresentationDataElement[]> {
  const cardDetails: KioskRegCardDetails = yield select(
    getRegistrationCardDetails
  );
  const communicationTypes: KioskCommunicationType[] | undefined = yield select(
    getCommunicationTypeEntities
  );
  const communicationModeOrder: string[] = yield select(
    getCommunicationModeOrder
  );
  const communicationChannels:
    | KioskCommunicationChannel[]
    | undefined = yield select(getSortedProfileCommunicationChannels);
  const generalSettings: GeneralSettingsStorage | undefined = yield select(
    getGeneralSettings
  );
  const fieldsConfiguration: SectionConfiguration | undefined = yield select(
    getFieldsConfiguration
  );
  const featureToggles: FeatureTogglesStorage | undefined = yield select(
    getFeatureToggles
  );

  const contactsData = featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
    ? mapContactsDataV2(communicationChannels, {
        showOnlyPrimary: true,
        emailFieldsConfiguration: fieldsConfiguration?.emailObjectCollection,
        mobileFieldsConfiguration: fieldsConfiguration?.mobileObjectCollection,
        phoneFieldsConfiguration: fieldsConfiguration?.phoneObjectCollection,
      })
    : mapContactsData(
        {
          phones: cardDetails.profile.phones,
          mobiles: cardDetails.profile.mobiles,
          emails: cardDetails.profile.emails,
        },
        {
          modeOrder: communicationModeOrder,
          isEmailEnabled: generalSettings?.DISPLAY_EMAIL,
          isMobileEnabled: generalSettings?.DISPLAY_MOBILE,
          isPhoneEnabled: generalSettings?.DISPLAY_PHONE,
          communicationTypes,
          showOnlyPrimary: true,
        }
      );

  return contactsData;
}

function* getDocumentsData(): SagasGenerator<ProfilePresentationDataElement[]> {
  const identityDocuments: KioskIdentityDocument[] | undefined = yield select(
    getValidProfileDocuments
  );
  const dateTimeFormats: DateTimeFormats = yield select(getDateTimeFormats);
  const propertyConfiguration:
    | KioskConfigurationProperty
    | undefined = yield select(getPropertyConfiguration);
  const fieldsConfiguration: SectionConfiguration | undefined = yield select(
    getFieldsConfiguration
  );
  const featureToggles: FeatureTogglesStorage | undefined = yield select(
    getFeatureToggles
  );

  const documentsData = featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
    ? mapDocumentsDataV2(identityDocuments, {
        fieldsConfiguration:
          fieldsConfiguration?.identityDocumentObjectCollection,
        shortDateFormat: dateTimeFormats?.shortDateFormat,
      })
    : mapDocumentsData(identityDocuments, {
        shortDateFormat: dateTimeFormats?.shortDateFormat,
        businessDate: propertyConfiguration?.businessDate,
      });

  return documentsData;
}

function* getAdditionalDetailsData(): SagasGenerator<
  AdditionalDetailsPresentationDataItem[]
> {
  const etd: string | undefined = yield select(getEtd);
  const reservation: KioskReservation | undefined = yield select(
    getReservation
  );
  const dateTimeFormats: DateTimeFormats = yield select(getDateTimeFormats);
  const fieldsConfiguration: SectionConfiguration | undefined = yield select(
    getFieldsConfiguration
  );
  const featureToggles: FeatureTogglesStorage | undefined = yield select(
    getFeatureToggles
  );
  const generalSettings: GeneralSettingsStorage | undefined = yield select(
    getGeneralSettings
  );
  const reservationDetailsObject =
    fieldsConfiguration?.reservationDetailsObject;

  const isPurposeOfStayEnabled = featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
    ? reservationDetailsObject?.purposeOfStayField?.isVisible
    : generalSettings?.DISPLAY_PURPOSE_OF_STAY;

  const isEtdEnabled = featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
    ? reservationDetailsObject?.estimatedTimeOfDepartureField?.isVisible
    : true;

  return [
    isPurposeOfStayEnabled
      ? {
          label: i18next.t(
            'COMPONENTS.ADDITIONAL_DETAILS_SECTION.PURPOSE_OF_STAY'
          ),
          value: reservation?.purposeOfStay?.description,
        }
      : undefined,
    isEtdEnabled
      ? {
          label: i18next.t('COMPONENTS.ADDITIONAL_DETAILS_SECTION.ETD'),
          value:
            etd &&
            DateManager.getFormattedTime(etd, dateTimeFormats?.timeFormat),
        }
      : undefined,
  ].filter(isDefined);
}

function* getPurchaseElementsData(): SagasGenerator<string[] | undefined> {
  const addedInSessionPurchaseElements: SessionPurchaseElementDetails[] = yield select(
    getAddedInSessionPurchaseElements
  );
  const adultCount: number = yield select(getAdultCount);
  const childCount: number = yield select(getChildCount);

  const purchaseElements = mapStayEnhancements(
    addedInSessionPurchaseElements,
    adultCount,
    childCount
  )?.map((item) => {
    const totalPrice = `${i18next.t(
      'REGISTRATION_CARD_PURCHASE_ELEMENTS.TOTAL_PRICE'
    )} ${item.price}`;

    const itemsCounter = i18next.t('REGISTRATION_CARD_PURCHASE_ELEMENTS.ITEM', {
      count: item.amount,
    });

    return !!item.amount && item.amount > 1
      ? `${item.name} (${itemsCounter}, ${totalPrice})`
      : `${item.name} (${totalPrice})`;
  });

  return purchaseElements;
}

function* getLogoBase64(): SagasGenerator<string | undefined> {
  const images: ImagesSettingsStorage | undefined = yield select(getImages);

  const logoBlob: Blob | undefined = images?.LOGO
    ? yield objectUrlToBlob(images.LOGO)
    : undefined;

  const convertedLogo: Blob | undefined =
    logoBlob && logoBlob.type === ImageMimeTypes.PNG
      ? yield convertImageTo(logoBlob, ImageMimeTypes.JPEG)
      : logoBlob;

  const logoBase64: string | undefined = convertedLogo
    ? yield blobToBase64(convertedLogo)
    : undefined;

  return logoBase64;
}

function* getPDFPresentationData(
  signatureBlob: Blob
): SagasGenerator<RegistrationCardPdfData> {
  const consents: KioskConfigurationConsent[] | undefined = yield select(
    getSummaryConsents
  );
  const reservationHeaderDefinition:
    | KioskReservationHeaderDefinition[]
    | undefined = yield select(getReservationHeaderDefinition);

  const propertyConfiguration:
    | KioskConfigurationProperty
    | undefined = yield select(getPropertyConfiguration);
  const customMessages:
    | CustomMessagesSettingsStorage
    | undefined = yield select(getCustomMessages);
  const dateTimeFormats: DateTimeFormats = yield select(getDateTimeFormats);
  const generalSettings: GeneralSettingsStorage | undefined = yield select(
    getGeneralSettings
  );

  const cardDetails: KioskRegCardDetails = yield select(
    getRegistrationCardDetails
  );

  const preferencesOptions: PreferenceOptionsGroup[] = yield select(
    getPreferencesGroupOptions
  );

  const date = DateManager.getFormattedDate(
    propertyConfiguration?.businessDate,
    dateTimeFormats?.shortDateFormat
  );
  const time = DateManager.getFormattedTime(
    new Date(),
    dateTimeFormats?.timeFormat
  );

  const reservationData: ReservationPresentationDataElement = yield call(
    getReservationData
  );
  const personalData: ProfilePresentationDataElement[] = yield call(
    getPersonalData
  );
  const addressesData: ProfilePresentationDataElement[] = yield call(
    getAddressesData
  );
  const contactsData: ProfilePresentationDataElement[] = yield call(
    getContactsData
  );
  const documentsData: ProfilePresentationDataElement[] = yield call(
    getDocumentsData
  );
  const additionalDetailsData: AdditionalDetailsPresentationDataItem[] = yield call(
    getAdditionalDetailsData
  );
  const purchaseElementsData: string[] | undefined = yield call(
    getPurchaseElementsData
  );

  const logoBase64: string | undefined = yield call(getLogoBase64);
  const signatureBase64: string = yield blobToBase64(signatureBlob);

  const pdfPresentationData: RegistrationCardPdfData = {
    reservationSubsections: reservationData,
    personalSubsection: personalData,
    addressSection: addressesData,
    contactSection: contactsData,
    documentSubsenctions: documentsData,
    complementaryDetails: mapComplementaryData(
      cardDetails.reservation.reservationHeader,
      reservationHeaderDefinition,
      {
        shortDateFormat: dateTimeFormats?.shortDateFormat,
        timeFormat: dateTimeFormats?.timeFormat,
        enabledCustomFields: generalSettings?.RESERVATION_HEADER_CUSTOM_FIELDS,
      }
    ),
    preferenceSection: mapPreferenceSectionData(preferencesOptions),
    consentsSection: mapConsentsData(cardDetails.profile.consents, consents),
    additionalDetailsSection: additionalDetailsData,
    disclaimer: getSafeExternalContent(customMessages?.DISCLAIMER),
    registrationCardNumber: cardDetails.confirmationNumber,
    creationDateTime: `${date} ${time}`,
    signature: signatureBase64,
    logo: logoBase64,
    consentsBodySection: consents,
    purchaseElements: purchaseElementsData,
  };

  return pdfPresentationData;
}

function* getPDFConfig(): SagasGenerator<RegistrationCardPdfConfig> {
  const generalSettings: GeneralSettingsStorage | undefined = yield select(
    getGeneralSettings
  );

  const fieldsConfiguration: SectionConfiguration | undefined = yield select(
    getFieldsConfiguration
  );

  const featureToggles: FeatureTogglesStorage | undefined = yield select(
    getFeatureToggles
  );

  const isPersonalDetailsVisible: boolean = yield select(
    getIsPersonalDetailsVisible
  );

  const isPurchaseElementSelectionEnabled = yield select(
    getIsPurchaseElementSelectionEnabled
  );

  const pdfConfig: RegistrationCardPdfConfig = {
    isPersonalSectionEnabled: isPersonalDetailsVisible,
    isPersonalDetilsEnabled: featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
      ? fieldsConfiguration?.guestPersonalDetailsObject?.isVisible ?? true
      : true,
    isAddressEnabled: featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
      ? fieldsConfiguration?.addressesObjectCollection?.isVisible ?? true
      : generalSettings?.DISPLAY_ADDRESS ?? true,
    isContactEnabled: featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
      ? (fieldsConfiguration?.emailObjectCollection?.isVisible ||
          fieldsConfiguration?.phoneObjectCollection?.isVisible ||
          fieldsConfiguration?.mobileObjectCollection?.isVisible) ??
        true
      : (generalSettings?.DISPLAY_PHONE ||
          generalSettings?.DISPLAY_MOBILE ||
          generalSettings?.DISPLAY_EMAIL) ??
        false,
    isDocumentEnabled: featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
      ? fieldsConfiguration?.identityDocumentObjectCollection?.isVisible ?? true
      : true,
    isAdditionalDetailsEnabled: featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
      ? fieldsConfiguration?.reservationDetailsObject?.isVisible
      : true,
    isComplementaryDetailsAvailable: Boolean(
      generalSettings?.RESERVATION_HEADER_CUSTOM_FIELDS?.length
    ),
    isPrintConsentDescription: generalSettings?.PRINT_CONSENT_DESCRIPTION,
    isPreferencesEnabled: generalSettings?.DISPLAY_PREFERENCES,
    isPurchaseElementSelectionEnabled,
  };

  return pdfConfig;
}

export function* createPDF(signature: Blob): SagasGenerator<Blob> {
  const pdfPresentationData: RegistrationCardPdfData = yield call(
    getPDFPresentationData,
    signature
  );
  const pdfConfig: RegistrationCardPdfConfig = yield call(getPDFConfig);
  const pdfContent: string = createRegistrationCardPdfContent(
    pdfPresentationData,
    pdfConfig
  );

  const pdfBlob: Blob = yield new PDFCreator().createPDF(
    pdfContent,
    i18next.dir()
  );

  return pdfBlob;
}
