export const extendDataByDisplaySequence = <
  TData extends { type?: { code?: string | undefined } },
  TDictionary extends {
    code?: string | undefined;
    displaySequence?: number | undefined;
  }
>(
  data: TData[],
  dictionary: TDictionary[]
): Array<TData & { displaySequence?: number }> => {
  return data.map((record) => {
    const displaySequence = dictionary.find(
      (type) => type.code === record.type?.code
    )?.displaySequence;

    return {
      ...record,
      ...(displaySequence ? { displaySequence } : {}),
    };
  });
};
