import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  ButtonPattern,
  Flex,
  FlexDirection,
  IconTypes,
  Text,
  TextSize,
} from '@ac/kiosk-components';

import { getFieldsConfiguration } from 'store/settings/selectors';
import { useSubForm } from 'utils/form/subFormApi';
import { useMobilesInitialValues } from 'views/RegistrationCardEditPersonalV2/hooks/useFormInitialValues/useMobilesInitialValues';
import {
  EntityType,
  useRemoveEntity,
} from 'views/RegistrationCardEditPersonalV2/hooks/useRemoveEntity';
import { useSetEntityAsMain } from 'views/RegistrationCardEditPersonalV2/hooks/useSetEntityAsMain';
import {
  FormProperties,
  PhonesFormValues,
} from 'views/RegistrationCardEditPersonalV2/types';

import { ContactMobilesFormFieldCollection } from './components/MobilesFormFields';
import { SingleMobileSubForm } from './components/SingleMobileSubForm';

const MOBILES_PATH: [FormProperties.mobiles] = [FormProperties.mobiles];

interface Props {
  dataTestSelector?: string;
}

export const MobilesSubForm = ({ dataTestSelector }: Props): JSX.Element => {
  const { t } = useTranslation();
  const setAsMain = useSetEntityAsMain();
  const removeEntity = useRemoveEntity(EntityType.Mobiles);
  const { prepareInitialEmptyMobile } = useMobilesInitialValues();
  const subFormState = useSubForm<PhonesFormValues[]>(MOBILES_PATH, {
    valid: true,
  });

  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const isMobileRequired =
    fieldsConfiguration?.mobileObjectCollection?.isRequired;

  return (
    <Box dataTestSelector={dataTestSelector}>
      <Text size={TextSize.lg} className="spacing-bottom-xlg">
        {t('REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.MOBILE.TITLE')}
      </Text>

      <ContactMobilesFormFieldCollection
        subscription={{}}
        valuePath={MOBILES_PATH}
      >
        {(fieldFieldArrayRenderProps): JSX.Element => {
          const mobilesAmount =
            subFormState.api.getState()?.values?.length ?? 0;
          const isTheOnlyOneEntity = mobilesAmount === 1;

          return (
            <>
              {mobilesAmount === 0 ? (
                <Text
                  dataTestSelector={dataTestSelector?.concat(
                    '-not-provided-description'
                  )}
                  className="spacing-bottom-xlg"
                >
                  {t(
                    'REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.MOBILE.NOT_PROVIDED'
                  )}
                </Text>
              ) : (
                <Flex direction={FlexDirection.column} className="gap-xlg">
                  {fieldFieldArrayRenderProps.fields.map(
                    (path, index, name) => (
                      <SingleMobileSubForm
                        key={name}
                        path={path}
                        dataTestSelector={dataTestSelector?.concat(
                          '-single-address'
                        )}
                        isHeaderHidden={isTheOnlyOneEntity && isMobileRequired}
                        onRemoveClick={(): void => {
                          removeEntity(
                            subFormState.api.getState(),
                            fieldFieldArrayRenderProps,
                            index
                          );
                        }}
                        onSetAsMainClick={(): void => {
                          setAsMain(
                            subFormState.api.getState(),
                            fieldFieldArrayRenderProps,
                            index
                          );
                        }}
                      />
                    )
                  )}
                </Flex>
              )}
              <Button
                icon={IconTypes.plus}
                pattern={ButtonPattern.tertiary}
                disabled={!subFormState.valid}
                dataTestSelector={dataTestSelector?.concat(
                  '-add-additional-button'
                )}
                onClick={(): void =>
                  fieldFieldArrayRenderProps.fields.push(
                    prepareInitialEmptyMobile(!mobilesAmount)
                  )
                }
              >
                {t(
                  !mobilesAmount
                    ? 'REGISTRATION_CARD_EDIT_PERSONAL.CONTACT.MOBILE.ADD_NEW_BUTTON'
                    : 'REGISTRATION_CARD_EDIT_PERSONAL.SHARED.ADD_NEXT'
                )}
              </Button>
            </>
          );
        }}
      </ContactMobilesFormFieldCollection>
    </Box>
  );
};
