export enum FormProperties {
  purposeOfStay = 'purposeOfStay',
  etd = 'etd',
  termsAndCondition = 'termsAndCondition',
  preferences = 'preferences',
}

export interface FormValues {
  [FormProperties.purposeOfStay]: string | undefined;
  [FormProperties.etd]: string | undefined;
  [FormProperties.termsAndCondition]: {
    [key: string]: boolean;
  };
  [FormProperties.preferences]: {
    [attributeGroupCode: string]: {
      [attributeKey: string]: boolean;
    };
  };
}
