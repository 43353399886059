import { Checkbox } from '@ac/kiosk-components';
import { formSingleCheckboxFieldFactory } from '@ac/react-infrastructure';

import { mapCheckboxFieldRenderProps } from 'utils/form';

import { BaseObject } from 'types/shared';

import { BasicFieldProvider, ComponentsData } from './basicFieldProvider';

const FormField = formSingleCheckboxFieldFactory<BaseObject>();

export class BooleanFieldProvider extends BasicFieldProvider<
  typeof FormField,
  typeof Checkbox
> {
  getComponentsData(): ComponentsData<typeof FormField, typeof Checkbox> {
    return {
      FormField,
      formFieldRenderPropsMapper: mapCheckboxFieldRenderProps,
      Component: Checkbox,
      componentProps: {
        label: this.reservationHeaderDefinition.displayName,
      },
    };
  }
}
