import { getFactory, getFileFactory, postFactory } from '@ac/library-api';
import {
  getRequester,
  postRequester,
} from '@ac/library-api/dist/api/requester';

import { ImageSizeQueryParams } from './entries/shared/queryParams';
import {
  KioskPurchaseElementAvailabilityItemDto,
  KioskRegCardDetails,
  KioskStartProcess,
  KioskUpdateConsents,
  KioskUpdateProfile,
  KioskUpdatePurchaseElementsDto,
  KioskUpdateReservationHeader,
  KioskUpdateStay,
  PurchaseElementContentImagePathParams,
  RawKioskRegCardDetails,
} from './entries';

const path = {
  start: '/kiosk/v0/electronic-registration/process/start',
  regCard: '/kiosk/v0/electronic-registration/process/registration-card',
  cancel: '/kiosk/v0/electronic-registration/process/cancel',
  updateStay: '/kiosk/v0/electronic-registration/process/update-stay',
  updateConsents: '/kiosk/v0/electronic-registration/process/update-consents',
  updateProfile: '/kiosk/v0/electronic-registration/process/update-profile',
  uploadSignedCard:
    '/kiosk/v0/electronic-registration/process/upload-signed-card',
  updateReservationHeader:
    '/kiosk/v0/electronic-registration/process/update-reservation-header',
  availablePurchaseElements:
    '/kiosk/v0/electronic-registration/process/available-purchase-elements',
  purchaseElementContentImage:
    '/kiosk/v0/electronic-registration/process/purchase-elements/{purchaseElementId}/content/image/{imageId}',
  addPurchaseElement:
    '/kiosk/v0/electronic-registration/process/add-purchase-elements',
};

export const ApiCacheKeyProcessRegCard = 'KioskApiCacheKeyProcessRegCard';
export const ApiCacheKeyProcessRegCardPurchaseElements =
  'KioskApiCacheKeyProcessRegCardPurchaseElements';

export const startElectronicRegistrationProcess = postFactory<
  undefined,
  KioskStartProcess
>(path.start);

export const getProcessRegCardDetails = getFactory<
  undefined,
  RawKioskRegCardDetails,
  KioskRegCardDetails
>({
  path: path.regCard,
  cacheKey: ApiCacheKeyProcessRegCard,
  ctor: KioskRegCardDetails,
});

export const cancelElectronicRegistrationProcess = postFactory(path.cancel);

export const updateStay = postFactory<undefined, KioskUpdateStay>(
  path.updateStay
);

export const updateConsents = postFactory<undefined, KioskUpdateConsents>(
  path.updateConsents
);

export const updateProfile = postFactory<undefined, KioskUpdateProfile>(
  path.updateProfile
);

export const uploadSignedCard = postFactory<undefined, FormData>(
  path.uploadSignedCard
);

export const updateReservationHeader = postFactory<
  undefined,
  KioskUpdateReservationHeader
>(path.updateReservationHeader);

export const getAvailablePurchaseElements = getRequester<
  undefined,
  KioskPurchaseElementAvailabilityItemDto[]
>({
  path: path.availablePurchaseElements,
  cacheKey: ApiCacheKeyProcessRegCardPurchaseElements,
});

export const getPurchaseElementContentImage = getFileFactory<
  PurchaseElementContentImagePathParams,
  ImageSizeQueryParams
>({
  path: path.purchaseElementContentImage,
});

export const updatePurchaseElement = postRequester<
  undefined,
  KioskUpdatePurchaseElementsDto
>(path.addPurchaseElement);
