import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  KioskComponentsConfigurator,
  LogReporter,
  SelectField,
  SelectFieldType,
  ServiceModal,
} from '@ac/kiosk-components';
import { Form, formFieldFactory } from '@ac/react-infrastructure';

import {
  EXPORT_AUTH_LOGS_FILE_NAME,
  EXPORT_ERROR_LOGS_FILE_NAME,
  LOG_TYPES,
  paths,
} from 'configs';
import { Authorizer } from 'services';
import { setCurrentDevice } from 'store/app/actions';
import { getCurrentDeviceId, getCurrentUser } from 'store/app/selectors';
import { getPropertyConfiguration } from 'store/settings/selectors';
import { mapFieldRenderProps } from 'utils/form';
import { useRouter } from 'utils/hooks';
import { fetchDeviceList } from 'views/Login/store/actions';
import {
  getDeviceOptions,
  getFetchingDeviceState,
} from 'views/Login/store/selectors';

import './EnvironmentSettingsModal.scss';

export interface DeviceSelectionFormData {
  device: string;
}

const FormField = formFieldFactory<DeviceSelectionFormData>();

interface EnvironmentSettingsModalProps {
  onCancelClick?: () => void;
}

export const EnvironmentSettingsModal = ({
  onCancelClick,
}: EnvironmentSettingsModalProps): JSX.Element => {
  const router = useRouter();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const currentDeviceId = useSelector(getCurrentDeviceId);
  const areDevicesLoading = useSelector(getFetchingDeviceState);
  const deviceOptions = useSelector(getDeviceOptions);

  const isDeviceSelectionEnabled = useMemo(() => {
    return router.isCurrentPath(paths.EXTERNAL_DEVICE_ENTRY_PATH);
  }, [router]);

  const propertyName = useMemo(() => {
    if (!propertyConfiguration) return;

    const { name, code } = propertyConfiguration;

    return name && code ? `${name} (${code})` : name;
  }, [propertyConfiguration]);

  useEffect(() => {
    if (isDeviceSelectionEnabled) {
      dispatch(fetchDeviceList.trigger());
    }
  }, [isDeviceSelectionEnabled, dispatch]);

  const handleDeviceChange = useCallback(
    (formData: DeviceSelectionFormData) => {
      dispatch(setCurrentDevice(formData.device));
    },
    [dispatch]
  );

  return (
    <Form
      initialValues={{
        device: currentDeviceId,
      }}
      onSubmit={handleDeviceChange}
    >
      {(formRenderProps): JSX.Element => (
        <ServiceModal
          authLogsEnabled
          authLogs={LogReporter.getLogs(LOG_TYPES.auth)}
          errorLogsEnabled
          errorLogs={LogReporter.getLogs(LOG_TYPES.app)}
          onCancelClick={onCancelClick}
          onDebugModeClick={(): void =>
            KioskComponentsConfigurator.openConsoleSimulator()
          }
          onLogoutClick={Authorizer.logout}
          systemInfoForKpeEnabled
          exportAuthLogsFileName={EXPORT_AUTH_LOGS_FILE_NAME}
          exportErrorLogsFileName={EXPORT_ERROR_LOGS_FILE_NAME}
          userName={currentUser?.userName}
          propertyName={propertyName}
          onSaveClick={formRenderProps.handleSubmit}
          isSaveButtonDisabled={
            !isDeviceSelectionEnabled ||
            formRenderProps.invalid ||
            areDevicesLoading ||
            !formRenderProps.dirty
          }
        >
          {isDeviceSelectionEnabled && (
            <div className="env-settings-modal-device-selection">
              <FormField valuePath="device">
                {(deviceFormField): JSX.Element => (
                  <SelectField
                    {...mapFieldRenderProps(deviceFormField)}
                    className="env-settings-modal-device-field"
                    placeholder={t('LOGIN.FORM.SELECT_DEVICE_PLACEHOLDER')}
                    type={SelectFieldType.inline}
                    label="DEVICE SELECTION"
                    options={deviceOptions}
                    optionsLoading={areDevicesLoading}
                  />
                )}
              </FormField>
            </div>
          )}
        </ServiceModal>
      )}
    </Form>
  );
};
