import i18next from 'i18next';

import { isDefined } from '@ac/library-utils/dist/utils';

import {
  AdditionalNamesTypes,
  KioskRegCardDetails,
} from 'api/KioskApi/entries';
import { DateManager } from 'utils';
import { DEFAULT_DATE_FORMAT } from 'utils/DateManager';

import { ProfilePresentationDataElement } from './types';

export interface MapPersonalDataOptions {
  shortDateFormat?: string;
  isMiddleNameEnabled?: boolean;
  isSuffixEnabled?: boolean;
}

export const mapPersonalData = (
  data?: KioskRegCardDetails,
  options?: MapPersonalDataOptions
): ProfilePresentationDataElement[] => {
  if (!data) return [];

  const {
    firstName,
    lastName,
    birthday,
    middleName,
    preferredLanguageProfileDetails,
    nationality,
    suffix,
    title,
    additionalNames,
  } = data.profile.personalDetails || {};

  const companyName = data.reservation.linkedCompany?.name;
  const paymentMethods = data.account?.paymentMethods || [];
  const paymentTypes = paymentMethods
    .map((method) => {
      return [
        method.transactionCode?.parentTransactionCode?.description,
        method.transactionCode?.description,
      ]
        .filter(isDefined)
        .join(' - ');
    })
    .filter(Boolean);

  const configurationShortDateFormat =
    options?.shortDateFormat || DEFAULT_DATE_FORMAT;

  const parsedDateFormat = !birthday?.year
    ? configurationShortDateFormat.replace(/[^\w]?[yY]+[^\w]?/g, '')
    : configurationShortDateFormat;

  const birthdayDay =
    birthday &&
    DateManager.getFormattedDateWithConditionalYear(
      {
        ...birthday,
        month: birthday.month - 1,
      },
      parsedDateFormat
    );

  const altName =
    preferredLanguageProfileDetails &&
    [
      preferredLanguageProfileDetails.firstname,
      preferredLanguageProfileDetails.lastname,
    ]
      .filter(isDefined)
      .join(' ');

  const secondSurname = additionalNames?.find(
    (name) => name.type === AdditionalNamesTypes.SecondSurname
  );

  return [
    {
      sectionData: [
        {
          value: title?.description,
          label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.PERSON_TITLE'),
        },
        {
          value: lastName,
          label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.LAST_NAME'),
        },
        {
          value: secondSurname?.value,
          label: i18next.t(
            'COMPONENTS.PERSONAL_DETAILS_SECTION.SECOND_SURNAME'
          ),
        },
        {
          value: firstName,
          label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.FIRST_NAME'),
        },
        ...(options?.isMiddleNameEnabled
          ? [
              {
                value: middleName,
                label: i18next.t(
                  'COMPONENTS.PERSONAL_DETAILS_SECTION.MIDDLE_NAME'
                ),
              },
            ]
          : []),
        ...(altName
          ? [
              {
                value: altName,
                label: i18next.t(
                  'COMPONENTS.PERSONAL_DETAILS_SECTION.ALT_NAME'
                ),
              },
            ]
          : []),
        ...(options?.isSuffixEnabled
          ? [
              {
                value: suffix?.description,
                label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.SUFFIX'),
              },
            ]
          : []),
        {
          value: birthdayDay,
          label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.DATE_OF_BIRTH'),
        },
        {
          value: nationality?.name,
          label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.NATIONALITY'),
        },
        {
          value: companyName,
          label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.COMPANY_NAME'),
        },
        {
          value: paymentTypes,
          label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.PAYMENT_TYPE'),
          isInSeparateLine: true,
        },
      ],
    },
  ];
};
