import {
  BaseKioskConfigurationEntity,
  KioskPricingEntity,
  RawBaseKioskConfigurationEntity,
  RawKioskPricingEntity,
} from '../shared';

export interface RawKioskBreakdown {
  fromDate: string;
  toDate: string;
  adultCount: number;
  childCount: number;
  ratePlan?: RawBaseKioskConfigurationEntity;
  totalDailyPrice?: RawKioskPricingEntity;
}

export class KioskBreakdown {
  public fromDate: string;
  public toDate: string;
  public adultCount: number;
  public childCount: number;
  public ratePlan?: BaseKioskConfigurationEntity;
  public totalDailyPrice?: KioskPricingEntity;

  constructor(raw: RawKioskBreakdown) {
    this.fromDate = raw.fromDate;
    this.toDate = raw.toDate;
    this.adultCount = raw.adultCount;
    this.childCount = raw.childCount;
    this.ratePlan =
      raw.ratePlan && new BaseKioskConfigurationEntity(raw.ratePlan);
    this.totalDailyPrice =
      raw.totalDailyPrice && new KioskPricingEntity(raw.totalDailyPrice);
  }
}
