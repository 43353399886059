import { DATE_PICKER_DATE_FORMAT } from '@ac/kiosk-components';
import {
  createRequiredValidator,
  ValidationResult,
  ValidationSchema,
} from '@ac/react-infrastructure';

import { CompareDateType, createDateCompareValidator } from 'utils/form';

export interface DocumentValidationSchema {
  type: ValidationResult;
  number: ValidationResult;
  expiryDate: ValidationResult;
}

export const documentValidationSchema = (
  businessDate: string
): ValidationSchema<DocumentValidationSchema> => ({
  number: createRequiredValidator('VALIDATION.FIELD_IS_REQUIRED'),
  type: createRequiredValidator('VALIDATION.SELECT_TYPE'),
  expiryDate: createDateCompareValidator(
    'VALIDATION.DOCUMENT_EXPIRED',
    businessDate,
    CompareDateType.isSameOrBefore,
    DATE_PICKER_DATE_FORMAT
  ),
});
