import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getValidatedProfileData,
  getValidProfileDocuments,
} from 'store/electronicRegistrationProcess/selectors/profile';
import {
  getDateTimeFormats,
  getFeatureToggles,
  getFieldsConfiguration,
  getPropertyConfiguration,
} from 'store/settings/selectors';
import { mapDocumentsData } from 'utils/regCardPresentationDataMappers';
import { mapDocumentsData as mapDocumentsDataV2 } from 'utils/regCardPresentationDataMappers/v2/mapDocumentsData';

import { ProfilePresentationSectionData } from '../types';

export const useDocumentsConfiguration = (): ProfilePresentationSectionData => {
  const { i18n } = useTranslation();
  const featureToggles = useSelector(getFeatureToggles);
  const validatedProfileData = useSelector(getValidatedProfileData);
  const fieldsConfiguration = useSelector(getFieldsConfiguration);
  const dateTimeFormats = useSelector(getDateTimeFormats);
  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const identityDocuments = useSelector(getValidProfileDocuments);

  const documentsData = useMemo(
    () =>
      featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
        ? mapDocumentsDataV2(identityDocuments, {
            fieldsConfiguration:
              fieldsConfiguration?.identityDocumentObjectCollection,
            shortDateFormat: dateTimeFormats?.shortDateFormat,
          })
        : mapDocumentsData(identityDocuments, {
            shortDateFormat: dateTimeFormats?.shortDateFormat,
            businessDate: propertyConfiguration?.businessDate,
          }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      identityDocuments,
      featureToggles,
      i18n.language,
      propertyConfiguration,
      dateTimeFormats,
      fieldsConfiguration,
    ]
  );

  return useMemo(
    () => ({
      data: documentsData,
      isVisible: featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION
        ? fieldsConfiguration?.identityDocumentObjectCollection?.isVisible ??
          true
        : true,
      isMissingRequiredInformation:
        featureToggles?.INIT_3946_REG_CARD_CUSTOMIZATION && validatedProfileData
          ? !validatedProfileData.documents
          : false,
    }),
    [documentsData, featureToggles, fieldsConfiguration, validatedProfileData]
  );
};
