import {
  BaseKioskConfigurationEntity,
  KioskPreferredTitleDetails,
  RawBaseKioskConfigurationEntity,
  RawKioskPreferredTitleDetails,
} from 'api/KioskApi/entries';

export interface RawKioskPreferredLanguageDetails {
  firstname?: string;
  lastname?: string;
  title?: RawKioskPreferredTitleDetails;
  language?: RawBaseKioskConfigurationEntity;
}

export class KioskPreferredLanguageDetails {
  public firstname?: string;
  public lastname?: string;
  public title?: KioskPreferredTitleDetails;
  public language?: BaseKioskConfigurationEntity;

  constructor(raw: RawKioskPreferredLanguageDetails) {
    this.firstname = raw.firstname;
    this.lastname = raw.lastname;
    this.title = raw.title && new KioskPreferredTitleDetails(raw.title);
    this.language =
      raw.language && new BaseKioskConfigurationEntity(raw.language);
  }
}
