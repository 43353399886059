import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Loader, Text } from '@ac/kiosk-components';

import { View } from 'components';
import { paths } from 'configs';
import { stopPollingRegistrationCard } from 'store/app/actions';
import { prepareSetup } from 'store/settings/actions';
import { getAreSettingsInitialized } from 'store/settings/selectors';
import { useRouter } from 'utils/hooks';

import './Setup.scss';

export const Setup = (): JSX.Element => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const areSettingsInitialized = useSelector(getAreSettingsInitialized);

  useEffect(() => {
    if (areSettingsInitialized) {
      router.goTo(paths.EXTERNAL_DEVICE_ENTRY_PATH, { replace: true });
    } else {
      dispatch(stopPollingRegistrationCard());
      dispatch(prepareSetup.trigger({ withDefaultLanguage: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areSettingsInitialized]);

  return (
    <View>
      <div className="setup-view">
        <Loader />
        <Text className="spacing-top-xlg">{t('SHARED.SETTING_UP')}</Text>
      </div>
    </View>
  );
};
