import { useTranslation } from 'react-i18next';

import { InformationModal } from '@ac/kiosk-components';

interface Props {
  dataTestSelector?: string;
  onConfirm?: () => void;
}

export const AvailabilityErrorModal = ({
  dataTestSelector,
  onConfirm,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <InformationModal
      dataTestSelector={dataTestSelector}
      onConfirm={onConfirm}
      confirmButtonText={t(
        'REGISTRATION_CARD_PURCHASE_ELEMENTS.AVAILABILITY_ERROR_MODAL.CONFIRM_BUTTON'
      )}
      description={t(
        'translations:REGISTRATION_CARD_PURCHASE_ELEMENTS.AVAILABILITY_ERROR_MODAL.DESCRIPTION'
      )}
      title={t(
        'REGISTRATION_CARD_PURCHASE_ELEMENTS.AVAILABILITY_ERROR_MODAL.TITLE'
      )}
    />
  );
};
