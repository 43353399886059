import { KioskCustomMessageTranslationCollectionItem } from 'api/KioskApi/entries';

export const getTranslationContentFromCollection = (
  collection: KioskCustomMessageTranslationCollectionItem[],
  language: string
): string => {
  return (
    collection.find((item) => item.languageCode.toLowerCase() === language)
      ?.content ?? ''
  );
};
