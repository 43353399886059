import { createSelector } from 'reselect';

import { getAppErrors, getAppFetchState } from 'store/app/selectors';
import {
  getElectronicRegistrationProcessErrors,
  getElectronicRegistrationProcessFetchState,
} from 'store/electronicRegistrationProcess/selectors';
import {
  getSettingsErrors,
  getSettingsFetchState,
} from 'store/settings/selectors';

export const getGlobalErrors = createSelector(
  getAppErrors,
  getElectronicRegistrationProcessErrors,
  getSettingsErrors,
  (...args) => [...args].flat()
);

export const getGlobalStoreLoadingState = createSelector(
  getAppFetchState,
  getElectronicRegistrationProcessFetchState,
  getSettingsFetchState,
  (...args): boolean => args.includes(true)
);
