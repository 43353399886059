import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { paths } from 'configs/paths';
import {
  getCurrentDeviceId,
  getCurrentPropertyId,
  getIsEnvironmentPrepared,
} from 'store/app/selectors';

export const requirePropertyAndDevice = <Props extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<Props>
): React.ComponentType<Props> => {
  const PropertyAndDeviceValidation = (props: Props): JSX.Element => {
    const currentDeviceId = useSelector(getCurrentDeviceId);
    const currentPropertyId = useSelector(getCurrentPropertyId);
    const isEnvironmentPrepared = useSelector(getIsEnvironmentPrepared);

    if (!isEnvironmentPrepared || !(currentDeviceId && currentPropertyId)) {
      return <Navigate to={paths.LOGIN} replace />;
    }

    return <WrappedComponent {...props} />;
  };

  return PropertyAndDeviceValidation;
};
