import i18next from 'i18next';

import { KioskAddress } from 'api/KioskApi/entries';
import { EntitiesSettingsStorage } from 'store/settings/interfaces';
import { extendDataByDisplaySequence } from 'utils';
import { sortDisplayElements } from 'utils/sorting';
import { AddressFormValues } from 'views/RegistrationCardEditPersonal/types';

export const prepareAddressesInitialValues = (
  addresses: KioskAddress[],
  entities?: EntitiesSettingsStorage
): AddressFormValues[] => {
  const primary = addresses.filter((address) => address.isPrimary);
  const nonPrimary = addresses.filter((address) => !address.isPrimary);
  const sorted = entities?.addressTypes
    ? sortDisplayElements(
        extendDataByDisplaySequence(nonPrimary, entities.addressTypes)
      )
    : nonPrimary;

  return [...primary, ...sorted].map(prepareSingleAddressInitialValues);
};

export const prepareSingleAddressInitialValues = (
  address?: Partial<KioskAddress>
): AddressFormValues => ({
  id: address?.id,
  addressLine1: address?.addressLine1,
  addressLine2: address?.addressLine2,
  city: address?.city,
  country: address?.country?.code,
  postalCode: address?.postalCode,
  state: address?.state?.code,
  type: address?.type?.id,
  isPrimary: address?.isPrimary || false,
  languageCode: address?.language?.code || i18next.language.toUpperCase(),
  district: address?.district?.id,
});
