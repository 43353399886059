import { ValidationResult, ValidationSeverity } from '@ac/react-infrastructure';

export enum RangeValidityType {
  max,
  min,
}

export const createNumericStringRangeValidator = (
  validityType: RangeValidityType,
  constraint: number,
  description: string
) => (value: string | undefined): ValidationResult => {
  const numberValue = value ? parseFloat(value) : Number.NaN;
  if (isNaN(numberValue) || isNaN(Number(value))) return [];

  const isValid =
    validityType === RangeValidityType.min
      ? numberValue >= constraint
      : numberValue <= constraint;

  return isValid ? [] : [{ severity: ValidationSeverity.error, description }];
};
