import i18next from 'i18next';

import { isDefined } from '@ac/library-utils/dist/utils';

import {
  AdditionalNamesTypes,
  KioskRegCardDetails,
} from 'api/KioskApi/entries';
import { SectionConfiguration } from 'store/settings/interfaces/settingTypes/sectionConfiguration';
import { DateManager } from 'utils';
import { DEFAULT_DATE_FORMAT } from 'utils/DateManager';

import { ProfilePresentationDataElement } from '../types';

export interface PersonalDataVisibilityConfig {
  fieldsConfiguration?: SectionConfiguration;
  shortDateFormat?: string;
}

export const mapPersonalData = (
  data?: KioskRegCardDetails,
  settings?: PersonalDataVisibilityConfig
): ProfilePresentationDataElement[] => {
  if (!data) return [];

  const guestPersonalFieldConfig =
    settings?.fieldsConfiguration?.guestPersonalDetailsObject;
  const guestAlternateFieldConfig =
    settings?.fieldsConfiguration?.guestAlternateNameObject;
  const additionalInfoFieldConfig =
    settings?.fieldsConfiguration?.additionalInformationObject;

  const {
    firstName,
    lastName,
    birthday,
    middleName,
    preferredLanguageProfileDetails,
    nationality,
    suffix,
    title,
    additionalNames,
  } = data.profile.personalDetails || {};

  const companyName = data.reservation.linkedCompany?.name;
  const paymentMethods = data.account?.paymentMethods || [];
  const paymentTypes = paymentMethods
    .map((method) => {
      return [
        method.transactionCode?.parentTransactionCode?.description,
        method.transactionCode?.description,
      ]
        .filter(isDefined)
        .join(' - ');
    })
    .filter(Boolean);

  const configurationShortDateFormat =
    settings?.shortDateFormat || DEFAULT_DATE_FORMAT;

  const parsedDateFormat = !birthday?.year
    ? configurationShortDateFormat.replace(/[^\w]?[yY]+[^\w]?/g, '')
    : configurationShortDateFormat;

  const birthdayDay =
    birthday &&
    DateManager.getFormattedDateWithConditionalYear(
      {
        ...birthday,
        month: birthday.month - 1,
      },
      parsedDateFormat
    );

  const altName =
    preferredLanguageProfileDetails &&
    [
      guestAlternateFieldConfig?.firstNameField?.isVisible
        ? preferredLanguageProfileDetails.firstname
        : undefined,
      guestAlternateFieldConfig?.lastNameField?.isVisible
        ? preferredLanguageProfileDetails.lastname
        : undefined,
    ]
      .filter(isDefined)
      .join(' ');

  const secondSurname = additionalNames?.find(
    (name) => name.type === AdditionalNamesTypes.SecondSurname
  );

  return [
    {
      sectionData: [
        guestPersonalFieldConfig?.titleField?.isVisible
          ? {
              value: title?.description,
              label: i18next.t(
                'COMPONENTS.PERSONAL_DETAILS_SECTION.PERSON_TITLE'
              ),
            }
          : undefined,
        guestPersonalFieldConfig?.lastNameField?.isVisible
          ? {
              value: lastName,
              label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.LAST_NAME'),
            }
          : undefined,
        guestPersonalFieldConfig?.secondSurnameField?.isVisible
          ? {
              value: secondSurname?.value,
              label: i18next.t(
                'COMPONENTS.PERSONAL_DETAILS_SECTION.SECOND_SURNAME'
              ),
            }
          : undefined,
        guestPersonalFieldConfig?.firstNameField?.isVisible
          ? {
              value: firstName,
              label: i18next.t(
                'COMPONENTS.PERSONAL_DETAILS_SECTION.FIRST_NAME'
              ),
            }
          : undefined,
        guestPersonalFieldConfig?.middleNameField?.isVisible
          ? {
              value: middleName,
              label: i18next.t(
                'COMPONENTS.PERSONAL_DETAILS_SECTION.MIDDLE_NAME'
              ),
            }
          : undefined,
        guestAlternateFieldConfig?.isVisible
          ? {
              value: altName,
              label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.ALT_NAME'),
            }
          : undefined,
        guestPersonalFieldConfig?.suffixField?.isVisible
          ? {
              value: suffix?.description,
              label: i18next.t('COMPONENTS.PERSONAL_DETAILS_SECTION.SUFFIX'),
            }
          : undefined,
        guestPersonalFieldConfig?.dateOfBirthField?.isVisible
          ? {
              value: birthdayDay,
              label: i18next.t(
                'COMPONENTS.PERSONAL_DETAILS_SECTION.DATE_OF_BIRTH'
              ),
            }
          : undefined,
        guestPersonalFieldConfig?.nationalityField?.isVisible
          ? {
              value: nationality?.name,
              label: i18next.t(
                'COMPONENTS.PERSONAL_DETAILS_SECTION.NATIONALITY'
              ),
            }
          : undefined,
        additionalInfoFieldConfig?.companyNameField?.isVisible
          ? {
              value: companyName,
              label: i18next.t(
                'COMPONENTS.PERSONAL_DETAILS_SECTION.COMPANY_NAME'
              ),
            }
          : undefined,
        additionalInfoFieldConfig?.paymentTypeField?.isVisible
          ? {
              value: paymentTypes,
              label: i18next.t(
                'COMPONENTS.PERSONAL_DETAILS_SECTION.PAYMENT_TYPE'
              ),
              isInSeparateLine: true,
            }
          : undefined,
      ].filter(isDefined),
    },
  ];
};
