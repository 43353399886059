import get from 'lodash.get';

import {
  FieldState,
  ValidationResult,
  ValidationStatuses,
  Validator,
} from '@ac/react-infrastructure';

import { BaseObject } from 'types/shared';
import { ValidationStatusesTree } from 'types/validationStatusesTree';

import { isValidationResult } from './formErrors';

export class FormValidator<
  P extends BaseObject,
  T extends ValidationStatuses = ValidationStatusesTree<P>
> extends Validator<P, T> {
  public validate = (data: P): T => {
    return super.validate(data);
  };

  public validateSingleField = <DataT>(
    _value: DataT,
    allValues: P,
    metaData: Partial<FieldState<DataT>>
  ): undefined | ValidationResult => {
    const path = metaData.name?.includes('.')
      ? metaData.name.split('.')
      : metaData.name;

    if (!path || !metaData.name) return;

    const statusesTree = super.validate(allValues, path);
    this.isValid = !this.checkIsInvalid(statusesTree);

    return get(statusesTree, metaData.name) as undefined | ValidationResult;
  };

  public getInvalidFieldsPaths = (): string | unknown[] | undefined => {
    return this.preparePathOfInvalidField(this.statusesTree);
  };

  private preparePathOfInvalidField = (
    errors: T,
    parentKey?: string
  ): string | unknown[] | undefined => {
    if (!errors) return undefined;
    if (isValidationResult(errors)) {
      return parentKey;
    }

    const errorsArray = Object.entries(errors);

    return errorsArray
      .reduce((path, [key, statuses]) => {
        if (statuses) {
          path.push(
            this.preparePathOfInvalidField(
              statuses as T,
              `${parentKey ? parentKey + '.' : ''}${key}`
            )
          );
        }

        return path;
      }, [] as unknown[])
      .flat(Infinity);
  };

  private checkIsInvalid = (statuses: ValidationStatuses): boolean => {
    return Object.values(statuses).some((status) => {
      if (!status) return false;
      if (Array.isArray(status)) {
        return Boolean(status.length);
      }
      if (status && typeof status === 'object') {
        return this.checkIsInvalid(status);
      }

      return false;
    });
  };
}
