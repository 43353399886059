export interface RawKioskPricingEntity {
  net?: number;
  gross?: number;
  currency?: string;
  isConfidential?: boolean;
}

export class KioskPricingEntity {
  public net?: number;
  public gross?: number;
  public currency?: string;
  public isConfidential?: boolean;

  constructor(raw: RawKioskPricingEntity) {
    this.net = raw.net;
    this.gross = raw.gross;
    this.currency = raw.currency;
    this.isConfidential = raw.isConfidential;
  }
}
