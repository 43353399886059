import React from 'react';
import { useTranslation } from 'react-i18next';

import './TestEnvironmentBar.scss';

interface TestEnvironmentBarProps {
  dataTestSelector?: string;
}

export const TestEnvironmentBar = ({
  dataTestSelector,
}: TestEnvironmentBarProps): JSX.Element => {
  const [t] = useTranslation();

  return (
    <div
      className="test-environment-bar-component"
      data-test-selector={dataTestSelector}
    >
      {t('COMPONENTS.TEST_ENV_BAR.TITLE')}
    </div>
  );
};
