var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <li>\n      <p class=\"enhancement-item\">\n        "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "\n      </p>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<style>\n  .enhancement-list {\n    padding-inline-start: 16px !important;\n    margin: 0;\n  }\n\n  .enhancement-item {\n    font-size: 11px;\n    color: var(--black-color);\n    margin: 0 0 8px 0;\n  }\n</style>\n\n<ul class=\"enhancement-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":2},"end":{"line":21,"column":11}}})) != null ? stack1 : "")
    + "</ul>\n";
},"useData":true});