import { KioskProfile } from 'api/KioskApi/entries';
import {
  EntitiesSettingsStorage,
  GeneralSettingsStorage,
} from 'store/settings/interfaces';
import { FormValues } from 'views/RegistrationCardEditPersonal/types';

import { prepareAddressesInitialValues } from './prepareAddressesInitialValues';
import { prepareDocumentsInitialValues } from './prepareDocumentsInitialValues';
import { prepareEmailsInitialValues } from './prepareEmailsInitialValues';
import { preparePersonalDetailsInitialValues } from './preparePersonalDetailsInitialValues';
import { preparePhonesInitialValues } from './preparePhonesInitialValues';

export const prepareInitialValues = (
  profileData: KioskProfile,
  businessDate: string,
  settings?: GeneralSettingsStorage,
  entities?: EntitiesSettingsStorage
): FormValues => {
  const {
    personalDetails,
    addresses,
    emails,
    mobiles,
    phones,
    identityDocuments,
  } = profileData;

  return {
    personalDetails: preparePersonalDetailsInitialValues(
      personalDetails,
      settings
    ),

    ...(settings?.DISPLAY_ADDRESS && {
      addresses: prepareAddressesInitialValues(addresses, entities),
    }),

    ...(settings?.DISPLAY_EMAIL && {
      emails: prepareEmailsInitialValues(emails, entities),
    }),

    ...(settings?.DISPLAY_MOBILE && {
      mobiles: preparePhonesInitialValues(mobiles, entities),
    }),

    ...(settings?.DISPLAY_PHONE && {
      phones: preparePhonesInitialValues(phones, entities),
    }),

    documents: prepareDocumentsInitialValues(identityDocuments, businessDate),
  };
};
