import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  AlignItems,
  Badge,
  Button,
  ButtonPattern,
  Flex,
  IconTypes,
  JustifyContent,
  Text,
  TextWeight,
} from '@ac/kiosk-components';

import './SubFormActionHeader.scss';

interface SubFormActionHeaderProps {
  title: string;
  isMain?: boolean;
  deleteControlEnabled?: boolean;
  mainControlEnabled?: boolean;
  setAsMainButtonDisabled?: boolean;
  dataTestSelector?: string;
  className?: string;
  onSetAsMainClick?: () => void;
  onRemoveClick?: () => void;
}

export const SubFormActionHeader = ({
  title,
  isMain,
  deleteControlEnabled,
  mainControlEnabled,
  setAsMainButtonDisabled,
  dataTestSelector,
  className,
  onSetAsMainClick,
  onRemoveClick,
}: SubFormActionHeaderProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Flex
      dataTestSelector={dataTestSelector}
      alignItems={AlignItems.center}
      justifyContent={JustifyContent.spaceBetween}
      className={classNames('sub-form-action-header', className)}
    >
      <Text
        weight={TextWeight.bold}
        dataTestSelector={dataTestSelector?.concat('-title')}
      >
        {title}
      </Text>

      {mainControlEnabled && (
        <>
          {isMain ? (
            <Badge
              dataTestSelector={dataTestSelector?.concat('-main-badge')}
              className="spacing-inline-start-sm spacing-inline-end-auto"
            >
              {t('REGISTRATION_CARD_EDIT_PERSONAL.SHARED.MAIN_BADGE')}
            </Badge>
          ) : (
            <Button
              pattern={ButtonPattern.tertiary}
              dataTestSelector={dataTestSelector?.concat('-set-as-main-button')}
              className="spacing-inline-start-auto spacing-inline-end-xxlg"
              onClick={onSetAsMainClick}
              disabled={setAsMainButtonDisabled}
            >
              {t('REGISTRATION_CARD_EDIT_PERSONAL.SHARED.SET_AS_MAIN')}
            </Button>
          )}
        </>
      )}

      {deleteControlEnabled && (
        <Button
          dataTestSelector={dataTestSelector?.concat('-remove-button')}
          pattern={ButtonPattern.tertiary}
          icon={IconTypes.trash}
          onClick={onRemoveClick}
        />
      )}
    </Flex>
  );
};
