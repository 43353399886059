import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DATE_PICKER_DATE_FORMAT } from '@ac/kiosk-components';

import { GuestPersonalDetailsSection } from 'store/settings/interfaces/settingTypes/sectionConfiguration';
import { getPropertyConfiguration } from 'store/settings/selectors';
import { CompareDateType, createDateCompareValidator } from 'utils/form';
import {
  ConfigurationFieldsMap,
  DefaltValidationRules,
  SubFormFieldsConfiguration,
  useSubFormFieldsConfiguration,
} from 'views/RegistrationCardEditPersonalV2/hooks/useSubFormFieldsConfiguration';
import {
  FormProperties,
  FormValues,
  PersonalDetailsFormProperties,
  PersonalDetailsFormValues,
} from 'views/RegistrationCardEditPersonalV2/types';

const CONFIGURATION_TO_FORM_FIELD_MAP: ConfigurationFieldsMap<
  keyof GuestPersonalDetailsSection,
  PersonalDetailsFormValues
> = {
  dateOfBirthField: PersonalDetailsFormProperties.birthday,
  firstNameField: PersonalDetailsFormProperties.firstName,
  lastNameField: PersonalDetailsFormProperties.lastName,
  middleNameField: PersonalDetailsFormProperties.middleName,
  nationalityField: PersonalDetailsFormProperties.nationality,
  secondSurnameField: PersonalDetailsFormProperties.secondSurname,
  suffixField: PersonalDetailsFormProperties.suffix,
  titleField: PersonalDetailsFormProperties.title,
};

type PersonalDetailsFieldsConfigurationResult = SubFormFieldsConfiguration<
  FormValues,
  PersonalDetailsFormValues,
  [FormProperties.personalDetails]
>;

export const usePersonalDetailsFieldsConfiguration = (): PersonalDetailsFieldsConfigurationResult => {
  const propertyConfiguration = useSelector(getPropertyConfiguration);
  const getDocumentsSubFormConfiguration = useSubFormFieldsConfiguration<
    FormValues,
    PersonalDetailsFormValues,
    [FormProperties.personalDetails]
  >('guestPersonalDetailsObject', [FormProperties.personalDetails]);

  const defaultValidationRules = useMemo(
    (): DefaltValidationRules<FormValues, PersonalDetailsFormValues> => ({
      [PersonalDetailsFormProperties.birthday]: [
        createDateCompareValidator(
          'VALIDATION.INCORRECT_DATE',
          propertyConfiguration?.businessDate,
          CompareDateType.isSameOrAfter,
          DATE_PICKER_DATE_FORMAT
        ),
      ],
    }),
    [propertyConfiguration?.businessDate]
  );

  return getDocumentsSubFormConfiguration(
    CONFIGURATION_TO_FORM_FIELD_MAP,
    defaultValidationRules
  );
};
