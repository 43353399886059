import {
  FontFamilyCode,
  FontFamilyCodesMap,
} from '../interfaces/settingTypes/fontFamilyCodes';

export const getFontFamily = (fontCode?: FontFamilyCode): string => {
  const fontFamilyName = fontCode && FontFamilyCodesMap[fontCode];

  return [
    `"${fontFamilyName || FontFamilyCodesMap.OPENSANS}"`,
    'Roboto',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'sans-serif',
    'Arial',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(',');
};
