import { ApiError } from '@ac/library-api';

import { Store } from 'store/types';

export const getUpdateProfileState = (state: Store): boolean =>
  state.registrationCardEditPersonalView.fetching.updateProfile;

export const getRegistrationCardEditFetchingErrors = (
  state: Store
): Array<ApiError | Error> => {
  return state.registrationCardEditPersonalView.errors;
};
